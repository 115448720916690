import { Separator } from 'components/ui/separator';
import CommentList from 'modules/letsMeet/components/comments/commentList';
import InviteList from 'modules/letsMeet/components/inviteList/inviteList';
import { MeetInfo } from 'modules/letsMeet/components/meetInfo/meetInfo';
import MeetResponse from 'modules/letsMeet/components/meetResponse/meetResponse';
import MeetResponseAfter from 'modules/letsMeet/components/meetResponse/meetResponseAfter';
import { EventTitle } from 'modules/letsMeet/components/receive/eventTitle';
import ReceiveHeader from 'modules/letsMeet/components/receive/receiveHeader';
import ReceiveNavigationBar from 'modules/letsMeet/components/receive/receiveTopBar';
import {
  EventType,
  ReceiveMeetResponseState,
  ReceiveMeetResponseType,
} from 'modules/letsMeet/types/types';
import { useEffect, useState } from 'react';
import { toZonedTime } from 'date-fns-tz';
import { format } from 'date-fns';
import {
  useAddEventCommentMutation,
  useRespondEventMutation,
} from 'services/event.service';
import dayjs from 'dayjs';
import { IEvent } from 'types/event';
import { useAppSelector } from 'hooks/useAppSelector';
import EndedResponseView from 'modules/letsMeet/components/meetResponse/endedResponseView';
import { useNavigate } from 'react-router-dom';

interface ReceiveMeetLaterProps {
  event: IEvent | null;
  refetch?: () => void;
}

const ReceiveMeetLater = (receiveProps: ReceiveMeetLaterProps) => {
  const [state, setState] = useState(ReceiveMeetResponseState.NOT_RESPONDED);

  const user = useAppSelector((state) => state.auth.user);

  const [bottomSheet, setBottomSheet] = useState<boolean>(false);

  const [respondEvent, { data, error, isLoading }] = useRespondEventMutation();
  const [addCommentMutation] = useAddEventCommentMutation();

  const [userResponseStatus, setUserResponseStatus] = useState<number | undefined>(undefined);

  const navigate = useNavigate();

  const onRespond: (response: ReceiveMeetResponseType) => Promise<void> = (
    response: ReceiveMeetResponseType,
  ) => {
    /**
     * Status codes for invitations when a user responds:
     * 1 - Accepted: The user has accepted the invitation.
     * 2 - Rejected: The user has rejected the invitation.
     * 3 - Maybe: The user has responded with a 'maybe' to the invitation.
     */
    // to show loader
    // in here do the api call
    return new Promise(async (res) => {
      // setTimeout(() => {
      if (response === ReceiveMeetResponseType.ACCEPTED) {
        setState(ReceiveMeetResponseState.ACCEPTED);
        await respondEvent({
          eventId: receiveProps?.event?._id ?? '',
          response: 1,
          phoneNumber: user?.phoneNumber ?? '',
        });
      }

      if (response === ReceiveMeetResponseType.DECLINED) {
        setState(ReceiveMeetResponseState.DECLINED);
        await respondEvent({
          eventId: receiveProps?.event?._id ?? '',
          response: 2,
          phoneNumber: user?.phoneNumber ?? '',
        });
      }

      if (response === ReceiveMeetResponseType.MAYBE) {
        setState(ReceiveMeetResponseState.MAYBE);
        await respondEvent({
          eventId: receiveProps?.event?._id ?? '',
          response: 3,
          phoneNumber: user?.phoneNumber ?? '',
        });
      }

      res();
      receiveProps?.refetch && receiveProps?.refetch()
      // }, 2000);
    });
  };

  const getOrdinalIndicator = (day: number) => {
    const j = day % 10;
    const k = day % 100;
    if (j == 1 && k != 11) {
      return 'st';
    }
    if (j == 2 && k != 12) {
      return 'nd';
    }
    if (j == 3 && k != 13) {
      return 'rd';
    }
    return 'th';
  };

  const formatDate = (date: Date, timezone: string) => {
    const zonedDate = toZonedTime(date, timezone);
    const dayOfMonth = format(date, 'd');
    const ordinalIndicator = getOrdinalIndicator(Number(dayOfMonth));
    return `${format(date, 'EEE, MMM')} ${dayOfMonth}${ordinalIndicator}`;
  };

  const formatDateToLocalTimezoneOnly = (
    dateString: string,
    timezone: string,
  ) => {
    // Convert the UTC date string to a Date object
    const date = new Date(dateString);
    // Convert the date to the desired timezone
    const zonedDate = toZonedTime(date, timezone);
    return zonedDate;
  };

  const formatDateToLocalTimezone = (
    dateString: string,
    timezone: string,
    formatStr: string,
  ) => {
    // Convert the UTC date string to a Date object
    const date = new Date(dateString);
    // Convert the date to the desired timezone
    const zonedDate = toZonedTime(date, timezone);
    // Format the date to get only the date part in YYYY-MM-DD format
    const formattedDate = format(zonedDate, formatStr);
    return formattedDate;
  };

  const getUserTimezone = () => {
    // eslint-disable-next-line new-cap
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  };

  const startDateStr =
    (receiveProps.event?.start as unknown as string) ??
    new Date().toDateString(); // TODO: We need to declare date types as strings in IEvent
  const endDateStr =
    (receiveProps.event?.end as unknown as string) ?? new Date().toDateString();

  const formattedStart = formatDateToLocalTimezoneOnly(
    startDateStr,
    getUserTimezone(),
  );

  const startTime = formatDateToLocalTimezone(
    startDateStr,
    getUserTimezone(),
    'h:mma',
  );
  const endTime = formatDateToLocalTimezone(
    endDateStr,
    getUserTimezone(),
    'h:mma',
  );

  const checkEnded = () => {
    const formattedEnd = formatDateToLocalTimezoneOnly(
      endDateStr,
      getUserTimezone(),
    );
    const eventEndTime = dayjs.tz(formattedEnd);
    const currentTime = dayjs.tz(
      formatDateToLocalTimezoneOnly(new Date().toString(), getUserTimezone()),
    );

    console.log('eventEndTime', eventEndTime);
    console.log('currentTime', currentTime);

    if (eventEndTime.isBefore(currentTime)) {
      setState(ReceiveMeetResponseState.EXPIRED);
    }
  };

  useEffect(() => {

    if (receiveProps.event?.contacts) {
      // filter current user
      // NOTE: Updating the current filter to filter data from phonenumber
      const currentUser = receiveProps.event.contacts.find(
        (contact) => 
          (contact as any).phoneNumber === user?.phoneNumber
      );

      const status = currentUser?.status;

      setUserResponseStatus(status);

      if (status === 1) {
        setState(ReceiveMeetResponseState.ACCEPTED);
      } else if (status === 2) {
        setState(ReceiveMeetResponseState.DECLINED);
      }
    }

    checkEnded();

    const endCheck = setInterval(() => {
      checkEnded();
    }, 1000 * 60);

    return () => {
      clearInterval(endCheck);
    };
  }, [receiveProps]);

  // TODO: Need to understand if we want to show invitees that are actually going or the invites that were sent
  const attendingInvitees =
    receiveProps.event?.contacts
      ?.filter((contact) => contact.status === 1)
      .map((contact) => contact.name) ?? [];

  return (
    <>
      <div className="bg-header rounded-b-md w-full sticky top-0 z-10">
        <ReceiveNavigationBar event={receiveProps.event} />
        <ReceiveHeader
          expired={state === ReceiveMeetResponseState.EXPIRED}
          by={{
            name: `${receiveProps.event?.organizer.split(' ')[0]}`,
            id: `${receiveProps.event?.userId}`,
            photoURL: `${receiveProps.event?.organizerPhoto || ''}`,
          }}
          emoji={receiveProps.event?.emoji ?? ''}
          contacts={receiveProps.event?.contacts as any}
          type="later"
          date={formatDate(
            receiveProps.event?.start ?? new Date(),
            getUserTimezone(),
          )}
          start={startTime}
          end={endTime}
          event={receiveProps.event}
        />
      </div>

      <div className="px-8 ">
        <EventTitle
          title={receiveProps.event?.activity ?? ''}
          by={receiveProps.event?.organizer.split(' ')[0] ?? ''}
          type={EventType.MEET_LATER}
        />
        <Separator className="bg-white/20" />

        <div className={'my-6'}>
          <MeetInfo
            withPadding
            type="now-no-time"
            end={receiveProps.event?.end}
            location={receiveProps.event?.location ?? ''}
            details={receiveProps.event?.details}
          />
        </div>

        <InviteList title="Who’s going" list={attendingInvitees}  eventId={receiveProps.event?._id}/>

        <CommentList
          event={receiveProps}
          refetchEvent={receiveProps.refetch}
        />
      </div>

      <div
        className={`mt-2 fixed w-full ${bottomSheet ? 'bottom-0' : 'bottom-[-250px]'}`}
      >
        {state === ReceiveMeetResponseState.EXPIRED ? (          
          <EndedResponseView
            onSubmit={() =>navigate('/lets-meet')}
          />          
        ): state === ReceiveMeetResponseState.NOT_RESPONDED ? (
          <MeetResponse
            onSubmit={onRespond}
            event={receiveProps?.event}
            state={state}
            setState={setState}
            bottomSheet={bottomSheet}
            setBottomSheet={setBottomSheet}
            setUserResponseStatus={setUserResponseStatus}
            userResponseStatus={userResponseStatus}
          />
        ) : (
          <MeetResponseAfter
            type={EventType.MEET_NOW}
            status={state}
            bottomSheet={bottomSheet}
            setBottomSheet={setBottomSheet}
            onRespondChange={() => {
              setState(ReceiveMeetResponseState.NOT_RESPONDED);
            }}
          />
        )}
      </div>
    </>
  );
};

export default ReceiveMeetLater;
