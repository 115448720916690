import { CalendarWeekly } from 'modules/home/components/calendarWeekly';
import BgGradient from 'modules/home/components/gradient';
import ViewListHeader from 'modules/letsMeet/components/viewListHeader/ViewListHeader';

const HomeWeekly = () => {
  return (
    <div className={'p-5 text-white h-[100dvh] flex flex-col items-center relative'}>
      {/* header */}
      <div className="w-full">
        <ViewListHeader />
      </div>

      {/* calendar container */}
      <CalendarWeekly />

      <BgGradient />
    </div>
  );
};

export default HomeWeekly;
