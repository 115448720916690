// disable eslint for this file
/* eslint-disable */

import { AxiosError } from 'axios';

export class CustomApiError extends AxiosError {
  displayMessage: string;

  constructor(
    error: AxiosError,
    action: 'create' | 'read' | 'update' | 'delete' | undefined = undefined,
    resource:
      | 'user'
      | 'event'
      | 'timeslots'
      | 'notification'
      | 'availabilities'
      | undefined = undefined,
  ) {
    super();
    this.displayMessage =
      action && resource
        ? `An error occurred while ${action === 'create' ? 'creating' : action === 'delete' ? 'deleting' : action === 'read' ? 'getting' : action == 'update' ? 'updating' : ''} ${resource ?? ''}`
        : this.generateErrorMessage(error);
  }

  generateErrorMessage = (error: AxiosError) => {
    const code = error?.response?.status;

    switch (code) {
      case 400:
        return 'Your request is invalid!';
      case 401:
        return 'Your request is unauthorized!';
      case 403:
        return 'You are not allowed to access this resource!';
      case 404:
        return 'We did not find what you are looking for!';
      case 405:
        return 'Request method not allowed!';
      case 406:
        return 'Not Acceptable: The resource is not available in a format that matches request.';
      case 407:
        return 'Proxy Authentication Required: You must authenticate with a proxy server before this request can be served.';
      case 408:
        return 'Request Timeout: Your request took too long to process.';
      case 409:
        return 'You are already in!';
      case 410:
        return 'Gone: The resource you are looking for is no longer available.';
      case 411:
        return "Length Required: The 'Content-Length' header was not specified.";
      case 412:
        return 'Precondition Failed: The server does not meet one of the preconditions that the requester put on the request.';
      case 413:
        return 'Payload Too Large: The request is larger than the server is willing or able to process.';
      case 414:
        return 'URI Too Long: The URI provided was too long for the server to process.';
      case 415:
        return 'Unsupported Media Type: The format of the request is not supported by the server.';
      case 416:
        return 'Range Not Satisfiable: The requested range cannot be satisfied.';
      case 417:
        return 'Expectation Failed: The server cannot meet the requirements of the Expect request-header field.';
      case 500:
        return 'Internal Server Error: Something went wrong on our end!';
      case 501:
        return 'Not Implemented: The server does not recognize the request method.';
      case 502:
        return 'Bad Gateway: The server received an invalid response.';
      case 503:
        return 'Service Unavailable: Please try again later.';
      case 503:
        return 'Service Unavailable: Please try again later.';
      case 504:
        return 'Gateway Timeout: The server did not receive a timely response.';
      case 505:
        return 'HTTP Version Not Supported: The server does not support the HTTP protocol version used in the request.';
      default:
        return 'Something went wrong!';
    }
  };
}
