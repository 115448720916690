import { Separator } from 'components/ui/separator';
import CommentList from 'modules/letsMeet/components/comments/commentList';
import InviteList from 'modules/letsMeet/components/inviteList/inviteList';
import { MeetInfo } from 'modules/letsMeet/components/meetInfo/meetInfo';
import MeetResponse from 'modules/letsMeet/components/meetResponse/meetResponse';
import MeetResponseAfter from 'modules/letsMeet/components/meetResponse/meetResponseAfter';
import { EventTitle } from 'modules/letsMeet/components/receive/eventTitle';
import ReceiveHeader from 'modules/letsMeet/components/receive/receiveHeader';
import ReceiveNavigationBar from 'modules/letsMeet/components/receive/receiveTopBar';
import {
  EventType,
  ReceiveMeetResponseState,
  ReceiveMeetResponseType,
} from 'modules/letsMeet/types/types';
import { useEffect, useState } from 'react';
import { toZonedTime } from 'date-fns-tz';
import { format } from 'date-fns';
import {
  useAddEventCommentMutation,
  useRespondEventMutation,
} from 'services/event.service';
import dayjs from 'dayjs';
import { IEvent } from 'types/event';
import { useAppSelector } from 'hooks/useAppSelector';
import EndedResponseView from 'modules/letsMeet/components/meetResponse/endedResponseView';
import { useNavigate } from 'react-router-dom';

// #endregion
interface ReceiveMeetNowProps {
  event: IEvent | null;
  refetch?: () => void;
}
const ReceiveMeetNow = (receiveProps: ReceiveMeetNowProps) => {
  const [state, setState] = useState(ReceiveMeetResponseState.NOT_RESPONDED);

  const user = useAppSelector((state) => state.auth.user);

  const [bottomSheet, setBottomSheet] = useState<boolean>(false);

  const [respondEvent, { data, error, isLoading }] = useRespondEventMutation();

  const [addCommentMutation] = useAddEventCommentMutation();

  const [userResponseStatus, setUserResponseStatus] = useState<number | undefined>(undefined);

  const navigate = useNavigate();

  const onRespond: (response: ReceiveMeetResponseType) => Promise<void> = (
    response: ReceiveMeetResponseType,
  ) => {
    /**
     * Status codes for invitations when a user responds:
     * 1 - Accepted: The user has accepted the invitation.
     * 2 - Rejected: The user has rejected the invitation.
     * 3 - Maybe: The user has responded with a 'maybe' to the invitation.
     */
    // to show loader
    // in here do the api call
    return new Promise(async (res) => {
      if (response === ReceiveMeetResponseType.ACCEPTED) {
        setState(ReceiveMeetResponseState.ACCEPTED);
        await respondEvent({
          eventId: receiveProps?.event?._id ?? '',
          response: 1,
          phoneNumber: user?.phoneNumber ?? '',
        });
      }

      if (response === ReceiveMeetResponseType.DECLINED) {
        setState(ReceiveMeetResponseState.DECLINED);
        await respondEvent({
          eventId: receiveProps?.event?._id ?? '',
          response: 2,
          phoneNumber: user?.phoneNumber ?? '',
        });
      }

      if (response === ReceiveMeetResponseType.MAYBE) {
        setState(ReceiveMeetResponseState.MAYBE);
        await respondEvent({
          eventId: receiveProps?.event?._id ?? '',
          response: 3,
          phoneNumber: user?.phoneNumber ?? '',
        });
      }

      res();
      receiveProps?.refetch && receiveProps?.refetch()
    });
  };

  const startDateStr =
    (receiveProps.event?.start as unknown as string) ??
    new Date().toDateString(); // TODO: We need to declare date types as strings in IEvent
  const endDateStr =
    (receiveProps.event?.end as unknown as string) ?? new Date().toDateString();

  const checkEnded = () => {
    const formattedStart = formatDateToLocalTimezoneOnly(
      endDateStr,
      getUserTimezone(),
    );
    const eventEndTime = dayjs.tz(formattedStart);
    const currentTime = dayjs.tz(
      formatDateToLocalTimezoneOnly(new Date().toString(), getUserTimezone()),
    );

    if (eventEndTime.isBefore(currentTime)) {
      setState(ReceiveMeetResponseState.EXPIRED);
    }
  };

  useEffect(() => {

    if (receiveProps.event?.contacts) {
      // filter current user
      // NOTE: Updating the current filter to filter data from phonenumber
      const currentUser = receiveProps.event.contacts.find(
        (contact) => 
          (contact as any).phoneNumber === user?.phoneNumber
      );

      const status = currentUser?.status;
      console.log('status', status);

      setUserResponseStatus(status);

      if (status === 1) {
        setState(ReceiveMeetResponseState.ACCEPTED);
      } else if (status === 2) {
        setState(ReceiveMeetResponseState.DECLINED);
      }
    }
    
    checkEnded();

    const endCheck = setInterval(() => {
      checkEnded();
    }, 1000 * 60);

    return () => {
      clearInterval(endCheck);
    };
  }, [receiveProps]); // TODO: What exactly are we checking here?

  const formatDateToLocalTimezone = (
    dateString: string,
    timezone: string,
    formatStr: string,
  ) => {
    // Convert the UTC date string to a Date object
    const date = new Date(dateString);
    // Convert the date to the desired timezone
    const zonedDate = toZonedTime(date, timezone);
    // Format the date to get only the date part in YYYY-MM-DD format
    const formattedDate = format(zonedDate, formatStr);
    return formattedDate;
  };

  // TODO: Refactor this and other formatDateToLocalTimezone functions to a single function
  const formatDateToLocalTimezoneOnly = (
    dateString: string,
    timezone: string,
  ) => {
    // Convert the UTC date string to a Date object
    const date = new Date(dateString);
    // Convert the date to the desired timezone
    const zonedDate = toZonedTime(date, timezone);
    return zonedDate;
  };

  const getUserTimezone = () => {
    // eslint-disable-next-line new-cap
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  };

  const attendingInvitees =
    receiveProps.event?.contacts
      ?.filter((contact) => contact.status === 1)
      .map((contact) => contact.name) ?? [];

  useEffect(() => {
    console.log('state', state);
  }, [state]);

  return (
    <>
      <div className="bg-header rounded-b-md w-full sticky top-0 z-10">
        <ReceiveNavigationBar event={receiveProps?.event} />

        <ReceiveHeader
          expired={state === ReceiveMeetResponseState.EXPIRED}
          by={{
            name: `${receiveProps.event?.organizer.split(' ')[0]}`,
            id: `${receiveProps.event?.userId}`,
            photoURL: `${receiveProps.event?.organizerPhoto || ''}`,
          }}
          emoji={receiveProps.event?.emoji || '📖'}
          contacts={receiveProps.event?.contacts as any} // TODO: Fix contacts type
          type="now"
          start={formatDateToLocalTimezone(
            startDateStr,
            getUserTimezone(),
            'h:mma',
          )}
          end={formatDateToLocalTimezone(
            endDateStr,
            getUserTimezone(),
            'h:mma',
          )}
          event={receiveProps.event}
        />
      </div>

      <div className="px-8">
        <EventTitle
          title={receiveProps.event?.activity ?? ''}
          by={receiveProps.event?.organizer.split(' ')[0] ?? ''}
          type={EventType.MEET_NOW}
        />

        <Separator className="bg-white/20" />

        <div className={'my-6'}>
          <MeetInfo
            withPadding
            type="now-no-time"
            end={receiveProps.event?.end}
            location={receiveProps.event?.location ?? ''}
            details={receiveProps.event?.details}
          />
        </div>

        <InviteList
          title={
            state === ReceiveMeetResponseState.EXPIRED
              ? `Who were invited`
              : `Who’s going`
          }
          list={attendingInvitees}
          eventId={receiveProps.event?._id}
        />

        <CommentList
          event={receiveProps} // TODO: Remove any type from event in CommentList
          refetchEvent={receiveProps.refetch}
        />
      </div>

      <div
        className={`mt-auto fixed w-full ${bottomSheet ? 'bottom-0' : 'bottom-[-220px]'}`}
      >
        {state === ReceiveMeetResponseState.EXPIRED ? (          
          <EndedResponseView
            onSubmit={() =>navigate('/lets-meet')}
          />          
        ) : state === ReceiveMeetResponseState.NOT_RESPONDED ? (
          <MeetResponse
            onSubmit={onRespond}
            event={receiveProps?.event}
            state={state}
            setState={setState}
            bottomSheet={bottomSheet}
            setBottomSheet={setBottomSheet}
            userResponseStatus={userResponseStatus}
            setUserResponseStatus={setUserResponseStatus}
          />
        ) : (
          <MeetResponseAfter
            type={EventType.MEET_NOW}
            status={state}
            bottomSheet={bottomSheet}
            setBottomSheet={setBottomSheet}
            onRespondChange={() => {
              setState(ReceiveMeetResponseState.NOT_RESPONDED);
            }}
          />
        )}
      </div>
    </>
  );
};

export default ReceiveMeetNow;
