import { Text } from 'components/text/primary/Text';
import AvailabilityEmptyList from './emptyList';
import { ReactComponent as ClockIcon } from 'assets/images/activity/clock.svg';
import { Link } from 'react-router-dom';
import { IActivityPoll } from 'modules/activity/types/types';
import React, {
  ReactElement,
  JSXElementConstructor,
  ReactNode,
  ReactPortal,
  Key,
} from 'react';
import ProfileAvatar from 'components/avatar/ProfileAvatar';

interface IPoll {
  icon: string;
  body:
    | string
    | number
    | boolean
    | ReactElement<any, string | JSXElementConstructor<any>>
    | Iterable<ReactNode>
    | ReactPortal
    | null
    | undefined;
  title: string;
}
type ActivityPollProps = {
  polls: any;
};

const ActivityPolls: React.FC<ActivityPollProps> = ({ polls }) => {
  return (
    <div className="mt-3">
      <Text size="medium" className="py-1">
        Availability Polls
      </Text>

      <div className="my-2.5 flex gap-2.5 flex-col">
        {polls?.length === 0 ? (
          <AvailabilityEmptyList
            text="You have not received any invites yet"
            icon={<ClockIcon />}
          />
        ) : (
          polls?.map(
            (
              poll: {
                icon: string | undefined;
                body:
                  | string
                  | number
                  | boolean
                  | ReactElement<any, string | JSXElementConstructor<any>>
                  | Iterable<ReactNode>
                  | ReactPortal
                  | null
                  | undefined;
                title: any;
              },
              index: Key | null | undefined,
            ) => (
              <div
                key={index}
                className="flex py-3.5 px-2.5 gap-3 justify-between bg-box rounded-md"
              >
                <div className="flex items-center flex-row gap-2">
                  <div className="flex-shrink-0">
                    {/* <img
                              src={poll.icon}
                              alt={"Profile"}
                              className="w-10 h-10 rounded-full"
                          /> */}
                    <ProfileAvatar size={45} url={poll?.icon} hideBoarder />
                  </div>
                  <div className="text-sm max-w-60">
                    <span>{poll.body}</span>
                  </div>
                </div>
                <div className="flex items-center">
                  <Link
                    to={`/lets-meet/receive/${poll.title}`}
                    className="text-sm text-link underline"
                  >
                    Respond
                  </Link>
                </div>
              </div>
            ),
          )
        )}
      </div>
    </div>
  );
};

type PollProps = {
  poll: IActivityPoll;
};
const Poll: React.FC<PollProps> = ({ poll }) => {
  return (
    <div className="flex py-3.5 px-2.5 gap-3 justify-between bg-box rounded-md">
      <div className="flex items-center flex-row gap-2">
        <div className="flex-shrink-0">
          <img
            src={poll.by.photoURL}
            alt={poll.by.name}
            className="w-10 h-10 rounded-full"
          />
        </div>
        <div className="text-sm max-w-60">
          <span className="font-bold ">{poll.by.name}</span>wants to know when
          you are free for a <span className="font-bold ">{poll.title}</span>
        </div>
      </div>
      <div className="flex items-center">
        <Link
          to={`/lets-meet/view/${poll.id}`}
          className="text-sm text-link underline"
        >
          Respond
        </Link>
      </div>
    </div>
  );
};

export default ActivityPolls;
