import { cn } from 'utils/helpers';
import { ReceiveMeetResponseType } from 'modules/letsMeet/types/types';

type ResponseButtonProps = {
  onClick: () => void;
  selected: boolean;
  variant?: 'primary' | 'secondary';
  title: string;
  icon?: React.ReactNode;
  disabled?: boolean;
  className?: string;
};

const ResponseButton: React.FC<ResponseButtonProps> = (props) => {
  const variant: ResponseButtonProps['variant'] = props.variant ?? 'primary';

  return (
    <div
      className={cn(
        'flex justify-center items-center bg-[#DFC9FF] text-[#6000E9] py-2.5 mx-8 rounded-full gap-1.5 my-1.5 cursor-pointer',
        variant === 'secondary' && 'bg-transparent text-white underline my-2.5',
        props.selected && 'bg-secondary text-white',
        props.className??'',
      )}
      onClick={!props.disabled?props.onClick:undefined}
    >
      {props.icon && (
        <div
          className={cn(
            'h-4 w-4 flex items-center justify-center rounded-full bg-[#AC79F6]',
            props.selected && '[&>svg>path]:fill-white',
          )}
        >
          {props.icon}
        </div>
      )}

      <div className="font-medium leading-none">{props.title}</div>
    </div>
  );
};

export default ResponseButton;
