import { FC } from 'react';
import { cn } from 'utils/helpers';

interface ActionButtonProps {
  label: string;
  onClick: () => void;
  variant?: 'primary' | 'secondary';
  className?: string;
}

const ActionButton: FC<ActionButtonProps> = ({
  label,
  onClick,
  variant = 'primary',
  className,
}) => {
  const baseStyles =
    'px-6 py-2 rounded-full text-md font-semibold focus:outline-none w-full';
  const primaryStyles = 'bg-red-500 hover:bg-red-600 text-black';
  const secondaryStyles = 'bg-transparent text-white border border-white';

  const buttonStyles = `${baseStyles} ${variant === 'primary' ? primaryStyles : secondaryStyles}`;

  return (
    <button className={cn(buttonStyles, className)} onClick={onClick}>
      {label}
    </button>
  );
};

export default ActionButton;
