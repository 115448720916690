import caleidoBaseApi from 'services/apiClient';
import {
  AccessAndRefreshTokens,
  IUserResponse,
  IUserWithTokens,
} from 'modules/auth/types/types';

const AuthService = {
  register: (data: { email: string; password: string }) => {
    return caleidoBaseApi.post<IUserWithTokens>('/auth/register', data);
  },
  login: (data: { email: string; password: string }) => {
    return caleidoBaseApi.post<IUserWithTokens>('/auth/login', data);
  },
  refreshTokens: (refreshToken: string) => {
    return caleidoBaseApi.post<AccessAndRefreshTokens>('/auth/refresh-tokens', {
      refreshToken,
    });
  },

  whoAmI: () => {
    return caleidoBaseApi.get<IUserResponse>(`/auth/whoami`, {
      headers: {
        'Cache-Control': 'no-cache',
      },
    });
  },
  logout: () => {
    return caleidoBaseApi.post('/auth/logout', {
      refreshToken: localStorage.getItem('refreshToken'),
    });
  },
};

export { AuthService };
