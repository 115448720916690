import { forwardRef, type InputHTMLAttributes } from 'react';
import { Text } from 'components/text/primary/Text';
import { cn } from 'utils/helpers';

interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  className?: string;
  required?: boolean;
  error?: boolean | string;
  endAdornment?: React.ReactNode;
}

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  ({ label, className, required, error, endAdornment, ...props }, ref) => {
    return (
      <div className="relative flex flex-col w-full my-2">
        <div className="flex items-center justify-start space-x-2 mb-2">
          <Text size="small" className="text-base font-sofia-normal">
            {label}
          </Text>
          {required && <Text size="small">*</Text>}
        </div>
        <div className="flex relative">
          <input
            type="text"
            className={cn(
              'w-full max-w-sm mx-auto font-sans text-sm font-medium text-gray-300  rounded-full bg-[#32395E] disabled:opacity-30 focus:outline-none px-4 py-2.5 font-sofia-light', 
              className,
              `${error ? 'border-red-600' : 'border-slate-600 border-opacity-0'}`,
            )}
            ref={ref}
            required={required}
            autoComplete="off"
            {...props}
          />
          {endAdornment && (
            <div className="absolute right-4 h-full flex items-center">
              {endAdornment}
            </div>
          )}
        </div>
        {error && (
          <Text size="extra-small" type="error" className="mt-1.5">
            {error}
          </Text>
        )}
      </div>
    );
  },
);

TextField.displayName = 'TextField';

export default TextField;
