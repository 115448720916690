import React from 'react';
import { includes, xor } from 'lodash';
import { Text } from 'components/text/primary/Text';
import { cn } from 'utils/helpers';
import { daysOfWeek } from 'constants/common';

const dayKeys = Object.keys(daysOfWeek);

type WeekdaySelectorProps = {
  value: string[];
  onChange: (selectedDays: string[]) => void;
};

const WeekdaySelector: React.FC<WeekdaySelectorProps> = ({
  value,
  onChange,
}) => {
  const handleDayClick = (day: string) => {
    const newSelectedDays = xor(value, [day]);
    onChange(newSelectedDays);
  };

  return (
    <div className="flex flex-nowrap justify-center gap-x-1.5 gap-y-2  xs:gap-x-3 pt-2 pb-4 mb-2 mt-6 max-w-full">
      {dayKeys.map((dayKey) => {
        const day = daysOfWeek[dayKey];
        return (
          <button
            key={dayKey}
            onClick={() => handleDayClick(dayKey)}
            className={cn(
              'flex items-center justify-center text-white rounded-full h-10 w-10 focus:outline-none',
              includes(value, dayKey)
                ? 'bg-secondary'
                : 'bg-transparent border border-white',
            )}
          >
            <Text size="small">{day.prefix}</Text>
          </button>
        );
      })}
    </div>
  );
};

export default WeekdaySelector;
