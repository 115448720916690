import {useNavigate} from 'react-router-dom';
import {ChevronLeft} from 'lucide-react';
import OnboardingLayout from 'modules/auth/layouts/AuthLayout';
import {cn} from 'utils/helpers';
import {Text} from 'components/text/primary/Text';
import {ReactComponent as CaleidoLogoIcon} from 'assets/images/common/caleido.svg';

const About = () => {
    const navigate = useNavigate();
    return (
        <OnboardingLayout className={cn('justify-start', 'overflow-y-auto pb-12')}>
            <div className="w-full max-w-xs mt-8 min-w-64">
                <ChevronLeft className="cursor-pointer" onClick={() => navigate(-1)}/>
            </div>
            <div className="w-full max-w-xs my-8 mb-6 min-w-64">
                <CaleidoLogoIcon className={"mb-4"}/>
                <Text size="medium">About Caleido</Text>
                <div className="my-4 text-left">
                    <Text size="small" className="mb-2.5 font-semibold">
                        Viverra at et eu sed auctor rhoncus
                    </Text>
                    <Text size="small" className="mb-2">
                        Lorem ipsum dolor sit amet consectetur. Id massa sapien urna tempor suspendisse. Nibh dictum
                        imperdiet condimentum sagittis sed etiam mollis mi. Etiam tortor phasellus commodo enim. At
                        volutpat enim lacus elementum erat ultricies vivamus luctus nisl. Justo volutpat orci eleifend
                        turpis eget lacus euismod cras mattis. Purus rutrum varius sit augue eget eu porta. Viverra at
                        et eu sed auctor rhoncus suspendisse sollicitudin pellentesque. Gravida consequat in risus
                        cursus turpis aenean feugiat massa amet.
                    </Text>
                    <Text size="small" className="mb-2">
                        A aliquet nisl pellentesque fusce ut tortor volutpat laoreet amet. Sagittis eget integer lacus
                        non gravida elementum gravida ornare ultricies. Mattis integer risus amet porta sit suscipit ac
                        dui. Volutpat.
                    </Text>
                </div>
            </div>
        </OnboardingLayout>
    );
};

export default About;
