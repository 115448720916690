import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Check } from 'lucide-react';
import { Text } from 'components/text/primary/Text';
import {
  useGoogleCalEventsMutation,
  useGoogleCalTokenCheckQuery,
} from 'services/calendar.service';
import MainButton from 'modules/auth/components/MainButton';

type SyncCalenderProps = {
  setPrevOrSkip: () => void;
  setError: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const SyncCalender = forwardRef<(() => void) | null, SyncCalenderProps>(
  ({ setPrevOrSkip,setError }, ref) => {
    useImperativeHandle(ref, () => handleSubmit);

    const [triggerFetchEvents, { data: events }] = useGoogleCalEventsMutation();

    // const {data: authUrl, error: authUrlError, isLoading: authUrlIsLoading} = useGoogleCalAuthQuery();
    const authUrl = process.env.REACT_APP_GOOGLE_AUTH_URL;

    const [connecting, setConnecting] = useState(false);

    const { data: token, refetch: refetchToken } =
      useGoogleCalTokenCheckQuery();
    
    useEffect(() => {
      if (events) {
        refetchToken();
        setConnecting(false);
      }
      // hide errors when events and token are fetched
      if(events && token && Object.keys(events).length && Object.keys(token).length){
        setError(undefined);
      }

    }, [events, refetchToken]);

    // submit handler
    const handleSubmit = () => {
      if(events && token && Object.keys(events).length && Object.keys(token).length){
        setPrevOrSkip();
      }else{
        setError(`Want to skip this step? press "Skip" on the right corner`);
      }
    };

    useEffect(() => {
      return ()=>{setError(undefined)};
    },[]);

    const handleSignInClick = async () => {
      // popup auth window
      if (!authUrl) {
        return;
      }

      const authWindow = window.open(authUrl, '_blank', 'width=500,height=800');

      // check if the window is closed
      const interval = setInterval(() => {
        if (authWindow?.closed) {
          clearInterval(interval);
          setConnecting(true);
          triggerFetchEvents();
        }
      }, 1000);
    };

    return (
      <div className="flex flex-col items-center w-full gap-2">
        <div className="flex flex-col w-full gap-1">
          <Text size="large">Sync your calendar</Text>
          <Text size="small" className="text-sm font-sofia-extralight">
            Allows you to import your Google Calendar time blocks and push
            Caleido invites!
          </Text>

          {token?.token ? (
            <div
              onClick={handleSignInClick}
              className="w-full px-4 py-4 mt-8 bg-white border-0 rounded-2xl bg-opacity-10"
            >
              <div>
                <img
                  src="/google_icon.png"
                  alt="Google Icon"
                  width={30}
                  height={30}
                  className={'inline mr-1'}
                />
                <Text size="small" className={'inline'}>
                  Google Calendar
                </Text>
              </div>

              <hr className={'mt-2 mb-2 opacity-30'} />

              <div className={'flex justify-between items-center'}>
                {connecting ? (
                  <>
                    <div>
                      <p className={'text-xs inline'}>Connecting...</p>
                    </div>
                  </>
                ) : (
                  <>
                    <p className={'font-bold text-xs'}>{token?.email}</p>
                    <div>
                      <Check
                        className={'inline mr-1 text-green-400'}
                        size={16}
                      />
                      <p className={'text-xs inline text-green-400'}>
                        Connected
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
          ) : (
            <MainButton
              onClick={handleSignInClick}
              type="secondary"
              className="flex items-center justify-between w-full px-8 py-6 mt-8 bg-white border-0 rounded-full bg-opacity-10"
            >
              <div>
                <img
                  src="/google_icon.png"
                  alt="Google Icon"
                  width={30}
                  height={30}
                  className={'inline mr-1'}
                />
                <Text size="small" className={'inline'}>
                  {connecting ? `Connecting...` : `Sync Google Calendar`}
                </Text>
              </div>
            </MainButton>
          )}
        </div>
      </div>
    );
  },
);

SyncCalender.displayName = 'SyncCalender';

export default SyncCalender;
