import React, { ReactNode, useState } from 'react';
import { Transition } from '@headlessui/react';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@radix-ui/react-tabs';
import { ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';
import { Text } from 'components/text/primary/Text';
import { cn } from 'utils/helpers';

import styles from './mobileInstructions.module.css';

type MobileInstructionsTabsProps = {
  title?: ReactNode;
  iphoneInstructions: ReactNode[];
  androidInstructions: ReactNode[];
  className?: string;
};

const MobileInstructionsTabs: React.FC<MobileInstructionsTabsProps> = ({
  title,
  iphoneInstructions,
  androidInstructions,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(title ? false : true);
  const [activeTab, setActiveTab] = useState<'iphone' | 'android'>('iphone');

  const handleTabChange = (newValue: 'iphone' | 'android') => {
    setActiveTab(newValue);
  };

  const List = ({ children }: { children: ReactNode }) => (
    <li className="my-4 text-sm font-sofia-extralight text-white">
      {children}
    </li>
  );

  return (
    <div className={cn('w-full max-w-md mx-auto mt-1 mb-12', className)}>
      {title && (
        <button
          className="flex items-center justify-between w-full px-4 bg-transparent focus:outline-none"
          onClick={() => setIsOpen(!isOpen)}
        >
          {title}
          {isOpen ? (
            <ChevronUpIcon color="#fff" />
          ) : (
            <ChevronDownIcon color="#fff" />
          )}
        </button>
      )}
      <Transition
        show={isOpen}
        enter="transition-all duration-300"
        enterFrom="max-h-0 opacity-0"
        enterTo="max-h-screen opacity-100"
        leave="transition-all duration-300"
        leaveFrom="max-h-screen opacity-100"
        leaveTo="max-h-0 opacity-0"
      >
        <div className="px-4 mt-3 bg-transparent">
          <Tabs value={activeTab}>
            <TabsList className={styles.tabsContainer}>
              <TabsTrigger
                value="iphone"
                className={cn('py-2 text-left', {
                  [styles.active]: activeTab === 'iphone',
                })}
                onClick={() => handleTabChange('iphone')}
              >
                <Text size="small" className="py-1">
                  iPhone
                </Text>
              </TabsTrigger>
              <TabsTrigger
                value="android"
                className={cn('py-2 text-left', {
                  [styles.active]: activeTab === 'android',
                })}
                onClick={() => handleTabChange('android')}
              >
                <Text size="small" className="py-1">
                  Android
                </Text>
              </TabsTrigger>
            </TabsList>

            <TabsContent value="iphone">
              <ol className="list-decimal list-outside">
                {iphoneInstructions.map((instruction, index) => (
                  <List key={index}>{instruction}</List>
                ))}
              </ol>
            </TabsContent>
            <TabsContent value="android">
              <ol className="list-decimal list-outside">
                {androidInstructions.map((instruction, index) => (
                  <List key={index}>{instruction}</List>
                ))}
              </ol>
            </TabsContent>
          </Tabs>
        </div>
      </Transition>
    </div>
  );
};

export default MobileInstructionsTabs;
