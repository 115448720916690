import React, { useState, useEffect, useRef, ReactNode } from 'react';
import { debounce } from 'lodash';
import { Text } from 'components/text/primary/Text';

type ResendTimerProps = {
  initialTimer: number;
  onResend: () => void;
  loading: boolean;
};

type ResendBodyPops = {
  children: ReactNode;
  onClick?: React.MouseEventHandler<HTMLHeadingElement> | undefined;
};

const ResendBody: React.FC<ResendBodyPops> = ({ children, onClick }) => {
  return (
    <Text
      onClick={onClick}
      size="small"
      className="underline cursor-pointer decoration-solid"
    >
      {children}
    </Text>
  );
};

const ResendTimer: React.FC<ResendTimerProps> = ({
  initialTimer,
  onResend,
  loading,
}) => {
  const [timer, setTimer] = useState(initialTimer);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (timer > 0) {
      timerRef.current = setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    } else {
      clearTimeout(timerRef.current!);
    }

    return () => {
      clearTimeout(timerRef.current!);
    };
  }, [timer]);

  const handleResendClick = debounce((event) => {
    event.preventDefault();
    if (loading) return;
    setTimer(initialTimer);
    onResend();
  }, 1000);

  if (loading) {
    return <Text size="small">Sending OTP</Text>;
  }

  return (
    <>
      {timer > 0 ? (
        <ResendBody>Resend code in {timer}s</ResendBody>
      ) : (
        <ResendBody onClick={handleResendClick}>Resend OTP</ResendBody>
      )}
    </>
  );
};

export default ResendTimer;
