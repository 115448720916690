import { Calendar } from 'components/ui/calendar';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { DateSelectorProps } from './DateSelector.types';

const DateSelector = ({
  disableFutureDates,
  disablePastDates,
  disabledDates,
  onSelectedDatesChange,
  selectedValues,
  onDateClick,
  onMonthChange,
}: DateSelectorProps) => {
  const toDate = new Date();

  const [selectedDates, setSelectedDates] = useState<Date[] | undefined>(
    selectedValues ?? [toDate],
  );

  useEffect(() => {
    if (onSelectedDatesChange) {
      onSelectedDatesChange(selectedDates ?? []);
    }
  }, [selectedDates]);

  useEffect(() => {
    setSelectedDates(selectedValues);
  }, [selectedValues]);

  return (
    <Calendar
      toDate={disablePastDates ? toDate : undefined}
      fromDate={disableFutureDates ? toDate : undefined}
      disabled={(date) => {
        if (typeof disabledDates === 'function') {
          return disabledDates(date);
        } else if (disabledDates) {
          return disabledDates.includes(date);
        }
        return false;
      }}
      onMonthChange={(date) => {
        if (onMonthChange) onMonthChange(date);
      }}
      className={'bg-white/20 text-white font-normal w-[325px] '}
      mode="default"
      selected={selectedDates}
      onDayClick={(date) => {
        if (onDateClick) onDateClick(date);
        return;
        // toggle date selection (use in future if needed)
        const isDateSelected = selectedDates?.some((selectedDate) =>
          format(selectedDate, 'yyyy-MM-dd').includes(
            format(date, 'yyyy-MM-dd'),
          ),
        );
        if (isDateSelected) {
          setSelectedDates((prev) =>
            prev?.filter(
              (selectedDate) =>
                !format(selectedDate, 'yyyy-MM-dd').includes(
                  format(date, 'yyyy-MM-dd'),
                ),
            ),
          );
        } else {
          setSelectedDates((prev) => [...(prev ?? []), date]);
        }
      }}
      classNames={{
        nav_button: 'text-white  border-none w-4 h-4 font-bold',
        caption_label: 'uppercase font-semibold',
        head_cell: 'text-white font-medium text-[10px] mx-auto pt-3 uppercase',
        cell: 'text-white font-normal rounded-full mx-auto active:text-[#6000E9] font-sofia-normal',
        head: 'border-t border-[#BDBDBD]/20 mt-3',
      }}
      styles={{
        button: {
          borderRadius: '100%',
          fontSize: '13px',
          fontWeight: '500',
          position: 'relative',
        },
        nav_button_previous: {
          left: '0',
          position: 'absolute',
        },
        nav_button_next: {
          right: '0',
          position: 'absolute',
        },
      }}
      modifiersClassNames={{
        today: 'border-[#6000E9] border-[1px] bg-[#6000E9]',
        selected:
          'after:bg-[#D7C0FB] after:w-1 after:h-1 after:rounded-full after:absolute after:bottom-0 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2',
        disabled: 'text-[#c2c2c2]',
        outside: 'text-[#585858]',
      }}
      modifiersStyles={{
        selected: {
          // backgroundColor: '#6000E9',
        },
        outside: {
          color: '#585858',
        },
        disabled: {
          color: '#c2c2c2',
        },
      }}
    />
  );
};

export default DateSelector;
