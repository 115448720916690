import { useEffect, useState } from 'react';

// identifies whether in a real mobile device
function useIsMobileIOS() {
  const [isMobileIOS, setIsMobileIOS] = useState(false);
  useEffect(() => {
    const platform = navigator.platform;
    // NOTE: use if needed to identify mobile devices
    // const isMobile =/Linux armv7l|Linux aarch64|CrOS x86_64|Mobile|iPhone|iPad|iPod|Android|WinCE|WinPhone|Linux/.test(platform);
    // setIsMobileOS(isMobile);
    const isIOS = /iPhone|iPad|iPod/.test(platform);
    setIsMobileIOS(isIOS);
  }, []);
  return isMobileIOS;
}

export default useIsMobileIOS;
