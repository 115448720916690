import { RootState } from 'app/store';
import { CalendarDateSelector } from 'modules/home/components/calendarDateSelector';
import { CalendarEventDayCard } from 'modules/home/components/calendarEventDayCard';
import {
  EventObject,
  TimeOffObject,
} from 'components/eventCardItem/EventCardItem.types';
import { format, isValid, parseISO } from 'date-fns';
import { useAppSelector } from 'hooks/useAppSelector';
import { CalendarEvent } from 'modules/home/types/CalendarEvent';
import { CalendarManualBlock } from 'modules/home/types/CalendarManualBlock';
import { RBCSelectedSlot } from 'modules/home/types/RBCSelectedSlot';
import {
  filterDuplicateCalendarEvents,
  getDayStartAndEnd,
  getSearchParams,
  mapToCalendarEVents,
} from 'modules/home/utils/helpers';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './calendarDaily.css';

import 'react-big-calendar/lib/css/react-big-calendar.css';

import { BigCalendarContainer } from 'modules/home/components/bigCalendarContainer';
import { useApiCall } from 'hooks/axiosHooks';
import { calendarDailyFormatters } from 'modules/home/utils/utils';
import { useLocation } from 'react-router-dom';
import { CalendarDailyProps } from './CalendarDaily.types';
import {
  determineVariant,
  generateTimeOffsForUserAvailability,
} from 'utils/calendarUtils';
import { IAvailability } from 'modules/profile/types/types';
import { useGetGoogleCalendarBlocksQuery } from 'services/slices/calendarApiSlice';
import { GCalendarBlock } from 'modules/home/types/GCalendarBlock';

// localizer for the calendar
const localizer = momentLocalizer(moment);

const dateToday = new Date();

// define custom components
const customComponents = {
  // custom event component
  event: (props: { event: CalendarEvent; title: string }) => {
    const eventVariantValue = props?.event?.variant === 'blocked' ? 'blocked' : props?.event?.variantData?.isPending
    ? 'response'
    : determineVariant(props.event?.variantData)

    return (
      <CalendarEventDayCard
        id={props.event.id}
        variant={eventVariantValue}
        eventData={props.event?.eventData!}
        variantData={props.event?.variantData!}
      />
    );
  },
  week: {
    // custom week header with date labels
    header: (props: any) => {
      const { label, date, isOffRange } = props;
      return (
        <div
          style={{}}
          className="flex flex-col items-center h-auto space-y-[10px] w-[33px]"
        >
          <div className="text-white text-[8px] font-sofia-extralight leading-normal">
            {format(date, 'EEE')}
          </div>
          <div className="rounded-full w-8 h-8 flex justify-center items-center text-[13.8px] date-label-number">
            <span>{format(date, 'dd')}</span>
          </div>
        </div>
      );
    },
  },
};

// define formatters
const formatters = calendarDailyFormatters;

const CalendarDaily = ({ queryDate }: CalendarDailyProps) => {
  // get search params.
  const location = useLocation();
  const searchParams = getSearchParams(location.search ?? '');
  const filterEventId = decodeURIComponent(searchParams?.id ?? '');
  // NOTE: selected date is passed by parent as queryDate
  const focusToEventId = searchParams?.ref;

  // get user from store
  const user = useAppSelector((state: RootState) => state.auth.user);

  // selected date
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  // default date for calendar
  const [defaultDate, setDefaultDate] = useState<Date>(() =>
    queryDate
      ? (() => {
          const parsed = parseISO(queryDate);
          return isValid(parsed) ? parsed : new Date();
        })()
      : new Date(),
  );

  // selected event for view slider
  const [selectedViewEvent, setSelectedViewEvent] =
    useState<CalendarEvent | null>(null);

  // add manual time block
  const [addManualBlockData, setAddManualBlockData] =
    useState<CalendarManualBlock | null>(null);

  const [GCBlocks, setGCBlocks] = useState<GCalendarBlock[] | undefined>(
    undefined,
  );

  // initial data loaded flag
  const initialDataLoadedRef = useRef(false);

  // calendar ref
  const calendarRef = useRef<HTMLDivElement>(null);

  // initially set to null and then set to trigger scrolling
  useEffect(() => {
    setSelectedDate((prev) =>
      queryDate
        ? (() => {
            const parsed = parseISO(queryDate);
            return isValid(parsed) ? parsed : new Date();
          })()
        : new Date(),
    );
  }, []);

  // calculate date range for api queries
  const { endOfDay, startOfDay } = getDayStartAndEnd(
    selectedDate ?? defaultDate,
  );

  // handle selected date change
  const handleSelectedEventDateChange = (date: Date) => {
    setSelectedDate((prev) => date);
  };

  // handle event select in calendar
  const handleEventSelect = (event: CalendarEvent) => {
    if (event.variant === 'blocked' && !event.isDeletable) return;
    if (!addManualBlockData) setSelectedViewEvent((prev) => event);
  };

  // handle slot selecting start
  const handleSlotSelecting = (slotData: unknown) => {
    const slotInfo = slotData as RBCSelectedSlot;
    if (!selectedViewEvent)
      setAddManualBlockData((prev) => ({
        start: new Date(slotInfo.start),
        end: new Date(slotInfo.end),
        title: '',
      }));
    return false;
  };

  // --- API Binding ---

  // get events of the day
  const {
    data: events,
    refetch: refetchEvent,
    isFetching: isFetchingEvents,
    isLoading: isLoadingEvents,
  } = useApiCall();

  // get time offs of the day
  const {
    data: timeOffs,
    refetch: refetchTimeOffs,
    isFetching: isFetchingBlocks,
    isLoading: isLoadingBlocks,
  } = useApiCall();

  // get google events
  const {
    data: GCBlocksData,
    isFetching: GCBlocksIsFetching,
    isLoading: GCBlocksIsLoading,
    refetch: refetchGCBlocks,
    isError: GCBlocksError,
  } = useGetGoogleCalendarBlocksQuery({
    startDate: startOfDay.toISOString(),
    endDate: endOfDay.toISOString(),
  });

  useEffect(() => {
    if (GCBlocksData) {
      setGCBlocks(GCBlocksData);
    } else if (GCBlocksError) {
      setGCBlocks([]);
    }
  }, [GCBlocksData, GCBlocksError]);

  // TOOD: load pending RSVP of the day

  const loadEvents = () => {
    refetchEvent(
      `event/range?startDate=${startOfDay.toISOString()}&endDate=${endOfDay.toISOString()}`,
    );
  };

  const loadTimeOffs = () => {
    refetchTimeOffs(
      `time-off?startDate=${startOfDay.toISOString()}&endDate=${endOfDay.toISOString()}`,
    );
  };

  // refetch events on date change
  useEffect(() => {
    if (selectedDate) {
      loadEvents();
      loadTimeOffs();
      refetchGCBlocks();
    }
  }, [selectedDate]);

  // format events for display
  const displayEventList: CalendarEvent[] = useMemo(
    () =>
      filterDuplicateCalendarEvents([
        ...mapToCalendarEVents(
          (events ?? []) as EventObject[],
          timeOffs as TimeOffObject,
          GCBlocks,
        ),
        ...generateTimeOffsForUserAvailability(
          selectedDate,
          user && (user.availability as IAvailability),
        ),
      ]),
    [events, timeOffs, GCBlocks],
  );

  // initial data load handler
  const handleInitialDataLoad = () => {
    if (focusToEventId) {
      setTimeout(() => {
        const element = document.getElementById(focusToEventId);
        element &&
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 50);
    } else if (filterEventId) {
      setTimeout(() => {
        const el = document.getElementById(filterEventId);
        if (el) {
          el.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 200);
    } else {
      setTimeout(() => {
        const currentTimeElement = calendarRef.current?.querySelector(
          '.rbc-current-time-indicator',
        );
        if (currentTimeElement) {
          currentTimeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      }, 50);
    }
  };

  // initial data load effect
  useEffect(() => {
    if (events && timeOffs && GCBlocks && !initialDataLoadedRef.current) {
      handleInitialDataLoad();
      initialDataLoadedRef.current = true;
    }
  }, [events, timeOffs, GCBlocks]);

  return (
    <>
      <BigCalendarContainer
        addManualBlockData={addManualBlockData}
        selectedViewEvent={selectedViewEvent}
        onDrawerClose={() => {
          setAddManualBlockData((prev: any) => null);
          setSelectedViewEvent((prev) => null);
        }}
        onDrawerActionComplete={(variant) => {
          variant == 'event' && loadEvents();
          variant == 'timeOff' && loadTimeOffs();
        }}
        origin="daily"
        variant="daily"
      >
        <CalendarDateSelector
          onSelectedDateChange={handleSelectedEventDateChange}
          selectedDate={selectedDate ?? defaultDate}
        />
        <div className="mt-4 overflow-y-scroll w-full mb-7">
          <div
            className="h-full block w-100 bg-transparent text-white px-0 calendar-daily-container"
            ref={calendarRef}
          >
            <Calendar
              localizer={localizer}
              events={displayEventList}
              startAccessor="start"
              endAccessor="end"
              defaultDate={defaultDate}
              date={selectedDate ?? defaultDate}
              defaultView={'day'}
              toolbar={false}
              formats={formatters}
              step={15}
              timeslots={4}
              onSelectEvent={handleEventSelect}
              onSelectSlot={handleSlotSelecting}
              // onSelecting={handleSlotSelecting}
              components={customComponents}
              selectable={true}
              // longPressThreshold={200}
              // scrollToTime={dateToday}
              style={{ height: '150vh' }}
            />
          </div>
        </div>
        <div className="w-full text-[8px] relative">
          <span className="absolute top-[-20px] w-full block text-center font-sofia-ultralight">
            * Single tap or tap and drag to add a busy block
          </span>
        </div>
      </BigCalendarContainer>
    </>
  );
};

export default CalendarDaily;
