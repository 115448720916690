import BottomNav, { BottomNavType } from 'components/bottomNav/bottomNav';
import { DateSelectorDropDown } from 'components/dateSelectorDropDown';
import { EventFilterDropDown } from 'components/eventFilterDropDown';
import { AllEventPagination } from 'components/eventPagination/allEventsPagination';
import { DraftEventPagination } from 'components/eventPagination/draftEventsPagination';
import { MyEventPagination } from 'components/eventPagination/myEventsPagination';
import { PendingEventPagination } from 'components/eventPagination/pendingEventsPagination';
import { cn } from 'utils/helpers';
import BgGradient from 'modules/home/components/gradient';
import { getSearchParams } from 'modules/home/utils/helpers';
import ViewListHeader from 'modules/letsMeet/components/viewListHeader/ViewListHeader';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { EventType } from 'types/event';
import './HomeSummary.css';
import { useAppSelector } from 'hooks/useAppSelector';
import { RootState } from 'app/store';

const HomeSummary = () => {
  // today's date object
  const dateToday = useMemo(() => new Date(), []);

  // get search params
  const location = useLocation();
  const searchParams = getSearchParams(location.search ?? '');

  const user = useAppSelector((state: RootState) => state.auth.user);

  // decode filter from url
  const decodedFilter = decodeURIComponent(
    searchParams?.filter ?? 'All Events',
  );

  // focus event id from url
  const focusToEventId = searchParams?.ref;

  // selected event type
  const [selectedEventType, setSelectedEventType] = useState<
    EventType | undefined
  >('All Events');

  // set selected event type from url
  useEffect(() => {
    setSelectedEventType((prev) =>
      decodedFilter ? (decodedFilter as EventType) : undefined,
    );
  }, [decodedFilter]);

  // selected dates from date picker
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);

  return (
    <>
      <div className={`m-5 text-white`}>
        {/* header */}
        <ViewListHeader sticky />

        {/* event filter selectors */}
        <div
          className={cn(
            ' bg-[#FFFFFF]/[0.1] py-[8px] pl-[10px] pr-[6px] rounded-sm my-3 flex justify-between  backdrop-blur-[30px] sticky top-[75px] z-10',
          )}
        >
          <DateSelectorDropDown
            onDateChange={(date) => {
              setSelectedDate((prev) => date);
            }}
          />

          <EventFilterDropDown selectedEventType={selectedEventType} />
        </div>

        {/* event display */}
        {user && (
          <div className="space-y-2 grid grid-cols-1 gap-y-1 relative min-h-[calc(100vh-300px)]">
            {selectedEventType === 'All Events' ? (
              <AllEventPagination
                selectedDates={selectedDate ? [selectedDate] : []}
                selectedEventType={selectedEventType}
                focusToEventId={focusToEventId}
              />
            ) : selectedEventType === 'My Events' ? (
              <MyEventPagination
                selectedDates={selectedDate ? [selectedDate] : []}
                selectedEventType={selectedEventType}
              />
            ) : selectedEventType == 'Draft Events' ? (
              <DraftEventPagination
                selectedDates={selectedDate ? [selectedDate] : []}
                selectedEventType={selectedEventType}
              />
            ) : selectedEventType == 'Pending RSVPs' ? (
              <PendingEventPagination
                selectedDates={selectedDate ? [selectedDate] : []}
                selectedEventType={selectedEventType}
              />
            ) : (
              <></>
            )}
          </div>
        )}
        {/* bottom navigation */}
        <BottomNav type={BottomNavType.HOME} />

        <BgGradient />
      </div>
    </>
  );
};

export default HomeSummary;
