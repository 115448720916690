import { CalendarConfirmDialogProps } from './CalendarConfirmDialog.types';

const CalendarConfirmDialog = (props: CalendarConfirmDialogProps) => {
  const { cancelText, confirmText, description, onClose, onConfirm, title } =
    props;
  return (
    <div className="w-full h-full pb-8">
      <div className="px-8 flex flex-col items-center mt-7 justify-start space-x-2 text-white text-center">
        <h1 className="font-sofia-medium text-white text-center text-[20px]">
          Are you sure you want to delete the selected time block?
        </h1>
        <p className="text-base font-sofia-extralight mt-3">
          You cannot undo this action after you confirm.
        </p>
        <div className="flex justify-center space-x-3 font-sofia-normal text-sm mt-7">
          <button
            onClick={() => {
              onClose && onClose();
            }}
            className="py-2 px-6 bg-[#1F2339] border-[#E0E0E0] border-[1px] rounded-[100px] min-w-[130px]"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              onConfirm && onConfirm();
            }}
            className="py-2 px-6 text-[#060606] bg-[#FF6363] border-[#FF6363] border-[1px] rounded-[100px] min-w-[130px]"
          >
            Yes, Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default CalendarConfirmDialog;
