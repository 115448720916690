import { Navigate } from 'react-router-dom';

type AuthRoutePropType = {
  children: JSX.Element | null;
  isAuthenticated: boolean | undefined;
};

const PrivateRoute = ({
  children,
  isAuthenticated = false,
}: AuthRoutePropType) => {
  if (isAuthenticated) {
    return <>{children}</>;
  } else {
    return <Navigate to={'/auth/start'} />;
  }
};

export default PrivateRoute;
