import caleidoBaseApi from 'services/apiClient';

const SendHelpEmail = {
    sendHelpEmail: (data: {name: string, email: string, message: string}) => {
        return caleidoBaseApi.post<string>('/help/send', data);
    },
};

export const {
    sendHelpEmail,
} = SendHelpEmail;
