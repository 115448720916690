import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import LetsMeetLayout from 'modules/letsMeet/layouts/LetsMeetLayout';
import OverlayLoader from 'components/loader/OverlayLoader';
import { EventType } from 'modules/letsMeet/types/types';
import { Separator } from 'components/ui/separator';
import { MeetInfo } from 'modules/letsMeet/components/meetInfo/meetInfo';
import ViewInviteList from 'modules/letsMeet/components/inviteList/ViewInviteList';
import AvailableTimes from 'modules/letsMeet/components/meetInfo/availableTimes';
import { toast } from 'components/ui/toast/use-toast';
import MainButton from 'modules/auth/components/MainButton';
import { ChevronRight } from 'lucide-react';
import { Text } from 'components/text/primary/Text';
import {
  useAddEventCommentMutation,
  useUpdateEventMutation,
  useShareEventMutation,
} from 'services/event.service';
import EventIcon from 'components/eventIcon/EventIcon';
import { format } from 'date-fns';
import { useAppSelector } from 'hooks/useAppSelector';
import { RootState } from 'app/store';
import axios from 'axios';
import CommentList from 'modules/letsMeet/components/comments/commentList';
import { IEvent } from 'types/event';
import PollResults from 'modules/letsMeet/components/meetResponse/pollResults';
import { Contact } from 'components/eventCardItem/EventCardItem.types';
import { useSendNotificationMutation, useStopNotificationMutation } from 'services/notification.service';
import { NotificationType } from 'modules/letsMeet/utils/enum';
import { useUpdateActivityMutation } from 'services/activity.service';
import { getFormattedSharingText } from 'utils/helpers';

const ViewEvent = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [shareEvent] = useShareEventMutation();
  const [updateEvent] = useUpdateEventMutation();
  const [updateActivity] = useUpdateActivityMutation();
  const user = useAppSelector((state: RootState) => state.auth.user);
  // const { data: event, refetch: refetchEvent, error, isLoading } = useGetEventQuery(id || '');

  const [isLoading, setIsLoading] = useState(true);
  const [event, setEvent] = useState<IEvent | null>(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<number | null>(null); // for poll results

  const [myEvent, setMyEvent] = useState<boolean>(false);
  const [pastEvent, setPastEvent] = useState<boolean>(false);

  const [addCommentMutation] = useAddEventCommentMutation();
  const [sendNotification] = useSendNotificationMutation();
  const [stopNotification] = useStopNotificationMutation();


  // identify if logged in user is viewing the event
  const location = useLocation();
  const pathArray = location.pathname.split('/')
  const isLoggedInUserView = pathArray.length > 2 && pathArray[2] === 'view';
  let isByMe = false;
  if(isLoggedInUserView){
    isByMe = event && user ? event?.userId === user?.id : false;
  }


  useEffect(() => {
    const endDate = new Date(event?.end ?? 0);
    const currentDate = new Date();

    if (endDate < currentDate && event?.type !== 'poll') {
      setPastEvent(true)
    } else {
      setPastEvent(false)
    }
  }, [event]);
    
  useEffect(() => {
    const endDate = new Date(event?.end ?? 0);
    const currentDate = new Date();

    if (endDate < currentDate && event?.type !== 'poll') {
      setPastEvent(true)
    } else {
      setPastEvent(false)
    }

    if (event?.userId === user?.id) {
      setMyEvent(true);
    } else {
      setMyEvent(false);
    }
  },[event,user]);

  // useEffect(() => {
  //     refetchEvent();
  // }, []);

  const fetchEvent = async () => {
    setIsLoading(true);
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/event/share/${id}`)
      .then((response) => {
        console.log(response.data);
        setEvent(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchEvent();
  }, []);

  if (!id) {
    navigate('/lets-meet');
    return null;
  }

  const onShare = async (link: string) => {
    if (!navigator.share) {
      await navigator?.clipboard?.writeText(link);
      toast({
        title: 'Link copied to clipboard',
      });
    } else {
      await navigator.share({
        title: 'Share this event',
        /*
        text: `Your friend ${event?.organizer} invited you to ${event?.activity}! Click the link to join or learn.`,
        */
        text: getFormattedSharingText(user!, event!, link),
        url: link,
      });
    }
  };

  const formatTime = (time: string) => {
    let [hour, minute] = time.split(':').map(Number);
    const period = hour >= 12 ? 'PM' : 'AM';
    hour = hour % 12 || 12;
    return `${hour}:${minute.toString().padStart(2, '0')} ${period}`;
  };

  interface IDateInfo {
    date: Date;
    startTime: Date;
    endTime: Date;
  }
  const getMeetLaterDateInfo = (timeSlot: string) => {
    const [year, month, day, startTime, endTime] = timeSlot.split('-');
    const date = new Date(Number(year), Number(month) - 1, Number(day));
    const startDate = new Date(`${date.toDateString()} ${startTime}`);
    const endDate = new Date(`${date.toDateString()} ${endTime}`);

    return {
      date,
      startTime: startDate,
      endTime: endDate,
    } as IDateInfo;
  };

  const handleSubmit = async () => {
    if (event?.type === EventType.POLL) {
      console.log('new event to be created', event);

      const selectedTime =
        event?.pollData?.checkedTimes[selectedTimeSlot ?? 0].time;
      if (!selectedTime) {
        console.error('Invalid selected time');
        return;
      }
      sessionStorage.setItem('cal-event-converting-poll-event-id', event?._id);
      navigate(`/lets-meet/end-poll/${event?._id}/${selectedTime}`);

      // NOTE: Removed logic to move the functionality screen to EditEvent screen 

      // const dateInfo = getMeetLaterDateInfo(selectedTime);

      // // reset all contacts status to 0, to get all invitees reconfirmation for the event
      // const filteredContacts = event?.contacts?.map(contactData => ({
      //   ...contactData,
      //   status: 0
      // }))

      // const newEvent = {
      //   id: event?._id,
      //   activity: event?.activity,
      //   emoji: event?.emoji,
      //   location: event?.location,
      //   details: event?.details ?? '',
      //   type: 'later',
      //   start: dateInfo.startTime,
      //   end: dateInfo.endTime,
      //   // NOTE: Removed logic where filtering out the organizser from the contact list when poll converting to the meet later evnet
      //   // contacts:
      //   //   event?.contacts?.filter((contact) => contact.organizer === 0) ??
      //   //   ([] as Contact[]),
      //   contacts:
      //     filteredContacts ?? ([] as Contact[]),
      // };

      // console.log('new event to be created', newEvent);
      // updateEvent(newEvent)
      //   .then(async (response) => {
      //     updateActivity({
      //       eventId: event?._id,
      //       type: "later"
      //     })
      //     fetchEvent();
      //     await sendNotificationApi(NotificationType.POLL_TURNED_INTO_DATE)
      //     await sendStopNotificationApi(NotificationType.REMINDER_TO_PICK_DATE);
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //   });

      // createEvent(data)
      // .then((response) => {
      //     setCreateEventIsLoading(false);
      //     const responseId = response.data.message._id;
      //     navigate(`./review/${responseId}`);
      // })
      // .catch((error) => {
      //     setCreateEventIsLoading(false);
      //     console.error(error);
      // });
      // const selectedTime = event?.pollData?.checkedTimes[selectedTimeSlot ?? 0].time;
      // let dateInfo;
      // if (!selectedTime) {
      //    dateInfo = {
      //          date: new Date(),
      //          startTime: new Date(),
      //          endTime: new Date(),
      //    }
      // } else {
      //     dateInfo = getMeetLaterDateInfo(selectedTime);
      // }

      // console.log("dateInfo", dateInfo);
      // // parse the selected time for
      // // Navigate to the event creation page and pass event info
      // navigate('/lets-meet', {
      //     state: {
      //         eventData: event,
      //         dateInfo: dateInfo,
      //     },
      // });
      return;
    }

    // copy sharable link
    await onShare(`${process.env.REACT_APP_CLIENT_URL}/share/${event?._id}`);

    if (event?.publicEvent === false) {
      // send sms notification
      await shareEvent({
        fromName: user?.name.split(' ')[0] ?? '',
        eventName: event?.activity,
        endTime:
          event?.type === 'now'
            ? event?.end
              ? format(new Date(event.end), 'hh:mm a')
              : ''
            : null,
        eventLink: `${process.env.REACT_APP_CLIENT_URL}/share/${event?._id}`,
        to: event?.contacts?.map((contact) => contact.phoneNumber) ?? [],
        messageType:
          event?.type === 'now'
            ? 'MEET_NOW_NOTIFICATION'
            : event?.type === 'later'
              ? 'MEET_LATER_NOTIFICATION'
              : event?.type === 'poll'
                ? 'MEET_POLL_NOTIFICATION'
                : '',
      });
    }
  };

  const sendNotificationApi = async (type: NotificationType) => {
    const notificationData = {
      type,
      eventId: event?._id,
    }
    await sendNotification(notificationData)
  }
  const sendStopNotificationApi = async (type: NotificationType) => {
    const StopNotificationData = {
      notificationType: type,
      eventId: event?._id,
      responderId: user?.id,
    };
    await stopNotification(StopNotificationData)
  }

  if (isLoading) {
    return <OverlayLoader />;
  }
  
  if(isLoggedInUserView && !user){
    return <OverlayLoader />;
  }

  console.log('contacts', event?.contacts);
  const myInvitees =
    event?.contacts?.filter((contact) => contact.organizer === 0) ?? [];
  console.log('My invitees', myInvitees);
  return (
    <LetsMeetLayout
      eventId={id}
      myEvent={myEvent}
      deleteBtn={false}
      widget={'option'}
      actionOnHeaderRight=""
      footerText="Share Invite"
      onSubmit={handleSubmit}
      event={event}
      pastEvent={pastEvent}
    >
      {isLoading && !event ? (
        <OverlayLoader />
      ) : event ? (
        <>
          <main className="flex-grow mb-4 font-sofia">
            <div className="w-full my-5 mb-6">
              <div className="flex gap-2.5 items-center">
                {event.emoji && <EventIcon icon={event.emoji} />}
                <div>
                  <div className="text-xl font-semibold leading-none">
                    {event.activity}
                  </div>
                  <div className="text-sm font-light">
                    Created by{' '}
                    <span className="font-bold leading-none">
                      {isByMe ? 'You' : event.organizer}
                    </span>
                  </div>
                </div>
              </div>

              <Separator className="bg-white/20 my-6" />

              <MeetInfo {...event} />

              {/* {event.type === EventType.POLL && (
                <>
                  <Separator className="bg-white/20 my-6" /> */}
                  {/* <AvailableTimes times={event?.pollData?.checkedTimes.map(t => t.time) ?? []} type="poll" /> */}
                {/* </>
              )} */}

              {/* {!event.publicEvent && ( */}
                <>
                  <ViewInviteList list={myInvitees} eventId={event?._id} />
                  <Separator className="bg-white/20 my-6" />
                </>
              {/* )} */}

              {/* <Collapsible className="w-full">
                                <CollapsibleTrigger className="w-full flex justify-center mb-8 text-sm">
                                    Check what everyone is saying <ChevronDown className="ml-3"/>
                                </CollapsibleTrigger>
                                <CollapsibleContent className="flex justify-center">
                                    No comments yet
                                </CollapsibleContent>
                            </Collapsible> */}
              <CommentList
                event={{ event: event }}
                disabled={pastEvent}
                refetchEvent={fetchEvent}
              />

              {event.type === EventType.POLL && (
                <PollResults
                  pollData={event?.pollData}
                  totalUsers={Math.max(myInvitees.length, 1)}
                  showCreatorView
                  onSelectTimeSlot={(index: number | null) => {
                    setSelectedTimeSlot(index);
                  }}
                />
              )}
            </div>
          </main>
          <footer style={{ marginBottom: 'env(safe-area-inset-bottom)' }} className='pb-4'>
            {event.type === EventType.POLL ? (
              <MainButton
                type="primary"
                onClick={handleSubmit}
                icon={<ChevronRight color="#fff" />}
                disabled={pastEvent || selectedTimeSlot === null}
              >
                <Text size="small">End poll and create invite</Text>
              </MainButton>
            ) : (
              <MainButton
                type="primary"
                onClick={handleSubmit}
                icon={<ChevronRight color="#fff" />}
                disabled={pastEvent}
              >
                <Text size="small">Share invite</Text>
              </MainButton>
            )}
          </footer>
        </>
      ) : (
        <p>Error loading event</p>
      )}
    </LetsMeetLayout>
  );
};

ViewEvent.displayName = 'ViewEvent';
export default ViewEvent;
