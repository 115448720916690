import {
  Drawer as UIDrawer,
  DrawerContent,
  DrawerTrigger,
} from 'components/ui/drawer';
import { cn } from 'utils/helpers';
import styles from './drawer.module.css';

type DrawerProps = {
  className?: string;
  children: React.ReactNode;
  trigger?: React.ReactNode;
  visible?: boolean;
  setVisible?: (visible: boolean) => void;
};

const Drawer: React.FC<DrawerProps> = ({
  className,
  children,
  visible,
  setVisible,
  trigger,
}) => {
  return (
    <UIDrawer open={visible} onOpenChange={setVisible}>
      <DrawerTrigger>{trigger}</DrawerTrigger>
      <DrawerContent className={cn(className, styles.customClass)}>
        {children}
      </DrawerContent>
    </UIDrawer>
  );
};

export default Drawer;
