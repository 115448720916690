import { useEffect, useState } from 'react';

// identifies whether in a real mobile device
function useIsMobileOS() {
  const [isMobileOS, setIsMobileOS] = useState(false);
  useEffect(() => {
    const platform = navigator.platform;
    // NOTE: use if needed to identify mobile devices
    // const isMobile =/Linux armv7l|Linux aarch64|CrOS x86_64|Mobile|iPhone|iPad|iPod|Android|WinCE|WinPhone|Linux/.test(platform);
    // setIsMobileOS(isMobile);
    const isDesktop = /MacIntel|Win32|Linux x86_64|Linux i686/.test(platform);
    setIsMobileOS(!isDesktop);
  }, []);
  return isMobileOS;
}

export default useIsMobileOS;
