import { EventType } from 'modules/letsMeet/types/types';
import React from 'react';

type EventTitleProps = {
  title: string;
  by: string;
  type: EventType;
};

export const EventTitle: React.FC<EventTitleProps> = (props) => {
  return (
    <div className="py-6 text-center ">
      <div className="font-semibold text-xl">{props.title}</div>
      <div className="text-sm font-light">
        {props.type === EventType.MEET_NOW ? 'Created by' : 'Invited by'}{' '}
        <span className="font-medium">{props.by}</span>
      </div>
    </div>
  );
};
