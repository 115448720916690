import React, { useEffect } from 'react';

const useSessionStorage = (key: string, initialValue: unknown) => {
  const [value, setValue] = React.useState(() => {
    const value = sessionStorage.getItem(key);
    return value
      ? JSON.parse(value)
      : typeof initialValue === 'function'
        ? initialValue()
        : initialValue;
  });

  useEffect(() => {
    if (value === null || value === undefined) {
      // fix value disappear when refresh page
      // sessionStorage.removeItem(key);
    } else {
      sessionStorage.setItem(key, JSON.stringify(value));
    }
  }, [key, value]);

  return [value, setValue];
};

export default useSessionStorage;
