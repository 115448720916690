import React from 'react';

type ToggleSwitchProps = {
  isChecked: boolean;
  onChange: (checked: boolean) => void;
  className?: string;
};

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  isChecked,
  onChange,
  className,
}) => {
  return (
    <label className={`inline-flex items-center cursor-pointer ${className}`}>
      <input
        type="checkbox"
        className="sr-only peer"
        checked={isChecked}
        onChange={(event) => onChange(event.target.checked)}
      />
      <div className="relative w-9 h-5 bg-gray-400 bg-opacity-40 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-blue-950 after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-secondary"></div>
    </label>
  );
};

export default ToggleSwitch;
