import { Button } from 'components/ui/button';
import { format } from 'date-fns';
import { useAppSelector } from 'hooks/useAppSelector';
import { ChevronLeft } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { IEvent } from 'types/event';

type ReceiveTopBarProps = {
  event?: IEvent | null;
};

const ReceiveNavigationBar: React.FC<ReceiveTopBarProps> = (props) => {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.auth.user);

  const eventId = props.event?._id;
  const eventDate = props.event?.start
    ? format(new Date(props.event.start), 'yyyy-MM-dd')
    : '';
  return (
    <header className="flex flex-row justify-between items-center w-full text-[#060606] px-5 py-4">
      <div className="">
        {user&&<ChevronLeft
          className="cursor-pointer text-black"
          onClick={() => {
              user?navigate('/home'):navigate('/auth/login');
          }}
        />}
      </div>
      <Button className="text-sm !no-underline" variant="link">
        <Link
          to={
            eventDate
              ? `/home/daily?date=${eventDate}${eventId ? `&id=${eventId}` : ''}`
              : '#'
          }
        >
          View in Calendar
        </Link>
      </Button>
    </header>
  );
};

export default ReceiveNavigationBar;
