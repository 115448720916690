import { useNavigate, useParams } from 'react-router-dom';
import OnboardingLayout from 'modules/auth/layouts/AuthLayout';
import { cn } from 'utils/helpers';
import { useEffect, useState } from 'react';
import OverlayLoader from 'components/loader/OverlayLoader';
import { useAppSelector } from 'hooks/useAppSelector';

const SharePage = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const { user } = useAppSelector((state) => state.auth);

    useEffect(() => {
        if (user !== undefined) {
            if (user === null) {
                navigate(`/public/${id}`);
            } else {
                navigate(`/lets-meet/receive/${id}`);
            }
            setLoading(false);
        } else {
            if (localStorage.getItem('refreshToken') === null) {
                navigate(`/public/${id}`);
            }
        }
    }, [user, navigate, id]);

    return (
        <OnboardingLayout className={cn('justify-start', 'overflow-y-auto pb-12')}>
            {loading && <OverlayLoader />}
            <></>
        </OnboardingLayout>
    );
};

export default SharePage;