import { Text } from 'components/text/primary/Text';
import { cn } from 'utils/helpers';
import TimeSlot, {
  IStartAndEndTimeSlot,
} from 'modules/profile/components/availability/TimeSlot/TimeSlot';
import { IAvailabilityPeriod } from 'modules/profile/types/types';

type TimeSlotProps = {
  periods: IAvailabilityPeriod[];
  onAdd: () => void;
  onDelete: (id: string) => void;
  onTimeChange: (id: string, time: IStartAndEndTimeSlot) => void;
  error?: boolean;
  addDisabled?: boolean;
  allEventData?: [];
  selectedDate?: Date;
  showSelectedInitially?:boolean;
};

const TimeSlotLayout: React.FC<TimeSlotProps> = ({
  periods,
  onDelete,
  onAdd,
  onTimeChange,
  addDisabled,
  allEventData,
  selectedDate,
  showSelectedInitially
}) => {
  const filteredPeriods = periods?.filter((period) => !period.allDay) || [];

  // hide selector with a delay
  const onDelayedTImeChange = (id: string, time: IStartAndEndTimeSlot) => {
    setTimeout(() => {
      onTimeChange(id, time);
    }, 100);
  };

  const isOverlappingData = (
    start1: Date,
    end1: Date,
    start2: Date,
    end2: Date,
  ) => {
    return start1 < end2 && start2 < end1;
  };

  const isOverlapping = (startDate: any, endDate: any): boolean => {
    if (startDate && endDate) {
      const selectedDateObject = new Date(selectedDate ?? new Date());
      const datePart = selectedDateObject.toISOString().split('T')[0];

      const startTimePart = startDate?.split('T')[1];
      const endTimePart = endDate?.split('T')[1];

      const combinedStartDateTime = `${datePart}T${startTimePart}`;
      const combinedEndDateTime = `${datePart}T${endTimePart}`;

      const overlapping = allEventData?.some((event: any) => {
        return isOverlappingData(
          new Date(event.start),
          new Date(event.end),
          new Date(combinedStartDateTime),
          new Date(combinedEndDateTime),
        );
      });

      return !!overlapping;
    } else return false;
  };

  return (
    <>
      <div className="my-1">
        <div className="grid gap-4">
          {filteredPeriods.map((slot) => (
            <div key={slot.id} className="relative">
              <TimeSlot
                key={slot.id}
                id={slot.id}
                start={slot.start}
                end={slot.end}
                onDelete={() => onDelete(slot.id)}
                onTimeChange={onTimeChange}
                showSelectedInitially={showSelectedInitially}
              />
              {isOverlapping(slot.start.dateTime, slot.end?.dateTime) && (
                <Text size="small" className="text-red-500 text-left">
                  Heads up! This time overlaps with an existing block
                </Text>
              )}
            </div>
          ))}
        </div>
      </div>
      {!periods?.some((period) => period.allDay) && (
        <Text
          size="small"
          className={cn(
            'mt-4 underline cursor-pointer decoration-solid max-w-32',
            addDisabled && 'text-gray-400',
          )}
          onClick={() => !addDisabled && onAdd()}
        >
          + Add Time Slot
        </Text>
      )}
    </>
  );
};

export default TimeSlotLayout;
