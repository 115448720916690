import { EventCardItem, EventCardItemProps } from 'components/eventCardItem';
import {
  EventItem,
  EventObject,
  sampleEvents,
  sampleUpEvents,
} from 'components/eventCardItem/EventCardItem.types';
import { EventFilterDropDown } from 'components/eventFilterDropDown';
import { determineVariant } from 'utils/calendarUtils';
import { Key, useEffect, useRef, useState } from 'react';
import { useGetAllEventsQuery } from 'services/event.service';
import InfiniteScroll from 'react-infinite-scroll-component';

const HomeCards = () => {
  const { data: events = [], refetch: refetchEvent } = useGetAllEventsQuery({
    page: 0,
    history: 'false',
  });
  const [eventList, setEventList] = useState<EventObject[]>(sampleEvents);
  const [eventListTop, setEventListTop] =
    useState<EventObject[]>(sampleUpEvents);
  const [scrollDirection, setScrollDirection] = useState<'up' | 'down'>('down');
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const user = {
    imageUrl:
      'https://qph.cf2.quoracdn.net/main-qimg-aa7cd3b369403bb01727809f8fbdf25b-lq',
    userName: 'amanda-fields',
    firstName: 'Amanda',
  };

  const fetchData = () => {
    console.log('Fetching more data... || fetchData');
    setEventList((prevEvents) => [...prevEvents, ...sampleEvents]);
  };

  const fetchDataTop = () => {
    console.log('Fetching more data... || fetchDataTop');
    setEventListTop((prevEvents) => [...prevEvents, ...sampleUpEvents]);
  };

  const handleScrollDirection = () => {
    const scrollTop = scrollContainerRef.current?.scrollTop || 0;
    const containerHeight = scrollContainerRef.current?.clientHeight || 0;
    const scrollHeight = scrollContainerRef.current?.scrollHeight || 0;

    setScrollDirection(() => {
      const currentScrollY = scrollTop;

      if (currentScrollY <= 0) {
        return 'up';
      } else if (scrollTop + containerHeight >= scrollHeight) {
        return 'down';
      }
      return scrollDirection;
    });

    if (scrollDirection === 'up') {
      fetchDataTop();
    } else if (scrollDirection === 'down') {
      fetchData();
    }
  };

  useEffect(() => {
    const container = scrollContainerRef.current;

    container?.addEventListener('scroll', handleScrollDirection);

    return () => {
      container?.removeEventListener('scroll', handleScrollDirection);
    };
  }, [scrollDirection]);

  return (
    <div className="text-white py-14 px-8">
      <div>
        <div className="flex justify-start space-x-4 items-center">
          <img
            className="w-[42px] h-[42px] rounded-full"
            src={user?.imageUrl}
            alt={user.userName}
          />
          <span className="text-sm font-medium">{`@${user.userName}`}</span>
        </div>
        <p className="font-medium leading-8 mt-3 text-center">
          Welcome {user?.firstName ?? 'User'}! Any plans today?
        </p>
      </div>

      <div className="bg-[#FFFFFF1A] py-[6px] pl-[10px] pr-[6px] rounded-sm mt-[11px] flex justify-between mb-5">
        <EventFilterDropDown />
        <EventFilterDropDown />
      </div>

      <div
        className="flex gap-3 flex-col overflow-y"
        id="scrollableDiv"
        ref={scrollContainerRef}
      >
        <div className="py-1.5 px-2.5 bg-black rounded-[100px] w-14 text-center text-[14px] leading-4 font-bold">
          Past
        </div>
        <InfiniteScroll
          dataLength={eventListTop.length}
          next={fetchDataTop}
          style={{ display: 'flex', flexDirection: 'column-reverse' }}
          inverse={true}
          hasMore={true}
          loader={<h4>Loading...</h4>}
          scrollableTarget="scrollableDiv"
        >
          {eventListTop.map(
            (event: EventObject, index: Key | null | undefined) => (
              <div key={index} className="mb-2">
                <EventCardItem
                  id={event?.event?._id}
                  eventData={event?.event}
                  variant={determineVariant(event?.variantData)}
                  additionalVariants={event?.variantData}
                />
              </div>
            ),
          )}
        </InfiniteScroll>
        <div className="py-1.5 px-2.5 bg-theme rounded-[100px] w-24 text-center text-[14px] leading-4 font-bold">
          Upcoming
        </div>
        <InfiniteScroll
          dataLength={eventList.length}
          next={fetchData}
          hasMore={true}
          loader={<h4>Loading...</h4>}
          refreshFunction={() => refetchEvent()} // This will refresh the events
          pullDownToRefresh
          pullDownToRefreshThreshold={50}
          pullDownToRefreshContent={
            <h3 style={{ textAlign: 'center' }}>
              &#8595; Pull down to refresh
            </h3>
          }
          releaseToRefreshContent={
            <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
          }
        >
          {eventList.map(
            (event: EventObject, index: Key | null | undefined) => (
              <div key={index} className="mb-2">
                <EventCardItem
                  id={event?.event?._id}
                  eventData={event?.event}
                  variant={determineVariant(event?.variantData)}
                  additionalVariants={event?.variantData}
                />
              </div>
            ),
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default HomeCards;
