import {
  forwardRef,
  useEffect,
  useRef,
  type TextareaHTMLAttributes,
} from 'react';
import { Text } from 'components/text/primary/Text';
import { cn } from 'utils/helpers';

interface TextFieldProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  className?: string;
  required?: boolean;
  error?: boolean | string;
  endAdornment?: React.ReactNode;
}

const TextAreaField = forwardRef<HTMLTextAreaElement, TextFieldProps>(
  ({ label, className, required, error, endAdornment, ...props }, ref) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
      if (textareaRef.current) {
        const textarea = textareaRef.current;
        // Set height based on content
        textarea.style.height = 'auto'; // Reset height
        const scrollHeight = textarea.scrollHeight;
        // Update height to fit content but not exceed two lines
        textarea.style.height = `${Math.max(scrollHeight, textarea.scrollHeight)}px`;
      }
    }, [props.value]); // Re-run when value changes

    return (
      <div className="relative flex flex-col w-full my-2">
        <div className="flex items-center justify-start mb-2 space-x-2">
          <Text size="small" className="text-base font-sofia-normal">
            {label}
          </Text>
          {required && <Text size="small">*</Text>}
        </div>
        <div className="flex relative">
          <textarea
            className={cn(
              'w-full max-w-sm mx-auto font-sans text-sm font-medium text-gray-300 rounded-lg bg-[#32395E] focus:outline-none px-4  py-2.5 resize-none font-sofia-light',
              className,
              `${error ? 'border-red-600' : 'border-slate-600 border-opacity-0'}`,
            )}
            ref={textareaRef}
            required={required}
            rows={1}
            style={{
              borderRadius: '24px', // Increase border-radius for more curvature
              boxSizing: 'border-box', // Ensure padding is included in height
            }}
            {...props}
          />
          {endAdornment && (
            <div className="absolute right-4 h-full flex items-center">
              {endAdornment}
            </div>
          )}
        </div>
        {error && (
          <Text size="extra-small" type="error" className="mt-1.5">
            {error}
          </Text>
        )}
      </div>
    );
  },
);

TextAreaField.displayName = 'TextAreaField';

export default TextAreaField;
