import { forwardRef, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronLeft } from 'lucide-react';
import { cn } from 'utils/helpers';

type LayoutProps = {
  children: ReactNode;
  onSubmit?: () => void;
  loading?: boolean;
};

const SettingsLayout = forwardRef<(() => void) | null, LayoutProps>(
  ({ children }, ref) => {
    const navigate = useNavigate();

    return (
      <div className="flex items-center justify-center text-white bg-main">
        <div
          className={cn(
            'flex flex-col min-h-screen w-full h-full max-w-md p-5',
          )}
        >
          <header className="flex flex-col w-full mt-5">
            <div className="w-full max-w-xs min-w-64">
              <ChevronLeft
                className="cursor-pointer"
                onClick={() => navigate(-1)}
              />
            </div>
          </header>
          {children}
        </div>
      </div>
    );
  },
);

SettingsLayout.displayName = 'SettingsLayout';

export default SettingsLayout;
