export const iphoneInstructions = [
  'Open the Contacts app on your iPhone.',
  'Tap "Lists" at the top left.',
  'Touch and hold an existing list.',
  'Tap "Export", select the fields ‘First name’ and ‘Number’, then tap "Done".',
  'Choose a method to send or save the exported contact cards.',
];

export const androidInstructions = [
  'Open the Contacts app on your Android.',
  'Tap "Lists" at the top left.',
  'Touch and hold an existing list.',
];

export const USERNAME_MIN_CHR = 6;
