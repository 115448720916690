type AvailabilityEmptyListProps = {
  text: string;
  icon: React.ReactNode;
};

const AvailabilityEmptyList: React.FC<AvailabilityEmptyListProps> = (props) => {
  return (
    <div className="flex flex-col items-center gap-2.5 bg-box p-3 rounded-md">
      {props.icon}
      <div className="text-sm font-light text-white/80 text-center">
        {props.text}
      </div>
    </div>
  );
};

export default AvailabilityEmptyList;
