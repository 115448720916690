import { useForm, Controller } from 'react-hook-form';
import { Check, ChevronRight } from 'lucide-react';
import SettingsLayout from 'modules/settings/layouts/SettingsLayout/SettingsLayout';
import ToggleSwitch from 'components/toggle/ToggleSwitch';
import { Text } from 'components/text/primary/Text';
import MainButton from 'modules/auth/components/MainButton';
// import { subscribeUser } from 'notificationSubscription';
import { useAppSelector } from 'hooks/useAppSelector';
import { RootState } from 'app/store';
import {
  getNotificationPermissions,
  updateNotificationPermissions,
} from 'modules/auth/slices/actions';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useEffect, useState } from 'react';
import OneSignal from 'react-onesignal';
import { toast } from 'components/ui/toast/use-toast';

type FormValues = {
  calender: boolean;
  event: boolean;
  caleido: boolean;
  text: boolean;
};

const EditNotifications = () => {
  const dispatch = useAppDispatch();
  const notification = useAppSelector(
    (state: RootState) => state.auth.notification,
  );

  const user = useAppSelector((state: RootState) => state.auth.user);

  // Loading state
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, reset } = useForm<FormValues>({
    defaultValues: {
      calender: notification.calender,
      event: notification.event,
      caleido: notification.caleido,
      text: notification.text,
    },
  });

  const onSubmit = async (formValues: FormValues) => {
    try {
      // Start loading
      setLoading(true);

      if (user && user.id) {
        // TODO: use await here after fixing OneSignal error
        dispatch(updateNotificationPermissions(user.id, formValues));

        // Stop loading
        setLoading(false);
        toast({
          title: '',
          description: (
            <div>Notification settings have been successfully saved!</div>
          ),
          fullscreen: true,
          backgroundColor: '#E0F4DB',
          textColor: '#060606',
          icon: <Check />,
          duration: 5000,
        });
      }

      // TODO: refactor OneSignal logic after fixing OneSignal error
      // NOT: This might not work due to not using await on OneSignal function calls
      if(user && user.id){
        // OneSignal - Allow Request Prompt
        OneSignal.logout()
        await OneSignal.Notifications.requestPermission();

        // OneSignal - Passing the User Details for OneSignal
        OneSignal.login(user?.id);
        OneSignal.User.addSms(user?.phoneNumber);
      }

    } catch (error) {
      console.error('Error updating notification settings:', error);
    }
  };

  useEffect(() => {
    if (user && user.id) {
      dispatch(getNotificationPermissions(user.id));
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (notification) {
      reset({
        calender: notification.calender,
        event: notification.event,
        caleido: notification.caleido,
        text: notification.text,
      });
    }
  }, [notification, reset]);

  return (
    <SettingsLayout>
      <main className="flex-grow mb-4">
        <div className="w-full my-8 mb-6">
          <Text size="large" className="w-full">
            Notification settings
          </Text>
          <form className="mt-12 space-y-5">
            <div className="flex items-center justify-between">
              <Text size="small" className="font-semibold">
                Calendar Invites
              </Text>
              <Controller
                name="calender"
                control={control}
                render={({ field }) => (
                  <ToggleSwitch
                    isChecked={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </div>
            <div className="flex items-center justify-between">
              <Text size="small" className="font-semibold">
                Event Settings
              </Text>
              <Controller
                name="event"
                control={control}
                render={({ field }) => (
                  <ToggleSwitch
                    isChecked={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </div>
            <div className="flex items-center justify-between">
              <Text size="small" className="font-semibold">
                From Caleido
              </Text>
              <Controller
                name="caleido"
                control={control}
                render={({ field }) => (
                  <ToggleSwitch
                    isChecked={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </div>
            <div className="flex items-center justify-between">
              <Text size="small" className="font-semibold">
                Text notifications
              </Text>
              <Controller
                name="text"
                control={control}
                render={({ field }) => (
                  <ToggleSwitch
                    isChecked={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </div>
          </form>
        </div>
      </main>{' '}
      <footer className="mb-4">
        <MainButton
          type="primary"
          icon={loading ? null : <ChevronRight color="#fff" />}
          onClick={handleSubmit(onSubmit)}
          disabled={loading}
        >
          <Text size="small">{loading ? 'Saving...' : 'Save Changes'}</Text>
        </MainButton>
      </footer>
    </SettingsLayout>
  );
};

export default EditNotifications;
