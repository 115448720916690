import { Dispatch } from 'redux';
import { UserService } from 'modules/auth/services/user.service';
import {
  setLoading,
  setError,
  logout,
  setUser,
  setNotificationPermissions,
} from 'modules/auth/slices/authSlice';
import { INotificationPermissions, IUser } from 'modules/auth/types/types';
import { AuthService } from 'modules/auth/services/auth.service';
import apiClient from 'services/apiClient';

// Function to logout the user
export const logoutAction = () => async (dispatch: Dispatch) => {
  try {
    setLoading(true);
    await AuthService.logout();
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(logout());
    setLoading(false);
  }
};

// Function to create a new user
export const createUserAction = (data: IUser) => async (dispatch: Dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await UserService.createUser(data);
    // handle success if needed
    console.log('User created successfully', response.data);
  } catch (error: any) {
    dispatch(setError(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};

// Function to get all users
export const getUsersAction = () => async (dispatch: Dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await UserService.getUsers();
    // handle success if needed
    console.log('Fetched users:', response.data);
  } catch (error: any) {
    dispatch(setError(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};

// Function to get a user by ID
export const getUserAction = (userId: string) => async (dispatch: Dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await UserService.getUser(userId);
    dispatch(setUser(response.data));
  } catch (error: any) {
    console.log('Error fetching user:', error);
    dispatch(setError(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};

// Function to get a logged In
export const whoAmIAction = () => async (dispatch: Dispatch) => {
  dispatch(setLoading(true));
  try {
    // const response = await AuthService.whoAmI();
    const response = await apiClient.get('/auth/whoami');
    dispatch(setUser(response.data));
  } catch (error: any) {
    dispatch(setError(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};

// Function to update a user by ID
export const updateUserAction =
  (userId: string, data: IUser) => async (dispatch: Dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await UserService.updateUser(userId, data);
      dispatch(setUser(response.data));
    } catch (error: any) {
      dispatch(setError(error.message));
    } finally {
      dispatch(setLoading(false));
    }
  };

// Function to delete a user by ID
export const deleteUserAction =
  (userId: string) => async (dispatch: Dispatch) => {
    dispatch(setLoading(true));
    try {
      await UserService.deleteUser(userId);
    } catch (error: any) {
      dispatch(setError(error.message));
    } finally {
      dispatch(setLoading(false));
    }
  };

// Function to get a user by ID
export const getNotificationPermissions =
  (id: string) => async (dispatch: Dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await UserService.getUserPermissions(id);
      console.log(response.data);
      dispatch(setNotificationPermissions(response.data));
    } catch (error: any) {
      console.log('Error fetching notification permissions:', error);
      dispatch(setError(error.message));
    } finally {
      dispatch(setLoading(false));
    }
  };

// Function to get a user by ID
export const updateNotificationPermissions =
  (id: string, data: INotificationPermissions) =>
  async (dispatch: Dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await UserService.updateUserPermissions(id, data);
      dispatch(setNotificationPermissions(response.data));
    } catch (error: any) {
      console.log('Error fetching notification permissions:', error);
      dispatch(setError(error.message));
    } finally {
      dispatch(setLoading(false));
    }
  };
