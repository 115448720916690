import React from 'react';
import { EventType } from 'modules/letsMeet/types/types';
import { format, parseISO } from 'date-fns';

type AvailableTimesProps = {
  times: string[];
  type: string;
};

const AvailableTimes: React.FC<AvailableTimesProps> = ({ times, type }) => {
  const formatDate = (dateString: string) => {
    try {
      const date = parseISO(dateString);
      return format(date, 'EEE, MMM do');
    } catch (error) {
      console.error('Error parsing date:', error);
      return 'Invalid Date';
    }
  };
 
  const formatTime = (dateString: string, timeString: string) => {
    try {
      const date = new Date(`${dateString}T${timeString}`);
      return format(date, 'hh:mm a');
    } catch (error) {
      console.error('Error parsing time:', error);
      return 'Invalid Time';
    }
  };

  return (
    <>
      {type === 'poll' && (
        <div className="flex flex-col gap-3 mb-2">
          <h2 className="text-base font-semibold mt-2">Available times</h2>
          {times.map((time, index) => {
            const matchResult = time.match(
              /(\d{4}-\d{2}-\d{2})-(\d{2}:\d{2})(Z|[+-]\d{2}:?\d{2})?-(\d{2}:\d{2})(Z|[+-]\d{2}:?\d{2})?/,
            );
            const [date, startTime, endTime] = matchResult
              ? [
                matchResult[1],
                matchResult[2] + (matchResult[3] || ''),
                matchResult[4] + (matchResult[5] || '')  
              ]
              : ['', '', ''];
            return (
              <div key={index} className="font-light text-sm">
                {`${formatDate(date)} • ${formatTime(date, startTime)} - ${formatTime(date, endTime)}`}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default AvailableTimes;
