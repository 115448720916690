import { ReactComponent as LaterIcon } from 'assets/images/event/later.svg';
import { CalendarConfirmDialog } from 'modules/home/components/calendarConfirmDialog';
import { formatDate } from 'date-fns';
import { ChevronRight } from 'lucide-react';
import {
  formatDateToLocalTimezone,
  getUserTimezone,
} from 'modules/home/utils/helpers';
import { useState } from 'react';
import { EventBlockCardItemProps } from './EventBlockCardItem.type';

const EventBlockCardItem = (props: EventBlockCardItemProps) => {
  const { endTime, startTime, title, variant, onClick, onDelete, onEdit,origin } =
    props;

  const [confirmState, setConfirmState] = useState<boolean>(false);

  const showTime = startTime && endTime;
  const formattedStartTime = !showTime
    ? ''
    : typeof startTime === 'string'
      ? formatDateToLocalTimezone(startTime, getUserTimezone())
      : startTime;
  const formattedEndTime = !showTime
    ? ''
    : typeof endTime === 'string'
      ? formatDateToLocalTimezone(endTime, getUserTimezone())
      : endTime;

  return confirmState ? (
    <CalendarConfirmDialog
      onClose={() => {
        setConfirmState((prev) => false);
      }}
      onConfirm={() => {
        onDelete && onDelete();
        setConfirmState((prev) => false);
      }}
    />
  ) : (
    <div className="w-full h-full">
      <div className="px-8 flex  items-center justify-start py-5 space-x-2">
        {showTime && (
          <div className="bg-[#F4E7FF] w-[54px] h-[54px] rounded-full flex flex-col items-center justify-center font-sofia-medium text-center text-[#6700E9]">
            <div className="text-xs mb-[-6px]">
              {new Date(formattedStartTime).toLocaleDateString('en-US', {
                weekday: 'short',
              })}
            </div>
            <div className="text-2xl">
              {new Date(formattedEndTime).toLocaleDateString('en-US', {
                day: '2-digit',
              })}
            </div>
          </div>
        )}

        <div className="text-white flex flex-col space-y-0 w-auto overflow-hidden ">
          <div className="text-lg font-sofia-semibold overflow-hidden whitespace-nowrap overflow-ellipsis max-w-52">
            {title}
          </div>
          <div className="flex gap-1 items-center text-sm font-sofia-light tracking-wider ">
            {showTime && (
              <>
                <LaterIcon />
                <span>
                  {formatDate(formattedStartTime, 'h:mma')} -{' '}
                  {formatDate(formattedEndTime, 'h:mma')}
                </span>
              </>
            )}
          </div>
        </div>
      </div>

      <div
        className="flex items-center justify-between pl-7 pr-[50px] h-[50px] bg-[#FF6363]  text-[#060606] text-base font-sofia-normal overflow-hidden cursor-pointer"
        onClick={() => {
          props.deletable && setConfirmState((prev) => true);
        }}
      >
        <span>Delete time block</span>
        <ChevronRight className="text-base w-4" />
      </div>
    </div>
  );
};

export default EventBlockCardItem;
