import React, { useCallback, useEffect, useRef, useState } from 'react';
import TextField from 'components/textfield/TextField';

interface PlacePrediction {
  description: string;
  place_id: string;
}

type CitySuggestionProps = {
  setSelectedLocation: (location: string) => void;
  locationMenuVisible: boolean;
  error: string;
  location: string | null;
};

declare global {
  namespace google {
    namespace maps {
      namespace places {
        class AutocompleteService {
          getPlacePredictions: (
            request: AutocompleteRequest,
            callback: (
              result: PlaceResult[],
              status: PlacesServiceStatus,
            ) => void,
          ) => void;
        }

        interface AutocompleteRequest {
          input: string;
          types: string[];
        }

        interface PlaceResult {
          description: string;
          place_id: string;
        }

        enum PlacesServiceStatus {
          OK,
        }
      }
    }
  }
}

const CitySuggestion: React.FC<CitySuggestionProps> = ({
  setSelectedLocation,
  locationMenuVisible,
  error,
  location,
}) => {
  const [suggestions, setSuggestions] = useState<PlacePrediction[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const autocompleteServiceRef =
    useRef<google.maps.places.AutocompleteService | null>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const loadScript = (url: string, callback: () => void) => {
      const script = document.createElement('script');
      script.src = url;
      script.async = true;
      script.onload = callback;
      document.body.appendChild(script);
    };

    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`,
      () => {
        autocompleteServiceRef.current =
          new window.google.maps.places.AutocompleteService();
      },
    );
  }, []);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setInputValue(value);
      setSelectedLocation(value);

      if (value) {
        autocompleteServiceRef.current?.getPlacePredictions(
          { input: value, types: ['establishment', 'geocode'] },
          (predictions, status) => {
            if (
              status === window.google.maps.places.PlacesServiceStatus.OK &&
              predictions
            ) {
              setIsVisible(true);
              setSuggestions(predictions);
            } else {
              setIsVisible(false);
              setSuggestions([]);
            }
          },
        );
      } else {
        setIsVisible(false);
        setSuggestions([]);
      }
    },
    [],
  );

  const handleSuggestionClick = useCallback(
    (suggestion: PlacePrediction) => {
      setIsVisible(false);
      setSelectedLocation(suggestion.description);
      setInputValue(suggestion.description);
      setSuggestions([]);
    },
    [setSelectedLocation],
  );

  useEffect(() => {
    if (!inputValue) {
      setIsVisible(false);
    }
  }, [locationMenuVisible, inputValue]);

  useEffect(() => {
    setInputValue(location || '');
  }, [location]);

  return (
    <div>
      <TextField
        label="Where?"
        placeholder="Enter location"
        value={inputValue}
        onChange={handleInputChange}
        required={false}
        error={error}
      />
      {isVisible && (
        <div className="relative" id='g-map-suggestions' onBlur={()=>{setIsVisible(false)}}>
          <div className="absolute z-10 bg-gray-800 rounded-xl w-full max-h-32 h-auto p-4 py-1 overflow-auto">
            {suggestions.map((suggestion, index) => (
              <div
                key={suggestion.place_id}
                onClick={() => handleSuggestionClick(suggestion)}
              >
                <p className="text-sm py-3">{suggestion.description}</p>
                {index !== suggestions.length - 1 && (
                  <hr className="opacity-20" />
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CitySuggestion;
