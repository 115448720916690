import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithReauth from 'services/mutationInterceptor';
import { NotificationType } from 'modules/letsMeet/utils/enum';

interface SendNotificationResponse {
  message: string;
  notificationId: string;
}

export interface SendNotificationData {
  type?: NotificationType;
  notificationType?: NotificationType;
  eventId?: string;
  responder?: string;
  ResponderName?: string;
  response?: string;
}

export const notificationMutation = createApi({
  reducerPath: 'notificationMutation',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    sendNotification: builder.mutation<SendNotificationResponse, SendNotificationData>({
      query: (data) => ({
        url: '/notification/send-notification',
        method: 'POST',
        body: data,
      }),
    }),
    stopNotification: builder.mutation<SendNotificationResponse, SendNotificationData>({
      query: (data) => ({
        url: '/notification/stop-notifying',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { useSendNotificationMutation, useStopNotificationMutation } = notificationMutation;
