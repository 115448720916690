import { EventBlockCardItem } from 'components/eventBlockCardItem';
import { EventCardItem } from 'components/eventCardItem';
import { useDeleteTimeOffMutation } from 'services/event.service';
import { ViewEventPopProps } from './ViewEventPop.types';
import OverlayLoader from 'components/loader/OverlayLoader';
import { toast } from 'components/ui/toast/use-toast';
import { CheckIcon } from 'lucide-react';
import { determineVariant } from 'utils/calendarUtils';

const ViewEventPop = ({
  event,
  onClose,
  onClick,
  onActionCancel,
  onActionConfirm,
  origin
}: ViewEventPopProps) => {
  // extract props to send to EventCardItem
  const eventVariant = event.variant ?? event?.eventData?.variant;
  const eventToShow = event?.eventData;
  const id = event.id;

  // API call to delete time block
  const [deleteTimeBlock, { isLoading, data }] = useDeleteTimeOffMutation();

  const handleDelete = async () => {
    try {
      const response = await deleteTimeBlock?.({
        id: event?.id,
      }).unwrap();

      console.log('Response time block : ', response);

      // close drawer after success and show toast
      if (response) {
        toast({
          title: '',
          description: <div>Time block deleted successfully!</div>,
          fullscreen: true,
          backgroundColor: '#E0F4DB',
          textColor: '#060606',
          icon: <CheckIcon />,
        });
        onActionConfirm && onActionConfirm('timeOff');
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log("---------------floating event", event)

  return (
    <div>
      {isLoading && <OverlayLoader />}
      {eventVariant == 'blocked' ? (
        <EventBlockCardItem
          endTime={event?.start}
          startTime={event?.end}
          title={event?.title ?? ''}
          onClick={onClick}
          onDelete={handleDelete}
          origin={origin}
          deletable={event.isDeletable}
        />
      ) : (
        <div className="pt-4">
          <EventCardItem
            eventData={eventToShow!}
            id={id}
            variant={determineVariant(event?.variantData)}
            origin={origin}
            additionalVariants={event?.variantData}
          />
        </div>
      )}
    </div>
  );
};

export default ViewEventPop;
