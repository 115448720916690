import React from 'react';
import EventIcon from 'components/eventIcon/EventIcon';

type IconsListProps = {
  activeIcon: string;
  fetchIcon: any;
};

const IconsList: React.FC<IconsListProps> = ({
  activeIcon,
  fetchIcon,
}: IconsListProps) => {
  const iconsList = [
    '🍽️',
    '🍻',
    '🏠',
    '👫',
    '💪',
    '⚽️',
    '🏀',
    '🎾',
    '⛳️',
    '🏈',
    '📺',
    '🪩',
    '💼',
    '🎉',
    '☕️',
    '🎼',
    '🍦',
    '😜',
    '🤫',
    '❤️',
    '🙏',
    '🎮',
    '🗻',
    '🌞',
    '🌊',
    '📚️',
    '🖌️️',
    '🚗️',
    '🎫️',
    '😺️',
    '🐶',
    '🎲️',
    '🛍️',
    '🍸',
    '🙌️',
  ];

  const handleIconSelect = (icon: string) => {
    if (activeIcon === icon) fetchIcon('');
    else fetchIcon(icon);
  };

  return (
    <>
      <div>
        <div className="scroll-ps-6 snap-x overflow-x-auto">
          <div className="px-6 mt-6 mb-4 whitespace-nowrap grid grid-cols-13 auto-cols-max gap-x-24 gap-y-4">
            {iconsList.map((icon, index) => (
              <>
                <div key={index}>
                  <div onClick={() => handleIconSelect(icon)}>
                    <EventIcon active={icon === activeIcon} icon={icon} />
                  </div>
                </div>
                {(index + 1) % 12 === 0 && <div className="w-2"></div>}
              </>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default IconsList;
