import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useAppSelector } from 'hooks/useAppSelector';
import {SETTINGS_ROUTES} from 'components/routes';
import PrivateRoute from './PrivateRoute';

const SettingsTree: React.FC = () => {
    const { isAuthenticated } = useAppSelector((state) => state.auth) ?? false;

    return (
        <>
            <Routes>
                {SETTINGS_ROUTES?.map((route, index) => (
                    <Route
                        key={index}
                        path={route.path}
                        element={
                            <PrivateRoute isAuthenticated={isAuthenticated}>
                                {route.element}
                            </PrivateRoute>
                        }
                    />
                ))}
            </Routes>
        </>
    );
};

export default SettingsTree;
