import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'services/mutationInterceptor';

/*
 *
 *  So we want to expose an endpoint that allows users to update an event without requiring authentication.
 *  But.. this will need to be revisited and thought about more carefully.
 *  Here's some info for the reader to consider:
 *
 *  Security Implications of Exposing Non-Authenticated Endpoints:
 *
 * 1. Unauthorized Access:
 *    - By exposing an endpoint that doesn't require authentication, you're allowing anyone to interact with it.
 *    - This could lead to unauthorized users modifying data, which could have serious security implications depending on what the endpoint does.
 *
 * 2. Data Validation:
 *    - Without authentication, you must rely heavily on data validation to ensure only valid and expected data is processed.
 *    - Consider implementing additional mechanisms like IP whitelisting, rate limiting, or CAPTCHA to mitigate the risk of abuse or unintended actions.
 *
 * 3. Audit and Monitoring:
 *    - Ensure robust logging and monitoring are in place for any actions performed via non-authenticated endpoints.
 *    - This will help you detect and respond to any misuse quickly, ensuring accountability.
 *
 * 4. Rate Limiting:
 *    - To protect your non-authenticated endpoint from abuse, implement rate limiting.
 *    - Rate limiting prevents a single user from overwhelming the service with requests, helping to mitigate denial-of-service attacks.
 *
 * 5. Limited Functionality:
 *    - The functionality exposed through the non-authenticated endpoint should be minimal.
 *    - Avoid allowing critical operations, such as deleting or updating sensitive data, via non-authenticated requests.
 */
interface EventResponse {
    // Define the structure of the event response here
  }

export const publicEventApi = createApi({
  reducerPath: 'publicEventApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    updatePublicEvent: builder.mutation<any, EventResponse>({
      query: (data) => ({
        url: '/public/event',
        method: 'PUT',
        body: data,
      }),
    }),
  }),
});

export const {
  useUpdatePublicEventMutation,
} = publicEventApi;