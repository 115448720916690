import countryCodes from 'data/country-codes.json';

export const maskPhoneNumber = (phone: string) => {
  // Match the country code and the rest of the number
  const match = phone.match(/^(\(\+\d{1,3}\))(.+)(\d{4})$/);
  if (!match) {
    return phone; // Return original if it doesn't match the expected format
  }
  const countryCode = match[1];
  const maskedPart = '*'.repeat(match[2].length);
  const lastFour = match[3];
  return `${countryCode}${maskedPart}${lastFour}`;
};


export const formatPhoneNumber = (phone: string) => {
  // Remove non-digit characters
  const cleaned = phone.replace(/\D/g, '');

  if (cleaned.length === 10) {
    // For 10-digit numbers
    return cleaned.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  } else if (cleaned.length === 9) {
    // For 9-digit numbers
    return cleaned.replace(/(\d{2})(\d{3})(\d{4})/, '($1) $2-$3');
  } else {
    return phone;
  }
};

export const getPrefixByCountryCode = (code: string) => {
  const country = countryCodes.find((country) => country.code === code);
  return country?.prefix;
};
