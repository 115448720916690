import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { LETS_MEET_ROUTES } from 'components/routes';
import { useAppSelector } from 'hooks/useAppSelector';
import PrivateRoute from './PrivateRoute';

const LetsMeetRoutes: React.FC = () => {
  const { isAuthenticated } = useAppSelector((state) => state.auth) ?? false;

  return (
    <>
      <Routes>
        {LETS_MEET_ROUTES.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              <PrivateRoute isAuthenticated={isAuthenticated}>
                {route.element}
              </PrivateRoute>
            }
          />
        ))}
      </Routes>
    </>
  );
};

export default LetsMeetRoutes;
