import caleidoBaseApi from 'services/apiClient';
import {
  INotificationPermissions,
  IUser,
  IUserResponse,
} from 'modules/auth/types/types';

const UserService = {
  createUser: (data: IUser) => {
    return caleidoBaseApi.post<IUser>('/users', data);
  },
  getUsers: () => {
    return caleidoBaseApi.get<IUserResponse>('/users');
  },
  getUser: (userId: string) => {
    return caleidoBaseApi.get<IUserResponse>(`/users/${userId}`);
  },
  updateUser: (userId: string, data: IUser) => {
    return caleidoBaseApi.patch<IUserResponse>(`/users/${userId}`, data);
  },
  deleteUser: (userId: string) => {
    return caleidoBaseApi.delete<void>(`/users/${userId}`);
  },
  updateUserPermissions: (id: string, data: INotificationPermissions) => {
    return caleidoBaseApi.patch<INotificationPermissions>(
      `/users/notification-permissions/${id}`,
      data,
    );
  },
  getUserPermissions: (id: string) => {
    return caleidoBaseApi.get<INotificationPermissions>(
      `/users/notification-permissions/${id}`,
    );
  },
};

export { UserService };
