import { Dialog, DialogContent, DialogTitle } from 'components/ui/dialog';

import MeetResponseAfterBody from 'modules/letsMeet/components/meetResponse/meetResponseAfterBody';
import { ReceiveMeetResponseType } from 'modules/letsMeet/types/types';

type ReceiveLaterPopupProps = {
  isOpen: boolean;
  onClose: () => void;
  triggerRespondChange: () => void;
  title?: string;
  emoji?: string;
  response: ReceiveMeetResponseType;
  isPublic?: boolean;
};

export const receivePublicMeetResponseContent = {
  [ReceiveMeetResponseType.ACCEPTED]: {
    emoji: '🎉',
    title: 'You are going!',
  },
  [ReceiveMeetResponseType.DECLINED]: {
    emoji: '😓',
    title: 'Sorry you can’t make it',
  },
  [ReceiveMeetResponseType.MAYBE]: {
    emoji: '🙌',
    title: 'You’re not sure',
  },
};

const ReceiveLaterPopup: React.FC<ReceiveLaterPopupProps> = (props) => {
  return (
    <Dialog open={props.isOpen} onOpenChange={(e) => !e && props.onClose()}>
      <DialogContent className="sm:max-w-md bg-[#1F2339] border-none box-border max-w-64 rounded-lg px-2">
        <MeetResponseAfterBody
          emoji={
            props.emoji ??
            receivePublicMeetResponseContent[props.response].emoji
          }
          title={
            props.title ??
            receivePublicMeetResponseContent[props.response].title
          }
          onRespondChange={props.triggerRespondChange}
          isPublic
          isPopup
          hideButton={props.isPublic}
        />
      </DialogContent>
   </Dialog>
  );
};

export default ReceiveLaterPopup;
