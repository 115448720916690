import { useState } from 'react';
import apiClient from 'services/apiClient';

export const useApiCall = () => {
  const [data, setData] = useState<any[] | any | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [error, setError] = useState<any | null>(null);

  const fetchData = async (path: string) => {
    if (isLoading || isFetching) return;
    try {
      setIsFetching((prev) => true);
      setIsLoading((prev) => true);
      const response = await apiClient.get(path);
      setData(response.data);
    } catch (error) {
      console.error('Error fetching upcoming events:', error);
      setError(error);
    } finally {
      setIsLoading((prev) => false);
      setIsFetching((prev) => false);
    }
  };
  return {
    data,
    refetch: fetchData,
    isLoading,
    isFetching,
    error: error,
  };
};
