import React from 'react';
import { cn } from 'utils/helpers';

interface CheckboxAsRadioButtonProps {
  className?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  checked?: boolean;
  value?: string | number;
}

const CheckboxAsRadioButton: React.FC<CheckboxAsRadioButtonProps> = ({
  className,
  disabled,
  ...rest
}) => {
  return (
    <label className={cn('inline-flex items-center', className)}>
      <input
        type="checkbox"
        className="hidden peer"
        disabled={disabled}
        {...rest}
      />
      <span
        className={cn(
          'w-4 h-4 border-2 border-gray-300 rounded-full',
          'peer-checked:border-violet-400 peer-checked:bg-violet-200',
          disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer',
        )}
      ></span>
    </label>
  );
};

export default CheckboxAsRadioButton;
