import { isEmpty } from 'lodash';
import { RootState } from 'app/store';
import OverlayLoader from 'components/loader/OverlayLoader';
import { useAppSelector } from 'hooks/useAppSelector';
import { useUpdateUserMutation } from 'modules/auth/services/user.mutation.service';
import SettingsLayout from 'modules/settings/layouts/SettingsLayout/SettingsLayout';
import Contacts from 'modules/settings/components/Contacts';

const EditContacts = () => {
  const user = useAppSelector((state: RootState) => state.auth.user);
  const [updateUser, { isLoading, data }] = useUpdateUserMutation();

  return (
    <SettingsLayout>
      <main className="flex-grow mb-4">
        <div className="w-full my-8 mb-6">
          {isEmpty(data ?? user) ? (
            <OverlayLoader />
          ) : (
            <Contacts
              update={updateUser}
              data={data ?? user}
              setPrevOrSkip={() => {}}
              loading={isLoading}
            />
          )}
        </div>
      </main>
    </SettingsLayout>
  );
};

export default EditContacts;
