import { Button } from 'components/ui/button';
import { useEffect, useRef, useState } from 'react';

type UnlockResponseViewProps = {
  onSubmit: () => void;
};

const UnlockResponseView: React.FC<UnlockResponseViewProps> = (props) => {
  const ref = useRef<HTMLDivElement>(null);

  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.clientHeight);
    }
  }, []);
  return (
    <>
      <div
        style={{
          height: height,
        }}
      ></div>
      <div
        ref={ref}
        className="bg-white/10 text-center flex justify-center px-2 pt-24 pb-36 fixed z-10 bottom-0 w-full backdrop-blur-2xl rounded-t-xl max-w-md"
      >
        <div className="max-w-72 flex justify-center flex-col">
          <div className="font-semibold text-xl">
            Respond to unlock the rest of the details
          </div>
          <div className="mt-5">
            <Button
              className="bg-[#6000E9] py-4 px-6 h-9 rounded-full"
              onClick={props.onSubmit}
            >
              Add my response
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UnlockResponseView;
