import { formatDateToLocalTimezone } from 'modules/letsMeet/utils/lib';
import { ReactComponent as CrownIcon } from 'assets/images/activity/crown.svg';
import { DeleteIcon, Pencil, Trash, Trash2 } from 'lucide-react';
type CommentPropType = {
  comment: any;
  onDelete?: () => void;
  onEdit?: () => void;
  blocked?: boolean;
};

const Comment: React.FC<CommentPropType> = ({ comment,onDelete ,onEdit,blocked}) => {
  return (
    <div className=" items-center bg-[#32395E] p-3.5 rounded-md my-3">
      <div className="flex items-center justify-between">
        <span className="flex font-semibold gap-1 items-center leading-none ">
          {comment?.by.name} {comment?.by.organizer === 1 && <CrownIcon />}
        </span>
        <span className='flex space-x-4'>
          {blocked === true ? <></> : onEdit && <Pencil className='cursor-pointer w-3 h-3' onClick={()=>{onEdit()}}/>}
          {blocked === true ? <></> : onDelete && <Trash2 className="cursor-pointer w-3 h-3" onClick={()=>{onDelete()}}/>}
        </span>
      </div>
      <div className="text-sm font-medium py-2 leading-none break-words">
        {comment?.comment}
      </div>
      <div className="text-sm font-light flex gap-1.5 items-center">
        {comment && formatDateToLocalTimezone(comment.timestamp, 'h:mma')}
        <div className="bg-[#6000E9] h-1 w-1 rounded-full " />
        {comment &&
          formatDateToLocalTimezone(comment.timestamp, 'do MMM, yyyy')}
      </div>
    </div>
  );
};
export default Comment;
