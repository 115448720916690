import { formatDateTimeSlot, formatDateToLocalTimezone, getUserTimezone, isTimePassed } from 'modules/letsMeet/utils/lib';
import React, { useState } from 'react';
import { IPollData } from 'types/event';
import { format, parseISO } from 'date-fns';

interface PollResultsProps {
  pollData: IPollData | undefined; // Poll data
  totalUsers: number; // Total number of users in the event
  showCreatorView?: boolean; // Whether the user is the creator of the event
  onSelectTimeSlot?: (index: number | null) => void; // Callback to send selected time slot to parent
}

const PollResults: React.FC<PollResultsProps> = ({ pollData, totalUsers, showCreatorView, onSelectTimeSlot }) => {
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<number | null>(null);

  const formatTimeSlot = (timeSlot: string) => {
    try { 
    const [year, month, day, startTime, endTime] = timeSlot.split('-');
    const date = new Date(Date.UTC(Number(year), Number(month) - 1, Number(day)));
    const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'short' });
    const monthName = date.toLocaleDateString('en-US', { month: 'short' });
    const dayWithSuffix = `${day}${getDaySuffix(day)}`;
    return `${dayOfWeek}, ${monthName} ${dayWithSuffix}`;
    } catch (e) {
      return 'Invalid Date';
    }
  };

  const getDaySuffix = (day: string) => {
    const dayNum = Number(day);
    if (dayNum >= 11 && dayNum <= 13) return 'th';
    switch (dayNum % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };

  const formatDate = (dateString: string) => {
    try {
      const date = parseISO(dateString);
      return format(date, 'EEE, MMM do');
    } catch (error) {
      console.error('Error parsing date:', error);
      return 'Invalid Date';
    }
  };
 
  const formatTime = (dateString: string, timeString: string) => {
    try {
      const date = new Date(`${dateString}T${timeString}`);
      return format(date, 'hh:mm a');
    } catch (error) {
      console.error('Error parsing time:', error);
      return 'Invalid Time';
    }
  };

  const handleTimeSlotSelect = (index: number) => {
    const newSelectedTimeSlot = selectedTimeSlot === index ? null : index;
    setSelectedTimeSlot(newSelectedTimeSlot);
    if (onSelectTimeSlot) {
      onSelectTimeSlot(newSelectedTimeSlot); // Pass the selected time slot to the parent
    }
  };

  return (
    <div>
      <h2 className="text-base font-semibold mb-2">Poll results</h2>
      {showCreatorView &&
        <div className="text-xs font-medium mb-2">Select a date to create event</div>
      }
      {pollData?.checkedTimes.map((timeSlot, index) => {
        const voterCount = timeSlot.voters.length || 0;
        const percentage = Math.round((voterCount / totalUsers) * 100);
        const isPassed = isTimePassed(timeSlot.time)

        const matchResult = timeSlot.time.match(
          /(\d{4}-\d{2}-\d{2})-(\d{2}:\d{2})(Z|[+-]\d{2}:?\d{2})?-(\d{2}:\d{2})(Z|[+-]\d{2}:?\d{2})?/,
        );
        const [date, startTime, endTime] = matchResult
          ? [
            matchResult[1],
            matchResult[2] + (matchResult[3] || ''),
            matchResult[4] + (matchResult[5] || '')  
          ]
          : ['', '', ''];
        
        return (
          <div key={index} className="mb-3 p-4 bg-[#2C3051] rounded-md shadow-md relative">
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-2">
                {showCreatorView && (
                  <input
                    type="radio"
                    checked={selectedTimeSlot === index}
                    onChange={() => handleTimeSlotSelect(index)}
                    className="form-radio text-purple-600"
                    disabled={isPassed}
                  />
                )}
                <span className={`text-sm font-medium ${isPassed? "text-white/50" : "text-white"}`}>
                  {formatDate(date)}
                </span>
                <span className="w-1.5 h-1.5 bg-purple-600 rounded-full inline-block"></span>
                <span className={`text-sm ${isPassed ? "text-white/50" : "text-white"}`}>
                  {`${formatTime(date, startTime)} - ${formatTime(date, endTime)}`}
                </span>
              </div>
              <span className="text-sm font-bold text-[#D7C0FB]" style={{ marginLeft: '8px' }}>
                {percentage}%
              </span>
            </div>
            <div className={`absolute bottom-0 left-0 w-full ${percentage === 0 ? 'bg-[#2C3051]' : 'bg-[#D7C0FB]'} rounded-b-md h-1`}>
              {percentage > 0 && (
                <div className="bg-[#6000E9] h-1" style={{ width: `${percentage}%` }}></div>
              )}
            </div>
            {showCreatorView && selectedTimeSlot === index && timeSlot.voters.length > 0 && (
              <div className="mt-3 mb-2">
                <h1 className="text-sm font-semibold text-white mb-4">Your friends who voted</h1>
                <div className="grid grid-cols-2 gap-2">
                  {timeSlot.voters.map((voter, voterIndex) => (
                    <div
                      key={voterIndex}
                      className="px-4 py-1.5 bg-[#EBDFFF] rounded-full text-sm text-[#121538] text-center font-medium"
                    >
                      {voter.name}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default PollResults;