import { createApi } from '@reduxjs/toolkit/query/react';
import { da } from 'date-fns/locale';
import baseQueryWithReauth, { baseQuery } from 'services/mutationInterceptor';

interface UploadResponse {
  signedUrl: string;
}

interface DeleteResponse {
  message: string;
}

interface FileData {
  file: File;
  optimize?: boolean;
}

interface DeleteData {
  key: string;
}

export const fileMutation = createApi({
  reducerPath: 'fileMutation',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    uploadFile: builder.mutation<UploadResponse, FileData>({
      query: (data) => {
        const formData = new FormData();
        formData.append('file', data.file);
        formData.append('optimize', data.optimize ? 'true' : 'false');
        return {
          url: '/upload',
          method: 'POST',
          body: formData,
        };
      },
    }),
    deleteFile: builder.mutation<DeleteResponse, DeleteData>({
      query: () => ({
        url: '/upload',
        method: 'DELETE',
      }),
    }),
  }),
});

export const { useUploadFileMutation, useDeleteFileMutation } = fileMutation;
