import { Link, useNavigate } from 'react-router-dom';
import { ChevronLeft } from 'lucide-react';
import OnboardingLayout from 'modules/auth/layouts/AuthLayout';
import { canGoBack, cn } from 'utils/helpers';
import { Text } from 'components/text/primary/Text';

const Terms = () => {
  const navigate = useNavigate();
  return (
    <OnboardingLayout className={cn('justify-start', 'overflow-y-auto pb-12')}>
      <div className="w-full max-w-sm mt-8 min-w-64">
        <ChevronLeft
          className="cursor-pointer"
          onClick={() => (canGoBack() ? navigate(-1) : navigate('/auth/start'))}
        />
      </div>

      <div className="w-full max-w-sm my-8 mb-6 min-w-64">
        <Text size="medium">Caleido LLC Terms of Service</Text>
        <Text size="small" className="my-2 font-semibold">
          (Updated & Effective October 2nd, 2024)
        </Text>

        <div className="my-4 text-left">
          <Text size="small" className="mb-2">
            Caleido LLC maintains this website, Caleido, and other linked and
            related sites or applications for the use of its customers, vendors,
            and other website users (“<strong>Users</strong>”) upon agreement to
            the following terms.
          </Text>

          <Text size="small" className="mb-2">
            Please read the following terms carefully before using the
            www.caleido.social website. Use of this website indicates acceptance
            of these “<strong>Terms of Service</strong>” and forms a binding
            agreement between you and Caleido LLC. If you do not agree to the
            following terms, do not use this website or application.
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            1. What Should I Know About Use of Caleido’s Site?
          </Text>

          <Text size="small" className="mb-2">
            These terms of service (“<strong>Terms</strong>” or “
            <strong>Terms of Service</strong>”) form an agreement between you,
            either as an individual or as the representative of an entity (“
            <strong>User,</strong>” “<strong>Customer,</strong>” “
            <strong>Your,</strong>” or “<strong>You</strong>”) and Caleido LLC
            (“<strong>Caleido,</strong>” “<strong>Us,</strong>” “
            <strong>We,</strong>” or “<strong>Our</strong>”). The Terms along
            with any documents expressly incorporated herein by reference,
            dictate the conditions under which you may access and utilize the
            Caleido website, www.caleido.social, or any of our other web
            properties, including mobile websites, applications, or digital
            platforms that link to or refer to these Terms of Service
            (collectively, the “<strong>Site</strong>”).
          </Text>
          <Text size="small" className="mb-2">
            In the event of any conflict between these Terms of Service and the
            conditions of any service contract between you and Caleido, the
            terms of the service contract will prevail concerning the specific
            services detailed therein. By engaging with the Site, including but
            not limited to browsing the Site, using our services, or providing
            information to Caleido, you accept and are bound by these Terms of
            Service. If any aspect of these Terms is not acceptable to you, you
            are not granted permission to access or use the Site.
          </Text>
          <Text size="small" className="mb-2">
            The Site is accessible to users who are 18 years or older and wish
            to participate in coordinated events and meetup services facilitated
            by Caleido (collectively referred to as, “
            <strong>Caleido Services</strong>”). Caleido helps users to plan and
            coordinate the logistics of their meetups through text-based and
            in-app invites, polls, and reminders. By using the Site, you certify
            that you are of legal age to form a binding contract with Caleido
            and satisfy all eligibility requirements. If these requirements are
            not met, you are not permitted to access or use the Site.
          </Text>
          <Text size="small" className="mb-2">
            Additionally, you acknowledge that certain privacy practices are
            applicable to your use of the Site and consent to such practices.
            For more information, please refer to Caleido’s Privacy Policy here
            (“<strong>Privacy Policy</strong>”). It is crucial for users to have
            adequate language proficiency in the language used to present
            Caleido Services.
          </Text>
          <Text size="small" className="mb-2">
            Note that these prices for Caleido Services and other digital
            products available for purchase on the Site exclude any taxes or
            additional fees that may apply.
          </Text>

          <Text size="small" className="mb-2">
            <strong>
              IMPORTANT NOTICE: <u>SECTION 23</u> OF THESE TERMS OF SERVICE
              CONTAINS AN ARBITRATION PROVISION. BY ACCESSING OR USING THE SITE,
              YOU ACKNOWLEDGE AND AGREE TO BE BOUND BY THESE TERMS OF SERVICE
              AND OUR PRIVACY POLICY, FOUND ON OUR WEBSITE INCORPORATED HEREIN
              BY REFERENCE.
            </strong>
            If you do not wish to accept these Terms of Service or the Privacy
            Policy, you are not permitted to access or use the Site.
          </Text>
          <Text size="small" className="mb-2">
            Caleido is a social calendar web-app that helps make it easier for
            users to find time with friends, coordinate events, and stay
            committed to plans through reminders.
          </Text>
          <Text size="small" className="mb-2">
            Users are prohibited from copying, distributing, publishing,
            posting, displaying, performing, modifying, creating derivative
            works from, transmitting, or in any way exploiting any content from
            Caleido Services and/or the Site. Selling or offering any of the
            content for sale, allowing unauthorized third-party access to it, or
            using such content to construct any database is strictly forbidden.
            Users are expected to maintain strict confidentiality of all
            information provided on this Site (“<strong>Materials</strong>”),
            not selling, auctioning, renting, loaning, gifting, describing,
            summarizing, or otherwise revealing the Materials or their contents,
            to any unauthorized person or entity. A violation of these Terms of
            Use will automatically terminate your authorized use of the Site.
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            2. What Should I Know About Changes to Caleido’s Terms of Service?
          </Text>
          <Text size="small" className="mb-2">
            Caleido reserves the right to revise, update, or modify the Site,
            its associated services, and these Terms of Service at any time and
            at our sole discretion. All changes become effective immediately
            upon their posting to the Site and apply to all subsequent access
            and continued use of the Site. In addition to updating the Site,
            Caleido may also periodically revise the content provided on the
            Site. However, please note that such content might not necessarily
            reflect the most current information available, and we are under no
            obligation to maintain its completeness or accuracy at all times.
          </Text>
          <Text size="small" className="mb-2">
            Your continued usage of the Site subsequent to the posting of any
            revised Terms of Service signifies your acceptance of, and agreement
            to abide by, these changes. It is your responsibility to frequently
            review this page each time you access the Site to ensure you are
            apprised of any changes, as they bind you legally. We encourage you
            to periodically review these Terms of Service to stay informed about
            the most recent version.
          </Text>
          <Text size="small" className="mb-2">
            Nevertheless, any modifications to the dispute resolution provisions
            outlined in the{' '}
            <strong>
              <u>Governing Law and Jurisdiction</u>
            </strong>{' '}
            section will not apply to any disputes for which the parties have
            actual notice on or prior to the date the change is posted on the
            Site.
          </Text>
          <Text size="small" className="mb-2">
            Caleido reserves the right, at its sole discretion and at any time,
            to discontinue the Site or any component thereof, with or without
            notice. Furthermore, Caleido may restrict your use of the Site, with
            or without prior notice. You agree that you possess no proprietary
            rights in the Site, and that Caleido will bear no liability towards
            you if the Site is discontinued or if your ability to access the
            Site, or any content you may have posted thereon, is terminated.
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            3. Information About You and Your Visits to the Website; Protection
            of Your Privacy
          </Text>
          <Text size="small" className="mb-2">
            All data and information that we collect on the Site are governed by
            our Privacy Policy, which is incorporated into these Terms of
            Service by reference. Your use of the Site constitutes your
            acknowledgment of, and consent to, our collection, storage, use, and
            disclosure of your information as outlined in our Privacy Policy. By
            using the Site, you consent to all actions taken by use with respect
            to your information, consistent with our Privacy Policy.
          </Text>
          <Text size="small" className="mb-2">
            All information that you share with us or that we gather through
            your utilization of the Site is subject to our Privacy Policy, which
            is available on the Site. This Privacy Policy outlines how we
            collect, use, store, and safeguard your personal data, and addresses
            your rights and our responsibilities regarding your information.
          </Text>
          <Text size="small" className="mb-2">
            By accessing and using the Site, you give your explicit consent to
            all actions we undertake in accordance with our Privacy Policy. This
            includes, but is not limited to, the collection, processing,
            storage, and sharing of your data, as far as legally permissible and
            as detailed in our Privacy Policy.
          </Text>
          <Text size="small" className="mb-2">
            Please ensure that you thoroughly review our Privacy Policy and
            understand how we manage your data. If you have any queries or
            concerns, please do not hesitate to contact us. Your trust and
            privacy are of paramount importance to us, and we commit to handling
            your data responsibly and in line with applicable privacy and data
            protection laws.
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            4. Hyperlinks
          </Text>
          <Text size="small" className="mb-2">
            The Site may contain hyperlinks or references to external websites
            that are operated by third parties, and are neither maintained by
            nor affiliated with Caleido. These hyperlinks are provided merely as
            a convenience for your reference and informational purposes only.
            Caleido has not thoroughly reviewed these external websites, and
            does not exercise any control over them. Therefore, Caleido cannot
            be held responsible for any aspect of these websites, including
            their content, their privacy practices, or any other policies.
          </Text>
          <Text size="small" className="mb-2">
            Caleido does not endorse, nor does it make any representations or
            warranties about the content, completeness, or accuracy of these
            third-party websites. This includes hyperlinks contained in
            advertisements, which may take the form of banner advertisements and
            sponsored links. We exert no control over the content of these
            third-party websites or resources and cannot be held responsible for
            any loss or damage that may result from your utilization of these
            websites.
          </Text>
          <Text size="small" className="mb-2">
            Please be aware that any information you submit to a third-party
            website that is accessible from our Site is subject to that
            website’s privacy policy. Caleido has no control over how these
            third-party websites collect, use, or otherwise handle your
            information.
          </Text>
          <Text size="small" className="mb-2">
            If you decide to access any of the external websites linked to our
            Site, you are choosing to do so entirely at your own risk. It
            remains your responsibility to ensure the safety of your digital
            environment. This means that you should be vigilant about checking
            the links that you click on, and the software that you download,
            whether from our Site or from other websites or applications. You
            should ensure that these digital interactions do not expose you to
            harmful elements such as viruses, worms, trojan horses, and other
            items of a potentially destructive nature.
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            5. User Representations and Warranties
          </Text>
          <Text size="small" className="mb-2">
            As a User, you represent and warrant that you are not an agent or
            employee of any company that offers similar services to Caleido. You
            also affirm that your use of the Site and Materials is solely for
            the purpose of enhancing your understanding, and participation in
            Caleido offerings, including but not limited to, your ability to
            plan and coordinate the logistics of user meetups through text-based
            and in-app invites, polls and reminders.
          </Text>
          <Text size="small" className="mb-2">
            You further warrant that your interactions with Caleido and use of
            the Site, Services, and Materials will not infringe upon or violate
            the rights of any third party or violate any local, state, national,
            or international laws or regulations, or any policies or guidelines
            established by Caleido.
          </Text>
          <Text size="small" className="mb-2">
            Any violation of this warranty may lead to the termination of your
            access to the Site and Caleido Services, at the sole discretion of
            Caleido.
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            6. Terms of Use for the Site
          </Text>
          <Text size="small" className="mb-2">
            The Site provides access to our social calendar web-app that helps
            make it easier for you to find time with friends, coordinate events,
            and stay committed to plans through reminders, and other products
            and services (the “<strong>Offerings</strong>”) that we provide to
            our users and customers. The content on the Site provided by Caleido
            or its licensors, including but not limited to graphics,
            photographs, images, screenshots, text, trademarks, logos, product
            and program names, slogans, and the compilation of these (“
            <strong>Caleido Content</strong>”) is the property of Caleido and
            its licensors, protected under copyright, trademark, and other
            intellectual property laws. The Site is not intended to grant any
            rights or licenses to download or use any Caleido Content without
            express written permission.
          </Text>
          <Text size="small" className="mb-2">
            You agree not to download, display, or use any Caleido Content from
            the Site for unauthorized purposes, in connection with products or
            services unrelated to Caleido, or in a way that could cause
            confusion among users, disparage Caleido or its licensors, dilute
            the strength of Caleido’s property, or infringe upon Caleido’s or
            its licensors’ intellectual property rights. Misuse of any Caleido
            Content from the Site is strictly prohibited.
          </Text>
          <Text size="small" className="mb-2">
            BY USING THE SITE, YOU ASSERT THAT YOU HAVE AUTHORITY TO UTILIZE OUR
            SERVICES AND PRODUCTS FOR YOURSELF OR THE ENTITY YOU REPRESENT. IF
            YOU ARE ACTING ON BEHALF OF A BUSINESS, YOU ASSERT THAT YOU HAVE THE
            AUTHORITY TO BIND THE BUSINESS TO THESE TERMS OF SERVICE.
          </Text>
          <Text size="small" className="mb-2">
            From time to time, certain areas of the Site may allow you to submit
            photos, written posts, designs, events, and other materials (“
            <strong>User Content</strong>”). By using these features, you agree
            to the outlined responsibilities regarding User Content in our User
            Responsibilities and Technology Security section. Any User Content
            you submit gives Caleido the right to use, modify, publicly display,
            reproduce, and distribute such User Content, as explained further in
            the User Responsibilities section.
          </Text>
          <Text size="small" className="mb-2">
            Descriptions of our Offerings on the Site are intended to provide a
            general overview and do not guarantee specific content. Caleido
            reserves the right to modify any Offering details at its discretion
          </Text>
          <Text size="small" className="mb-2">
            Caleido does not control nor does Caleido assume responsibility for
            the types of events hosted on the platform and what may occur at any
            events available through Caleido Services. Caleido will attempt to
            restrict all illegal activity on the Site according to US law and
            protect the safety and privacy of its users.
          </Text>
          <Text size="small" className="mb-2">
            <i>
              By displaying, publishing, or otherwise posting any User Content
              on or through the Site, you hereby irrevocably grant to Caleido a
              non-exclusive, fully-sublicensable, transferrable, irrevocable,
              worldwide, fully-paid up, royalty-free license to copy, export,
              adapt, edit, translate, use, modify, publicly perform, publicly
              display, broadcast, publish, reproduce, and distribute such User
              Content in any and all media now known, or hereinafter developed,
              without the requirement to make payment to you or to any third
              party or the need to seek your or any third party permission.
              Further, you waive all moral rights in and to all User Content
              that you post on, upload or otherwise submit to or through, the
              Site in favor of Caleido. This license and waiver of rights
              includes the right to host, index, cache, distribute, and tag any
              User Content, as well as the right to sublicense User Content to
              third parties, including other users, for use on other media or
              platforms known or hereinafter developed, such as for use on
              mobile phones, in video or music software computer programs.{' '}
              <strong>
                You continue to retain all ownership rights you hold in your
                User Content, and you retain any right to use your User Content
                in any way you choose, subject to these Terms of Service and the
                license described herein.
              </strong>{' '}
              You represent and warrant that you own all of the User Content
              submitted, displayed, published or posted by you on the Site and
              otherwise have the right to grant the license set forth herein,
              and the displaying, publishing or posting of any content you
              submit, and our use thereof does not and will not violate the
              privacy rights, publicity rights, copyrights, trademark rights,
              patents, contract rights or any other intellectual property rights
              or other rights of any person or entity.
            </i>
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            7. Ownership of Intellectual Property Rights, Trademarks and
            Copyrights on the Site
          </Text>
          <Text size="small" className="mb-2">
            All contents of this Site are &copy;2024 Caleido LLC or respective
            third-party owners. All rights are reserved.
          </Text>
          <Text size="small" className="mb-2">
            The Site’s entire contents, features, and functionality, including
            but not limited to all information, software, text, displays,
            images, logos, graphics, pictures, data, sound files, photographs,
            video, and audio, and the design, selection, and arrangement
            thereof, are owned by Caleido, its licensors, or other providers of
            such material. These contents are protected by United States and
            international copyright, trademark, patent, trade secret, and other
            intellectual property or proprietary rights laws (whether registered
            or not).
          </Text>
          <Text size="small" className="mb-2">
            If you become aware of a potential infringement of our intellectual
            property, please contact us at{' '}
            <Link to={'mailto:hi@caleido.social'}>hi@caleido.social</Link>.
          </Text>
          <Text size="small" className="mb-2">
            We respect the intellectual property rights of others and respond to
            any claim that content posted on the Site infringes on the
            copyright, trademark, or other intellectual property rights or any
            person or entity. If you believe in good faith that the content on
            the Site infringes on your intellectual property rights, you or your
            agent may send us a written notice titled “ Infringement of
            Intellectual Property Rights – DMCA.” Your notice should include the
            following:
            <ul className="list-disc ml-8 my-2">
              <li>
                An electronic or physical signature of the person authorized to
                act on behalf of the intellectual property right owner’s
                interest;
              </li>
              <li>
                A description of the work that you claim has been infringed,
                including the URL (i.e., web page address) of the location where
                the work exists or a copy of the work;
              </li>
              <li>Your name, email, address and telephone number; and</li>
              <li>
                A statement by you that you have a good faith belief that the
                disputed use is not authorized by the owner, its agent, or the
                law.
              </li>
            </ul>
          </Text>
          <Text size="small" className="mb-2">
            Please note that we will not process your complaint if it is not
            properly filled out or is incomplete. Misrepresentation or bad faith
            claims regarding the infringement of your intellectual property
            rights by the content posted on the Site may make you liable for
            damages, including but not limited to costs and attorney’s fees.
          </Text>
          <Text size="small" className="mb-2">
            Please submit your claim by contacting Caleido via the following:
            <br />
            Email:{' '}
            <Link to={'mailto:hi@caleido.social'}>hi@caleido.social</Link>
            <br />
            Country: United States of America
          </Text>
          <Text size="small" className="mb-2">
            These Terms of Service permit you to use the Site for your personal,
            non-commercial use. You must not upload, distribute, modify,
            publicly display, republish, store, or transmit any of the materials
            on the Site, except as permitted by these Terms of Service. Actions
            such as temporary storage of materials in RAM incidental to
            accessing and viewing those materials, or storing files
            automatically cached by your web browser for display enhancement
            purposes for personal, non-commercial use, are permissible.
          </Text>
          <Text size="small" className="mb-2">
            You must not modify, delete, or alter any copyright, trademark, or
            other proprietary rights notices from copies of materials from the
            Site. Any breach of these Terms of Service may result in your right
            to use the Site being immediately revoked, and you may be required
            to return or destroy any copies of the materials you have made. No
            right, title, or interest in or to the Site or any content on the
            Site is transferred to you. All rights not expressly granted are
            reserved by Caleido. Unauthorized use of the Site may breach
            copyright, trademark, and other laws.
          </Text>
          <Text size="small" className="mb-2">
            Caleido and certain other brands, trademarks, and service marks are
            marks of Caleido and its affiliates. The Materials on the Site are
            copyrighted, and any unauthorized use may violate copyright,
            trademark, and other laws. All materials--including but not limited
            to text, photographs, video, audio, and images--appearing on the
            Site are protected by copyrights and/or other proprietary rights
            belonging to Caleido and/or other third parties.
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            8. Prohibited Uses of the Site
          </Text>
          <Text size="small" className="mb-2">
            You are required to use this Site in compliance with all applicable
            laws, rules, regulations, and these Terms at all times. The Site is
            to be used only for lawful purposes and in accordance with these
            Terms of Service. The following non-exhaustive list outlines
            prohibited uses of this Site. By using the Site, you agree not to:
            <ul className="list-disc ml-8 my-2">
              <li>
                Violate any applicable federal, state, provincial, local, or
                international law or regulation (including, without limitation,
                any laws regarding the export of data or software to and from
                the US or other countries).
              </li>
              <li>
                Exploit, harm, or attempt to exploit or harm minors in any way
                by exposing them to inappropriate content, asking for personal
                information, or otherwise.
              </li>
              <li>
                Transmit, or procure the sending of, any advertising or
                promotional material without our prior written consent,
                including any “junk mail,” “ chain letter,” “spam,” or any other
                similar solicitation.
              </li>
              <li>
                Impersonate or attempt to impersonate Caleido, a Caleido
                employee, a Caleido agent, another user or customer of our
                products and services, or any other person or entity (including,
                without limitation, by using the email addresses or screen names
                associated with any of the foregoing).
              </li>
              <li>
                Misrepresent your identity or affiliation with any person or
                entity.
              </li>
              <li>
                Engage in any conduct that restricts or inhibits anyone’s use or
                enjoyment of the Site, or which, as determined by us, may harm
                Caleido or users of the Site, or expose them to liability
              </li>
            </ul>
          </Text>
          <Text size="small" className="mb-2">
            Further, you agree not to:
            <ul className="list-disc ml-8 my-2">
              <li>
                Use the Site in any manner that could disable, overburden,
                damage, or impair the Site or interfere with any other party’s
                use of the Site, including their ability to engage in real-time
                activities through the Site.
              </li>
              <li>
                Abuse the Site by requesting an unreasonable amount of requests
                not reasonably related to purchasing a Caleido product or
                service, i.e., imposing an unreasonable load on the service
                infrastructure and attempting to overburden the services.
              </li>
              <li>
                Use any robot, spider, or other automatic device, process, or
                means to access the Site for any purpose, including using any
                manual process or means for monitoring or copying any of the
                material on the Site or for any other unauthorized purpose.
              </li>
              <li>
                Use any manual process to monitor or copy any of the material on
                the Site, or for any other purpose not expressly authorized in
                these Terms of Service, without our prior written consent.
              </li>
              <li>
                Use any device, software, or routine that interferes with the
                proper working of the Site.
              </li>
              <li>
                Introduce any viruses, Trojan horses, worms, logic bombs, or
                other material which is malicious or technologically harmful.
              </li>
              <li>
                Attempt to gain unauthorized access to, interfere with, damage,
                or disrupt any parts of the Site, the server on which the Site
                is stored, or any server, computer, or database connected to the
                Site.
              </li>
              <li>
                Attempt to attack or attack the Site via a denial-of-service
                attack or a distributed denial-of-service attack.
              </li>
              <li>
                Use any electronic communication feature of the Site for any
                purpose that is unlawful, tortious, abusive, intrusive on
                another’s privacy, harassing, libelous, defamatory,
                embarrassing, obscene, threatening, or hateful, all as
                determined by Caleido in its sole and absolute discretion.
              </li>
              <li>
                Upload, post, reproduce, or distribute any information,
                software, or other material protected by copyright or any other
                intellectual property right (as well as rights of publicity and
                privacy) without first obtaining the permission of the owner of
                such rights.
              </li>
              <li>Collect or store personal data about other users.</li>
              <li>
                Use the Site for any commercial purpose not expressly approved
                by Caleido in writing
              </li>
            </ul>
          </Text>
          <Text size="small" className="mb-2">
            Engaging in any of the prohibited uses may result in the termination
            of your right to use the services and the Site.
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            9. Geographic Restrictions
          </Text>
          <Text size="small" className="mb-2">
            The Site is operated by Caleido from its offices in the State of New
            York, United States. While we have designed the Site to be
            accessible and usable for persons located in the United States, we
            cannot guarantee that the Site, or any of its contents, are
            appropriate or available for use in other locations
          </Text>
          <Text size="small" className="mb-2">
            If you choose to access the Site from outside these areas, such
            access will be on your own initiative. You will be solely
            responsible for ensuring compliance with local laws, rules, and
            regulations, as well as these Terms of Service. Please be aware that
            any access to the Site from jurisdictions where its contents are
            illegal or strictly regulated is strictly prohibited.
          </Text>
          <Text size="small" className="mb-2">
            We reserve the right to limit the availability of the Site or any
            portion of the Site, to any person, geographic area, or
            jurisdiction, at any time and in our sole discretion. If any
            material on this Site, or your use of the Site, is contrary to the
            laws of the place where you are when you access it, the Site is not
            intended for you, and we ask you not to use the Site.
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            10. User Responsibilities and Technology Security
          </Text>
          <Text size="small" className="mb-2">
            Users are responsible for maintaining the confidentiality of their
            assigned account credentials necessary to access Caleido Services.
            Should there be any breach, suspected breach, or unauthorized use of
            their login credentials, users are obligated to notify Caleido
            immediately.
          </Text>
          <Text size="small" className="mb-2">
            While Caleido strives to provide Services that are broadly
            accessible and useful, we do not guarantee that our Services are
            appropriate or available for every audience or every location. It is
            possible that accessing Caleido Services might not be legal in
            certain jurisdictions. Users who choose to access our Services from
            such locations do so on their own initiative and are solely
            responsible for compliance with local laws.
          </Text>
          <Text size="small" className="mb-2">
            Caleido reserves the right to remove or restrict access to our
            Services for any user who fails to adhere to our guidelines, or who
            disrespects Caleido staff, other users, or the community at large.
          </Text>
          <Text size="small" className="mb-2">
            Our Services are primarily facilitated through the Site. Users are
            responsible for ensuring that they possess the necessary hardware,
            software, and internet connectivity to fully utilize our Services.
          </Text>
          <Text size="small" className="mb-2">
            Caleido is not responsible for any service interruptions or errors
            that arise from circumstances outside of our control. This includes,
            but is not limited to:
            <ul className="list-disc ml-8 my-2">
              <li>
                loss of connectivity or service interruptions on Caleido’s end
                or the user’s end;
              </li>
              <li>
                technical difficulties, glitches, or other problems with the
                online learning management system; or
              </li>
              <li>
                issues with the user’s personal hardware, software, or internet
                connection.
              </li>
            </ul>
          </Text>
          <Text size="small" className="mb-2">
            In any such instances, Caleido will strive to resolve the issue
            promptly but cannot be held accountable for any resulting
            difficulties in accessing our Services or associated materials.
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            11. Accounts Creation and Management; Site Access and Security
          </Text>
          <Text size="small" className="mb-2">
            <u>Account Creation and Management</u>
          </Text>
          <Text size="small" className="mb-2">
            The use of the Site may require account creation or submission of
            certain information, which you affirm to be correct, current,
            complete, and authorized to disclose. As part of the registration
            process, you’ll be asked to provide your name, phone number, and a
            valid e-mail address. You are entirely responsible for maintaining
            the confidentiality of your account and for all activities occurring
            under your account, including without limitation restricting access
            to your devices. You accept responsibility for all activities that
            occur under your account, whether your password is for our Site or a
            third-party service. You agree to notify Caleido immediately of any
            unauthorized use of your account
          </Text>
          <Text size="small" className="mb-2">
            When you create an account on our Site, you affirm that you are at
            least 18 years of age, and the information you provide is accurate,
            comprehensive, and up-to-date. Any account with inaccurate,
            incomplete, or obsolete information may be terminated immediately.
          </Text>
          <Text size="small" className="mb-2">
            <u>Accessing the Site and Account Security</u>
          </Text>
          <Text size="small" className="mb-2">
            We reserve the right to modify, withdraw, or discontinue the Site
            and any service or material we provide in association with the Site,
            at our sole discretion without prior notice. We shall not be liable
            if for any reason all or any part of the Site or online content is
            unavailable at any time or for any period. Please note, our Site may
            depend on third-party applications that we use or that you have
            provided.
          </Text>
          <Text size="small" className="mb-2">
            You are responsible for:
            <ul className="list-disc ml-8 my-2">
              <li>Making all arrangements necessary to access the Site.</li>
              <li>
                Ensuring that all individuals who access the Site through your
                Internet connection are aware of these Terms of Service and our
                Privacy Policy and comply with them.
              </li>
            </ul>
          </Text>
          <Text size="small" className="mb-2">
            Some resources or features of the Site may require you to provide
            certain registration details or other information. If you choose not
            to provide such information, your access to certain content or
            participation in certain features may be limited or prohibited.
          </Text>
          <Text size="small" className="mb-2">
            You further agree not to email, post, or otherwise disseminate any
            information providing you access to the Site. Caleido is not liable
            for any loss incurred as a result of someone else using your
            account, either with or without your knowledge. We also bear no
            responsibility for any delay in shutting down your account after you
            have reported a security breach to us.
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            12. Mobile Device Access
          </Text>
          <Text size="small" className="mb-2">
            If you access the Site using a mobile device, your wireless service
            provider’s standard charges, data rates, and other fees may apply.
            Furthermore, depending on your mobile device and service provider,
            downloading, installing, or using certain features of the Site could
            be restricted or prohibited. It’s worth noting that not all features
            of the Site may be compatible with all carriers or devices.
          </Text>
          <Text size="small" className="mb-2">
            You are responsible for understanding the terms of your mobile
            device and service agreement and for any mobile data or other fees
            that using the Site might incur. We recommend contacting your
            service provider for more details regarding these potential charges
            or restrictions. Caleido bears no responsibility for any additional
            charges, restrictions, or compatibility issues that may arise from
            mobile device usage.
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            13. No Guaranteed Outcomes
          </Text>
          <Text size="small" className="mb-2">
            Users acknowledge that using the Site and Caleido Services does not
            guarantee any specific result. Caleido does not make any promises,
            explicit or implied, that users will gain any particular financial
            or other economic benefit by using the Site or Caleido Services.
          </Text>
          <Text size="small" className="mb-2">
            The Company’s role is to help users plan and coordinate the
            logistics of their meetups through text-based and in-app invites,
            polls, and reminders. However, the Company cannot control external
            factors or decisions made by third-party entities.
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            14. Independent Status
          </Text>
          <Text size="small" className="mb-2">
            Caleido operates as an independent technology company that offers a
            social calendar web app that helps make it easier for you to find
            time with friends, coordinate events, and stay committed to plans
            through reminders. Caleido does not act as an agent or
            representative, and does not operate on behalf of any specific
            organization or entity.
          </Text>
          <Text size="small" className="mb-2">
            Our relationship with users and third-party organizations is
            independent, and we do not receive fees from such institutions or
            programs for referring or placing a user. Caleido’s mission is to
            provide the tools and services that help our users manage their data
            and projects more efficiently and to facilitate helping users plan
            and coordinate the logistics of their meetups through text-based and
            in-app invites, polls, and reminders, while maintaining impartiality
            and independence in our operations.
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            15. Code of Conduct
          </Text>
          <Text size="small" className="mb-2">
            Users should understand that Caleido operates as a technology
            company, and not as a(n) event planning service. Users, by using our
            services, pledge to adhere to the highest standards of integrity and
            honesty. This includes refraining from any act of dishonesty or
            deceit, such as plagiarism, fraud, or misrepresentation. Any such
            misconduct is neither endorsed nor condoned by Caleido.
          </Text>
          <Text size="small" className="mb-2">
            Should we discover that a User has committed any act of dishonesty
            or misconduct while using our services, Caleido reserves the right
            to terminate the User’s access to our services immediately, without
            a refund. This commitment to ethical conduct is fundamental to the
            mission and values of Caleido, and we expect all Users to uphold
            this standard.
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            16. Monitoring, Enforcement, and Termination
          </Text>
          <Text size="small" className="mb-2">
            We reserve the right to:
            <ul className="list-disc ml-8 my-2">
              <li>
                Pursue legal actions, including, but not limited to, notifying
                law enforcement authorities, for any illegal or unauthorized use
                of the Site.
              </li>
              <li>
                Suspend or terminate your access to all or part of the Site for
                any reason or no reason at all, including, but not limited to,
                any violation of these Terms of Service.
              </li>
            </ul>
          </Text>
          <Text size="small" className="mb-2">
            In the enforcement of these terms, we maintain the right to
            cooperate fully with any law enforcement authorities or court orders
            requesting or directing us to disclose the identity or other
            information of anyone posting any materials on or through the Site.
            BY USING OUR SERVICES, YOU WAIVE AND HOLD HARMLESS CALEIDO AND ITS
            AFFILIATES, LICENSEES, SERVICE PROVIDERS, AND CONTRACTORS FROM ANY
            CLAIMS RESULTING FROM ANY ACTION TAKEN BY CALEIDO AND ANY OF THE
            FOREGOING PARTIES DURING, OR TAKEN AS A CONSEQUENCE OF,
            INVESTIGATIONS BY EITHER CALEIDO, OR LAW ENFORCEMENT AUTHORITIES.
          </Text>
          <Text size="small" className="mb-2">
            Please be aware that we do not guarantee to review all material
            before it is posted on the Site and cannot ensure prompt removal of
            objectionable material after it has been posted. As such, we assume
            no liability for any action or inaction regarding transmissions,
            communications, or content provided by any user or third party. We
            have no liability or responsibility to anyone for the performance or
            nonperformance of the activities described in this section.
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            17. Updates to the Website
          </Text>
          <Text size="small" className="mb-2">
            We regularly update and modify the content on the Site, however, we
            make no representation, warranty, or guarantee that the content on
            the Site is accurate, complete, or current at all times. The
            material on the Site may be out of date or may become out of date at
            any given time, and we are under no obligation to update or maintain
            the currency of such material. It is your responsibility to monitor
            changes to the Site and ensure that the information you rely upon is
            up to date.
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            18. Dependence on Information Provided
          </Text>
          <Text size="small" className="mb-2">
            The information provided on or through the Site is made available
            exclusively for informational purposes and should not be used as the
            sole basis for making decisions without consulting primary, more
            accurate, or more timely sources of information. While we strive to
            keep the information on the Site up to date and correct, we make no
            representations or warranties of any kind, express or implied, about
            the accuracy, completeness, reliability, suitability, or
            availability of any information, products, services, or related
            graphics contained on the Site. Any reliance you place on such
            information is therefore strictly at your own risk. We disclaim all
            liability and responsibility arising from any reliance placed on
            such materials by you or any other visitor to the Site, or by anyone
            who may be informed of any of its contents. We expressly disclaim
            any and all liability in connection with decisions made or actions
            taken based on the contents of the Site.
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            19. Cancelation by the Company
          </Text>
          <Text size="small" className="mb-2">
            At our sole discretion, Caleido reserves the right to cancel any of
            its services, for any reason, including but not limited to instances
            of fraud, inaccuracies, or unavailability of the purchased items or
            services. In such scenarios, Users will be notified in writing
            (which may include email communication) prior to the termination of
            the service.
          </Text>
          <Text size="small" className="mb-2">
            In the event of a planned cancellation, we will notify you
            accordingly. Our liability for such cancellations will be confined
            to any direct charges paid for the canceled services.
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            20. Disclaimer of Warranties
          </Text>
          <Text size="small" className="mb-2">
            You acknowledge that we cannot guarantee or assure that files
            available for download from the internet or the Site will be devoid
            of viruses or other harmful code. It is your responsibility to
            establish suitable procedures and checks to meet your specific
            requirements for anti-virus protection and data accuracy for both
            input and output. Additionally, you are expected to maintain an
            external means for the reconstruction of any lost data.
          </Text>
          <Text size="small" className="mb-2">
            <u>Availability, Errors, and Inaccuracies</u>
          </Text>
          <Text size="small" className="mb-2">
            Caleido does not guarantee the availability of the information,
            products, or services provided on the Site and accepts no liability
            for any errors or inaccuracies therein. There may be delays in
            updating information on the Site or in our advertising on other
            websites. The information, products, and services available on the
            Site may include errors or inaccuracies, or may not be complete or
            current. Products or services may be mispriced or unavailable, and
            we expressly reserve the right to correct any pricing errors on our
            Site. The presentation or offer of any product or service on this
            Site does not constitute an endorsement or recommendation of such
            product or service by Caleido.
          </Text>
          <Text size="small" className="mb-2">
            IN COMPLIANCE WITH APPLICABLE LAWS, WE WILL NOT BE HELD LIABLE FOR
            ANY DAMAGE OR LOSS CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK,
            VIRUSES, OR OTHER HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER
            EQUIPMENT, SOFTWARE, DATA, OR OTHER PROPRIETARY MATERIAL AS A RESULT
            OF YOUR USE OF THE SITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH
            THE SITE, OR DUE TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT,
            OR ANY SITE LINKED TO IT.
          </Text>
          <Text size="small" className="mb-2">
            OUR SITE IS PROVIDED ON AN “<strong>AS IS, AS AVAILABLE</strong>”
            BASIS. TO THE GREATEST EXTENT PERMITTED BY LAW, WE MAKE NO
            WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO
            MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, REGARDING THIS
            SITE, OR ANY INFORMATION, CONTENT, MATERIALS, OR PRODUCTS (INCLUDING
            SOFTWARE) CONTAINED HEREIN. CALEIDO (INCLUDING ITS OFFICERS,
            DIRECTORS, EMPLOYEES, SUPPLIERS, AND PROGRAMMERS) SHALL UNDER NO
            CIRCUMSTANCES BE HELD LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
            SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES RESULTING FROM THE USE
            OF OR INABILITY TO USE THE SITE, OR ANY PRODUCTS OR SERVICES
            AVAILABLE ON IT. NOR SHALL CALEIDO BE LIABLE FOR DAMAGES ARISING
            FROM MISTAKES, OMISSIONS, INTERRUPTIONS, FILE DELETIONS, ERRORS,
            DEFECTS, OPERATIONAL OR TRANSMISSION DELAYS, OR ANY PERFORMANCE
            FAILURES, WHETHER OR NOT CAUSED BY EVENTS BEYOND CALEIDO’S
            REASONABLE CONTROL, SUCH AS ACTS OF GOD, COMMUNICATIONS LINE
            FAILURE, THEFT, DESTRUCTION, OR UNAUTHORIZED ACCESS TO THE SITE’S
            RECORDS, PROGRAMS, OR SERVICES. UNDER NO CIRCUMSTANCES, INCLUDING
            BUT NOT LIMITED TO NEGLIGENCE, WILL CALEIDO OR ITS AFFILIATES OR
            AGENTS BE LIABLE FOR ANY DAMAGES RESULTING FROM THE USE OF, OR THE
            INABILITY TO USE, THE SITE, EVEN IF CALEIDO HAS BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES. MATERIALS ARE DOWNLOADED AT YOUR OWN
            RISK. CERTAIN JURISDICTIONS DO NOT PERMIT LIMITATIONS ON IMPLIED
            WARRANTIES OR THE EXCLUSION OF LIABILITY FOR CERTAIN TYPES OF
            DAMAGES, THEREFORE, SOME OR ALL OF THE ABOVE DISCLAIMERS,
            EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, DEPENDING ON YOUR
            LOCATION.
          </Text>
          <Text size="small" className="mb-2">
            The products or services, or both, described on the Site are offered
            in jurisdictions where they may be legally sold. The information on
            the Site does not constitute an offer or solicitation by anyone in
            any jurisdiction in which an offer or solicitation cannot legally be
            made, or to any person to whom it is unlawful to make a
            solicitation.
          </Text>
          <Text size="small" className="mb-2">
            THE ABOVE DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR
            LIMITED UNDER APPLICABLE LAW.
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            21. Limitation of Liability & Remedies
          </Text>
          <Text size="small" className="mb-2">
            Caleido, its suppliers, or other third parties referenced on the
            Site shall not be liable for any damages of any kind (including, but
            not limited to, those resulting from reduced outcomes, service
            interruptions, or incorrect information) resulting from the use,
            delayed use, or inability to use the Site, any websites linked to
            this Site, or any materials or information contained on any such
            sites. This limitation applies regardless of the legal theory the
            claim is based on, whether it be warranty, contract, tort, or
            otherwise, and whether or not Caleido has been advised of the
            potential for such damages. This includes, but is not limited to,
            your reliance upon opinions or information appearing on this Site,
            any computer viruses, information, software, linked websites
            operated by third parties, products or services obtained through
            this Site, whether based on a theory of negligence, contract, tort,
            strict liability, or consumer protection statutes, even if Caleido
            has been advised of the possibility of such damages. If your use of
            materials or information from this Site necessitates servicing,
            repair, or correction of equipment or data, you assume all costs
            thereof.
          </Text>
          <Text size="small" className="mb-2">
            TO THE MAXIMUM EXTENT ALLOWED BY LAW, CALEIDO DOES NOT MAKE ANY
            REPRESENTATIONS ABOUT THE RELIABILITY OF THE FEATURES OF THIS SITE,
            CALEIDO CONTENT, USER CONTENT, OR ANY OTHER SITE FEATURES, AND
            DISCLAIMS ALL LIABILITY IN THE EVENT OF ANY SERVICE FAILURE. YOU
            ACKNOWLEDGE THAT THE ENTIRE RISK ARISING OUT OF YOUR USE OF THIS
            SITE AND THE PURCHASE OF CALEIDO SERVICES REMAIN SOLELY WITH YOU.
            NEITHER CALEIDO, NOR ANY OTHER PERSON OR ENTITY INVOLVED IN THE
            CREATION, PRODUCTION, OR DELIVERY OF THE SITE, WILL BE LIABLE FOR
            INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES, INCLUDING
            LOST PROFITS, LOSS OF DATA OR GOODWILL, SERVICE INTERRUPTION,
            COMPUTER DAMAGE OR SYSTEM FAILURE, OR THE COST OF SUBSTITUTE
            SERVICES OR SITE, OR FOR ANY DAMAGES FOR PERSONAL OR BODILY INJURY
            OR EMOTIONAL DISTRESS ARISING OUT OF OR IN CONNECTION WITH THESE
            TERMS OF SERVICE OR FROM THE USE OF OR INABILITY TO USE THE SITE,
            CALEIDO WEBSITES, OR THE INABILITY TO USE OR ACCESS A PROFILE OR ANY
            SPECIFIC PROFILE CONTENT, WHETHER BASED ON WARRANTY, CONTRACT, TORT
            (INCLUDING NEGLIGENCE), PRODUCT LIABILITY, OR ANY OTHER LEGAL
            THEORY, AND WHETHER OR NOT CALEIDO HAS BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH
            HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.
          </Text>
          <Text size="small" className="mb-2">
            IF, NOTWITHSTANDING THE LIMITATIONS SET OUT ABOVE, CALEIDO IS FOUND
            LIABLE FOR ANY LOSS OR DAMAGE WHICH ARISES OUT OF OR IS IN ANY WAY
            CONNECTED WITH ANY OF THE OCCURRENCES DESCRIBED ABOVE, THE CALEIDO’S
            LIABILITY WILL IN NO EVENT EXCEED THE AMOUNT YOU HAVE PAID TO
            CALEIDO IN CONNECTION WITH SUCH TRANSACTION(S) ON THIS SITE OR
            TWENTY THOUSAND DOLLARS, WHICHEVER IS LESS. THIS LIMITATION OF
            LIABILITY REFLECTS THE ALLOCATED RISK BETWEEN YOU AND CALEIDO AND IS
            A FUNDAMENTAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN YOU AND
            CALEIDO. THE LIMITATION SPECIFIED IN THIS SECTION WILL SURVIVE AND
            APPLY EVEN IF ANY LIMITED REMEDY SPECIFIED IN THESE TERMS IS FOUND
            TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.
          </Text>
          <Text size="small" className="mb-2">
            The limitation of liability set out above does not apply to
            liability resulting from our gross negligence or willful misconduct
            or death or bodily injury caused by products you purchase through
            the Site.
          </Text>
          <Text size="small" className="mb-2">
            Caleido does not endorse, verify, evaluate or guarantee any
            information provided by users and nothing shall be considered as an
            endorsement, verification, or guarantee of any User Content. You
            shall not create or distribute information, including, but not
            limited to, advertisements, press releases, or other marketing
            materials, or include links to any sites that contain or suggest an
            endorsement by Caleido without the prior review and written approval
            of Caleido.
          </Text>
          <Text size="small" className="mb-2">
            THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED
            OR LIMITED UNDER APPLICABLE LAW.
          </Text>
          <Text size="small" className="mb-2">
            In the event of a breach of these Terms relating to your purchase,
            you agree that your sole remedy shall be to pursue dispute
            resolution as stipulated in “Section 23. Arbitration” below. This
            remedy is intended to be your exclusive remedy for any breach of
            these Terms as it relates to your purchase.
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            22. Indemnification
          </Text>
          <Text size="small" className="mb-2">
            You agree to indemnify, defend, and hold Caleido, along with its
            parent organizations, subsidiaries, affiliates, licensors, and
            service providers, and its and their respective officers, directors,
            employees, contractors, agents, licensors, suppliers, successors,
            and assigns harmless from and against any claims, liabilities,
            liens, damages, judgments, awards, demands, losses, costs, expenses
            or fees, including reasonable attorneys’ fees and costs that arise
            from or are related to your breach of these Terms of Service or your
            use of the Site. This includes but is not limited to, your use of
            the Site’s content, services, and products beyond what is expressly
            authorized in these Terms of Service, or any violations of the same
            by any individuals under your control. It also includes any issues
            that arise from the use of any content--including User Content--that
            you have submitted, posted, transmitted, or otherwise provided to
            Caleido of the Site, or your use of any information obtained from
            the Site.
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            23. Dispute Resolution and Binding Arbitration
          </Text>
          <Text size="small" className="mb-2">
            At the sole discretion of Caleido, it may require that any disputes,
            controversies, or claims arising out of or relating to these Terms
            of Service or the use of the Site, including but not limited to
            disputes regarding their formation, interpretation, breach,
            violation, nullity, invalidity, non-performance, termination, or
            otherwise, be finally settled by binding arbitration under the
            Commercial Arbitration Rules and Supplementary Procedures for
            Consumer-Related Disputes (collectively, “<strong>AAA Rules</strong>
            ”) of the American Arbitration Association (“<strong>AAA</strong>”),
            as modified by these Terms, and will be administered by the AAA.
          </Text>
          <Text size="small" className="mb-2">
            The arbitration will be conducted by a single arbitrator selected in
            accordance with the AAA Rules in the State of New York, United
            States, unless you and Caleido agree otherwise. The laws of the
            State of New York shall govern all matters arising out of or
            relating to these Terms of Service, including, without limitation,
            its interpretation, construction, performance, and enforcement. Any
            award of the arbitrator(s) shall be final and binding on each of the
            parties, and may be entered as a judgment in any court of competent
            jurisdiction.
          </Text>
          <Text size="small" className="mb-2">
            This arbitration agreement will survive the termination of your
            relationship with Caleido.
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            24. Governing Law and Jurisdiction
          </Text>
          <Text size="small" className="mb-2">
            Notwithstanding the foregoing, Caleido retains the right to seek
            injunctive or other equitable relief in a court of competent
            jurisdiction to prevent the actual or threatened infringement,
            misappropriation, or violation of our copyrights, trademarks, trade
            secrets, patents, or other intellectual property rights. Despite our
            policies, we reserve the right to bring any suit, action, or
            proceeding against you for breach of these Terms of Service in your
            country of residence or any other relevant country. You waive any
            and all objections to the exercise of jurisdiction over you by such
            courts and to venue in such courts.
          </Text>
          <Text size="small" className="mb-2">
            These Terms of Service, their interpretation, performance, and any
            disputes that may arise from them shall be governed by and construed
            in accordance with the internal laws of the State of New York,
            United States, without regard to its principles of conflicts of
            laws. The provisions of the United Nations Convention on Contracts
            for the International Sale of Goods, the Uniform Commercial Code,
            and Incoterms do not apply.
          </Text>
          <Text size="small" className="mb-2">
            All legal actions or proceedings arising under or in connection with
            these Terms of Service shall be brought exclusively in the federal
            or state courts within the county of New York, in the State of New
            York, United States. By using the Site, you irrevocably consent and
            submit to the personal jurisdiction of such courts for the purpose
            of any such action, and waive any objections to the jurisdiction of
            such courts or that such courts represent an inconvenient forum.
          </Text>
          <Text size="small" className="mb-2">
            Please note that your use of the Site may be subject to other local,
            state, national, and international laws. You acknowledge and agree
            that you and Caleido are each waiving the right to a trial by jury
            or to participate as a plaintiff or class member in any purported
            class action or representative proceeding. Further, unless both you
            and Caleido otherwise agree in writing, the arbitrator may not
            consolidate more than one person’s claims, and may not otherwise
            preside over any form of any class or representative proceeding. If
            this specific paragraph is held unenforceable, then the remainder of
            this section shall be construed to preserve its essence.
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            25. Time Limitation for Claims
          </Text>
          <Text size="small" className="mb-2">
            YOU AGREE THAT ANY CAUSE OF ACTION, CLAIM, OR DISPUTE THAT YOU MAY
            HAVE ARISING OUT OF OR RELATED TO THESE TERMS OF SERVICE, YOUR USE
            OF THE SITE, OR CALEIDO’S SERVICES (COLLECTIVELY, “
            <strong>CLAIM</strong>”) MUST BE COMMENCED WITHIN SIX (6) MONTHS
            AFTER THE CLAIM ARISES. FAILURE TO INITIATE A CLAIM WITHIN THAT SIX
            (6) MONTHS PERIOD WILL RESULT IN THE CLAIM BEING FOREVER BARRED,
            REGARDLESS OF ANY LAW TO THE CONTRARY. THIS PERIOD IS NOT EXTENDED
            IF CALEIDO CHOOSES TO SUSPEND OR TERMINATE YOUR ACCESS TO THE SITE
            OR ITS SERVICES FOR ANY SUSPECTED OR ACTUAL BREACH OF THESE TERMS OF
            SERVICE.
          </Text>
          <Text size="small" className="mb-2">
            This section relating to the time limitation for claims will survive
            any termination of your access to the Site or Caleido’s services.
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            26. Waiver and Severability
          </Text>

          <Text size="small" className="mb-2">
            No act, omission or delay by Caleido in exercising any right or
            remedy provided under these Terms of Service or by law shall
            constitute a waiver of that or any other right or remedy, nor shall
            it prevent or restrict that further exercise of that or any other
            right or remedy by Caleido. No single or partial exercise of such
            right or remedy shall prevent or restrict the further exercise of
            that or any other right or remedy.
          </Text>
          <Text size="small" className="mb-2">
            In the event that any provision of these Terms of Service is
            determined by a competent authority to be invalid, unlawful or
            unenforceable to any extent, such provision will to that extent be
            severed from the remaining provisions which will continue to be
            valid and enforceable to the fullest extent permitted by law. If any
            provision of these Terms of Service is deemed invalid or
            unenforceable, the remaining portions shall remain in effect and the
            invalid or unenforceable provision will be deemed modified so as to
            be valid and enforceable to the maximum extent permitted by law.
          </Text>
          <Text size="small" className="mb-2">
            This clause shall not apply if the severance alters the basic nature
            of these Terms of Service or its contrary to public policy. In such
            cases, Caleido reserves the right to terminate your use of the Site
            and its services.
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            27. Entire Agreement; Force Majeure
          </Text>
          <Text size="small" className="mb-2">
            The Terms of Service, Privacy Policy, and any other guidelines,
            rules, or operating policies that Caleido may post on the Site, or
            provide to you from time to time, constitute the complete and
            exclusive understanding and agreement between you and Caleido
            concerning your use of the Site, and supersede and govern over all
            prior written and verbal agreements, representations, and
            understandings related to the Site.
          </Text>
          <Text size="small" className="mb-2">
            No partnership, joint venture, franchise, or agency relationship is
            intended or created between you and Caleido by these Terms of
            Service. Neither party has the power to obligate or bind the other
            beyond what is established in this agreement.
          </Text>
          <Text size="small" className="mb-2">
            If Caleido is prevented from carrying out its obligations under
            these Terms of Service due to circumstances beyond its reasonable
            control, including but not limited to acts of God, war, fire, riot,
            terrorism, earthquake, government mandates, or other similar events,
            this will not be deemed a breach of these Terms of Service. Upon
            occurrence of a Force Majeure event, Caleido will be excused from
            any further performance or obligation of the affected obligation for
            as long as such circumstances persist, and such non-performance
            shall not constitute a breach of this agreement.
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            28. Your Comments and Concerns
          </Text>
          <Text size="small" className="mb-2">
            The Site is operated by Caleido located in Brooklyn, NY 11237.
          </Text>
          <Text size="small" className="mb-2">
            We value your feedback and comments and are always interested in
            learning about ways we can improve your experience on our Site.
            Please direct all feedback, comments, and other inquiries, including
            any requests for technical support or concerns relating to the Site,
            to our dedicated support team at:{' '}
            <Link to={'mailto:hi@caleido.social'}>hi@caleido.social</Link>.
          </Text>
          <Text size="small" className="mb-2">
            For prompt resolution of your issues, please include as much detail
            as possible in your communication, including specifics about your
            issue, error messages, and any steps you have already taken to
            attempt to resolve the issue. We aim to respond to all
            communications within a reasonable timeframe, typically within 49
            hours during normal business hours.
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            29. Effective Date and Updates
          </Text>
          <Text size="small" className="mb-2">
            The Terms of Service herein are effective as of October 2nd, 2024.
            As our business evolves, Caleido reserves the right to revise,
            modify, or update these Terms at any time at our sole discretion.
            When we make changes, we will update the date at the top of the
            Terms and, in some cases, we may provide additional notice such as
            adding a statement to our homepage or sending you an email
            notification.
          </Text>
          <Text size="small" className="mb-2">
            Please ensure you review these Terms periodically to stay informed
            about any modifications. Your continued use of this Site following
            the posting of revised Terms of Service means that you accept and
            agree to the changes. If you do not agree to the amended Terms, you
            must stop using the Site.
          </Text>
          <Text size="small" className="mb-2">
            Please note that we reserve the right to determine the form and
            means of providing notifications to you, provided that you may opt
            out of certain means of notification as described in these Terms and
            our Privacy Policy.
          </Text>
        </div>
      </div>
    </OnboardingLayout>
  );
};

export default Terms;
