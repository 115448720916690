import Activities from 'modules/activity/activity';
import SignUp from 'modules/auth/pages/login/SignUp';
import Start from 'modules/auth/pages/start/Start';
import LoginVerify from 'modules/auth/pages/verify/LoginVerify';
import Verify from 'modules/auth/pages/verify/Verify';
import { HomeCards } from 'modules/home/pages/homeCards';
import { HomeDaily } from 'modules/home/pages/homeDaily';
import { HomeMonthly } from 'modules/home/pages/homeMonthly';
import { HomeSummary } from 'modules/home/pages/homeSummary';
import { HomeWeekly } from 'modules/home/pages/homeWeekly';
import CreateEvent from 'modules/letsMeet/pages/createEvent/CreateEvent';
import DraftEvent from 'modules/letsMeet/pages/draftEvents/DraftEvent';
import EditEvent from 'modules/letsMeet/pages/editEvent/EditEvent';
import MyEvent from 'modules/letsMeet/pages/myEvent/MyEvent';
import ReceiveEvent from 'modules/letsMeet/pages/receiveEvent/receiveEvent';
import ReceivePublicEvent from 'modules/letsMeet/pages/receivePublicEvent/receivePublicEvent';
import ReviewEvent from 'modules/letsMeet/pages/reviewEvent/ReviewEvent';
import ShareEvent from 'modules/letsMeet/pages/shareEvent/ShareEvent';
import ViewEvent from 'modules/letsMeet/pages/viewEvent/ViewEvent';
import Profile from 'modules/profile/pages/Profile';
import EditAvailability from 'modules/settings/pages/editAvailability/EditAvailability';
import EditContacts from 'modules/settings/pages/editContacts/EditContacts';
import EditNotifications from 'modules/settings/pages/editNotifications/EditNotifications';
import EditProfile from 'modules/settings/pages/editProfile/EditProfile';
import Help from 'modules/settings/pages/help/Help';
import Settings from 'modules/settings/pages/menu/Settings';
import { ReactElement } from 'react';
import { AuthType } from 'types/common';

export type RouteElement = { element: ReactElement; path: string };

const AUTH_PATHS = {
  START: 'start',
  SIGN_UP: 'create',
  SIGN_IN: 'login',
  TERMS: 'terms',
  VERIFY: 'verify',
  LOGIN_VERIFY: 'login-verify',
};

export const AUTH_ROUTES: RouteElement[] = [
  {
    element: <Start />,
    path: AUTH_PATHS.START,
  },
  {
    element: <SignUp type={AuthType.SIGN_UP} />,
    path: AUTH_PATHS.SIGN_UP,
  },
  {
    element: <SignUp type={AuthType.SIGN_IN} />,
    path: AUTH_PATHS.SIGN_IN,
  },
  {
    element: <Verify />,
    path: AUTH_PATHS.VERIFY,
  },
  {
    element: <LoginVerify />,
    path: AUTH_PATHS.LOGIN_VERIFY,
  },
];

const SETTINGS_PATH = {
  SETTINGS: '',
  EDIT_PROFILE: 'edit/profile',
  HELP: 'help',
  EDIT_AVAILABILITY: 'edit/availability',
  EDIT_CONTACTS: 'edit/contacts',
  EDIT_NOTIFICATIONS: 'edit/notifications',
};

export const SETTINGS_ROUTES: RouteElement[] = [
  {
    element: <Settings />,
    path: SETTINGS_PATH.SETTINGS,
  },
  {
    element: <EditProfile />,
    path: SETTINGS_PATH.EDIT_PROFILE,
  },
  {
    element: <Help />,
    path: SETTINGS_PATH.HELP,
  },
  {
    element: <EditAvailability />,
    path: SETTINGS_PATH.EDIT_AVAILABILITY,
  },
  {
    element: <EditContacts />,
    path: SETTINGS_PATH.EDIT_CONTACTS,
  },
  {
    element: <EditNotifications />,
    path: SETTINGS_PATH.EDIT_NOTIFICATIONS,
  },
];

const ONBOARDING_PATH = {
  ONBOARD: 'onboarding',
};

export const ONBOARDING_ROUTES: RouteElement[] = [
  {
    element: <Profile />,
    path: ONBOARDING_PATH.ONBOARD,
  },
];

const LETS_MEET_PATH = {
  CREATE_EVENT: '',
  MY_EVENT: 'my',
  ALL_EVENT: 'all',
  DRAFT_EVENT: 'draft',
  REVIEW_EVENT: 'review/:id',
  SHARE_EVENT: 'share/:id',
  EDIT_EVENT: 'edit/:id',
  END_POLL_EVENT: 'end-poll/:id/:dateSelected',
  VIEW_EVENT: 'view/:id',
  RECEIVE_EVENT: 'receive/:id',
  PUBLIC_EVENT: 'public/:id',
};

export const LETS_MEET_ROUTES: RouteElement[] = [
  {
    element: <CreateEvent />,
    path: LETS_MEET_PATH.CREATE_EVENT,
  },
  {
    element: <HomeSummary />,
    path: LETS_MEET_PATH.ALL_EVENT,
  },
  {
    element: <ReviewEvent />,
    path: LETS_MEET_PATH.REVIEW_EVENT,
  },
  {
    element: <ShareEvent />,
    path: LETS_MEET_PATH.SHARE_EVENT,
  },
  {
    element: <EditEvent />,
    path: LETS_MEET_PATH.EDIT_EVENT,
  },
  {
    element: <EditEvent endPollEvent />,
    path: LETS_MEET_PATH.END_POLL_EVENT,
  },
  {
    element: <DraftEvent />,
    path: LETS_MEET_PATH.DRAFT_EVENT,
  },
  {
    element: <MyEvent />,
    path: LETS_MEET_PATH.MY_EVENT,
  },
  {
    element: <ViewEvent />,
    path: LETS_MEET_PATH.VIEW_EVENT,
  },
  {
    element: <ReceiveEvent />,
    path: LETS_MEET_PATH.RECEIVE_EVENT,
  },
  {
    element: <ReceivePublicEvent />,
    path: LETS_MEET_PATH.PUBLIC_EVENT,
  },
];

export const ACTIVITIES_PATH = {
  ACTIVITIES: '',
};

export const ACTIVITIES_ROUTES: RouteElement[] = [
  {
    element: <Activities />,
    path: ACTIVITIES_PATH.ACTIVITIES,
  },
];

const HOME_PATH = {
  HOME: '',
  HOME_SUMMARY: 'summary',
  HOME_DAILY: 'daily',
  HOME_WEEKLY: 'weekly',
  HOME_MONTHLY: 'monthly',
  HOME_CARDS: 'cards'  
};

export const HOME_ROUTES: RouteElement[] = [
  {
    element: <HomeSummary />,
    path: HOME_PATH.HOME,
  },
  {
    element: <HomeSummary />,
    path: HOME_PATH.HOME_SUMMARY,
  },
  {
    element: <HomeDaily />,
    path: HOME_PATH.HOME_DAILY,
  },
  {
    element: <HomeWeekly />,
    path: HOME_PATH.HOME_WEEKLY,
  },
  {
    element: <HomeMonthly />,
    path: HOME_PATH.HOME_MONTHLY,
  },
  {
    element: <HomeCards />,
    path: HOME_PATH.HOME_CARDS,
  }
];
