import { useEffect, useState } from 'react';
import type React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { cloneDeep, isEmpty } from 'lodash';
import AvailabilityLayout from 'modules/profile/layouts/AvailabilityLayout/AvailabilityLayout';
import TimeSlotLayout from 'modules/profile/layouts/TimeSlotLayout/TimeSlotLayout';
import type {
  IAvailabilityPeriod,
  IAvailability,
} from 'modules/profile/types/types';
import { AvailabilityPeriodModel } from 'modules/profile/model/Period';
import { daysOfWeek } from 'constants/common';
import type { IStartAndEndTimeSlot } from 'modules/profile/components/availability/TimeSlot/TimeSlot';
import { Text } from 'components/text/primary/Text';
import {
  isTimeSlotAvailable,
  validateAvailabilityPeriods,
} from 'modules/profile/utils/availability';
import { v4 as uuidv4 } from 'uuid';

dayjs.extend(utc);
dayjs.extend(timezone);

type AvailabilitySlotProps = {
  title: keyof IAvailability;
  periods: IAvailabilityPeriod[];
  availability: IAvailability;
  onChange: (availability: IAvailability) => void;
  selectedDays: string[];
  setSelectedDays: (selectedDays: string[]) => void;
};

// export const calculateTotalMinutesAndCheck = (
//   periods: IAvailabilityPeriod[],
// ): boolean => {
//   const totalMinutes = periods.reduce(
//     (total, { start, end }) =>
//       total + dayjs(end.dateTime).diff(dayjs(start.dateTime), 'minute'),
//     0,
//   );
//   return totalMinutes <= 24 * 60;
// };

const AvailabilitySlot: React.FC<AvailabilitySlotProps> = ({
  title,
  periods,
  availability,
  onChange,
  selectedDays,
  setSelectedDays,
}) => {
  const [error, setError] = useState<string | null>(null);
  const [isChecked, setIsChecked] = useState<boolean>(
    availability?.[title]?.[0]?.allDay ?? false,
  );

  useEffect(() => {
    setError(null);
    if (periods.length < 2) return;

    if (validateAvailabilityPeriods(periods))
      setError('The time slot overlaps with an existing slot.');
  }, [periods]);

  const handleCheckboxChange = (checked: boolean) => {
    setIsChecked(checked);
    setError(null);

    const cloneAvailability = cloneDeep(availability);

    if (checked) {
      cloneAvailability[title] = [
        new AvailabilityPeriodModel(
          [],
          checked,
          uuidv4(),
          dayjs().startOf('day').format(),
          dayjs().endOf('day').format(),
        ),
      ];
    } else {
      cloneAvailability[title] = [new AvailabilityPeriodModel()];
    }
    onChange(cloneAvailability);
  };

  const handleDelete = (id: string) => {
    setError(null);
    const cloneAvailability = cloneDeep(availability);
    if (cloneAvailability[title]) {
      cloneAvailability[title] = cloneAvailability?.[title]?.filter(
        (period) => period.id !== id,
      );
    }
    if (isEmpty(cloneAvailability[title])) {
      delete cloneAvailability[title];
      setSelectedDays(selectedDays.filter((days) => days !== title));
    }
    onChange(cloneAvailability);
  };

  const handleAvailableTimeClick = () => {
    setError(null);
    try {
      const cloneAvailability = cloneDeep(availability);
      const periods = cloneAvailability[title] || [];
      if (isTimeSlotAvailable(periods)) {
        cloneAvailability[title]?.push(new AvailabilityPeriodModel(periods));
        onChange(cloneAvailability);
      } else {
        setError(
          'Not enough time left in the 24-hour period to add a new availability period.',
        );
      }
    } catch (error) {
      setError(
        'Not enough time left in the 24-hour period to add a new availability period.',
      );
    }
  };

  const handleTimeSlotChange = (
    slotId: string,
    newTimeSlot: IStartAndEndTimeSlot,
  ) => {
    // const isValid = validateTimeSlot(newTimeSlot);
    // if (!isValid) return;
    const updatedAvailability = { ...availability };
    const daySlots = updatedAvailability[title];
    if (daySlots) {
      const updatedSlots: IAvailabilityPeriod[] = daySlots.map((slot) => {
        if (slot.id === slotId) {
          return {
            ...slot,
            start: newTimeSlot.start,
            end: newTimeSlot.end,
          };
        }
        return slot;
      });

      onChange({ ...availability, [title]: updatedSlots });
    }
  };
  // const validateTimeSlot = (timeSlot: IStartAndEndTimeSlot): boolean => {
  //   setError(null);
  //   const { start, end } = timeSlot;
  //   const startTime = dayjs(start.dateTime, 'HH:mm A');
  //   const endTime = dayjs(end.dateTime, 'HH:mm A');

  //   // Check if time strings are valid
  //   if (!startTime.isValid() || !startTime.isValid()) {
  //     setError('Invalid time format.');
  //     return false;
  //   }

  //   // Validate that the start time is before the end time
  //   if (!startTime.isBefore(endTime)) {
  //     setError('Start time must be before end time.');
  //     return false;
  //   }

  //   const updatedAvailabilityPeriods = [
  //     ...((availability?.[title] as IAvailabilityPeriod[]) ?? []),
  //   ];
  //   updatedAvailabilityPeriods?.push(
  //     new AvailabilityPeriodModel(
  //       periods,
  //       false,
  //       null,
  //       timeSlot.start.dateTime,
  //       timeSlot.end.dateTime,
  //     ),
  //   );
  //   const isValidTimeSlots = validateAvailabilityPeriods(
  //     updatedAvailabilityPeriods!,
  //   );

  //   if (!isValidTimeSlots) {
  //     setError('Selected time slots are overlapping!');
  //     return false;
  //   }

  //   // if (calculateTotalMinutesAndCheck(updatedAvailabilityPeriods)) {
  //   //   setError(
  //   //     'Not enough time left in the 24-hour period to add a new availability period.',
  //   //   );
  //   //   return false;
  //   // }

  //   return true;
  // };

  return (
    <AvailabilityLayout
      title={daysOfWeek[title].label}
      onChange={handleCheckboxChange}
      isChecked={isChecked}
    >
      {error && (
        <Text size="small" type="error" className="m-2 text-center -w-full">
          {error}
        </Text>
      )}
      <TimeSlotLayout
        periods={periods}
        onDelete={handleDelete}
        onAdd={handleAvailableTimeClick}
        onTimeChange={handleTimeSlotChange}
      />
    </AvailabilityLayout>
  );
};

export default AvailabilitySlot;
