import { get, isArray, isEmpty, isObject } from 'lodash';
import { IUser } from 'modules/auth/types/types';
import { Contact } from 'modules/profile/types/types';
import {
  CountryCode,
  parsePhoneNumber,
  parsePhoneNumberFromString,
} from 'libphonenumber-js';

type FieldIndexTuple = [string, number];

const getNextFormIndex = (
  user: IUser,
  fieldIndexTuples: FieldIndexTuple[],
): number => {
  for (const [] of fieldIndexTuples) {
    if (!isEmpty(user?.contacts)) {
      return 4;
    }
    if (!isEmpty(user?.availability)) {
      return 2;
    }
    if (!isEmpty(user?.name)) {
      return 1;
    }
  }
  return 0;
};

/**
 * Transforms a VCF (vCard) object into a Contact object.
 *
 * @param {Object} input - The input VCF object.
 * @return {Contact} The transformed Contact object.
 *
 * @example
 * const input = {
 *   fn: { _data: 'John Doe' },
 *   tel: [{ _data: '123-456-7890' }],
 *   email: { _data: 'john.doe@example.com' },
 *   photo: { _data: 'http://example.com/photo.jpg' },
 * };
 *
 * const contact = transformVCFToContact(input);
 * console.log(contact);
 * // Output: { name: 'John Doe', phoneNumber: '123-456-7890', email: 'john.doe@example.com', photoURL: 'http://example.com/photo.jpg' }
 */
const transformVCFToContact = (input: any): Contact | null => {
  const getFieldValue = (field: any, isPhoto: boolean = false) => {
    let value = '';
    if (isArray(field)) {
      value = get(field, '[0]._data', '');
    } else if (isObject(field)) {
      value = get(field, '_data', '');
    }
    if(get(field, 'encoding') === "QUOTED-PRINTABLE" || get(field, '[0].encoding') === "QUOTED-PRINTABLE"){
      value = value.replace(/=([A-Fa-f0-9]{2})/g, (match, hex) => {
        return String.fromCharCode(parseInt(hex, 16));
      });
    }

    return isPhoto && value ? `base64,${value}` : value;
  };
  let name = getFieldValue(get(input, 'fn')) || getFieldValue(get(input, 'n'));

  const phoneNumber = getFieldValue(get(input, 'tel'));

  // Return immediately if required fields are empty
  if (isEmpty(name) || isEmpty(phoneNumber)) {
    console.count('Name or phone number is empty');
    return null;
  }

  const nameField = name.replace(/\\;/g, '__ESCAPED_SEMICOLON__');

  const nameParts = nameField.split(';');

  const [lastName, firstName, middleName, prefix, suffix] = nameParts.map(
    (part) => part.replace(/__ESCAPED_SEMICOLON__/g, ';'),
  );

  name =
    `${prefix || ''} ${firstName || ''} ${middleName || ''} ${lastName || ''} ${suffix || ''}`.trim();

  const validated = validatePhoneNumber(phoneNumber);

  if (!validated) {
    console.log(`Invalid phone number ${phoneNumber}`);
    return null;
  }

  // validate phone number

  const contact: Contact = {
    name,
    phoneNumber: validated,
  };
  // if (!isEmpty(email)) {
  //   contact.email = email;
  // }
  // if (!isEmpty(photoURL)) {
  //   contact.photoURL = photoURL;
  // }
  return contact;
};

const validatePhoneNumber = (phoneNumber: string): string | false => {
  let normalizedNumber = phoneNumber;

  // Check if the number needs a country code based on its format
  if (!phoneNumber.startsWith('+')) {
    if (phoneNumber.startsWith('0') && phoneNumber.length === 10) {
      // Sri Lankan format without country code
      normalizedNumber = `+94${phoneNumber.slice(1)}`;
    } else if (phoneNumber.length === 10 && /^[2-9]\d{9}$/.test(phoneNumber)) {
      // US format without country code
      normalizedNumber = `+1${phoneNumber}`;
    } else {
      return false; // Invalid format if neither US nor LK patterns match
    }
  }

  const num = parsePhoneNumberFromString(normalizedNumber);
  if (!num || !num.isValid()) return false;

  return num.number;
};

function getUniqueNewContacts(
  oldContacts: Contact[],
  newContacts: Contact[],
  validate = true,
): Contact[] {
  const oldPhonesSet = new Set(
    oldContacts.map((contact) => contact.phoneNumber),
  );

  const uniqueNewContactsMap = new Map<string, Contact>();
  newContacts.forEach((contact) => {
    if (
      !uniqueNewContactsMap.has(contact.phoneNumber) &&
      !oldPhonesSet.has(contact.phoneNumber)
    ) {
      uniqueNewContactsMap.set(contact.phoneNumber, contact);
    }
  });

  return Array.from(uniqueNewContactsMap.values());
}

const mergeContacts = (
  oldContacts: Contact[],
  newContacts: Contact[],
): Contact[] => {
  const oldPhonesSet = new Set(
    oldContacts.map((contact) => contact.phoneNumber),
  );

  const uniqueNewContactsMap = new Map<string, Contact>();

  oldContacts.forEach((contact) => {
    uniqueNewContactsMap.set(contact.phoneNumber, contact);
  });

  newContacts.forEach((contact) => {
    uniqueNewContactsMap.set(contact.phoneNumber, contact);
  });

  return Array.from(uniqueNewContactsMap.values());
};

export {
  getNextFormIndex,
  transformVCFToContact,
  validatePhoneNumber,
  getUniqueNewContacts,
  mergeContacts,
};
