import { RootState } from 'app/store';
import { ReactComponent as TrickCircle } from 'assets/images/common/tick-circle.svg';
import { ReactComponent as LaterIcon } from 'assets/images/event/later.svg';
import classNames from 'classnames';
import EventIconNoBg from 'components/eventIcon/EventIconNoBg';
import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { useAppSelector } from 'hooks/useAppSelector';
import { ChevronRight, Dot } from 'lucide-react';
import { Link } from 'react-router-dom';
import { EventCardItemProps } from './EventCardItem.types';
import styles from './eventCardItem.module.css';
import { isPending } from '@reduxjs/toolkit';

export const EventCardItem = ({
  id,
  variant,
  eventData,
  additionalVariants,
  origin,
}: EventCardItemProps) => {
  // get user from store
  const user = useAppSelector((state: RootState) => state.auth.user);
  const isByYou =
    eventData?.userId && user?.id && eventData?.userId == user?.id;

  // POLL EVENT FOOTER For Invitee
  const isPollFooter = eventData?.type === 'poll' && additionalVariants?.isAccepted && !isByYou
  // POLL EVENT FOOTER For Host in My Events filter
  const isPollFooterMyEvent = eventData?.type === 'poll' && variant === 'ongoing' && isByYou

  const hasFooter =
    ![
      'past',
      'accepted',
      'ongoing',
      'maybe',
      'blocked',
      'new',
      'poll',
    ].includes(variant) || isPollFooter || isPollFooterMyEvent;

  const containerClasses = classNames(
    'bg-white/30 rounded-[8px] block',
    {
      'border-l-[3px] border-[#C94A2E]': ['poll', 'response'].includes(variant),
    },
    {
      [styles.eventCardBackground]: variant === 'maybe',
    },
    {
      'filter grayscale': variant === 'past',
    },
  );

  const contentClasses = classNames(
    'px-[13px] py-[17px] text-theme flex gap-2 items-center relative',
    {
      'pb-[25px]': !hasFooter,
    },
  );

  const isPoll = variant === 'poll';
  const isDraft = variant === 'draft';
  const isPast = variant === 'past';
  const isAccepted = variant === 'accepted';
  const isResponse = variant === 'response' && !isByYou;
  const isPendingPoll =
    (variant === 'response' || variant === 'ongoing' || isAccepted) && eventData?.type === 'poll';
  const footerText =
    isPendingPoll && isByYou ? 'Confirm a time' : isDraft ? 'Edit' : 'Respond';
  const headerText = isDraft
    ? 'Created by '
    : isByYou
      ? 'Created by '
      : 'Invited by ';
  const activeContactsCount =
    eventData?.contacts?.filter(
      (contact) => contact?.status === 1 && contact?.organizer !== 1,
    ).length || 0;

  const responseText = isDraft
    ? 'Draft'
    : isPoll
      ? `${activeContactsCount} have responded`
      : `${activeContactsCount} ${isPast ? 'went' : 'are going'}`;

  const creatorName = isByYou ? 'You' : eventData?.organizer;

  const linkTo = isDraft
    ? `/lets-meet/edit/${eventData?._id}`
    : isByYou
      ? `/lets-meet/view/${eventData?._id}${origin ? `?origin=${origin}` : ''}`
      : `/lets-meet/receive/${eventData?._id}`;

  const formatDateToLocalTimezone = (
    dateString: string,
    timezone: string,
    eventData?: any,
  ) => {
    try {
      const date = new Date(dateString);
      const zonedDate = toZonedTime(date, timezone);
      return zonedDate;
    } catch (error) {
      console.error('Error converting date to local timezone', error);
    }
    return new Date();
  };

  const formatDate = (date: Date, formatStr: string) => {
    try {
      return format(date, formatStr);
    } catch (error) {
      console.error('Error formatting date', error);
    }
    return '';
  };

  const getUserTimezone = () => {
    // eslint-disable-next-line new-cap
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  };

  const eventStart =
    formatDateToLocalTimezone(eventData?.start, getUserTimezone()) ||
    new Date();
  const eventEnd =
    formatDateToLocalTimezone(eventData?.end, getUserTimezone()) || new Date();

  return (
    <div>
      <Link className={containerClasses} to={linkTo}>
        {/* Header */}
        <div className="mx-[13px] pt-3 pb-[9px] text-white text-xs font-normal border-b border-white/10 flex items-center justify-between">
          <div>
            <span>{headerText}</span>
            <span className="font-bold">{creatorName}</span>
            <Dot className="inline text-theme text-[4px]" />
            <span>{responseText}</span>
          </div>
          {isAccepted && !isByYou && <TrickCircle className="rounded-full" />}
        </div>

        {/* Content */}
        <div className={contentClasses}>
          {/* Date */}
          {isPendingPoll ? (
            <div className="bg-[#F4E7FF] w-[58px] h-[58px] rounded-full flex flex-col items-center justify-center font-semibold text-center">
              <div className="text-[18px] leading-6">TBD</div>
            </div>
          ) : (
            !isPoll && (
              <div className="bg-[#F4E7FF] w-[58px] h-[58px] rounded-full flex flex-col items-center justify-center font-semibold text-center">
                <div className="text-[11px] mb-[-5px] uppercase">
                  {new Date(
                    formatDateToLocalTimezone(
                      eventData.start,
                      getUserTimezone(),
                    ),
                  ).toLocaleDateString('en-US', { month: 'short' })}
                </div>
                <div className="text-[18px] leading-6">
                  {new Date(
                    formatDateToLocalTimezone(
                      eventData.start,
                      getUserTimezone(),
                    ),
                  ).toLocaleDateString('en-US', { day: '2-digit' })}
                </div>
                <div className="text-[11px] mt-[-5px]">
                  {new Date(
                    formatDateToLocalTimezone(
                      eventData.start,
                      getUserTimezone(),
                    ),
                  ).toLocaleDateString('en-US', { weekday: 'short' })}
                </div>
              </div>
            )
          )}

          <div className="text-white">
            <div className="mb-1 text-lg font-bold w-44 md:w-full line-clamp-1">
              {eventData?.activity}
            </div>
            <div className="flex gap-1 items-center text-sm font-normal">
              <LaterIcon />

              {additionalVariants?.isOngoing && !isResponse && !isPendingPoll ? (
                <span>Happening Now</span>
              ) : isPendingPoll ? (
                <span>TBD</span>
              ) : (
                <>
                  {!isPoll && (
                    <span>
                      {formatDate(
                        formatDateToLocalTimezone(
                          eventData.start,
                          getUserTimezone(),
                          eventData,
                        ),
                        'h:mma',
                      )}{' '}
                      -{' '}
                      {formatDate(
                        formatDateToLocalTimezone(
                          eventData.end,
                          getUserTimezone(),
                          eventData,
                        ),
                        'h:mma',
                      )}
                    </span>
                  )}
                </>
              )}
            </div>
          </div>

          <div className="absolute right-1">
            <EventIconNoBg icon={eventData?.emoji} size="large" />
          </div>
        </div>

        {/* Footer */}
        {hasFooter && (
          <Link
            to={linkTo}
            className="flex items-center justify-between px-3 py-2 bg-secondaryLight rounded-b-[8px] text-theme text-sm font-normal"
          >
            <span>
              {isPollFooter ? 'Waiting for confirmation' : `${footerText}`}
            </span>
            <ChevronRight className="text-sm" />
          </Link>
        )}
      </Link>
    </div>
  );
};
