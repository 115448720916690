import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

interface LinkButtonProps extends LinkProps {
  children: React.ReactNode;
}

const LinkButton: React.FC<LinkButtonProps> = ({ children, ...props }) => {
  return <Link {...props}>{children}</Link>;
};

export default LinkButton;
