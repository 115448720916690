import { RootState } from 'app/store';
import { ReactComponent as TrickCircle } from 'assets/images/common/tick-circle.svg';
import classNames from 'classnames';
import { useAppSelector } from 'hooks/useAppSelector';
import { ChevronRight, Dot } from 'lucide-react';
import { Link } from 'react-router-dom';
import styles from './CalendarEventDayCard.module.css';
import { CalendarEventDayCardProps } from './CalendarEventDayCard.types';
import { divide } from 'lodash';

const CalendarEventDayCard = ({
  variant,
  eventData,
  id,
  variantData,
}: CalendarEventDayCardProps) => {
  // get user from store
  const user = useAppSelector((state: RootState) => state.auth.user);
  const isByYou =
    eventData?.userId && user?.id && eventData?.userId == user?.id;

  const isBlocked = variant === 'blocked';
  const isPoll = variant === 'poll';
  const isDraft = variant === 'draft';
  const isPast = variant === 'past';
  const isAccepted = variant === 'accepted';
  const isResponse = variant === 'response' && !isByYou;
  const isPendingPoll =
    (variant === 'response' || isAccepted) && eventData?.type === 'poll';

  const hasFooter = ![
    'past',
    'accepted',
    'ongoing',
    'maybe',
    'blocked',
    'new',
    'poll',
  ].includes(variant);

  const containerClasses = classNames(
    'bg-white/30 rounded-[8px] block',
    {
      'border-l-[3px] border-[#C94A2E] calendar-card-left-border': [
        'poll',
        'response',
      ].includes(variant),
    },
    {
      [styles.eventCardBackground]: variant === 'maybe',
    },
    {
      'filter grayscale': variant === 'past',
    },
  );

  const contentClasses = classNames(
    'px-3 text-theme flex gap-2 items-center relative',
    {
      'pb-[25px]': !hasFooter,
    },
  );
  const headerText = isDraft
    ? 'Created by '
    : isByYou
      ? 'Created by '
      : 'Invited by ';
  const activeContactsCount =
    eventData?.contacts?.filter(
      (contact) => contact?.status === 1 && contact?.organizer !== 1,
    ).length || 0;

  const responseText = isDraft
    ? 'Draft'
    : isPoll
      ? `${activeContactsCount} have responded`
      : `${activeContactsCount} ${isPast ? 'went' : 'are going'}`;

  const creatorName = isByYou ? ' You' : ` ${eventData?.organizer}`;

  const formatDateToLocalTimezone = (dateString: string, timezone: string) => {
    const date = new Date(dateString);
    return date;
  };

  const getUserTimezone = () => {
    // eslint-disable-next-line new-cap
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  };

  // Define the function to return the appropriate component based on the duration range
  const getComponentByDuration = () => {
    if (eventData?.start && eventData?.end) {
      const startTime = new Date(eventData.start).getTime();
      const endTime = new Date(eventData.end).getTime();
      const durationInMinutes = (endTime - startTime) / 60000; // Convert ms to minutes

      switch (true) {
        case durationInMinutes <= 50:
          return (
            <div
              id={id}
              className={`calendar-event-card-container flex items-center justify-between px-3 ${containerClasses} h-full w-full relative z-20 `}
            >
              <div className=" text-white text-xs font-bold w-full  flex-wrap line-clamp-1">
                {eventData?.activity}
              </div>
              {isAccepted && !isByYou && (
                <TrickCircle className="rounded-full" />
              )}
            </div>
          );
        case durationInMinutes > 50 && durationInMinutes <= 60:
          return (
            <div
              id={id}
              className={`calendar-event-card-container px-3 py-1 ${containerClasses} h-full w-full relative z-20 `}
            >
              <div className=" text-white text-xs font-normal border-b border-white/10 flex items-center justify-between">
                <div className="flex flex-nowrap min-w-max gap-1">
                  <span>{headerText}</span>
                  <span className="font-bold">{creatorName}</span>
                  <Dot
                    height={16}
                    width={16}
                    className="inline text-theme text-[4px]"
                  />
                  <span>{responseText}</span>
                </div>
                {isAccepted && !isByYou && (
                  <TrickCircle className="rounded-full" />
                )}
              </div>
              <div className=" text-white text-xs font-bold w-full flex-wrap line-clamp-1">
                {eventData?.activity}
              </div>
            </div>
          );
        case durationInMinutes > 60 && durationInMinutes <= 120:
          return (
            <div
              id={id}
              className={`calendar-event-card-container px-3 py-1 ${containerClasses} h-full w-full relative z-20 `}
            >
              {/* Header */}
              <div className=" text-white text-xs font-normal border-b border-white/10 flex items-center justify-between">
                <div className="flex flex-nowrap min-w-max gap-1">
                  <span>{headerText}</span>
                  <span className="font-bold">{creatorName}</span>
                  <Dot
                    className="inline text-theme text-[4px]"
                    height={16}
                    width={16}
                  />
                  <span>{responseText}</span>
                </div>
                {isAccepted && !isByYou && (
                  <TrickCircle className="rounded-full" />
                )}
              </div>

              {/* Content */}
              <div className={`${contentClasses} !px-0`}>
                <div className="text-white pt-1">
                  <div className="mb-1 text-md font-bold w-full line-clamp-1">
                    {eventData?.activity}
                  </div>
                </div>
              </div>
            </div>
          );
        default:
          return (
            <div
              id={id}
              className={`calendar-event-card-container ${containerClasses} h-full w-full relative z-20 `}
            >
              {/* Header */}
              <div className="mx-3 py-2 text-white text-xs font-normal border-b border-white/10 flex items-center justify-between">
                <div className="flex flex-nowrap min-w-max">
                  <span>{headerText}</span>
                  <span className="font-bold">{creatorName}</span>
                  <Dot className="inline text-theme text-[4px]" />
                  <span>{responseText}</span>
                </div>
                {isAccepted && !isByYou && (
                  <TrickCircle className="rounded-full" />
                )}
              </div>

              {/* Content */}
              <div className={contentClasses}>
                <div className="text-white">
                  <div className="mb-1 text-lg font-bold w-full flex-wrap line-clamp-2">
                    {eventData?.activity}
                  </div>
                </div>
              </div>
            </div>
          );
      }
    }
    return null;
  };

  return isBlocked ? (
    <div
      id={id}
      className={`event-card-blocked h-full w-full flex flex-col items-start content-start`}
    >
      <p className="text-[10px] p-1 w-full flex-wrap line-clamp-1 font-sofia-medium">
        {eventData?.activity ?? ''}
      </p>
    </div>
  ) : (
    getComponentByDuration()
  );
};

export default CalendarEventDayCard;