import { RootState } from 'app/store';
import {
  EventObject,
  TimeOffObject,
} from 'components/eventCardItem/EventCardItem.types';
import { useAppSelector } from 'hooks/useAppSelector';
import { determineVariant, generateTimeOffsForUserAvailability, generateTimeOffsForUserAvailabilityForRange } from 'utils/calendarUtils';
import { CalendarEventWeekCard } from 'modules/home/components/calendarEventWeekCard/CalendarEventWeekCard';
import { CalendarEvent } from 'modules/home/types/CalendarEvent';
import { CalendarManualBlock } from 'modules/home/types/CalendarManualBlock';
import { RBCSelectedSlot } from 'modules/home/types/RBCSelectedSlot';
import {
  filterDuplicateCalendarEvents,
  getSearchParams,
  getWeekStartAndEnd,
  mapToCalendarEVents,
} from 'modules/home/utils/helpers';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './calendarWeekly.css';

import TouchContainer from 'components/touchContainer/TouchContainer';
import { BigCalendarContainer } from 'modules/home/components/bigCalendarContainer';
import { CalendarHeaderDateLabel } from 'modules/home/components/calendarHeaderDateLabel';
import { useApiCall } from 'hooks/axiosHooks';
import { calendarWeeklyFormatters } from 'modules/home/utils/utils';
import { IAvailability } from 'modules/profile/types/types';
import { useLocation } from 'react-router-dom';
import { parse } from 'date-fns';
import { useGetGoogleCalendarBlocksQuery } from 'services/slices/calendarApiSlice';
import { GCalendarBlock } from 'modules/home/types/GCalendarBlock';

// localizer for the calendar
const localizer = momentLocalizer(moment);

// swipe gesture threshold
const swipeThreshold = 100;

const dateToday = new Date();

// define formatters
const formatters = calendarWeeklyFormatters;

// define custom components
const customComponents = {
  // custom event component
  event: (props: { event: CalendarEvent; title: string }) => {
    const eventVariantValue = props?.event?.variant === 'blocked' ? 'blocked' : props?.event?.variantData?.isPending
    ? 'response'
    : determineVariant(props.event?.variantData)
    
    return (
      <CalendarEventWeekCard
        eventData={props.event?.eventData!}
        variantData={props.event?.variantData!}
        id={props.event?.id}
        view={'week'}
        variant={eventVariantValue}
      />
    );
  },
  week: {
    // custom week header with date labels
    header: (props: any) => {
      const { label, date, isOffRange } = props;
      return (
        <CalendarHeaderDateLabel
          date={date}
          isOffRange={isOffRange}
          label={label}
          today={dateToday}
        />
      );
    },
  },
};

const CalendarWeekly = () => {
  // get user from store
  const user = useAppSelector((state: RootState) => state.auth.user);

  // get search params
  const location = useLocation();
  const searchParams = getSearchParams(location.search ?? '');
  const focusToEventId = searchParams?.ref;
  const focusEventDate = searchParams?.date;
  const formattedFocusEventDate = focusEventDate ? parse(focusEventDate,'yyyy-MM-dd',new Date()): null;

  // default date
  const [defaultDate, setDefaultDate] = useState<Date>(() => formattedFocusEventDate??new Date());

  // selected event date
  const [selectedEventDate, setSelectedEventDate] = useState<Date | null>(formattedFocusEventDate);

  const [GCBlocks, setGCBlocks] = useState<GCalendarBlock[]|undefined>(undefined);

  // initial data loaded flag
  const initialDataLoadedRef = useRef(false);

  // calendar ref
  const calendarRef = useRef<HTMLDivElement>(null);

  // selected event for view slider
  const [selectedViewEvent, setSelectedViewEvent] =
    useState<CalendarEvent | null>(null);

  // add manual time block
  const [addManualBlockData, setAddManualBlockData] =
    useState<CalendarManualBlock | null>(null);

  // initially set to null and then set to trigger scrolling
  useEffect(() => {
    !formattedFocusEventDate && setSelectedEventDate((prev) => new Date());   
  }, []);

  // calculate date range for api queries
  const { endOfWeek, startOfWeek } = getWeekStartAndEnd(
    selectedEventDate ?? defaultDate,
  );

  // handle selected date change
  const handleSelectedEventDateChange = (date: Date) => {
    setSelectedEventDate((prev) => date);
  };

  // handle event select in calendar
  const handleEventSelect = (event: CalendarEvent) => {
    if(event.variant==='blocked' && !event.isDeletable) return;
    if (!addManualBlockData) setSelectedViewEvent((prev) => event);
  };

  // handle slot selecting start
  const handleSlotSelecting = (slotData: unknown) => {
    const slotInfo = slotData as RBCSelectedSlot;
    if (!selectedViewEvent)
      setAddManualBlockData((prev) => ({
        start: new Date(slotInfo.start),
        end: new Date(slotInfo.end),
        title: '',
      }));
    return false;
  };

  // view state change handler
  const [view, setView] = useState('month');

  // view change handler
  const onView = useCallback(
    (newView: string) => {
      return setView(newView), [setView];
    },
    [setView],
  );

  // --- API binding ---

  // get events of the day
  const { data: events, refetch: refetchEvent } = useApiCall();

  // get time offs of the day
  const { data: timeOffs, refetch: refetchTimeOffs } = useApiCall();

  // get google events
  const {data:GCBlocksData,isFetching:GCBlocksIsFetching,isLoading:GCBlocksIsLoading,refetch:refetchGCBlocks,isError:GCBlocksError} = useGetGoogleCalendarBlocksQuery({
    startDate: startOfWeek.toISOString(),
    endDate: endOfWeek.toISOString(),
  });

  useEffect(() => {
    if(GCBlocksData){
      setGCBlocks(GCBlocksData);
    }else if(GCBlocksError){
      setGCBlocks([]);
    }
  },[GCBlocksData,GCBlocksError]);

  const loadEvents = () => {
    refetchEvent(
      `event/range?startDate=${startOfWeek.toISOString()}&endDate=${endOfWeek.toISOString()}`,
    );
  };

  const loadTimeOffs = () => {
    refetchTimeOffs(
      `time-off?startDate=${startOfWeek.toISOString()}&endDate=${endOfWeek.toISOString()}`,
    );
  };

  useEffect(() => {
    if (selectedEventDate) {
      loadEvents();
      loadTimeOffs();
      refetchGCBlocks();
    }
  }, [selectedEventDate]);

  // format events for display
  const displayEventList: CalendarEvent[] = useMemo(
    () =>
      filterDuplicateCalendarEvents([
        ...mapToCalendarEVents(
          (events ?? []) as EventObject[],
          timeOffs ? (timeOffs as TimeOffObject) : undefined,
          GCBlocks,
        ),
        ...generateTimeOffsForUserAvailabilityForRange(
          startOfWeek,
          endOfWeek,
          user && user.availability as IAvailability,
        )
      ]
      ),
    [events, timeOffs, GCBlocks],
  );

  // initial data load handler
  const handleInitialDataLoad = () => {
    if(focusToEventId){
      setTimeout(() => {
        const element = document.getElementById(focusToEventId);
        element && element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 50);
    }else{
      setTimeout(() => {
        const currentTimeElement = calendarRef.current?.querySelector('.rbc-current-time-indicator');
        if (currentTimeElement) {
          currentTimeElement.scrollIntoView({behavior: 'smooth',block: 'center'});
        }
      }, 50);
    }
  };

  // initial data load effect
  useEffect(() => {
    if (events && timeOffs && GCBlocks && !initialDataLoadedRef.current) {
      handleInitialDataLoad();
      initialDataLoadedRef.current = true;
    }
  },[events, timeOffs, GCBlocks]);

  const handleTouchEnd = (mode: 'prev' | 'forward') => {
    // reduce 7 days from the selected date
    setSelectedEventDate((prev) => {
      const newDate = new Date(prev ?? defaultDate);
      newDate.setDate(
        mode == 'forward'
          ? newDate.getDate() + 7
          : mode == 'prev'
            ? newDate.getDate() - 7
            : newDate.getDate() - 7,
      );
      return newDate;
    });
  };

  return (
    <>
      <BigCalendarContainer
        addManualBlockData={addManualBlockData}
        selectedViewEvent={selectedViewEvent}
        onDrawerClose={() => {
          setAddManualBlockData((prev: any) => null);
          setSelectedViewEvent((prev) => null);
        }}
        onDrawerActionComplete={(variant) => {
          variant == 'event' && loadEvents();
          variant == 'timeOff' && loadTimeOffs();
        }}
        origin='weekly'
      >
        <div className="mt-4 overflow-y-scroll overflow-x-hidden w-full flex flex-col mb-7"
          ref={calendarRef}
        >
          <TouchContainer
            className="h-full w-100 bg-transparent text-white px-0 calendar-weekly-container"
            onTouchEnd={handleTouchEnd}
          >
            <Calendar
              localizer={localizer}
              events={displayEventList}
              startAccessor="start"
              endAccessor="end"
              defaultDate={defaultDate}
              date={selectedEventDate ?? defaultDate}
              onView={onView}
              view={'week'}
              toolbar={false}
              formats={formatters}
              step={15}
              timeslots={4}
              onSelectEvent={handleEventSelect}
              onSelectSlot={handleSlotSelecting}
              // onSelecting={handleSlotSelecting}
              components={customComponents}
              selectable={true}
              // scrollToTime={defaultDate}
              // scrollToTime={dateToday}
              // longPressThreshold={200}
            />
          </TouchContainer>
        </div>
        <div className='w-full text-[8px] relative'>
          <span className='absolute top-[-20px] w-full block text-center font-sofia-ultralight'>* Single tap or tap and drag to add a busy block</span>
        </div>
      </BigCalendarContainer>
    </>
  );
};

export default CalendarWeekly;
