import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { debounce, isEmpty } from 'lodash';
import { Text } from 'components/text/primary/Text';
import { Contact } from 'modules/profile/types/types';
import { ReactComponent as ContactIcon } from 'assets/images/import/contacts-icon.svg';

type ContactProps = {
  id: number;
  name: string;
  photoURL?: string | null | undefined;
  children?: React.ReactNode;
  element: ReactNode;
};

type ContactsLayoutProps = {
  children: React.ReactElement<Contact>[];
  isSearching?: boolean;
  isDrawerOpen?: boolean;
};

const ContactNotFound = ({ isFromSearch }: { isFromSearch: boolean }) => (
  <div className="flex flex-col items-center gap-0.5 px-4 py-6 my-6 bg-[#32395E] rounded-lg">
    <ContactIcon />
    <Text size="small" className="mt-3 font-semibold text-center">
      {isFromSearch ? 'No contacts found.' : 'No contacts added yet.'}
    </Text>
    <Text size="small" className="mt-0.5 font-thin text-center">
      You can add contacts or import your phone’s contact book to Caleido
    </Text>
  </div>
);

const smoothScrollTo = (container: HTMLElement, targetTop: number) => {
  const startTop = container.scrollTop;
  const distance = targetTop - startTop;
  const duration = 300;
  let startTime: number | null = null;

  const step = (timestamp: number) => {
    if (!startTime) startTime = timestamp;
    const progress = timestamp - startTime;
    const progressRatio = Math.min(progress / duration, 1);

    container.scrollTop = startTop + distance * progressRatio;

    if (progress < duration) {
      requestAnimationFrame(step);
    }
  };

  requestAnimationFrame(step);
};

const ContactsLayout: React.FC<ContactsLayoutProps> = ({
  children,
  isSearching,
  isDrawerOpen,
}) => {
  const [selectedLetter, setSelectedLetter] = useState<string | null>('A');
  const letterBarRefs = useRef<(HTMLDivElement | null)[]>([]);

  const savedScrollPosition = useRef<number>(0);

  const contacts = useMemo(() => {
    return React.Children.map(children, (child, index) => ({
      name: child.props.name,
      element: child,
      id: index,
    }));
  }, [children]);

  const sortedContacts = useMemo(
    () => contacts.sort((a, b) => a.name.localeCompare(b.name)),
    [contacts],
  );

  const groupedContacts = useMemo(() => {
    return sortedContacts.reduce<{ [key: string]: ContactProps[] }>(
      (acc, contact) => {
        const firstLetter = contact.name[0].toUpperCase();
        if (!acc[firstLetter]) {
          acc[firstLetter] = [];
        }
        acc[firstLetter].push(contact);
        return acc;
      },
      {},
    );
  }, [sortedContacts]);

  const alphabet = useMemo(() => {
    return 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('').filter((letter) => {
      return !isEmpty(groupedContacts[letter]);
    });
  }, [groupedContacts]);

  const letterRefs = useMemo(
    () =>
      alphabet.reduce<{ [key: string]: React.RefObject<HTMLDivElement> }>(
        (acc, letter) => {
          acc[letter] = React.createRef();
          return acc;
        },
        {},
      ),
    [alphabet],
  );

  const handleScrollToCenter = (index: number) => {
    if (letterBarRefs.current[index]) {
      const element = letterBarRefs.current[index];

      if (!element) return;

      const container = element.parentElement;
      if (container) {
        const containerRect = container.getBoundingClientRect();
        const elementRect = element.getBoundingClientRect();
        const offset =
          elementRect.top -
          containerRect.top -
          containerRect.height / 2 +
          elementRect.height / 2;
        smoothScrollTo(container, container.scrollTop + offset);
      }
    }
  };

  const handleScroll = debounce(() => {
    let topmostLetter: string | null = null;

    for (const letter in letterRefs) {
      if (letterRefs[letter]) {
        const rect = letterRefs[letter].current?.getBoundingClientRect();
        if (rect && rect.top >= 0 && rect.top < window.innerHeight) {
          topmostLetter = letter;
          break;
        }
      }
    }

    if (topmostLetter) {
      setSelectedLetter(topmostLetter);

      const index = alphabet.indexOf(topmostLetter ?? 'A');
      if (index !== -1) {
        handleScrollToCenter(index);
      }
    }
  }, 200);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    if (isDrawerOpen) savedScrollPosition.current = window.scrollY;
    else {
      setTimeout(() => {
        window.scrollTo(0, savedScrollPosition.current);
      }, 350);
    }
  }, [isDrawerOpen]);

  const handleLetterClick = (letter: string, index: number) => {

    // implementing better scrolling behavior
    const elements = document.getElementsByClassName('contact-search-name');
    const element = Array.from(elements).find((el) => el.textContent?.startsWith(letter));
    element?.scrollIntoView({
      block:'center',      
      behavior: 'smooth' 
    });

    /*
    const contactRef = letterRefs[letter];
    if (contactRef && contactRef.current) {
      contactRef.current.scrollIntoView({
        block: 'start',
        inline: 'nearest',
        behavior: 'smooth',
      });
    }
    */
    setSelectedLetter(letter);
  };

  return (
    <div className="relative bg-transparent">
      <Text size="small">
        {isSearching ? 'Contacts Found' : 'All contacts'} ({contacts?.length})
      </Text>
      <div className="fixed top-[50%] translate-y-[-50%] right-[14px] z-10 flex flex-col items-center justify-center py-3 overflow-auto h-auto space-y-2 text-center">
        {alphabet.map((letter, index) => (
          <div
            key={letter}
            ref={(el) => (letterBarRefs.current[index] = el)}
            className={`text-sm cursor-pointer select-none font-sofia-extralight w-auto ${
              selectedLetter === letter ? 'font-sofia-semibold' : ''
            }`}
            onClick={() => {
              handleLetterClick(letter, index);
            }}
          >
            {letter}
          </div>
        ))}
      </div>
      <div className="mr-4">
        {Object.keys(groupedContacts).map((letter) => (
          <div key={letter} ref={letterRefs[letter]}>
            <div className="flex items-center gap-3 py-2 my-1 text-lg font-light">
              {letter}
              <span className="w-full my-1 border-t border-slate-600"></span>
            </div>
            {groupedContacts[letter].map((contact) => (
              <div key={contact.id} className="py-1">
                {contact.element}
              </div>
            ))}
          </div>
        ))}
        {isEmpty(contacts) && (
          <ContactNotFound isFromSearch={isSearching ?? false} />
        )}
      </div>
    </div>
  );
};

export default ContactsLayout;
