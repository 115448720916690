import { ReactNode } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import OverlayLoader from 'components/loader/OverlayLoader';
import { Progress } from 'components/ui/progress';
import LinkButton from 'components/buttons/main/LinkButton';
import { cn } from 'utils/helpers';
import MainButton from 'modules/auth/components/MainButton';
import { Text } from 'components/text/primary/Text';

import styles from './profileLayout.module.css';
import { Button } from 'components/ui/button';

type LayoutProps = {
  children: ReactNode;
  onSkip?: () => void;
  onSubmit?: () => void;
  onPrevious?: () => void;
  submitText?: string;
  loading?: boolean;
  progress?: number | null | undefined;
  isLastStep?: boolean;
  isNextEnabled?: boolean;
  isSkipEnabled?: boolean;
  isPrevEnabled?: boolean;
  error?: string;
};

const ProfileOnboardingLayout = ({
  children,
  onSkip,
  onSubmit,
  onPrevious,
  submitText = 'Next',
  loading,
  progress = 0,
  isLastStep = false,
  isNextEnabled,
  isSkipEnabled,
  isPrevEnabled,
  error,
}: LayoutProps) => {
  return (
    <div className="flex items-center justify-center text-white bg-main">
      <div
        className={cn(
          'flex flex-col min-h-screen w-full h-full max-w-md py-8 px-6',
          styles.background,
        )}
      >
        <header className="flex flex-col w-full my-6">
          <div className="flex items-center justify-between mb-4">
            {isPrevEnabled &&
            <>
            {onPrevious ? (
              <ChevronLeft className="cursor-pointer" onClick={onPrevious} />
            ) : !isLastStep ? (
              <LinkButton to="/auth/start">
                <ChevronLeft className="cursor-pointer" />
              </LinkButton>
            ) : (
              <></>
            )}
            </>
            }
            {onSkip && isSkipEnabled ? (
              <Button
                variant="link"
                onClick={onSkip}
                className="text-white py-1 h-auto font-light text-sm  hover:no-underline "
              >
                Skip
              </Button>
            ) : (
              <div className="h-7 w-3" />
            )}
          </div>
          <div>
            {progress && progress > 0 ? <Progress value={progress} /> : <></>}
          </div>
        </header>
        <main className="flex-grow mb-4 flex flex-col">{children}</main>
        <footer className="mb-4">
          {error && (
            <Text size="small" type="error" className='py-2'>
              {error}
            </Text>
          )}
          {onSubmit && isNextEnabled && (
            <MainButton
              type="primary"
              icon={<ChevronRight color="#fff" />}
              onClick={isNextEnabled&&!error?onSubmit:undefined}
              loading={loading}
            >
              <Text size="small" className="text-base">
                {submitText}
              </Text>
            </MainButton>
          )}
        </footer>
      </div>
      {loading && <OverlayLoader />}
    </div>
  );
};

export default ProfileOnboardingLayout;
