import moment from 'moment';

export const calendarWeeklyFormatters = {
  dayHeaderFormat: (date: Date, culture: any, localizer: any) => {
    return moment(date).format('dddd, MMMM D of YYYY');
  },
  timeGutterFormat: (date: Date, culture: any, localizer: any) => {
    return moment(date).format('h A');
  },
};

export const calendarDailyFormatters = {
  dayHeaderFormat: (date: Date, culture: any, localizer: any) => {
    return moment(date).format('dddd, MMMM D of YYYY');
  },
  timeGutterFormat: (date: Date, culture: any, localizer: any) => {
    return moment(date).format('h A');
  },
};
