import { Text } from 'components/text/primary/Text';
import { ChevronLeft } from 'lucide-react';
import OnboardingLayout from 'modules/auth/layouts/AuthLayout';
import { Link, useNavigate } from 'react-router-dom';
import { canGoBack, cn } from 'utils/helpers';

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  return (
    <OnboardingLayout className={cn('justify-start', 'overflow-y-auto pb-12')}>
      <div className="w-full max-w-sm mt-8 min-w-64">
        <ChevronLeft
          className="cursor-pointer"
          onClick={() => (canGoBack() ? navigate(-1) : navigate('/auth/start'))}
        />
      </div>
      <div className="w-full max-w-sm my-8 mb-6 min-w-64">
        <Text size="medium">Caleido LLC Privacy Policy</Text>
        <Text size="small" className="my-2 font-semibold">
          (Updated & Effective October 2nd, 2024)
        </Text>

        <div className="my-4 text-left">
          {/* TODO: include PDF link here */}
          {/* <Text size="small" className="mb-2.5 font-semibold">
            Our privacy policy is available in PDF form here.
          </Text> */}

          <Text size="small" className="mb-2">
            Since our beginning, people like you have trusted Caleido LLC (“
            <strong>us,</strong>” “<strong>our,</strong>” “<strong>we</strong>”
            or “<strong>Caleido</strong>”) by using our technologies and
            following our journey. We respect your privacy and are committed to
            protecting it.
          </Text>
          <Text size="small" className="mb-2">
            We offer this privacy policy (this “<strong>Privacy Policy</strong>
            ”) to explain how Caleido collects, uses, discloses, and protects
            information that we collect about you, and your rights with respect
            to that information.
          </Text>
          <Text size="small" className="mb-2">
            This Privacy Policy covers information we collect from or about you
            when you give it to us, visit our website (including, but not
            limited to, mobile websites and applications),or use one of our
            apps. It also covers information that we may receive from other
            persons or companies who have received your information and can
            share it with us.
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            1. What information do we collect and what do we do with it?
          </Text>
          <Text size="small" className="mb-2">
            We collect information both directly from you and automatically when
            you visit this Site or access our Services.
          </Text>
          <Text size="small" className="mb-2">
            <strong>Information We Collect</strong>
          </Text>
          <Text size="small" className="mb-2">
            We (and the vendors and third parties that perform services on our
            behalf) may collect, receive, and develop several different types of
            personal information about you depending on the nature of your
            interactions with us, which we have grouped together as follows:
            <ul className="list-disc ml-8 my-2">
              <li>
                <strong>
                  <u>Usage and Activity Information.</u>
                </strong>{' '}
                While providing the Services, we collect information about your
                use of our Services, as well as any information that you submit
                to our Services, such as any content that you type into the
                software, essays, or answers to customer service inquiries. In
                addition, we may ask you for personal information at other
                times, such as when you contact our technical support team, send
                us an email, complete a user survey or testimonial or otherwise
                communicate with us.
              </li>
              <li>
                <strong>
                  <u>User Content.</u>
                </strong>{' '}
                Examples include but are not limited to, audio or visual
                information you post to this Site, like photos or videos,
                reviews, and other feedback that you provide to us on or through
                our social media pages or websites about our services
                (collectively “User Content”). If you choose to provide User
                Content, it may be made available on public areas of our website
                or on or through our social media channels
              </li>
              <li>
                <strong>
                  <u>Identity information.</u>
                </strong>{' '}
                Examples include first name and last name.
              </li>
              <li>
                <strong>
                  <u>Contact Information.</u>
                </strong>{' '}
                Examples include telephone numbers.
              </li>
              <li>
                <strong>
                  <u>Internet or Other Electronic Activity.</u>
                </strong>{' '}
                Information regarding your interaction with this Site.
              </li>
              <li>
                <strong>
                  <u>Transaction Information.</u>
                </strong>{' '}
                Examples include details about payments to and from you and
                other details of products and services you have purchased from
                us.
              </li>
              <li>
                <strong>
                  <u>Profile Information.</u>
                </strong>{' '}
                Examples include your username and phone number, order history
                (such as purchases or orders made by you), your interests,
                university preferences, feedback and survey responses.
              </li>
              <li>
                <strong>
                  <u>Marketing and Communications Information.</u>
                </strong>{' '}
                Examples include your preferences in receiving marketing from us
                and our third parties and your communication preferences.
              </li>
              <li>
                <strong>
                  <u>Location Information.</u>
                </strong>{' '}
                We collect information about your social availability and
                general location using your IP address and your zip code, such
                as when you search online for product retailers or coordinate
                events near you, for example.
              </li>
              <li>
                <strong>
                  <u>Technical Information We Automatically Collect.</u>
                </strong>{' '}
                Like most websites and online services, we automatically collect
                certain types of information about visitors to this Site and
                users of our Services through cookies and other similar
                technologies. Examples include URL, internet protocol (IP)
                address, device identifier, device name and model, operating
                system type, name and version, web pages viewed, links clicked,
                your login information, browser type and version, browsing and
                search history on this Site, what areas of this Site you visit
                and for how long, what products or content you view and click
                on, what website you came from to get to us and what website you
                go to when you leave us, your interactions with any of our
                digital ads and content on this Site, scrolling activity, time
                zone setting and location, browser plug-in types and versions,
                operating system and platform, content entry, mouse movements,
                screen captures, the search terms and other data you enter,
                methods used to navigate away from the page, information from
                tracking tools we use like cookies and pixels to automatically
                collect information about how you interact with this Site and
                online content, and other technology on the devices you use to
                access this Site.
              </li>
            </ul>
          </Text>
          <Text size="small" className="mb-2">
            Some of the information we collect about you may be personal
            information, or personal data, that can be used to identify you
            including, for example, your name or email address. In some
            jurisdictions, certain unique identifiers like IP addresses and
            other usage or technical information may be considered personal
            information. Personal information does not include data where your
            identity has been removed so that we can no longer identify you
            (anonymous data), which we may use for any purpose.
          </Text>
          <Text size="small" className="mb-2">
            We may collect analytics data, or use third-party analytics tools,
            such as Google Analytics, to help us measure traffic and usage
            trends for our Services and to understand more about the
            demographics and behaviors of our users. You can learn more about
            Google’s practices at{' '}
            <Link
              target="_blank"
              className="underline"
              to={'https://www.google.com/policies/privacy/partners'}
            >
              https://www.google.com/policies/privacy/partners
            </Link>
            .
          </Text>
          <Text size="small" className="mb-2">
            We also leverage trusted third-party service providers to enhance
            your experience and improve our Services. These providers adhere to
            strict data privacy regulations and are only authorized to access
            and use your information to perform the specific services we
            request. Here are some examples:
            <ul className="list-disc ml-8 my-2">
              <li>
                <strong>
                  <u>Twilio:</u>
                </strong>{' '}
                We may utilize Twilio’s services for functionalities like
                sending SMS notifications or verification codes. You can learn
                more about Twilio’s practices at{' '}
                <Link
                  target="_blank"
                  className="underline"
                  to={'https://www.twilio.com/en-us/legal/privacy'}
                >
                  https://www.twilio.com/en-us/legal/privacy
                </Link>
                .
              </li>
              <li>
                <strong>
                  <u>AWS (Data Hosting):</u>
                </strong>{' '}
                We utilize Amazon Web Services (AWS) for secure data storage and
                hosting of Caleido Services. You can learn more about AWS’s
                practices at{' '}
                <Link
                  target="_blank"
                  className="underline"
                  to={'https://aws.amazon.com/privacy'}
                >
                  https://aws.amazon.com/privacy
                </Link>
                .
              </li>
              <li>
                <strong>Google Calendar (Time Blocks):</strong> With your
                consent, we can integrate with Google Calendar to display
                relevant time blocks within our platform.
              </li>
              <li>
                <strong>OneSignal (In-App Notifications):</strong> OneSignal
                allows us to send you in-app notifications to keep you informed
                about important updates or activities within Caleido Services.
                You can learn more about OneSignal’s practices at{' '}
                <Link
                  target="_blank"
                  className="underline"
                  to={'https://onesignal.com/privacy_policy'}
                >
                  https://onesignal.com/privacy_policy
                </Link>
                .
              </li>
            </ul>
          </Text>
          <Text size="small" className="mb-2">
            We use this automatically-collected information to: (i) improve user
            experience; (ii) provide and monitor the effectiveness of our
            Services; (iii) monitor aggregate metrics such as a total number of
            visitors, traffic, usage; (iv) diagnose or fix technology problems;
            (v) investigate fraud or misuse of our Services and (vi) otherwise
            to plan for and enhance our Services.
          </Text>
          <Text size="small" className="mb-2">
            Most browsers are initially set up to accept cookies, but you can
            reset your browser to refuse all cookies or to indicate when a
            cookie is being sent. However, some features and services
            (particularly those that require you to sign in) may not function
            properly if your cookies are disabled. You may also set your email
            options to prevent the automatic downloading of images that may
            contain technologies that would allow us to know whether you have
            accessed your email and performed certain functions with it.
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            2. How do we use your information?
          </Text>
          <Text size="small" className="mb-2">
            We use personal information for the purposes described below:
          </Text>
          <Text size="small" className="mb-2">
            <strong>Business Operations</strong>
          </Text>
          <Text size="small" className="mb-2">
            We may use your information to conduct our business operations
            including for the following purposes:
            <ul className="list-disc ml-8 my-2">
              <li>
                To provide and maintain the Services, and to resolve disputes.
              </li>
              <li>
                To identify users for themselves and such user’s contacts when
                sending invitations.
              </li>
              <li>
                To verify user information and to provide you with products and
                services you request from us.
              </li>
              <li> To provide customer service and respond to inquiries.</li>
              <li>
                To conduct market research and to develop new products and
                services.
              </li>
              <li>
                To develop and provide advertising tailored to your interest and
                to determine the effectiveness of our advertising.
              </li>
              <li>
                To provide you with the information or support that you request
                from us, including to send invitations and understand time
                blocks.
              </li>
              <li>
                To improve our website, products or services, marketing,
                customer relationships, and experiences.
              </li>
              <li>
                To notify you of Caleido events, special offers, updates, and
                products and services that we think may be of interest to you.
                To learn more about your choices with regards to these
                communications see How Do I Withdraw My Consent?
              </li>
              <li>
                To manage our relationship with you which may include notifying
                you about changes to our Terms of Service or this Privacy
                Policy, providing you notices about your account, asking you to
                leave a review or take a survey, addressing any concerns you may
                have about our products or services.
              </li>
              <li>
                {' '}
                To provide, process, or fulfill orders, payments, financing, or
                transactions for you, Schools, teachers, or student users, with
                the information, products, services or support that you request
                form us or to fulfill any other purpose for which you provide
                it.
              </li>

              <li>
                {' '}
                To carry out our obligations and enforce our rights and to
                protect this Site, customers, and other individuals including to
                protect against or identify possible fraudulent transactions.
              </li>
              <li>
                {' '}
                To protect against malicious, deceptive, fraudulent or illegal
                activity, and prosecuting those responsible for such activities.
              </li>
              <li>
                {' '}
                For any other purpose as may be permitted by law or with your
                consent or that we describe when you provide the information.
              </li>
            </ul>
          </Text>

          <Text size="small" className="mb-2">
            <strong>Personalization and Improvement</strong>
          </Text>
          <Text size="small" className="mb-2">
            We use information to personalize this Site to individual users and
            offer personalized content and instructions while using our
            Services, including on various devices you may use to access our
            Services. We collect statistics to better understand how users
            access and use our Services, monitor the effectiveness of our
            Services, detect usage patterns, and to diagnose or fix technology
            problems. We also use information to demonstrate the effectiveness
            of our Services and perform research, and to develop, support, and
            improve our Services and other educational products and services.
          </Text>

          <Text size="small" className="mb-2">
            <strong>Communication</strong>
          </Text>
          <Text size="small" className="mb-2">
            To enhance your experience, we may send you push notifications to
            your device to alert you of important activities or updates within
            our Services, such as new messages, notifications from other users,
            or updates to your account. For example, we may send email
            notifications when a user completes an activity, to provide receipt
            for payment or other such notices. We may occasionally send
            promotional or informational emails to users. If you no longer wish
            to receive these emails, you can unsubscribe by clicking the
            “unsubscribe” link at the bottom or each email. We may use this data
            to conduct market and efficacy research.
          </Text>
          <Text size="small" className="mb-2">
            We may use information that is not personal information for any
            purpose. For example, we may aggregate usage data from many people
            in a way that does not identify any individuals to calculate the
            percentage of users accessing a feature on this Site.
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            3. How do you get my consent?
          </Text>
          <Text size="small" className="mb-2">
            When you provide us with personal information to use the software,
            you imply that you consent to our collecting it and using it for
            that specific reason only.
          </Text>
          <Text size="small" className="mb-2">
            If we ask for your personal information for a secondary reason, like
            marketing, we will either ask you directly for your expressed
            consent or provide you with an opportunity to say no.
          </Text>
          <Text size="small" className="mb-2">
            California residents under 16 years of age may have additional
            rights regarding the collection and sale of their personal
            information. Please see the following link for more information{' '}
            <Link to={'https://www.oag.ca.gov/privacy/ccpa'}>
              <i>
                <u>here</u>
              </i>
            </Link>
            .
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            4. How do we share your information?
          </Text>
          <Text size="small" className="mb-2">
            We may share or disclose your personal information as necessary to
            provide our Services or with your consent or permission.
            Additionally, we may share personal information in the
            circumstances:
            <ul className="list-disc ml-8 my-2">
              <li>
                <strong>
                  <u> Business Transfers.</u>
                </strong>{' '}
                In the event of a corporate transaction, such as a merger,
                acquisition, sale of company assets or shares, financing, change
                of control, bankruptcy or other corporate or restructuring
                event, we may disclose personal information to relevant parties.
                If this involves the transfer of your, we will ensure the new
                owner adheres to the terms of this Privacy Policy or provide you
                with the opportunity to opt out by terminating your account and
                deleting your data before the transfer.
              </li>
              <li>
                <strong>
                  <u> Legal Requirements.</u>
                </strong>{' '}
                We may disclose personal information to comply with applicable
                laws, regulations, legal processes, or government requests. This
                may include responding to subpoenas, court orders, or other
                legal proceedings.
              </li>
              <li>
                <strong>Fraud Prevention and Security.</strong> To protect the
                integrity of our Services and the safety of our users, we may
                share personal information with law enforcement or other
                relevant parties to investigate and prevent fraud, security
                breaches, or other illegal activities.
              </li>
              <li>
                <strong>User Safety.</strong> In situations where we believe it
                is necessary to protect the rights, property, or personal safety
                of Caleido, its users, or the public, we may disclose personal
                information.
              </li>
              <li>
                <strong>Third-Party Services.</strong> We may share your
                information with trusted third-party service providers who
                assist us in operating our Services. These providers are bound
                by confidentiality agreements and are only allowed to access and
                use your information for the purposes of providing their
                services.
              </li>
            </ul>
          </Text>
          <Text size="small" className="mb-2">
            Examples of third-party service providers include:
            <ul className="list-disc ml-8 my-2">
              <li>Cloud Computing Services</li>
              <li>Data Analytics Services</li>
              <li>Data Storage Service Providers</li>
              <li>Product Engineering & Design Tools</li>
              <li>User Account Registration & Authentication Services</li>
              <li>Website Hosting Service Providers</li>
            </ul>
          </Text>
          <Text size="small" className="mb-2">
            Please note that we do not sell or share your personal information
            with third parties for marketing purposes. The only information
            shared with third parties is at your explicit request, such as when
            you connect your account with a single sign-on (SSO) provider.
          </Text>
          <Text size="small" className="mb-2">
            Your personal information is not visible to other users of our
            Services without your consent.
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            5. How is my information retained and/or deleted?
          </Text>
          <Text size="small" className="mb-2">
            We will retain your information for as long as necessary to fulfill
            the identified purpose, which may extend beyond the termination of
            our relationship with you. For example, we may retain certain data
            as necessary to prevent fraud or future abuse, for legitimate
            business purposes, or as required by law. We may retain indefinitely
            information that has been de-identified or aggregated such that it
            can no longer reasonably identify a particular individual. All
            retained personal information will remain subject to the terms of
            this Privacy Policy. Otherwise, we will delete personal information
            upon request or according to our standard data retention schedule.
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            6. How do I withdraw my consent?
          </Text>
          <Text size="small" className="mb-2">
            You may opt-out of receiving promotional emails from us by:
            <ul className="list-disc ml-8 my-2">
              <li> Choosing not to opt-in if presented with the option.</li>
              <li>
                Contacting us at{' '}
                <Link to={'mailto:hi@caledio.social'}>hi@caledio.social</Link>
              </li>
              <li>
                Replying to “unsubscribe” links in any promotional emails we
                send you.
              </li>
            </ul>
          </Text>
          <Text size="small" className="mb-2">
            <strong>Please note:</strong> Opting out of marketing communications
            may not apply to information provided for transactions or product
            registrations.
            <ul className="list-disc ml-8 my-2">
              <li>
                <strong>
                  <u>Accessing, Updating and Deleting Your Information.</u>
                </strong>{' '}
                If you have a user account with us, you may be able to access,
                update, or delete your information directly within your account
                settings. If you do not have an account, or if you encounter any
                difficulties managing your information, you can contact us to
                request access to, correction of, or deletion of your personal
                information. We may not be able to accommodate a request to
                change information if it would violate any law or legal
                requirement or negatively affect the information’s accuracy.
              </li>
              <li>
                <strong>
                  <u> Cookies and Automatic Data Collection Technologies.</u>
                </strong>{' '}
                We use cookies and similar technologies to collect information
                about your activity on our platform. You can adjust your browser
                settings to manage cookies and related technologies.
              </li>
              <li>
                <strong>
                  <u> Interest Based Advertising.</u>
                </strong>{' '}
                Please see How <i>Do I Withdraw My Consent?</i> above for
                information on how to opt-out of interest-based advertising.
              </li>
              <li>
                <strong>
                  <u> Google Maps APIs.</u>
                </strong>{' '}
                Our platform utilizes Google Maps APIs. This may involve
                collecting and processing information such as name, phone
                number, email, event details (name, location, guests, timing),
                comments, contacts (name and number), and social media
                availability. Refer to Google’s privacy policy for more
                information about their data practices:{' '}
                <Link
                  target="_blank"
                  className="underline"
                  to={'https://policies.google.com/privacy'}
                >
                  Link to Google Privacy Policy.
                </Link>
              </li>
              <li>
                <strong>
                  <u> Nevada Residents.</u>
                </strong>{' '}
                Nevada residents have the right to opt out of the future sales
                of their personal information. To submit a request, please
                contact us at{' '}
                <Link to={'mailto:hi@caledio.social'}>hi@caledio.social</Link>.
              </li>
            </ul>
          </Text>
          <Text size="small" className="mb-2">
            <u>User Rights and Data Control</u>
          </Text>
          <Text size="small" className="mb-2">
            You have the right to exercise control over your personal
            information as follows:
            <ul className="list-disc ml-8 my-2">
              <li>
                <strong>
                  <u> Account Deletion:</u>
                </strong>{' '}
                To delete your account and all associated data, please follow
                the instructions provided within your account settings or
                contact our support team.
              </li>
              <li>
                <strong>
                  <u> Event Management:</u>
                </strong>{' '}
                You are not required to submit your phone number to respond to
                an event. You can delete events that you have created or are
                participating in.
              </li>
              <li>
                <strong>
                  <u> Calendar Synchronization:</u>
                </strong>{' '}
                You can choose to opt in or out of synching your Google Calendar
                with the Site.
              </li>
              <li>
                <strong>
                  <u> Contact Synchronization:</u>
                </strong>{' '}
                You can opt-in or out of syncing your contacts with our
                platform. You can delete contact data that you have added to the
                Site.
              </li>
              <li>
                <strong>
                  <u> Text Message Notifications:</u>
                </strong>{' '}
                You can opt out of receiving text message notifications by
                adjusting your settings within your account or contacting our
                support team.
              </li>
            </ul>
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            7. California Residents - CCPA
          </Text>
          <Text size="small" className="mb-2">
            The California Consumer Privacy Act (CCPA) grants you additional
            rights and choices regarding your personal information. You can find
            more details about the CCPA here:{' '}
            <Link target="_blank" className="underline" to={'/'}>
              link
            </Link>
            .
          </Text>
          <Text size="small" className="mb-2">
            The list below summarizes the categories of personal information we
            collect and the purposes for which we use and disclose it:
          </Text>

          <Text size="small" className="mb-2">
            <b>Categories of Personal Information</b>
          </Text>
          <Text size="small" className="mb-2">
            <ul className="list-disc ml-8 my-2">
              <li>
                Identifiers, such as your name, username, email address, phone
                number and IP address.
              </li>
              <li>Characteristics such as your race, gender and age.</li>
              <li>Financial information.</li>
              <li>Commercial information, such as purchases made.</li>
              <li>
                Internet activity information, such as information about your
                device and your use of our services.
              </li>
              <li>Geo-location information.</li>
              <li>
                Electronic, visual, or similar information, such as your profile
                picture or photos.
              </li>
              <li>Inferences drawn from the information identified above.</li>
            </ul>
          </Text>

          <Text size="small" className="mb-2">
            <b>Purposes for Collecting, Using, and Disclosing</b>
          </Text>
          <Text size="small" className="mb-2">
            <ul className="list-disc ml-8 my-2">
              <li>Provide you services.</li>
              <li>
                Communicate with you to provide updates or respond to your
                comments and questions.
              </li>
              <li>
                Detect security incidents and prevent fraud, debug and repair
                errors.
              </li>
              <li>Maintain your account.</li>
              <li>Provide customer service.</li>
              <li>
                Conduct research and development and other activities to improve
                our services.
              </li>
              <li>Marketing and advertising.</li>
            </ul>
          </Text>

          <Text size="small" className="mb-2">
            <b>Parties that May Receive Information</b>
          </Text>
          <Text size="small" className="mb-2">
            <ul className="list-disc ml-8 my-2">
              <li>Subsidiaries and affiliates of Caleido.</li>
              <li>Other users or the public, depending on the information.</li>
              <li>
                Third Parties that we employ to process the information on our
                behalf.
              </li>
              <li>
                Legal authorities or others as necessary to comply with law,
                regulation or a legal request; to protect the safety, rights, or
                property of the public, any person, or Caleido; or to detect,
                prevent, or otherwise address fraud, security or technical
                issues.
              </li>
              <li>Marketing Processors</li>
              <li>Payment Processors.</li>
            </ul>
          </Text>

          <Text size="small" className="mb-2">
            <b>Disclosing Sensitive Data</b>
          </Text>
          <Text size="small" className="mb-2">
            We disclose sensitive personal information, such as account login
            information, only as described in this Privacy Policy and with your
            consent.
          </Text>
          <Text size="small" className="mb-2">
            We collect information from a variety of sources, including:
            <ul className="list-disc ml-8 my-2">
              <li> You, directly through your use of our Services</li>
              <li> Your devices</li>
            </ul>
          </Text>

          <Text size="small" className="mb-2">
            <b>Data Retention</b>
          </Text>
          <Text size="small" className="mb-2">
            We retain your personal information only as long as necessary to
            fulfill the purposes described in this Privacy Policy and to comply
            with legal or regulatory obligations. When no longer needed, we
            securely erase or anonymize your information.
          </Text>
          <Text size="small" className="mb-2">
            The CCPA grants you the following rights:
            <ul className="list-disc ml-8 my-2">
              <li>
                <strong>
                  <u> Right to Know and Access:</u>
                </strong>{' '}
                You can request to learn more about our information practices
                and the specific pieces of personal information we have
                collected about you.
              </li>
              <li>
                <strong>
                  <u> Right to Correct:</u>
                </strong>{' '}
                You can correct inaccurate information by updating your profile
                information within your account.
              </li>
              <li>
                <strong>
                  <u> Right to Delete:</u>
                </strong>{' '}
                You can submit a verifiable request to have us delete the
                personal information we have collected about you.
              </li>
              <li>
                <strong>
                  <u> Right to Opt-out of Sales/Sharing:</u>
                </strong>{' '}
                You have the right to opt out of the “sale” or “sharing” of your
                information. You can exercise this right by contacting us or by
                using an opt-out preference signal (such as Global Privacy
                Control).
              </li>
              <li>
                <strong>
                  <u> Right to Equal Service:</u>
                </strong>{' '}
                We will not discriminate against you if you exercise your CCPA
                rights.
              </li>
            </ul>
          </Text>
          <Text size="small" className="mb-2">
            To exercise your rights under the CCPA, please contact us as:{' '}
            <Link to={'contact@caleido.social'}>contact@caleido.social</Link>
          </Text>
          <Text size="small" className="mb-2">
            In addition to exercising these rights yourself, you may also
            designate an authorized agent to make these requests on your behalf.
            If the authorized agent does not provide a valid power of attorney,
            we will ask you to verify the request directly via email or through
            your password-protected account.
          </Text>
          <Text size="small" className="mb-2">
            <b>Transmission of Information to Other Countries - GDPR</b>
          </Text>
          <Text size="small" className="mb-2">
            The servers that make our services available and databases where we
            house information may be located in the United States and in other
            countries that may have privacy laws that differ from where you
            live. We may use cloud services whose servers may be anywhere in the
            world. When we transfer information from the EEA to a country that
            doesn’t provide an adequate level of protection, we’ll only do so
            under appropriate safeguards per the EU general data protection
            regulation (GDPR) to protect your information, like standard
            contractual clauses.
          </Text>
          <Text size="small" className="mb-2">
            When we receive formal written complaints, we respond by contacting
            the person who made the complaint. We work with the appropriate
            regulatory authorities, including local data protection authorities,
            to resolve any complaints regarding the transfer of your data that
            we cannot resolve with you directly.
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            8. Disclosure
          </Text>
          <Text size="small" className="mb-2">
            We share information with other parties. We may disclose your
            non-personal information without restriction.
          </Text>
          <Text size="small" className="mb-2">
            We may share your personal information with:
            <ul className="list-disc ml-8 my-2">
              <li>
                Any member of our corporate group, which means our subsidiaries,
                affiliates, our ultimate holding company and its subsidiaries,
                and affiliates, where applicable.
              </li>
              <li>
                To contractors, service providers, and other third parties we
                use to support our business and who are bound by contractual
                obligations to keep personal information confidential and use it
                only for the purposes for which we disclose it to them.
              </li>
              <li>
                To our business partners who provide services on our behalf but
                who may also use the information we provide for their own
                commercial purposes. For example, some of our advertising
                partners may use information to market other parties’ products
                and services to you. For more information see{' '}
                <i>Interest-based advertising.</i>
              </li>
              <li>
                To public or government (including enforcement) authorities.
              </li>
              <li>
                To an actual or prospective buyer or other successor in the
                event of merger, divestiture, restructuring, reorganization,
                dissolution or other sale or transfer of some or all of our
                assets, where one of the transferred assets is the personal
                information we hold.
              </li>
              <li> If we offer a co-branded promotion, to a co-sponsor.</li>
              <li> To fulfill the purpose for which you provide it.</li>
            </ul>
          </Text>
          <Text size="small" className="mb-2">
            For any other purpose as may be permitted by law, or with your
            consent, or that we describe when you provide the information,
            including but not limited to, if you violate our Terms of Service.
          </Text>
          <Text size="small" className="mb-2">
            We may also disclose your personal information to:
            <ul className="list-disc ml-8 my-2">
              <li>
                Comply with any court order, law, or legal process, including
                responding to any government or regulatory request.
              </li>
              <li>
                Enforce our <i>Terms of Use</i> and other agreements with you.
              </li>
              <li>
                Protect the rights, property, or safety of our business, our
                employees, our customers, or others. This includes exchanging
                information with other companies and organizations for the
                purposes of cybersecurity, fraud protection and credit risk
                reduction.
              </li>
              <li>
                Investigate suspected violations of any law, rule or regulation,
                or the terms or policies for our website.
              </li>
            </ul>
          </Text>
          <Text size="small" className="mb-2">
            We may also use your information to contact you about our own and
            third-parties’ products and services that may be of interest to you.
            If you do not want us to use your information in this way, please
            adjust your user preferences in your account profile or opt-out as
            directed when you receive such an email from us. For more
            information, see <i>How Do I Withdraw My Consent?</i>
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            9. Security of Personal Information
          </Text>
          <Text size="small" className="mb-2">
            The security of our personal information is important to us. We have
            implemented a variety of physical, administrative and technological
            safeguards designed to preserve the integrity and security of all
            personal information we collect. Our employees are required to
            protect personal information in a manner consistent with the terms
            of this Privacy Policy, and violators will be subject to
            disciplinary action, up to and including termination and further
            legal action.
          </Text>
          <Text size="small" className="mb-2">
            Please be aware that despite our best efforts, no data security
            measures are impenetrable and we cannot guarantee the security of
            our systems 100%. In the event that any personal information under
            our control is compromised as a result of a breach of security, we
            will take reasonable steps to investigate the situation and take all
            steps required by law and regulations.
          </Text>
          <Text size="small" className="mb-2">
            The safety and security of your information also depends on you.
            Where we have given you (or where you have chosen) a password for
            access to certain parts of this Site, you are responsible for
            keeping this password confidential. We ask you not to share your
            password with anyone and you should take steps to protect against
            unauthorized access to your password, phone, and computer by, among
            other things, signing off after using a shared device, choosing a
            robust password that nobody else knows or can easily guess, and
            keeping your log-in and password private. We are not responsible for
            any lost, stolen, or compromised passwords or for any activity on
            your account via unauthorized password activity.
          </Text>
          <Text size="small" className="mb-2">
            Passwords are encrypted and securely stored while in motion and at
            rest.
          </Text>
          <Text size="small" className="mb-2">
            Unfortunately, the transmission of information via the internet is
            not completely secure. Although we do our best to protect your
            personal information, we cannot guarantee the security of your
            personal information transmitted to this Site. We urge you to be
            careful about giving out information in public areas of this Site
            like message boards. The information you share in public areas may
            be viewed by any user of this Site. Any transmission of personal
            information is at your own risk. We are not responsible for
            circumvention of any privacy settings or security measures contained
            on the Site.
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            10. Third-Party Services
          </Text>
          <Text size="small" className="mb-2">
            Some content or applications on this Site may be served by third
            parties, content providers and application providers including the
            following:
            <ul className="list-disc ml-8 my-2">
              <li>
                <strong>
                  <u>Plugins.</u>
                </strong>{' '}
                This Site makes available the option for you to use “plugins”
                that are operated by social media companies. If you choose to
                use one of these plugins, then it may collect information about
                you and send it back to the company that owns it. This may
                happen even if you do not click on the plugin, if you are logged
                into the social media website that owns the plugin when you
                visit our website. Information collected by a plugin is subject
                to this Privacy Policy and terms of the social media company
                that makes it. If you do not want the social media company who
                owns a plugin to collect information about you when you visit
                this Site, sign out of the social media network before visiting
                the Site. By interacting with a plugin when you are on this Site
                (for example, clicking the Facebook “Like” button), you are
                intentionally transferring information to that social media
                company. Further, if you are logged into a social media website
                when you visit this Site, then you are directing us to share
                your data with the social media company that owns the plugin.
              </li>
              <li>
                <strong>
                  <u>Third-party links.</u>
                </strong>{' '}
                This Site may contain links to other websites either on user
                profiles or in user generated events or content, none of which
                we control. Those websites have their own privacy policies and
                terms, and certain third-party service providers, such as
                payment gateways and other payment transaction processors, have
                their own privacy policies in respect to the information we are
                required to provide to them for your purchase-related
                transactions. For these providers, we recommend that you read
                their privacy policies so you can understand the manner in which
                your personal information will be handled by these providers.
              </li>
            </ul>
          </Text>
          <Text size="small" className="mb-2">
            Certain providers may be located in or have facilities that are
            located in a different jurisdiction than either you or us. If you
            elect to proceed with a transaction that involves the services of a
            third-party service provider, then your information may become
            subject to the laws of the jurisdiction(s) in which that service
            provider or its facilities are located.
          </Text>
          <Text size="small" className="mb-2">
            As an example, if you are located in Canada and your transaction is
            processed by a payment gateway located in the United States, then
            your personal information used in completing that transaction may be
            subject to disclosure under United States legislation, including the
            Patriot Act.
          </Text>
          <Text size="small" className="mb-2">
            Once you leave our course website or are redirected to a third-party
            website or application, you are no longer governed by this Privacy
            Policy or our website’s Terms of Service.
          </Text>
          <Text size="small" className="mb-2">
            We are not responsible for the privacy practices of other websites
            and encourage you to read their privacy statements.
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            11. Other States Privacy Rights
          </Text>
          <Text size="small" className="mb-2">
            <b>Nevada</b>
          </Text>
          <Text size="small" className="mb-2">
            Nevada residents have the right to opt out of the sale of certain
            “covered information” collected by operators of websites or online
            services. We do not currently cover information, as “sale” is
            defined by such law, nor do we have plans to do so.
          </Text>
          <Text size="small" className="mb-2">
            <b>
              Colorado, Connecticut, Virginia, Utah, Iowa, Indiana, Tennessee,
              Texas, and Florida
            </b>
          </Text>
          <Text size="small" className="mb-2">
            Colorado, Connecticut, Virginia, Utah, Iowa, Indiana, Tennessee,
            Texas, and Florida residents can request information about the
            personal data collected, used, shared, or sold; a copy of their
            personal data; the correction of inaccurate personal data, and the
            deletion of your personal data. Residents of these states can also
            opt out of the sale or sharing of their personal data.
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            12. Copyright Infringement
          </Text>
          <Text size="small" className="mb-2">
            We take claims of copyright infringement seriously. We will respond
            to notices of alleged copyright infringement that comply with
            applicable law. If you believe any materials accessible on or from
            our services infringes your copyright, you may request removal of
            those materials (or access to them) from the services by submitting
            written notification to us as designated below. It is our policy to
            terminate the accounts of repeat infringers.
          </Text>
          <Text size="small" className="mb-2">
            In accordance with the Online Copyright Infringement Liability
            Limitation Act of the Digital Millennium Copyright Act (17 U.S.C. §
            512) (“<strong>DMCA</strong>”), the written notice (the “
            <strong>DMCA Notice</strong>”) must include substantially the
            following:
            <ul className="list-disc ml-8 my-2">
              <li>Your physical or electronic signature.</li>
              <li>
                Identification of the copyrighted work you believe to have been
                infringed or, if the claim involves multiple works on the Site,
                a representative list of such works.
              </li>
              <li>
                Identification of the material you believe to be infringing in a
                sufficiently precise manner to allow us to locate that material.
              </li>
              <li>
                Adequate information by which we can contact you (including your
                name, postal address, telephone number, and, if available, email
                address).
              </li>
              <li>
                A statement that you have a good faith belief that use of the
                copyrighted material is not authorized by the copyright owner,
                its agent, or the law.
              </li>
              <li>
                A statement that the information in the written notice is
                accurate.
              </li>
              <li>
                A statement, under penalty of perjury, that you are authorized
                to act on behalf of the copyright owner.
              </li>
            </ul>
          </Text>
          <Text size="small" className="mb-2">
            If you fail to comply with all of the requirements of Section
            512(c)(3) of the DMCA, Your DMCA Notice may not be effective. Please
            be aware that if you knowingly materially misrepresent that material
            or activity on the Site is infringing your copyright, you may be
            held liable for damages (including costs and attorneys’ fees) under
            Section 512(f) of the DMCA.
          </Text>
          <Text size="small" className="mb-2">
            DMCA Notices must be delivered to our designated Copyright Agent at{' '}
            <Link to={'mailto:hi@caledio.social'}>hi@caledio.social</Link>.
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            13. Internet-based Advertising
          </Text>
          <Text size="small" className="mb-2">
            Some content or applications on this Site, including advertisements,
            are served by third parties, including advertisers, ad networks, and
            servers, content partners, or application providers. These third
            parties may use cookies alone or in conjunction with web beacons or
            other tracking technologies to collect information about you when
            you use this Site. The information they collect may be associated
            with your personal information or they may collect information,
            including personal information, about your online activities over
            time and across different websites and other online services. They
            may use this information to provide you with interest-based
            (behavioral) advertising or other targeted content. Our partners may
            use non-cookie technologies on which browser settings that block
            cookies might have no effect. Your browser may not permit you to
            block such technologies.
          </Text>
          <Text size="small" className="mb-2">
            For this reason, keep in mind, you can block the collection and use
            of information related to you by advertising companies for the
            purpose of serving interest based advertising by visiting the
            following platforms of self-regulatory programs of which those
            companies are members:
            <ul className="list-disc ml-8 my-2">
              <li>
                The NAI’s opt-out platform is available{' '}
                <Link
                  target="_blank"
                  className="underline"
                  to={'https://optout.networkadvertising.org/?c=1'}
                >
                  <i>here.</i>
                </Link>
              </li>
              <li>
                The DAA’s opt-out platform is available{' '}
                <Link
                  target="_blank"
                  className="underline"
                  to={'https://optout.aboutads.info/?c=2&lang=EN'}
                >
                  <i>here.</i>
                </Link>
              </li>
            </ul>
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            14. Cookies
          </Text>
          <Text size="small" className="mb-2">
            This Site uses automatic data collection technologies to distinguish
            you from other website users, including but not limited to cookies,
            and similar technologies, which are files or pieces of text that
            download to a device when a visitor accesses a website or app.
          </Text>
          <Text size="small" className="mb-2">
            This helps us deliver a better and more personalized service when
            you browse this Site. It also allows us to improve this Site by
            enabling us to:
            <ul className="list-disc ml-8 my-2">
              <li>Estimate our audience size and usage patterns.</li>
              <li>
                Store your preferences so we may customize the Site according to
                your individual interests.
              </li>
              <li>Speed up your searches.</li>
              <li>Recognize you when you return to our website.</li>
            </ul>
          </Text>
          <Text size="small" className="mb-2">
            We also may use these technologies to collect information about your
            online activities over time and across third-party websites or other
            online services (behavioral tracking). For more information, see the
            Interest-based advertising section of this Privacy Policy.
          </Text>
          <Text size="small" className="mb-2">
            The technologies we use for this automatic data collection may
            include:
            <ul className="list-disc ml-8 my-2">
              <li>
                <strong>Cookies (or browser cookies).</strong> A cookie is a
                small file placed on the hard drive of your computer. For
                information about managing browser settings to refuse cookies,
                see <i>How Do I Withdraw My Consent?</i>
              </li>
              <li>
                <strong>Flash Cookies.</strong> Certain features of the Site may
                use Flash cookies (local stored objects) instead of browser
                cookies to collect and store information about your preferences
                and navigation to, from, and on the Site. For information about
                managing Flash cookies see <i>How Do I Withdraw My Consent?</i>
              </li>
              <li>
                <strong>Web Beacons.</strong> The Site pages, advertisements,
                and e-mails may contain small transparent embedded images or
                objects known as web beacons (also referred to as clear gifs,
                pixel tags, and single-pixel gifs) that permit us, for example,
                to count website page visitors or email readers, or to compile
                other similar statistics such as recording website content
                popularity or verifying system and server integrity. For
                information about managing web beacons, see{' '}
                <i>
                  How Do I Withdraw My Consent? and Internet-based Advertising.
                </i>
              </li>
            </ul>
          </Text>
          <Text size="small" className="mb-2">
            Depending on which website this notice is posted on, we may use
            Google Analytics to provide certain analytics services for us. More
            information about Google Analytics can be found in the
            <i>Google Analytics Terms of Use</i> and the{' '}
            <i>Google Analytics Privacy Policy.</i>
          </Text>
          <Text size="small" className="mb-2">
            We collect cookies (further detailed below) or similar tracking
            technologies. This means information that our website’s server
            transfers to your computer. This information can be used to track
            your session on our website. Cookies may also be used to customize
            our website content for you as an individual. If you are using one
            of the common Internet web browsers, you can set up your browser to
            either let you know when you receive a cookie or to deny cookie
            access to your computer.
          </Text>
          <Text size="small" className="mb-2">
            <ul className="list-disc ml-8">
              <li>
                We use cookies to recognize your device and provide you with a
                personalized experience.
              </li>
              <li>
                We also use cookies to attribute visits to this Site to
                third-party sources and to serve targeted ads from Google,
                Facebook, Instagram and other third-party vendors.
              </li>
              <li>
                Our third-party advertisers use cookies to track your prior
                visits to this Site and elsewhere on the Internet in order to
                serve you targeted ads. For more information about targeted or
                behavioral advertising, please visit{' '}
                <Link
                  target="_blank"
                  className="underline"
                  to={
                    'https://www.networkadvertising.org/understanding-online-advertising.'
                  }
                >
                  https://www.networkadvertising.org/understanding-online-advertising.
                </Link>
              </li>
              <li>
                <strong>
                  <u>Opting Out:</u>
                </strong>{' '}
                You can opt-out of targeted ads served via specific third-party
                vendors by visiting the Digital Advertising Alliance’s Opt-Out
                page.
              </li>
              <li>
                We may also use automated tracking methods on the Site, in
                communications with you, and in our products and services, to
                measure performance and engagement.
              </li>
              <li>
                Opting out: You can opt out of targeted ads served via specific
                third-party vendors by visiting the Digital Advertising
                Alliance’s{' '}
                <strong>
                  <u>Opt-Out page.</u>
                </strong>
              </li>
              <li>
                Please note that because there is no consistent industry
                understanding of how to respond to “Do Not Track” signals, we do
                not alter our data collection and usage practices when we detect
                such a signal from your browser.
              </li>
            </ul>
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            15. Web Analysis Tools
          </Text>
          <Text size="small" className="mb-2">
            We may use web analysis tools that are built into the Caleido
            website to measure and collect anonymous session information.
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            16. Changes to this Privacy Policy
          </Text>
          <Text size="small" className="mb-2">
            We reserve the right to amend or modify this Privacy Policy at any
            time, so please review it frequently. Changes and clarifications
            will take effect immediately upon their posting on the website. If
            we make material changes to this policy, we will notify you here
            that it has been updated, so that you are aware of what information
            we collect, how we use it, and under what circumstances, if any, we
            use and/or disclose it.
          </Text>
          <Text size="small" className="mb-2">
            If this Site or course is acquired or merged with another company,
            your information may be transferred to the new owners so that we may
            continue to sell products to you.
          </Text>

          <Text size="small" className="mt-6 mb-2.5 font-semibold">
            17. Questions and Contact Information
          </Text>
          <Text size="small" className="mb-2">
            If you would like to: access, correct, amend or delete any personal
            information we have about you, register a complaint, or simply want
            more information contact our team at{' '}
            <Link to={'mailto:hi@caledio.social'}>hi@caledio.social</Link>.
          </Text>
        </div>
      </div>
    </OnboardingLayout>
  );
};

export default PrivacyPolicy;
