import { createApi } from '@reduxjs/toolkit/query/react';
import { GCalendarBlockResponse } from 'modules/home/types/GCalendarBlock';
import baseQueryWithReauth from 'services/mutationInterceptor';

export const calendarApiSlice = createApi({
  reducerPath: 'calendarApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Calendar', 'calendar-time-blocks'],
  endpoints: (builder) => ({
    getGoogleCalendarBlocks: builder.query<
      any,
      { startDate: string; endDate: string }
    >({
      query: ({ startDate, endDate }) => ({
        url: `calendar/time-blocks?startDate=${startDate}&endDate=${endDate}`,
        method: 'GET',
      }),
      transformErrorResponse: (error) => {
        return [];
      },
      transformResponse: (response: GCalendarBlockResponse) => {
        if (!response || !response.events) return [];
        return response.events;
      },
      providesTags: (result, error, { endDate, startDate }) => [
        { type: 'calendar-time-blocks', endDate, startDate },
      ],
    }),
  }),
});

export const { useGetGoogleCalendarBlocksQuery } = calendarApiSlice;
