import { useRef } from 'react';
import { TouchContainerProps } from './TouchContainer.types';

const TouchContainer = ({
  children,
  className,
  onClick,
  onTouchEnd,
  onTouchStart,
  style,
  touchThreshold,
}: TouchContainerProps) => {
  const swipeThreshold = touchThreshold ?? 100;

  const startXRef = useRef(0);

  const handleTouchStart = (e: any) => {
    onTouchStart && onTouchStart();
    const touch = e.touches[0];
    startXRef.current = touch.clientX;
  };

  const handleTouchEnd = (e: any) => {
    const touch = e.changedTouches[0];
    const endX = touch.clientX;
    const diffX = endX - startXRef.current;

    if (diffX > swipeThreshold) {
      // swipe right
      onTouchEnd && onTouchEnd('prev');
    } else if (diffX < -swipeThreshold) {
      // swipe left
      onTouchEnd && onTouchEnd('forward');
    }
  };

  return (
    <div
      className={`${className ?? ''}`}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onClick={onClick}
      style={style}
    >
      {children}
    </div>
  );
};

export default TouchContainer;
