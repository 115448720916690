import { useAppSelector } from 'hooks/useAppSelector';
import ActivityHeader from './components/header';
import ActivityLayout from './layouts/ActivityLayout';
import ActivityInvites from './components/invites';
import ActivityPolls from './components/poll';
import {
  ActivityType,
  FriendActivityType,
  IActivityInvite,
  IActivityPoll,
  IUserActivity,
} from 'modules/activity/types/types';
import UserActivities from './components/userActivities';
import BottomNav, { BottomNavType } from 'components/bottomNav/bottomNav';
import { useGetActivitiesQuery } from 'services/activity.service';
import { useEffect } from 'react';

// #region IInvite Test Data

type Activity = {
  body: string;
  eventDate: string;
  icon: string;
  status: number;
  timestamp: string;
  title: string;
  type: string;
  userId: string;
  __v: number;
  _id: string;
};

const invites: IActivityInvite[] = [
  {
    id: '1',
    type: 'now',
    by: {
      id: 'user1',
      name: 'Alice Johnson',
      username: 'alicej',
      photoURL: 'https://i.pravatar.cc/300?img=1',
    },
  },
  {
    id: '2',
    type: 'later',
    by: {
      id: 'user2',
      name: 'Bob Smith',
      username: 'bobsmith',
      photoURL: 'https://i.pravatar.cc/300?img=2',
    },
  },
];

// #endregion

// #region IActivityPoll Test Data

const activityPolls: IActivityPoll[] = [
  {
    id: '1',
    title: 'Group Project Discussion',
    by: {
      id: 'user1',
      name: 'Alice Johnson',
      username: 'alicej',
      photoURL: 'https://i.pravatar.cc/300?q=1',
    },
  },
  {
    id: '2',
    title: 'Study at Night',
    by: {
      id: 'user2',
      name: 'Bob Smith',
      username: 'bobsmith',
      photoURL: 'https://i.pravatar.cc/300?q=2',
    },
  },
  {
    id: '3',
    title: 'Weekend Coding Marathon',
    by: {
      id: 'user3',
      name: 'Carol White',
      username: 'carolw',
      photoURL: 'https://i.pravatar.cc/300?q=3',
    },
  },
];

// #endregion

// #region User Activities

const userActivities: IUserActivity[] = [
  {
    id: '1',
    type: ActivityType.FRIEND,
    by: {
      id: 'user1',
      name: 'Alice',
      username: 'alice123',
      photoURL: 'https://i.pravatar.cc/300?img=1',
    },
    friendActivityType: FriendActivityType.ACCEPT_EVENT,
  },
  {
    id: '2',
    type: ActivityType.EVENT,
    event: {
      id: 'event1',
      title: 'Tech Conference 2024',
      emoji: '🖥️',
    },
  },
  {
    id: '3',
    type: ActivityType.FRIEND,
    by: {
      id: 'user2',
      name: 'Bob',
      username: 'bob456',
      photoURL: 'https://i.pravatar.cc/300?img=2',
    },
    friendActivityType: FriendActivityType.REJECT_EVENT,
  },
  {
    id: '4',
    type: ActivityType.EVENT,
    event: {
      id: 'event2',
      title: 'Music Festival',
      emoji: '🎵',
    },
  },
  {
    id: '5',
    type: ActivityType.FRIEND,
    by: {
      id: 'user3',
      name: 'Charlie',
      username: 'charlie789',
      photoURL: 'https://i.pravatar.cc/300?img=3',
    },
    friendActivityType: FriendActivityType.COMMENT_EVENT,
  },
];

// #endregion User Activities

const Activities: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);

  const { data: eventActivityData, refetch: getEventActivity } =
    useGetActivitiesQuery('all');

  // based on the type of eventActivityData divide data into three arrays as eventData, pollData and friendData
  const eventData = eventActivityData?.filter(
    (activity: { type: string }) => activity.type === 'event',
  );
  const sortedEventData = eventData?.sort((a: Activity, b: Activity) => {
    return new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime();
  });

  const pollData = eventActivityData?.filter(
    (activity: { type: string }) => activity.type === 'poll',
  );



  const friendData = eventActivityData?.filter(
    (activity: { type: string }) => activity.type === 'friend',
  );

  // refresh every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      getEventActivity();
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  if (!user) {
    return null;
  }

  return (
    <ActivityLayout>
      <ActivityHeader user={user} />
      <ActivityInvites invites={sortedEventData ?? []} />
      <ActivityPolls polls={pollData ?? []} />
      <UserActivities activities={friendData ?? []} />
      <BottomNav type={BottomNavType.ACTIVITY ?? []} />
    </ActivityLayout>
  );
};

Activities.displayName = 'Activities';
export default Activities;
