import { useRef } from 'react';
import { isEmpty } from 'lodash';
import { ChevronRight } from 'lucide-react';
import { useAppSelector } from 'hooks/useAppSelector';
import { RootState } from 'app/store';
import SettingsLayout from 'modules/settings/layouts/SettingsLayout/SettingsLayout';
import MainButton from 'modules/auth/components/MainButton';
import { Text } from 'components/text/primary/Text';

import OverlayLoader from 'components/loader/OverlayLoader';
import Availability from 'modules/profile/components/availability/Availability';
import { useUpdateUserMutation } from 'modules/auth/services/user.mutation.service';

const EditAvailability = () => {
  const availabilityRef = useRef<any>(null);
  const user = useAppSelector((state: RootState) => state.auth.user);
  const [updateUser, { isLoading, data }] = useUpdateUserMutation();

  const handleSubmit = () => {
    if (availabilityRef?.current) {
      availabilityRef?.current();
    }
  };

  return (
    <SettingsLayout>
      <main className="flex-grow mb-4">
        <div className="w-full my-8 mb-6">
          {isEmpty(data ?? user) ? (
              <OverlayLoader/>
          ) : (
              <Availability
                  ref={availabilityRef}
                  update={updateUser}
                  data={data ?? user}
                  setPrevOrSkip={() => {
                  }}
                  title="Edit your social availability"
              />
          )}
        </div>
      </main>
      <footer className="mb-4">
        <MainButton
          type="primary"
          icon={<ChevronRight color="#fff" />}
          onClick={handleSubmit}
          loading={isLoading}
        >
          <Text size="small">Save Changes</Text>
        </MainButton>
      </footer>
    </SettingsLayout>
  );
};

export default EditAvailability;
