import { useRef } from 'react';
import { ChevronRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'hooks/useAppSelector';
import { RootState } from 'app/store';
import { useUpdateUserSettingsMutation } from 'modules/auth/services/user.mutation.service';
import SettingsLayout from 'modules/settings/layouts/SettingsLayout/SettingsLayout';
import MainButton from 'modules/auth/components/MainButton';
import { Text } from 'components/text/primary/Text';

import Account from 'modules/profile/components/account/Account';
import OverlayLoader from 'components/loader/OverlayLoader';

const EditProfile = () => {
  const formSubmitRef = useRef<(() => void) | null>(null);

  const user = useAppSelector((state: RootState) => state.auth.user);
  const [updateUser, { isLoading: updateUserIsLoading, data }] =
    useUpdateUserSettingsMutation();

  const handleSubmit = () => {
    if (formSubmitRef.current) {
      formSubmitRef.current();
    }
  };

  return (
    <SettingsLayout>
      <main className="flex-grow mb-4">
        <div className="w-full my-8 mb-6">
          <div className="flex flex-col items-center w-full gap-2">
            <Account
              ref={formSubmitRef}
              update={updateUser}
              data={data ?? user}
              setPrevOrSkip={() => {}}
              isEdit
            />
            <div className="flex flex-col w-full pt-6 mt-5 border-t border-slate-600">
              <Text size="small" className="w-full font-bold text-md">
                Change Phone Number
              </Text>
              <Text size="small" className="my-3">
                Request to change by sending an email to support@caleido.social
              </Text>
              <Link
                to="mailto:support@caleido.social"
                className="text-sm text-purple-200 underline"
              >
                Send Email
              </Link>
            </div>
          </div>
        </div>
      </main>
      <footer className="mb-4">
        <MainButton
          type="primary"
          icon={<ChevronRight color="#fff" />}
          onClick={handleSubmit}
          loading={updateUserIsLoading}
        >
          <Text size="small">Save Changes</Text>
        </MainButton>
      </footer>
      {updateUserIsLoading && <OverlayLoader />}
    </SettingsLayout>
  );
};

export default EditProfile;
