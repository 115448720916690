interface EventIconProps {
  icon: string;
  active?: boolean;
  size?: 'medium' | 'large' | 'base';
  background?: string;
}

export default function EventIcon({
  icon,
  active,
  size,
  background,
}: EventIconProps) {
  const container = {
    medium: '48',
    base: '65',
    large: '80',
  }[size || 'large'];

  const fontSize = {
    medium: '28',
    base: '35',
    large: '56',
  }[size || 'large'];

  return (
    <div
      className={`bg-white ${active ? `bg-opacity-30` : `bg-opacity-10`} rounded-full flex justify-center items-center`}
      style={{
        backgroundColor: background,
        height: `${container}px`,
        width: `${container}px`,
      }}
    >
      <span
        // className="text-2xl"
        style={{
          fontSize: `${fontSize}px`,
        }}
      >
        {icon}
      </span>
    </div>
  );
}

// <svg
// width={container}
// height={container}
// xmlns="http://www.w3.org/2000/svg"
// className={`bg-white ${active ? `bg-opacity-30` : `bg-opacity-10`} rounded-full`}
// style={{ backgroundColor: background }}
// >
// <defs>
//   <mask id="emoji-mask">
//     <rect width="60" height="60" fill="white" />
//     <text
//       x="50%"
//       y="50%"
//       fontSize={fontSize}
//       fill="black"
//       textAnchor="middle"
//       dominantBaseline="middle"
//     >
//       {icon}
//     </text>
//   </mask>
//   <filter id="purple-blend" x="0" y="0" width="100%" height="100%">
//     <feFlood floodColor="rgba(96, 0, 233, 1)" result="flood" />
//     <feComposite
//       in="flood"
//       in2="SourceAlpha"
//       operator="in"
//       result="mask"
//     />
//     <feBlend in="SourceGraphic" in2="mask" mode="luminosity" />
//   </filter>
// </defs>
// <text
//   x="48%"
//   y="56%"
//   fontSize={fontSize}
//   fill="black"
//   filter="url(#purple-blend)"
//   textAnchor="middle"
//   dominantBaseline="middle"
// >
//   {icon}️
// </text>
// </svg>
