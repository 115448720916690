import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from 'components/ui/toast/toast';
import { useToast } from 'components/ui/toast/use-toast';
import { cn } from 'utils/helpers';

export function Toaster() {
  const { toasts } = useToast();
  return (
    <ToastProvider>
      {toasts.map(function ({
        id,
        title,
        description,
        action,
        icon,
        fullscreen,
        backgroundColor,
        textColor,
        ...props
      }) {
        return (
          <Toast
            key={id}
            {...props}
            fullscreen={fullscreen}
            className={'bg-indigo-950 text-white border-none'}
            style={{ backgroundColor, color: textColor }}
          >
            <div className="flex items-center gap-2">
              <div>{icon}</div>
              <div>
                {title && <ToastTitle>{title}</ToastTitle>}
                {description && (
                  <ToastDescription>{description}</ToastDescription>
                )}
              </div>
            </div>

            {action}

            <ToastClose className={'text-white'} />
          </Toast>
        );
      })}

      <ToastViewport fullScreen={toasts.some((i) => i.fullscreen)} />
    </ToastProvider>
  );
}
