const BgGradient = () => (
  <div
    className="fixed w-[512px] h-[1085px] rounded-[1085px] opacity-70 blur-[100px] top-0 left-0 -z-10"
    style={{
      background: `linear-gradient(162deg, #121538 0%, #121538 25%, #111329 25%, #07031C 27.02%, #0E0335 39.2%, #170259 47.33%, #260392 58.95%, #401DBD 72.46%, #7876E6 79.31%)`,
    }}
  ></div>
);

export default BgGradient;
