import { isEmpty } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import tokenService from 'modules/auth/utils/tokenUtils';
import {
  INotificationPermissions,
  IUser,
  IUserResponse,
  IUserWithTokens,
} from 'modules/auth/types/types';
import { Contact } from 'modules/profile/types/types';

interface AuthState {
  isAuthenticated: boolean;
  user: IUser | undefined;
  error: string | undefined;
  loading: boolean;
  notification: INotificationPermissions;
}

const initialState: AuthState = {
  isAuthenticated:
    !isEmpty(localStorage.accessToken) || !isEmpty(localStorage.refreshToken),
  user: undefined,
  error: undefined,
  loading: false,
  notification: {
    calender: false,
    event: false,
    caleido: false,
    text: false,
  } as INotificationPermissions,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<any>) {
      state.loading = action.payload;
    },
    login(state, action: PayloadAction<IUserWithTokens>) {
      state.isAuthenticated = true;
      state.loading = false;
      state.user = action.payload.user;
      state.error = undefined;
      tokenService.setToken(action.payload.tokens.access.token);
      tokenService.setRefreshToken(action.payload.tokens.refresh.token);
    },

    setUser(state, action: PayloadAction<IUserResponse>) {
      state.loading = false;
      const payload = action.payload;

      const contacts: Contact[] | null = payload.contacts
        ? typeof payload.contacts === 'string'
          ? (JSON.parse(payload.contacts) as Contact[])
          : payload.contacts
        : null;

      state.user = {
        ...payload,
        contacts: contacts as Contact[],
      };
    },
    logout(state) {
      state.isAuthenticated = false;
      state.user = undefined;
      state.loading = false;
      state.error = undefined;
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = '/auth/start';
    },
    setError(state, action: PayloadAction<string>) {
      // Note: Commented this code to remove unnecessary local storage clearing on error
      state.error = action.payload;
      console.error(action.payload);
      state.loading = false;
      // state.user = undefined;
      // state.isAuthenticated = false;
      // localStorage.clear();
    },
    setNotificationPermissions(
      state,
      action: PayloadAction<INotificationPermissions>,
    ) {
      console.log(action.payload);
      state.notification = action.payload;
    },
  },
});

export const {
  login,
  logout,
  setError,
  setLoading,
  setUser,
  setNotificationPermissions,
} = authSlice.actions;
export default authSlice.reducer;
