import {
  ReceiveMeetResponseState,
  ReceiveMeetResponseType,
  ReceivePublicMeetResponseState,
} from 'modules/letsMeet/types/types';
import { useEffect, useState } from 'react';
import ResponseButton from './responseButton';

import { ReactComponent as YesIcon } from 'assets/images/activity/yes.svg';
import { ReactComponent as NoIcon } from 'assets/images/activity/no.svg';
import ActionButton from 'components/buttons/action/ActionButton';
import MainButton from 'modules/auth/components/MainButton';
import { ChevronRight } from 'lucide-react';
import { toast } from 'components/ui/toast/use-toast';
import { cn } from 'utils/helpers';
import TextField from 'components/textfield/TextField';
import { Drawer } from 'vaul';
import OverlayLoader from 'components/loader/OverlayLoader';
import { Text } from 'components/text/primary/Text';
import { useCheckTimeAvaliabilityQuery } from 'services/slices/eventsApiSlice';
import { da } from 'date-fns/locale';
import { DraggableContainer } from 'components/draggableContainer';

type MeetPrivateResponseProps = {
  public?: false;
  onSubmit: (response: ReceiveMeetResponseType) => Promise<void>;
  state: ReceiveMeetResponseState;
  setState: (state: ReceiveMeetResponseState) => void;
  bottomSheet?: boolean;
  setBottomSheet?: (state: boolean) => void;
  event: any,
  userResponseStatus?: number,
  setUserResponseStatus?: any
};

type MeetPublicResponseProps = {
  public: true;
  hideMaybe?: boolean;
  onSubmit: (response: ReceiveMeetResponseType, name: string) => Promise<void>;
  state: ReceivePublicMeetResponseState;
  setState: (state: ReceivePublicMeetResponseState) => void;
  setParentName: any;
  bottomSheet?: boolean;
  setBottomSheet?: (state: boolean) => void;
  event: any,
  userResponseStatus?: number,
  setUserResponseStatus?: any
};

type MeetResponseProps = MeetPrivateResponseProps | MeetPublicResponseProps;

const MeetResponse: React.FC<MeetResponseProps> = (props) => {
  const [response, setResponse] = useState<ReceiveMeetResponseType | undefined>(()=>{
      if(props.userResponseStatus){
          if(props.userResponseStatus === 1){
              return ReceiveMeetResponseType.ACCEPTED;
          }else if(props.userResponseStatus === 2){
              return ReceiveMeetResponseType.DECLINED;
          }else if(props.userResponseStatus === 3){
              return ReceiveMeetResponseType.MAYBE;
          }
      }
      return undefined;
    }
  );

  const [snap, setSnap] = useState<number | string | null>('98px');

  const [submitting, setSubmitting] = useState(false);

  const nameStoredInStorage = props.public ? localStorage.getItem('userName') : ''
  const [name, setName] = useState<string>(nameStoredInStorage ?? '');
  const [isAvailability, setIsAvailability] = useState<boolean>(false);
  const [isDrawerComponentVisible, setIsDrawerComponentVisible] = useState(true);

  const handleResponseChange = (_response: ReceiveMeetResponseType) => {
    if (submitting) return;
    setResponse(_response);
    if(props.userResponseStatus){
      if(_response===ReceiveMeetResponseType.ACCEPTED){
        props.setUserResponseStatus(1);
      }else if(_response===ReceiveMeetResponseType.DECLINED){
        props.setUserResponseStatus(2);
      }else if(_response===ReceiveMeetResponseType.MAYBE){
        props.setUserResponseStatus(3);
      }
    }
  };

  const submitResponse = async () => {
    if (submitting) return;

    if (props.public) {
      props.setParentName(name);
    }

    if (props.public && name.trim() === '') {
      toast({
        title: 'Please enter your name',
      });
      return;
    }

    if (!response) {
      toast({
        title: 'Please select a response',
      });
      return;
    }
    setSubmitting(true);

    if (props.public) {
      await props.onSubmit(response, name);
      localStorage.setItem('userName', name);
    }
    else await props.onSubmit(response);

   
    if (props.setBottomSheet) {
      props.setBottomSheet(false);
    }
    // after 3 seconds props.setBottomSheet(false) will be called
    setTimeout(() => {
      setSubmitting(false);
    }, 2000);
  };

  const { data, refetch } = useCheckTimeAvaliabilityQuery({
    startDateTime: new Date(props?.event?.start).toISOString(),
    endDateTime: new Date(props?.event?.end).toISOString(),
  });
  
  useEffect(() => {
    refetch();
  }, [refetch]);
  
  useEffect(() => {
    if (data?.availability) {
      setIsAvailability(data.availability);
    }
  }, [data]);
  
  if (submitting) {
    return <OverlayLoader/>
  }

  // TODO: identify proper instances for drawer modal
  // const isDrawerModal = response === undefined;
  const isDrawerModal = false;

  return (    
    isDrawerComponentVisible?
      <Drawer.Root modal={isDrawerModal} onClose={()=>setIsDrawerComponentVisible(false)} open={isDrawerComponentVisible} repositionInputs={false}>  
      <Drawer.Overlay className="fixed inset-0 bg-black/40 touch-none hidden" />
      <Drawer.Portal>

        <Drawer.Content
          autoFocus={false}
          onOpenAutoFocus={(e) => e.preventDefault()}
          className=" flex flex-col rounded-t-[10px] mt-24 h-fit fixed bottom-0 left-0 right-0 outline-none border-none">
          <div
            className={cn(
              'flex flex-col bg-[#1F2339] text-white rounded-t-xl pb-16',
              props.public && 'rounded-none',
            )}
          >
            <div className={'flex justify-center mt-4 px-7 pt-3 pb-2 '}>
              <div className="w-28 h-2 rounded-full bg-purple-800"></div>
            </div>

            {props.public && (
              <>
                <div className="mx-8 pt-5">
                  <TextField
                    label="What is your name?"
                    required
                    placeholder="Your Name"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </div>
                <div className="mx-8 pt-5">
                  <TextField
                    label="Phone number"
                    required
                    placeholder=""
                    value={""}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    maxLength={10}
                  />
                </div>
              </>
            )}

            <div
              className="text-xl font-semibold text-center pt-3.5 mb-2.5 leading-8"
              onClick={() =>
                props?.setBottomSheet
                  ? props.setBottomSheet(!props.bottomSheet)
                  : null
              }
            >
              Are you going?
            </div>

            <div className="mb-2">
              <ResponseButton
                selected={response === ReceiveMeetResponseType.ACCEPTED}
                onClick={() =>
                  handleResponseChange(ReceiveMeetResponseType.ACCEPTED)
                }
                title="Yes"
                icon={<YesIcon />}
              />

              <ResponseButton
                selected={response === ReceiveMeetResponseType.DECLINED}
                onClick={() =>
                  handleResponseChange(ReceiveMeetResponseType.DECLINED)
                }
                title="No"
                icon={<NoIcon />}
              />

              {!(props.public && props.hideMaybe) && (
                <ResponseButton
                  selected={response === ReceiveMeetResponseType.MAYBE}
                  onClick={() =>
                    handleResponseChange(ReceiveMeetResponseType.MAYBE)
                  }
                  title="Maybe"
                  variant="secondary"
                />
              )}
              {!isAvailability && <Text size="small" className="text-red-500 text-left flex justify-center items-center mx-8 gap-1.5 my-1.5" >
                Heads Up! This time overlaps with an existing block
              </Text>}
            </div>
            <MainButton
              type="primary"
              onClick={submitResponse}
              className="mx-8 box-border w-auto"
              icon={<ChevronRight color="#fff" />}
              loading={submitting}
            >
              Send Response
            </MainButton>
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
    :
    <DraggableContainer onDragStart={()=>{setIsDrawerComponentVisible(true)}} longTouchThreshold={10}>
      <div className="flex flex-col bg-[#1F2339] px-7 pt-3 pb-4 fixed bottom-0 w-full" onClick={()=>setIsDrawerComponentVisible(true)}>
        <div className={'flex justify-center mt-4'} >
          <div className="w-28 h-2 rounded-full bg-purple-800"></div>
        </div>
      </div>
    </DraggableContainer>
  );
};

export default MeetResponse;
