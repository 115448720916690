import {
  Datepicker,
  DatepickerEvent,
} from '@meinefinsternis/react-horizontal-date-picker';
import { enUS } from 'date-fns/locale';
import {
  getWeekDatesAround,
  getYearDayRange,
  getYearStartAndEnd,
} from 'modules/home/utils/helpers';
import { useEffect, useMemo, useRef, useState } from 'react';
import './calendarDateSelector.css';
import { CalendarDateSelectorProps } from './CalendarDateSelector.types';

const CalendarDateSelector = ({
  onSelectedDateChange,
  selectedDate,
}: CalendarDateSelectorProps) => {
  const [displayDates, setDisplayDates] = useState<Date[]>([]);

  useEffect(() => {
    setDisplayDates(getWeekDatesAround(selectedDate ?? selectedDate));
  }, [selectedDate]);

  const { endOfRange: endOfYear, startOfRange: startOfYear } = useMemo(() => {
    return getYearDayRange(selectedDate);
  }, []);

  const datepickerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (datepickerRef.current) {
      const internalContainer = datepickerRef.current.querySelector(
        '.dr',
      ) as HTMLDivElement;
      const targetElement = internalContainer?.querySelector(
        '.or.FC',
      ) as HTMLDivElement;

      if (internalContainer && targetElement) {
        const targetOffsetLeft = targetElement.offsetLeft;
        const targetWidth = targetElement.offsetWidth;
        const containerWidth = internalContainer.clientWidth;

        // Set the scrollLeft so that the target element is centered
        internalContainer.scrollLeft =
          targetOffsetLeft - containerWidth / 2 + targetWidth / 2;
      }
    }
  }, []);

  return (
    <Datepicker
      ref={datepickerRef}
      onChange={(event: DatepickerEvent) => {
        const [startValue, endValue, rangeDates] = event;
        (startValue || endValue) &&
          onSelectedDateChange((startValue || endValue) as Date);
      }}
      locale={enUS}
      startValue={selectedDate}
      endValue={selectedDate}
      classNames={{
        monthLabel: '',
        dateLabel:
          'p-[10px] border-[1px] rounded-full font-normal font-manrope',
        dayLabel:
          '!text-white block mb-2 text-[8px] font-light leading-normal font-sofia-extralight',
        dayItem: '!bg-transparent text-white bg-white',
        rangeDays: '',
        selectedDay: '',
      }}
      startDate={startOfYear}
      endDate={endOfYear}
    />
  );

  // TODO: Use if the above component turns out to be not fitting
  // return (
  //   <DatePicker
  //     getSelectedDay={(date: Date) => onSelectedDateChange(date)}
  //     endDate={5}
  //     selectDate={selectedDate}
  //     labelFormat={'MMMM'}
  //     color={'#374e8c'}
  //   />
  // );

  // manual implementation
  // return (
  //   <div className="flex justify-center space-x-[9px]">
  //     {displayDates.map((date: Date, index: number) => {
  //       return (
  //         <div
  //           key={index}
  //           className={`flex flex-col items-center h-auto space-y-[10px] w-[33px] select-none cursor-pointer `}
  //           onClick={() => onSelectedDateChange(date)}
  //         >
  //           <div className="text-white text-[8px] font-light leading-normal">
  //             {format(date, 'EEE')}
  //           </div>
  //           <div
  //             className={`rounded-full w-8 h-8 flex justify-center items-center font-normal text-[13.8px] date-label-number ${isSameDay(date, selectedDate) ? 'bg-[#6000E9] !border-none' : ''}`}
  //           >
  //             <span>{format(date, 'dd')}</span>
  //           </div>
  //         </div>
  //       );
  //     })}
  //   </div>
  // );
};

export default CalendarDateSelector;
