import moment from 'moment';
import { useRef, useState } from 'react';
import { momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { DraggableContainerProps } from './DraggableContainer.types';

const localizer = momentLocalizer(moment);

const DraggableContainer = ({
  children,
  onDragEnd,
  onDragStart,
  onLongPressStart,
  className,
  longTouchThreshold,
}: DraggableContainerProps) => {
  const [touchTimeout, setTouchTimeout] = useState<any>(null);
  const [isLongTouch, setIsLongTouch] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const touchStartX = useRef<number | null>(null);
  const touchStartY = useRef<number | null>(null);

  const clearTouchTimeout = () => {
    if (touchTimeout) {
      clearTimeout(touchTimeout);
      setTouchTimeout(null);
    }
  };

  const handleTouchStart = (e: any) => {
    clearTouchTimeout();
    setIsDragging(false);

    const timeout = setTimeout(() => {
      setIsLongTouch(true);
      if (onLongPressStart) onLongPressStart(e);
    }, longTouchThreshold ?? 500);

    setTouchTimeout(timeout);
    touchStartX.current = e.touches[0].clientX;
    touchStartY.current = e.touches[0].clientY;
  };

  const handleTouchMove = (e: any) => {
    if (isLongTouch) {
      setIsDragging(true);
      if (onDragStart) onDragStart(e);
      // Optionally handle the dragging logic
      console.log('Long touch and dragging...');
    }
  };

  const handleTouchEnd = (e: any) => {
    clearTouchTimeout();
    if (isDragging) {
      if (onDragEnd) onDragEnd(e);
    }
    setIsLongTouch(false);
    setIsDragging(false);
  };

  const handleMouseDown = (e: any) => {
    clearTouchTimeout();
    setIsDragging(false);

    const timeout = setTimeout(() => {
      setIsLongTouch(true);
      if (onLongPressStart) onLongPressStart(e);
    }, longTouchThreshold ?? 1000);

    setTouchTimeout(timeout);
    touchStartX.current = e.clientX;
    touchStartY.current = e.clientY;
  };

  const handleMouseMove = (e: any) => {
    if (isLongTouch) {
      setIsDragging(true);
      if (onDragStart) onDragStart(e);
      console.log('Dragging with mouse...');
    }
  };

  const handleMouseUp = (e: any) => {
    clearTouchTimeout();
    if (isDragging) {
      if (onDragEnd) onDragEnd(e);
    }
    setIsLongTouch(false);
    setIsDragging(false);
  };

  return (
    <div
      className={`${className}`}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    >
      {children}
    </div>
  );
};

export default DraggableContainer;
