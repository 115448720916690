import React, { createContext, useContext, useState, ReactNode } from 'react';

interface AuthState {
  phoneNumber: {
    code: string;
    number: string;
    to: string;
  };
  formattedPhoneNumber: string;
  termsAccepted: boolean;
}

interface AuthContextProps {
  state: AuthState;
  setAuthState: (startState: AuthState) => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const useAuthContext = (): AuthContextProps => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthContext must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [state, setState] = useState<AuthState>({
    phoneNumber: { code: '+1', number: '', to: '' },
    formattedPhoneNumber: '',
    termsAccepted: false,
  });

  const setAuthState = (startState: AuthState) => {
    setState(startState);
  };

  return (
    <AuthContext.Provider value={{ state, setAuthState }}>
      {children}
    </AuthContext.Provider>
  );
};
