import {createApi} from '@reduxjs/toolkit/query/react';
import baseQueryWithReauth from 'services/mutationInterceptor';

interface CreateActivityRequest {
    title: string;
    body: string;
    type: string;
}
interface UpdateActivityRequest {
    eventId: string;
    type: string;
}

export const activityApi = createApi({
    reducerPath: 'activityApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Get', 'Activity'],
    endpoints: (builder) => ({
        createActivity: builder.mutation<any, CreateActivityRequest>({
            query: (data) => ({
                url: '/activity',
                method: 'POST',
                body: data,
            }),
        }),
        getActivities: builder.query<any, string>({
            query: (type) => ({
                url: `/activity/${type}`,
                method: 'GET',
                headers: {
                    'Cache-Control': 'no-cache',
                },
            }),
            transformResponse: (response) => {
                console.log("Event response:", response);
                return response;
            },
        }),
        updateActivity: builder.mutation<any, UpdateActivityRequest>({
            query: (data) => ({
                url: '/activity/update',
                method: 'PUT',
                body: data,
            }),
        }),
    })
});

export const {
    useCreateActivityMutation,
    useGetActivitiesQuery,
    useUpdateActivityMutation,
} = activityApi;