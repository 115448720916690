import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';

// extend dayjs with relativeTime plugin
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);

interface TimeSlot {
  dateTime: string;
  timeZone: string;
}

/**
 * Formats a date-time string to 'hh:mm A' in the specified time zone.
 *
 * @param {string} dateTime - The date-time string to format.
 * @param {string} timeZone - The time zone to use for formatting.
 * @return {string} - The formatted time string with AM/PM.
 *
 * @example
 * const formattedTime = formatDateTime('2024-06-27T09:00:00', 'America/New_York');
 * console.log('Formatted Time:', formattedTime); // Output: Formatted Time: 09:00 AM
 */
export function formatDateTime(dateTime: string, timeZone: string): string {
  if (!dateTime) {
    return '';
  }
  return dayjs(dateTime).tz(timeZone).format('hh:mm A');
}

/**
 * Converts a date-time string from one time zone to another.
 *
 * @param {string} dateTime - The date-time string to convert.
 * @param {string} fromTimeZone - The source time zone.
 * @param {string} toTimeZone - The target time zone.
 * @return {string} - The converted date-time string.
 *
 * @example
 * const convertedTime = convertTimeZone('2024-06-27T09:00:00', 'America/New_York', 'Europe/London');
 * console.log('Converted Time:', convertedTime); // Output: Converted Time: 2024-06-27T14:00:00+01:00
 */
export function convertTimeZone(
  dateTime: string,
  fromTimeZone: string,
  toTimeZone: string,
): string {
  return dayjs.tz(dateTime, fromTimeZone).tz(toTimeZone).format();
}

/**
 * Parses a date-time string in the specified time zone to a Dayjs object.
 *
 * @param {string} dateTime - The date-time string to parse.
 * @param {string} timeZone - The time zone of the date-time string.
 * @return {dayjs.Dayjs} - The Dayjs object.
 *
 * @example
 * const parsedDateTime = parseDateTime('2024-06-27T09:00:00', 'America/New_York');
 * console.log('Parsed DateTime:', parsedDateTime.toString()); // Output: Parsed DateTime: 2024-06-27T09:00:00-04:00
 */
export function parseDateTime(dateTime: string, timeZone: string): dayjs.Dayjs {
  return dayjs.tz(dateTime, timeZone);
}

/**
 * Generates 30-minute time slots for a 24-hour period from 00:00 to 23:30 of the current date.
 *
 * @return {TimeSlot[]} - An array of time slot objects with dateTime in local time.
 *
 * @example
 * const timeSlots = generateTimeSlots();
 * console.log(timeSlots);
 */
export const generateTimeSlots = (): TimeSlot[] => {
  const slots: TimeSlot[] = [];
  const timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
  const today = dayjs().format('YYYY-MM-DD');

  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      const dateTime = dayjs.tz(
        `${today}T${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}:00`,
        timeZone,
      );
      slots.push({
        dateTime: dateTime.format(),
        timeZone,
      });
    }
  }

  return slots;
};

/**
 * Gets the current date-time in the specified time zone formatted as 'YYYY-MM-DDTHH:mm:ssZ'.
 *
 * @param {string} timeZone - The time zone to get the current date-time for.
 * @return {string} - The current date-time string.
 *
 * @example
 * const currentDateTime = getCurrentDateTimeInTimeZone('Asia/Colombo');
 * console.log('Current DateTime in Colombo:', currentDateTime); // Output: Current DateTime in Colombo: (current date and time in 'YYYY-MM-DDTHH:mm:ssZ' format)
 */
export function getCurrentDateTimeInTimeZone(timeZone: string): string {
  return dayjs().tz(timeZone).format();
}

/**
 * Gets the "time from now" for a given date.
 *
 * @param {string | Date} date - The date to get the "time from now" for.
 * @return {string} - The "time from now" string.
 *
 * @example
 * const timeFromNow = getFromNowTime('2023-07-07T12:00:00Z');
 * console.log('Time from now:', timeFromNow); // Output: Time from now: (time from the provided date to now)
 */
export function getFromNowTime(date: string | Date): string {
  return dayjs(date).fromNow();
}

export default {
  formatDateTime,
  convertTimeZone,
  parseDateTime,
  getCurrentDateTimeInTimeZone,
  generateTimeSlots,
  getFromNowTime,
};
