import type React from 'react';
import type { ChangeEvent } from 'react';
import { useState } from 'react';
import { isEmpty } from 'lodash';
import { Loader } from 'lucide-react';
import { ReactComponent as AvatarImage } from 'assets/images/common/avatar.svg';
import { ReactComponent as CameraImage } from 'assets/images/common/camera.svg';
import { ReactComponent as DeleteImage } from 'assets/images/common/delete.svg';
import { cn } from 'utils/helpers';
import { Text } from 'components/text/primary/Text';
import { useUploadFileMutation } from 'services/upload.service';

type ProfileAvatarProps = {
  uploadable?: boolean;
  disableDelete?: boolean;
  onUpload?: (file: File, optimize?: boolean) => Promise<void>;
  onDelete?: () => Promise<void>;
  className?: string;
  loading?: boolean;
  error?: boolean;
  url?: string;
  size?: number;
  hideBoarder?: boolean;
};

const ProfileAvatar: React.FC<ProfileAvatarProps> = ({
  uploadable,
  onUpload,
  onDelete,
  className,
  loading,
  url,
  size = 100,
  disableDelete,
  hideBoarder,
}) => {
  const [image, setImage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [imageError, setImageError] = useState(false);

  const [uploadFile, { isLoading: isUploading, error }] =
    useUploadFileMutation();

  const handleImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    const maxSize = 10 * 1024 * 1024;

    if (file) {
      if (file.size > maxSize) {
        setErrorMessage('File size exceeds 10MB');
        return;
      }
      setErrorMessage(null);
      try {
        await onUpload?.(file, true);
      } catch (error) {
        console.log(error);
        setImage(null);
        setErrorMessage('Error uploading image!');
      } finally {
        setImage(null);
      }
      const reader = new FileReader();
      reader.onloadend = () => setImage(reader.result as string);
      reader.readAsDataURL(file);
    }
  };

  const handleImageDelete = async () => {
    try {
      await onDelete?.();
    } catch (error) {
      console.log(error);
      setErrorMessage('Error deleting image!');
    } finally {
      setImage(image);
    }
  };

  return (
    <div className={cn('relative flex flex-col items-center gap-2', className)}>
      <div className={`flex items-center justify-center bg-purple-500 rounded-full`} style={{
          width: size,
          height: size,
          backgroundColor: 'white',
        }}>
          {url || !isEmpty(url) ? <>{loading ? <Loader className="animate-spin" color="#6000E9" size={22} /> : <div
              className={cn(
                'relative rounded-full',
                onDelete && 'overflow-hidden',
              )}
            >
              {onDelete && (
                <div
                  className={cn(
                    'absolute inset-0 flex items-center justify-center bg-black/80 opacity-0 hover:opacity-100 transition-opacity duration-300 ease-in-out rounded-full w-full h-full z-20',
                    disableDelete && 'hidden',
                  )}
                  onClick={handleImageDelete}
                  onKeyDown={(e) => e.key === 'Enter' && handleImageDelete()}
                >
                  <DeleteImage className="h-5 w-5" />
                </div>
              )}
              <img
                src={image ? image : url}
                alt="Profile Avatar"
                className={cn(
                  'rounded-full object-cover relative z-10 bg-white',
                  hideBoarder && 'z-0 static',
                )}
                style={{ width: size, height: size }}
                onError={() => setImageError(true)}
              />
              {!onDelete && (
                <div
                  className={cn(
                    'absolute bg-[#6000E9] top-1/2 transform -left-[3px] -translate-y-1/2 rounded-full',
                    hideBoarder && 'hidden',
                  )}
                  style={{
                    height: `${size + 6}px`,
                    width: `${(size + 12) / 2}px`,
                    borderRadius: `${size + 15}px 0 0 ${size + 15}px`,
                  }}
                />
              )}
            </div>}</> : <div
            className="flex items-center justify-center bg-white rounded-full"
            style={{ width: size - 4, height: size - 4 }}
          >
            {loading ? (
              <Loader className="animate-spin" color="#6000E9" size={22} />
            ) : (
              <AvatarImage />
            )}
          </div>}
          {uploadable && (
          <label
            className="absolute right-0 cursor-pointer bottom-[4%] z-20"
            htmlFor="avatar-upload"
          >
            <CameraImage className="rounded-full" />
            <input
              type="file"
              id="avatar-upload"
              accept="image/*"
              className="hidden"
              onChange={(e) => handleImageChange(e)}
              disabled={loading}
            />
          </label>
        )}
        </div>
        {errorMessage && (
        <Text size="small" type="error" className="mt-2">
          {errorMessage}
        </Text>
      )}
    </div>
  );
};

export default ProfileAvatar;
