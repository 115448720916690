import React, { useState, useCallback } from 'react';
import { first } from 'lodash';
import { useDropzone } from 'react-dropzone';
import { ReactComponent as UploadIcon } from 'assets/images/import/upload-icon.svg';
import { cn } from 'utils/helpers';
import { Text } from 'components/text/primary/Text';

interface FileUploadProps {
  loading?: boolean;
  error: string | null;
  setError: (error: string | null) => void;
  onFileChange: (file: File | null) => void;
  onRemove?: () => void;
  onDragEnter?: () => void;
  onDragLeave?: () => void;
  validateFile?: (file: File) => boolean;
  accept: {
    [key: string]: string[];
  };
}

const FileUpload: React.FC<FileUploadProps> = ({
  loading = false,
  onFileChange,
  onRemove,
  onDragEnter,
  onDragLeave,
  validateFile,
  accept,
  error,
  setError,
}) => {
  const [file, setFile] = useState<File | null>(null);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const selectedFile = first(acceptedFiles) ?? null;

      if (validateFile && !validateFile(selectedFile!)) {
        setError('Invalid file type');
        return;
      }
      setFile(selectedFile);
      onFileChange(selectedFile);
      setError(null);
    },
    [onFileChange, validateFile, setFile, setError],
  );

  const handleRemove = () => {
    setFile(null);
    onFileChange(null);
    if (onRemove) onRemove();
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDragEnter,
    onDragLeave,
    multiple: false,
    accept,
  });

  return (
    <div
      {...getRootProps()}
      className={cn(
        'border-2 border-dashed rounded-lg p-6 text-center cursor-pointer w-full my-2',
        isDragActive ? 'border-purple-600' : 'border-purple-300',
        loading ? 'bg-white bg-opacity-5' : 'bg-white bg-opacity-10',
        'text-white',
      )}
    >
      <input {...getInputProps()} />
      {loading ? (
        <div>
          <UploadIcon className="mx-auto text-purple-500" />
          <Text size="small" className="mt-4 font-bold">
            Importing contacts...
          </Text>
        </div>
      ) : (
        <div>
          <UploadIcon className="mx-auto text-purple-500" />
          {!file && (
            <>
              <Text size="small" className="mt-4 font-bold">
                Upload contact list
              </Text>
              <Text size="small" className="mt-1">
                File types supported: {Object.values(accept).flat().join(', ')}
              </Text>
            </>
          )}
          {error && <p className="mt-2 text-red-500">{error}</p>}
          {file && (
            <div className="flex flex-col items-center justify-center my-3">
              <Text size="small" className="mt-1 text-xs truncate">
                {file.name}
              </Text>
              <Text
                size="small"
                type="error"
                onClick={handleRemove}
                className="mt-2underline"
              >
                Remove
              </Text>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FileUpload;
