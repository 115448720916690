import React, { useEffect, useState } from 'react';
import { ReactComponent as DeleteIcon } from 'assets/images/profile/delete-icon.svg';
import { formatDateTime } from 'utils/dateTimeUtils';
import { isValidTimeSlot } from 'modules/profile/utils/availability';
import AvailabilityTimeSelector from 'modules/profile/components/availability/TimeDropDown/availabilityTimeSelector';
import { convertCommonToISO } from 'utils/calendarUtils';

export interface ITimeSlot {
  dateTime: string;
  timeZone: string;
}

export interface IStartAndEndTimeSlot {
  start: ITimeSlot;
  end?: ITimeSlot;
}

export type TimeSlotProps = {
  id: string;
  start: {
    dateTime: string;
    timeZone: string;
  };
  end?: {
    dateTime: string;
    timeZone: string;
  };
  onDelete: () => void;
  onTimeChange: (id: string, time: IStartAndEndTimeSlot) => void;
  showSelectedInitially?:boolean;
};

const TimeSlot: React.FC<TimeSlotProps> = ({
  id,
  start:rawStart,
  end:rawEnd,
  onDelete,
  onTimeChange,
  showSelectedInitially
}) => {
  const [error, setError] = useState<string | null>(null);

  const start = rawStart ? {dateTime:convertCommonToISO(rawStart.dateTime),timeZone: rawStart.timeZone,} : undefined;
  const end = rawEnd? {dateTime:convertCommonToISO(rawEnd.dateTime),timeZone: rawEnd.timeZone,}: undefined;

  const onStartTimeChange = (time: ITimeSlot) => {
    time.dateTime = convertCommonToISO(time.dateTime)
    onTimeChange(id, { start: time, end: end });
  };
  const onEndTimeChange = (time: ITimeSlot) => {
    !isValidTimeSlot(start, time) ? 'Invalid time slot.' : null;

    time.dateTime = convertCommonToISO(time.dateTime)
    onTimeChange(id, { start: start ? start : time, end: time });
  };

  useEffect(() => {
    setError(!isValidTimeSlot(start, end) ? 'Invalid time slot.' : null);
  }, [start, end]);

  return (
    <div>
      <div className="grid items-center grid-cols-[1fr_auto_1fr_auto] gap-4 text-sm w-full max-w-md">
        <AvailabilityTimeSelector
          selectedTime={start ? formatDateTime(start.dateTime, start.timeZone) : undefined}
          onTimeSelect={onStartTimeChange}
          error={!!error}
          showSelectedInitially={showSelectedInitially}
        />
        <div className="text-center">-</div>
        <AvailabilityTimeSelector
          selectedTime={
            end ? formatDateTime(end.dateTime, end.timeZone) : undefined
          }
          onTimeSelect={onEndTimeChange}
          error={!!error}
          startTime={start?.dateTime}
          showSelectedInitially={showSelectedInitially}
        />
        <button onClick={onDelete} className="text-red-500">
          <DeleteIcon />
        </button>
      </div>
      {error && (
        <div className="text-sm px-1 text-center mt-1 text-destructive">
          The end time should be a later timestamp than the start time.
        </div>
      )}
    </div>
  );
};

export default TimeSlot;
