import BottomNav, { BottomNavType } from 'components/bottomNav/bottomNav';
import { DateSelector } from 'components/dateSelector';
import { EventCardItem } from 'components/eventCardItem';
import { EventObject } from 'components/eventCardItem/EventCardItem.types';
import { isSameDay, parse } from 'date-fns';
import { determineVariant } from 'utils/calendarUtils';
import BgGradient from 'modules/home/components/gradient';
import {
  filterDuplicateEvents,
  formatWithOrdinal,
  getDayStartAndEnd,
  getMonthStartAndEnd,
  getSearchParams,
} from 'modules/home/utils/helpers';
import ViewListHeader from 'modules/letsMeet/components/viewListHeader/ViewListHeader';
import { Key, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetEventByDateRangeQuery } from 'services/slices/eventsApiSlice';
import InLineLoader from 'components/loader/InlineLoader';

const HomeMonthly = () => {
  // default date
  const { defaultDate } = useMemo(
    () => ({
      defaultDate: new Date(),
    }),
    [],
  );

  // get search params
  const location = useLocation();
  const searchParams = getSearchParams(location.search ?? '');
  const focusToEventId = searchParams?.ref;
  const focusEventDate = searchParams?.date;
  const formattedFocusEventDate = focusEventDate? parse(focusEventDate, 'yyyy-MM-dd', new Date()): null;

  // query skip flags
  const [dailyQueryEnabled, setDailyQueryEnabled] = useState(false);
  const [monthlyQueryEnabled, setMonthlyQueryEnabled] = useState(false);

  // selected dates
  const [selectedDate, setSelectedDate] = useState<Date | null>(
    formattedFocusEventDate ?? null,
  );
  const [selectedMonthDate, setSelectedMonthDate] = useState<Date>(defaultDate);

  // selected dates to indicate in calendar
  const [selectedEventDates, setSelectedEventDates] = useState<Date[]>([]);

  // display events
  const [displayEvents, setDisplayEvents] = useState<EventObject[]>([]);

  // get the start and end of selected date for api query
  const { startOfDay, endOfDay } = useMemo(() => {
    return getDayStartAndEnd(selectedDate ?? defaultDate);
  }, [selectedDate]);

  // initial data loaded flag
  const initialDataLoadedRef = useRef(false);

  // get the start and end of selected month for api query
  const { startOfMonth, endOfMonth } = useMemo(() => {
    return getMonthStartAndEnd(selectedMonthDate);
  }, [selectedMonthDate]);

  // call API
  const {
    data: dailyEvents,
    refetch: refetchDailyEvents,
    isUninitialized: isDailyEventsUninitialized,
    isLoading: isDailyEventsLoading,
    isFetching: isDailyEventsFetching,
  } = useGetEventByDateRangeQuery(
    {
      startDate: startOfDay.toISOString(),
      endDate: endOfDay.toISOString(),
    },
    { skip: !dailyQueryEnabled },
  );

  const {
    data: monthlyEvents,
    refetch: refetchMonthlyEvent,
    isUninitialized: isMonthlyEventsUninitialized,
  } = useGetEventByDateRangeQuery(
    {
      startDate: startOfMonth.toISOString(),
      endDate: endOfMonth.toISOString(),
    },
    { skip: !monthlyQueryEnabled },
  );

  // load monthly events to calendar indicators on first load
  useEffect(() => {
    setMonthlyQueryEnabled(true);
  }, []);

  // load events on selected date change
  useEffect(() => {
    if (selectedDate === null) return;
    if (!dailyQueryEnabled) setDailyQueryEnabled((prev) => true);
    else !isDailyEventsUninitialized && refetchDailyEvents();
  }, [selectedDate]);

  // load monthly events on month change
  useEffect(() => {
    // !isMonthlyEventsUninitialized && refetchMonthlyEvent();
  }, [selectedMonthDate]);

  // update display events when api data changes
  useEffect(() => {
    setDisplayEvents((prev) => filterDuplicateEvents(dailyEvents ?? []));
  }, [dailyEvents]);

  // get dates from the events to indicate
  useEffect(() => {
    const monthEventsList = monthlyEvents?.map(
      (event: EventObject) => new Date(event?.event?.start),
    );
    setSelectedEventDates(monthEventsList ?? []);
  }, [monthlyEvents]);


  // initial data load handler
  const handleInitialDataLoad = () => {
    focusToEventId && setTimeout(() => {
      const element = document.getElementById(focusToEventId);
      element && element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 50);
  };

  // initial data load effect
  useEffect(() => {
    if (dailyEvents && !initialDataLoadedRef.current) {
      handleInitialDataLoad();
      initialDataLoadedRef.current = true;
    }
  },[dailyEvents]);
  
  return (
    <>
      <div className={'p-5 text-white h-[100dvh] flex flex-col'}>
        {/* header */}
        <ViewListHeader />

        <div className="overflow-y-scroll  grow">
          {/* event filter selectors */}
          <div className="w-auto flex justify-center items-center flex-col">
            <DateSelector
              selectedValues={selectedEventDates}
              onDateClick={(date) => {
                setSelectedDate((prev) => date);
              }}
              onMonthChange={(date) => {
                setSelectedMonthDate((prev) => date);
                setDisplayEvents((prev) => []);
              }}
              disabledDates={(date: Date) => {
                return !selectedEventDates.some((d) => isSameDay(d, date));
              }}
            />
            <div className=" border-[1px] border-white/20 mt-[30px] w-full"></div>
          </div>

          {selectedDate && !isDailyEventsFetching && !isDailyEventsLoading && (
            <p className="font-semibold leading-[30px] mt-[30px] text-xl min-h-7">
              {formatWithOrdinal(selectedDate)}
            </p>
          )}

          <div className="mt-2 mb-5">
            {isDailyEventsFetching || isDailyEventsLoading ? (
              <div className="py-4">
                <InLineLoader />
              </div>
            ) : !selectedDate ? (
              <div className="text-center text-white h-auto flex justify-center items-center">
                <p></p>
              </div>
            ) : !displayEvents?.length ? (
              <div className="text-center text-white h-[148px] flex justify-center items-center">
                <p>No events found</p>
              </div>
            ) : (
              <></>
            )}
            <div className="flex gap-3 flex-col">
              {!isDailyEventsFetching && !isDailyEventsLoading && displayEvents?.map(
                  (event: EventObject, index: Key | null | undefined) => (
                    <div id={event?.event?._id} key={index}>
                      <EventCardItem
                        id={event?.event?._id}
                        key={index}
                        eventData={event?.event}
                        variant={determineVariant(event?.variantData)}
                        additionalVariants={event?.variantData}
                        origin="monthly"
                        />
                    </div>
                  ),
                )}
            </div>
          </div>
        </div>

        {/* event display */}
        <div className="space-y-2 grid grid-cols-1 gap-y-1">
          {/* bottom navigation */}
          <BottomNav type={BottomNavType.HOME} />

          <BgGradient />
        </div>
      </div>
    </>
  );
};

export default HomeMonthly;
