import React from 'react';
import { Loader } from 'lucide-react';

const InsideLoader: React.FC = () => {
  return (
    <div className="absolute w-100 h-100 top-0 left-0 inset-0 z-40 flex items-center justify-center bg-opacity-10 backdrop-blur-sm">
      <div className="flex flex-col items-center">
        <Loader className="w-12 h-12 text-white animate-spin" />
      </div>
    </div>
  );
};

export default InsideLoader;
