import React from 'react';
import { Loader } from 'lucide-react';

const OverlayLoader: React.FC = () => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-opacity-10 backdrop-blur-sm">
      <div className="flex flex-col items-center">
        <Loader className="w-12 h-12 text-white animate-spin" />
      </div>
    </div>
  );
};

export default OverlayLoader;
