import { useRef } from 'react';
import { isEmpty } from 'lodash';
import { Navigate, useNavigate } from 'react-router-dom';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useForm, Controller, Resolver } from 'react-hook-form';
import { cn } from 'utils/helpers';
import { login } from 'modules/auth/slices/authSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  useVerifyOtpMutation,
  useSendOtpMutation,
} from 'modules/auth/services/verification.mutation.service';
import { INVALID_OTP } from 'modules/auth/constants/constants';
import {
  getPrefixByCountryCode,
  formatPhoneNumber,
} from 'modules/auth/utils/stringUtils';

import { useAuthContext } from 'modules/auth/context/AuthContext';
import { IUser, OtpInputRef } from 'modules/auth/types/types';
import OtpInput from 'components/otpInput/OTPInput';
import { Text } from 'components/text/primary/Text';
import MainButton from 'modules/auth/components/MainButton';
import OnboardingLayout from 'modules/auth/layouts/AuthLayout';
import ResendTimer from 'modules/auth/components/ResendTimer';

import styles from './verify.module.css';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';

import OneSignal from 'react-onesignal';
import { updateNotificationPermissions } from 'modules/auth/slices/actions';
import useLocalStorage from 'hooks/useLocalStorage';

type FormData = {
  otp: string;
};

const RESEND_TIMEOUT = 30;

const resolver: Resolver<FormData> = async (values) => {
  const errors: any = {};
  const validatedValues = { ...values };

  if (!values.otp) {
    errors.otp = {
      type: 'required',
      message: 'OTP is required.',
    };
  } else if (values.otp.length !== 6 || !/^\d{6}$/.test(values.otp)) {
    errors.otp = {
      type: 'pattern',
      message: 'OTP must be a 6-digit number.',
    };
  }

  return {
    values: Object.keys(errors).length ? {} : validatedValues,
    errors,
  };
};

const Verify: React.FC = () => {
  const { state } = useAuthContext();
  const dispatch = useAppDispatch();
  const { phoneNumber, formattedPhoneNumber } = state;
  const navigate = useNavigate();
  const otpInputRef = useRef<OtpInputRef>(null);
  const [verifyOtp, { isLoading: isVerifying, isError }] =
    useVerifyOtpMutation();
  const [sendOtp, { isLoading: isSending }] = useSendOtpMutation();

  const [smsNotificationsAccepted,setSmsNotificationsAccepted] = useLocalStorage('cal-user-sms-notifications',false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>({ resolver });

  const onSubmit = async (data: FormData) => {
    try {
      if (!isEmpty(phoneNumber.to) && !isEmpty(data.otp)) {
        const num = parsePhoneNumber(
          phoneNumber.to,
          getPrefixByCountryCode(phoneNumber.code) as CountryCode,
        );

        console.log(`Creating Account with ${num.number}`);

        const response = await verifyOtp({
          to: num.number,
          otp: data.otp,
        }).unwrap();

        if (response) {
          dispatch(login(response));
          const user: IUser = response.user;

          if (isEmpty(user.name) || isEmpty(user.username)) {
            navigate('/profile/onboarding');
          } else {
            navigate('/home');
          }

          if(user && user.id){

            // ave notification preference
            dispatch(updateNotificationPermissions(user.id, {text:smsNotificationsAccepted} as any));

            // OneSignal - Allow Request Prompt
            OneSignal.logout()
            await OneSignal.Notifications.requestPermission();
    
            // OneSignal - Passing the User Details for OneSignal
            OneSignal.login(user?.id);
            OneSignal.User.addSms(user?.phoneNumber);
          }
        }
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleResend = async () => {
    try {
      if (!isEmpty(phoneNumber.to)) {
        reset();
        otpInputRef?.current?.reset();
        await sendOtp({ phoneNumber: phoneNumber.to }).unwrap();
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (isEmpty(phoneNumber.number) || isEmpty(phoneNumber.code)) {
    return <Navigate to={'/404'} />;
  }

  return (
    <OnboardingLayout className={cn('justify-between', styles.background)}>
      <div className="w-full max-w-xs mt-8 min-w-64">
        <ChevronLeft className="cursor-pointer" onClick={() => navigate(-1)} />
      </div>
      <div className="w-full max-w-xs my-8 min-w-64">
        <div className="flex flex-col gap-y-2">
          <Text size="large">Verify your number</Text>
          <div className="flex flex-col gap-2 mt-2 mb-20">
            <Text size="small" className="text-base">
              Enter the 6 digit code sent to{' '}
              <span className="ml-0.5 font-extralight text-secondary text-sm"> 
                {formatPhoneNumber(phoneNumber?.number)} 
              </span>
            </Text>
            <Controller
              name="otp"
              control={control}
              render={({ field }) => (
                <OtpInput
                  ref={otpInputRef}
                  length={6}
                  onChange={field.onChange}
                />
              )}
            />
            {errors.otp && (
              <Text size="extra-small" type="error">
                {errors.otp.message}
              </Text>
            )}
            {isError && (
              <Text size="extra-small" type="error">
                {INVALID_OTP}
              </Text>
            )}
            <ResendTimer
              initialTimer={RESEND_TIMEOUT}
              onResend={handleResend}
              loading={isSending}
            />
          </div>
          <MainButton
            type="primary"
            icon={<ChevronRight color="#fff" />}
            onClick={handleSubmit(onSubmit)}
            loading={isVerifying}
          >
            <Text size="small" className="text-base font-sofia-medium">
              Create Account
            </Text>
          </MainButton>
        </div>
      </div>
    </OnboardingLayout>
  );
};

export default Verify;
