import ProfileAvatar from 'components/avatar/ProfileAvatar';
import { IUser } from 'modules/auth/types/types';
import SideBarButton from 'components/sidebar/sidebarButton';

type ActivityHeaderProps = {
  user: IUser;
};

const ActivityHeader: React.FC<ActivityHeaderProps> = ({ user }) => {
  return (
    <div className="flex items-center justify-between ">
      <ProfileAvatar size={45} url={user?.photoURL} />

      <SideBarButton />
    </div>
  );
};

export default ActivityHeader;
