type LayoutProps = {
  children: React.ReactNode;
};

const ReceiveLayout: React.FC<LayoutProps> = (props) => {
  return (
    <div className="flex items-center justify-center text-white bg-main h-[100dvh] relative">
      <div className={'flex flex-col  h-[100dvh] w-full max-w-md overflow-y-auto'}>
        <div className="flex flex-col flex-1 w-full">
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default ReceiveLayout;
