import React from 'react';
import { Loader } from 'lucide-react';

const InLineLoader: React.FC = () => {
  return (
    <div className="w-100 inset-0 z-40 flex items-center justify-center bg-opacity-10 backdrop-blur-sm">
      <div className="flex flex-col items-center">
        <Loader className="w-4 h-4 text-white animate-spin" />
      </div>
    </div>
  );
};

export default InLineLoader;
