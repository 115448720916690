import EventIcon from 'components/eventIcon/EventIcon';
import { IUser } from 'modules/auth/types/types';
import ProfileAvatar from 'components/avatar/ProfileAvatar';
import React from 'react';
import { useAppSelector } from 'hooks/useAppSelector';
import { RootState } from 'app/store';
import { IEvent } from 'types';
import caleidoBaseApi from 'services/apiClient';
import useLocalStorage from 'hooks/useLocalStorage';


type ReceiveHeaderNow = {
  type: 'now';
  end: string;
};

type ReceiveHeaderLater = {
  type: 'later';
  start: string;
  end: string;
  date: string;
};

type ReceivePoll = {
  type: 'poll';
};

type ReceiveHeaderProps = {
  by: Pick<IUser, 'id' | 'name' | 'photoURL'>;
  emoji: string;
  contacts: (Pick<IUser, 'id' | 'photoURL'> & { organizer: number,status: number, userId: string })[];
  start?: string | null;
  expired?: boolean | null;
  event?:IEvent|null;
} & (ReceiveHeaderNow | ReceiveHeaderLater | ReceivePoll);

const ReceiveHeader: React.FC<ReceiveHeaderProps> = (props) => {
  const [eventResponses, setEventResponses] = useLocalStorage('cal-user-event-responses', {});

  const hasValueOfTwo = Object.values(eventResponses).some((value) => value === 2);
  // const filteredContacts = props.contacts.filter(contact => contact.organizer !== 1 && contact.status !== 1);
  const filteredContacts = props.event?.contacts?.filter(contact => contact.organizer !== 1 )??[];
  const user = useAppSelector((state: RootState) => state.auth.user);

  // Find the user's contact if they exist in the list
  const userContact = props.contacts.find(contact => contact?.userId === user?.id);
  const isByYou = userContact?.userId === user?.id


  // TODO: get the event created by user details from the event
  const organizer = props.event?.contacts?.find(contact => contact.organizer === 1);
  const organizerImageUrl = organizer?.photoURL;


  const RenderTime = () => {
    if (props.type === 'now')
      return (
        <div className="uppercase">
          {props.expired ? props.start : `Now`} {props.expired ? `-` : 'Until'}{' '}
          {props.end}
        </div>
      );

    if (props.type === 'later')
      return (
        <div>
          <div> {props.date}</div>
          <div>
            {props.start} - {props.end}
          </div>
        </div>
      );

    return <div>When can we meet?</div>;
  };

  return (
    <div className="flex justify-between items-center px-5 pb-4 gap-1.5">
      <div className="flex gap-2.5">
        <EventIcon icon={props.emoji} size="base" active background="#cfb2fb" />
        <div className="flex flex-col justify-center gap-1">
          <div className="text-dark font-bold leading-none text-sm">
            {userContact?.status === 2 || hasValueOfTwo ? <>You are not joining {props.by.name}</> : <>{props.by.name} {props.expired ? `wanted` : `wants`} {props?.type === 'poll' ? `to find time to hang!`:`you to join`}</>}
          </div>
          <div className="Westmount text-[#6000E9] text-lg tracking-tighter leading-none">
            <RenderTime />
          </div>
        </div>
      </div>
      <div className="relative mr-4">
        {/* <img
            src={props.by.photoURL}
            alt={props.by.name}
            className="h-7 w-7 rounded-full mr-4"
        /> */}
        <ProfileAvatar size={30} url={organizerImageUrl??''} hideBoarder />
        {filteredContacts.length >0 && (
          <div
            className="bg-secondary h-7 w-7 flex justify-center items-center font-bold rounded-full text-sm absolute top-0"
            style={{ right: '-20px' }}
          >
            {props.type === 'poll' ?
              <div>+{filteredContacts.length}</div>
              :
              <div>+{filteredContacts.length}</div>
            }
          </div>
        )}
      </div>
    </div>
  );
};

export default ReceiveHeader;
