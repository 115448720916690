import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithReauth from 'services/mutationInterceptor';
import type {
  IUser,
  IUserForEdit,
  IUserForEditRequest,
} from 'modules/auth/types/types';

export const userMutation = createApi({
  reducerPath: 'userMutation',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    createUser: builder.mutation<IUser, IUser>({
      query: (body) => ({
        url: '/users',
        method: 'POST',
        body,
      }),
    }),
    getUsers: builder.query<IUser[], void>({
      query: () => ({
        url: '/users',
        method: 'GET',
      }),
    }),
    getUser: builder.query<IUserForEdit, string>({
      query: (userId) => ({
        url: `/users/${userId}`,
        method: 'GET',
      }),
    }),
    updateUser: builder.mutation<IUser, { data: IUser }>({
      query: ({ data }) => ({
        url: '/users',
        method: 'PATCH',
        body: data,
      }),
    }),
    updateUserSettings: builder.mutation<IUser, { data: IUserForEditRequest }>({
      query: ({ data }) => ({
        url: '/users',
        method: 'PATCH',
        body: data,
      }),
    }),
    deleteUser: builder.mutation<void, string>({
      query: (userId) => ({
        url: `/users/${userId}`,
        method: 'DELETE',
      }),
    }),
    getUsernameSuggestions: builder.query<
      { suggestions: string[] | undefined; matches: boolean },
      { baseUsername: string }
    >({
      query: ({ baseUsername }) => ({
        url: '/users/username-suggestions',
        method: 'GET',
        params: { baseUsername },
      }),
    }),

    // updateContacts: builder.mutation<IUser, { data: IUser }>({
    //   query: () => ({
    //     url: '/users/contacts',
    //     method: 'POST',
    //   }),
    // }),
  }),
});

export const {
  useCreateUserMutation,
  useGetUsersQuery,
  useGetUserQuery,
  useUpdateUserMutation,
  useUpdateUserSettingsMutation,
  useDeleteUserMutation,
  useLazyGetUsernameSuggestionsQuery,
  // useUpdateContactsMutation,
} = userMutation;
