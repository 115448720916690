import { formatDateTimeSlot, formatDateToLocalTimezone, getUserTimezone, isTimePassed } from "modules/letsMeet/utils/lib";
import { useState } from "react";
import { IPollData } from "types/event";
import dayjs from 'dayjs';
import { format, parseISO } from 'date-fns';

interface PollTimeSelectorProps {
  pollData: IPollData;
  userId: string;
  onTimeSelectionChange: (updatedSelectedTimes: string[]) => void;
}

const PollTimeSelector: React.FC<PollTimeSelectorProps> = ({
  pollData,
  userId,
  onTimeSelectionChange,
}) => {
  const [selectedTimes, setSelectedTimes] = useState<string[]>([]);
  const [notAvailable, setNotAvailable] = useState<boolean>(false);
 


  const handleTimeToggle = (time: string) => {
    let updatedSelectedTimes: string[] = JSON.parse(JSON.stringify(selectedTimes));
  
      if (time === 'Not available on all dates') {
        setNotAvailable(!notAvailable);
        updatedSelectedTimes = !notAvailable ? ['Not available on all dates'] : [];
      } else {
        if (notAvailable){
          updatedSelectedTimes = selectedTimes
        } else {
          if (selectedTimes.includes(time)) {
            updatedSelectedTimes = selectedTimes.filter((t) => t !== time);
          } else {
            updatedSelectedTimes = [...selectedTimes, time];
          }
        }
      }

    setSelectedTimes(updatedSelectedTimes);

    onTimeSelectionChange(updatedSelectedTimes);
  };

  const formatTimeSlot = (timeSlot: string) => {
    const [year, month, day, startTime, endTime] = timeSlot.split('-');

    const date = new Date(Date.UTC(Number(year), Number(month) - 1, Number(day)));

    const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'short' });
    const monthName = date.toLocaleDateString('en-US', { month: 'short' });
    const dayWithSuffix = `${day}${getDaySuffix(day)}`;

    return `${dayOfWeek}, ${monthName} ${dayWithSuffix}`;
  };

  const getDaySuffix = (day: string) => {
    const dayNum = Number(day);
    if (dayNum >= 11 && dayNum <= 13) {
      return 'th';
    }
    switch (dayNum % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };

  const formatDate = (dateString: string) => {
    try {
      const date = parseISO(dateString);
      return format(date, 'EEE, MMM do');
    } catch (error) {
      console.error('Error parsing date:', error);
      return 'Invalid Date';
    }
  };
 
  const formatTime = (dateString: string, timeString: string) => {
    try {
      const date = new Date(`${dateString}T${timeString}`);
      return format(date, 'hh:mm a');
    } catch (error) {
      console.error('Error parsing time:', error);
      return 'Invalid Time';
    }
  };

  return (
    <div>
      <h2 className="text-base font-semibold mb-1">When are you available?</h2>
      <p className="text-xs text-white mb-2">Select all that apply</p>

      {pollData.checkedTimes.map((timeSlot, index) => {
        const isPassed = isTimePassed(timeSlot.time)

        const matchResult = timeSlot.time.match(
          /(\d{4}-\d{2}-\d{2})-(\d{2}:\d{2})(Z|[+-]\d{2}:?\d{2})?-(\d{2}:\d{2})(Z|[+-]\d{2}:?\d{2})?/,
        );
        const [date, startTime, endTime] = matchResult
          ? [
            matchResult[1],
            matchResult[2] + (matchResult[3] || ''),
            matchResult[4] + (matchResult[5] || '')  
          ]
          : ['', '', ''];

        return(
        <div
          key={index}
          className={`mb-3 p-4 bg-[#2C3051] rounded-md shadow-md flex items-center ${notAvailable || isPassed ? 'opacity-50 cursor-not-allowed' : ''}`}
          onClick={() => (!notAvailable && !isPassed) && handleTimeToggle(timeSlot.time)} // Prevent clicking if "Not available on all dates" is selected
        >
          <div
            className={`h-5 w-5 mr-3 border-2 rounded bg-[#D7C0FB] border-[#D7C0FB] flex items-center justify-center cursor-pointer`}
          >
            {selectedTimes.includes(timeSlot.time) && (
              <div className="h-3 w-3 bg-[#6000E9] rounded"></div>
            )}
          </div>
          <div className="flex justify-between items-center w-full">
            <div className="flex items-center gap-2">
              <span className="text-sm font-medium text-white">
                {formatDate(date)}
              </span>
              <span className="w-1.5 h-1.5 bg-purple-600 rounded-full inline-block"></span>
              <span className="text-sm text-white">
                {`${formatTime(date, startTime)} - ${formatTime(date, endTime)}`}
              </span>
            </div>
          </div>
        </div>
      )})}

      <div
        className="mb-3 p-4 bg-[#2C3051] rounded-md shadow-md flex items-center"
        onClick={() => handleTimeToggle('Not available on all dates')}
      >
        <div
          className={`h-5 w-5 mr-3 border-2 rounded bg-[#D7C0FB] border-[#D7C0FB] flex items-center justify-center cursor-pointer`}
        >
          {selectedTimes.includes('Not available on all dates') && (
            <div className="h-3 w-3 bg-[#6000E9] rounded"></div>
          )}
        </div>
        <span className="text-sm font-medium text-white">
          Not available on all dates
        </span>
      </div>
    </div>
  );
};

export default PollTimeSelector;
