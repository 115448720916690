import { ReactNode, FC } from 'react';
import { Text } from 'components/text/primary/Text';
import { cn } from 'utils/helpers';
import ToggleSwitch from 'components/toggle/ToggleSwitch';

type AvailabilityLayoutProps = {
  children: ReactNode;
  title: string;
  onChange: (checked: boolean) => void;
  isChecked: boolean;
};

const AvailabilityLayout: FC<AvailabilityLayoutProps> = ({
  children,
  title,
  onChange,
  isChecked,
}) => {
  return (
    <div className={cn('flex flex-col w-full')}>
      <div className="flex items-center justify-between w-full">
        <Text size="small" className="font-bold text-md">
          {title}
        </Text>
        <div className="flex items-center gap-2">
          <Text size="small" className="inline-flex items-center gap-3">
            Available full day
            <ToggleSwitch
              className=" peer"
              isChecked={isChecked}
              onChange={onChange}
            />
          </Text>
        </div>
      </div>
      <div className={cn('flex-grow', !isChecked && 'my-3')}>{children}</div>
    </div>
  );
};

export default AvailabilityLayout;
