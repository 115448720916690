import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from 'components/ui/collapsible';
import { ChevronDown } from 'lucide-react';
import { useEffect, useMemo, useRef, useState } from 'react';

import { ReactComponent as MessageIcon } from 'assets/images/activity/message.svg';
import { ReactComponent as SendIcon } from 'assets/images/activity/send.svg';
import { Button } from 'components/ui/button';
import Comment from './comment';
import { cn, getCommentsFromEvent, getRandomHexID } from 'utils/helpers';
import { useAddEventCommentMutation } from 'services/event.service';
import { useAppSelector } from 'hooks/useAppSelector';
import { RootState } from 'app/store';
import { TextField } from '@mui/material';
import caleidoBaseApi from 'services/apiClient';
import { IEvent } from 'types';
import InLineLoader from 'components/loader/InlineLoader';
import { set } from 'lodash';
import useKeyboardHeightReset from 'hooks/useKeyboardHeightReset';
import useLocalStorage from 'hooks/useLocalStorage';
import useSessionStorage from 'hooks/useSessionStorage';
import OverlayLoader from 'components/loader/OverlayLoader';

export type IComment = {
  id?: string;
  by?: any;
  comment?: string;
  timestamp?: string;
};
// TODO: Add types for event
type CommentListProps = {
  refetchEvent?: () => void;
  event?: any | null;
  disabled?: boolean
};
const CommentList: React.FC<CommentListProps> = ({
  event,
  disabled = false,
  refetchEvent
}) => {
  const [open, setOpen] = useState(true);
  const [input, setInput] = useState('');
  const [commentsList, setCommentsList] = useState<IComment[]>([]);

  const [isDeleting, setIsDeleting] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [reloading, setReloading] = useState(false);
  const [editingComment, setEditingComment] = useState<any | null>(null);

  const [guestName, setGuestName] = useSessionStorage("cal-user-name", "");
  const [storedPhoneNumber, setStoredPhoneNumber] = useSessionStorage("cal-user-phone-number", "");
  const [storedCountryCode, setStoredCountryCode] = useSessionStorage("cal-user-country-code", "+1");

  const guestPhoneNumber = useMemo(() =>storedPhoneNumber!=''?storedCountryCode+storedPhoneNumber:undefined, [storedPhoneNumber]);

  // fix for mobile keyboard height issue
  const { isKeyboardOpen } = useKeyboardHeightReset();

  const [addCommentMutation] = useAddEventCommentMutation();

  const user = useAppSelector((state: RootState) => state.auth.user);

  const expired = useMemo(() => new Date(event?.event?.end).getTime() < new Date().getTime(), [event]);

  // NOTE: This functionality does not support for guest comments
  // TODO: Properly implement BE for adding comments by guests
  const addComment = async (newComment: string) => {

    if (newComment === '' || !event.event._id) return;

    await addCommentMutation({ eventId: event.event._id, comment: newComment });

    // append new comment to the list to the top
    const newCommentObj = {
      id: event.event._id,
      by: {
        id: user ? user.id : '',
        name: user ? user.name : '',
      },
      comment: newComment,
      timestamp: new Date().toISOString(),
    };

    setCommentsList([newCommentObj, ...commentsList]);
    setIsAdding(false);
    setInput('');
    refetchEvent && refetchEvent();
  };

  
  // take the comment from the list and put it in the input field
  const editComment = async (eventId:string, comment:any) => {
    setEditingComment(comment);
    setInput(comment.comment);
    // TODO: uncomment to remove the comment from the list while editing.
    // setCommentsList(commentsList.filter((c) => c.id !== comment.id));
  }
  
  // TODO: remove after proper API is implemented for editing and deleting comments.
  // Taking the latest updated event before deleting or editing.
  const getEvent= async (eventId: string) => {
   return caleidoBaseApi.get(`/event/share/${eventId}`);
  }

  // TODO: implement proper API for deleting comment.
  // NOTE: This may not be reliable for concurrent updates. But only the PUT api is available.
  const updateComment = async () => {
    if (!editingComment) return;
    setIsEditing(true);
    getEvent(event.event._id).then((res) => {
      const eventBeforeUpdate = res.data;
      eventBeforeUpdate.contacts = eventBeforeUpdate?.contacts?.map((contact: any) => {
        const updatedContact = contact;
        if(updatedContact.comments) updatedContact.comments = contact.comments.map((comment: any) => {
          if(comment._id === editingComment.id) comment.comment = input;
          return comment;
        });
        return updatedContact;
      });
      updateEvent(eventBeforeUpdate).then((res) => {
        reloadCommentList();
      }).finally(() => {
        setIsEditing(false);
        setEditingComment(null);
        setInput('');
      });
    }).catch((err) => {
      setIsEditing(false);      
    });

  }

  // TODO: implement proper API for deleting comment.
  // NOTE: This may not be reliable for concurrent updates. But only the PUT api is available. Using the /public/event PUT api.
  const deleteComment = async (eventId:string,commentId: string) => {
    setIsDeleting(true);
    getEvent(eventId).then((res) => {
      const eventBeforeUpdate = res.data;
      eventBeforeUpdate.contacts = eventBeforeUpdate?.contacts?.map((contact: any) => {
        const updatedContact = contact;
        if(updatedContact.comments) updatedContact.comments = contact.comments.filter((comment: any) => comment._id !== commentId);
        return updatedContact;
      });
      updateEvent(eventBeforeUpdate).then((res) => {
        setCommentsList(commentsList.filter((c) => c.id !== commentId));
        refetchEvent && refetchEvent();
      }).finally(() => {
        setIsDeleting(false);
      });
    }).catch((err) => { 
      setIsDeleting(false);
    });
  }  

  // TODO: implement proper API for adding comment.
  // NOTE: This may not be reliable for concurrent updates. But only the PUT api is available for guest comments. Using the /public/event PUT api.
  const addNewComment = async (eventId: string, comment: any) => {
    // prevent empty comments
    if (comment === '') return;
    
    const phoneNumberToCheck = user ? user.phoneNumber : guestPhoneNumber;
    // TODO: properly identify guest name
    const storedGuestName = JSON.parse(sessionStorage.getItem('cal-user-name')??'""');
    const guestUserName = storedGuestName? storedGuestName : "Guest";
    setIsAdding(true);
    getEvent(eventId).then((res) => {
      const eventBeforeUpdate = res.data;
      let alternateFlow = false;
      if(user){
        // logged in user adding comments
        addComment(input);
      }else if(phoneNumberToCheck){
        // guest user with phone number adding comments
        let contactFound = false;
        eventBeforeUpdate.contacts = eventBeforeUpdate?.contacts?.map((contact: any) => {
          if(contactFound) return contact;
          const updatedContact = contact;
          if(updatedContact.phoneNumber == phoneNumberToCheck){
            contactFound = true;
            if(!updatedContact.comments) updatedContact.comments = [];
            updatedContact.comments.push({comment:input, timestamp: new Date().toISOString(),_id:getRandomHexID()});
          }
          return updatedContact;
        });
        // if contact not found add new contact with comment
        if(!contactFound){
          eventBeforeUpdate.contacts.push({
            name:guestUserName,
            phoneNumber:phoneNumberToCheck??'',
            comments:[{comment:input, timestamp: new Date().toISOString(),_id:getRandomHexID()}],
            _id:getRandomHexID(),
            _commentGuestId: getRandomHexID(),
          });
        }
        alternateFlow = true;
      }else{
        // guest user only with name adding comment

        // check if no phoneNumber and same name is already present
        const contactFound = eventBeforeUpdate.contacts?.find((contact:any)=>contact.phoneNumber==='+' && contact.name===guestUserName);
        if(contactFound){
          if(!contactFound.comments) contactFound.comments = [];
          contactFound.comments.push({comment:input, timestamp: new Date().toISOString(),_id:getRandomHexID()});
        }else{          
          eventBeforeUpdate.contacts.push({
            name:guestUserName,
            phoneNumber:'',
            comments:[{comment:input, timestamp: new Date().toISOString(),_id:getRandomHexID()}],
            _id:getRandomHexID(),
            _commentGuestId: getRandomHexID(),
          });
        }
        alternateFlow = true;
      }
      if(alternateFlow){
        updateEvent(eventBeforeUpdate).then((res) => {
          // TODO: properly refetch from parent component
          // refetchEvent && refetchEvent();
          reloadCommentList();
          console.log("reloading comments");
        }).finally(() => {
          setIsAdding(false);
          setInput('');
        });      
      }
    }).catch((err) => {
      setIsAdding(false);
    });
  }

  const reloadCommentList = () => {
    const tmpEventId = event?.event?._id;
    if (tmpEventId && !isAdding && !isEditing && !isDeleting) {
      setReloading(true);
      getEvent(tmpEventId).then((res) => {
        if(res.data?.contacts){
          const newComments = getCommentsFromEvent(res.data);
          setCommentsList(newComments);
        }
      }).catch((err) => {}).finally(() => {
        setReloading(false);
      });
    }    
  }
  
  // TODO: implement proper API for updating comments.
  // NOTE: This may not be reliable for concurrent updates. But only the PUT api is available. Using the /public/event PUT api.
  const updateEvent = async (event:IEvent) => {
    const formattedEvent:any = {...event,id:event._id};
    return caleidoBaseApi.put(`/public/event`, event);
  }

  // TODO: use the helper method to get comments from the event
  useEffect(() => {

    if (event) {
      const newCommentsList = event.event.contacts
        .flatMap(
          (contact: {
            comments?: any[];
            phoneNumber: any;
            name: any;
            status: any;
            organizer: any;
          }) =>
            contact.comments
              ? contact.comments.map((comment) => ({
                eventId: event.event._id,
                id: comment._id,
                by: {
                  phoneNumber: contact.phoneNumber,
                  name: contact.name,
                  status: contact.status,
                  organizer: contact.organizer,
                },
                comment: comment.comment,
                timestamp: comment.timestamp,
              }))
              : [],
        )
        .sort(
          (
            a: { timestamp: string | number | Date },
            b: { timestamp: string | number | Date },
          ) =>
            new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime(),
        );
      setCommentsList(newCommentsList);
    }
  }, []);


  // fetch the list of comments every 15 seconds
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    intervalRef.current = setInterval(() => {
        reloadCommentList();
      }, 15000);  
    return () => {
      if(intervalRef.current) clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    console.log('commentsList', commentsList);
  }, [commentsList]);

  useEffect(() => {
    console.log(commentsList);
  }, [commentsList]);

  if (!event) {
    return <></>;
  }

  // check if user is host
  const isHost = event? event?.event?.userId === user?._id : false;
  
  return (
    <Collapsible
      className="w-full my-5"
      open={open}
      onOpenChange={(e) => setOpen(e)}
    >
      {isAdding||isEditing? <OverlayLoader/>:null}
      <CollapsibleTrigger className="w-full flex justify-center items-end mb-2 text-sm">
        Check what everyone is saying{' '}
        <ChevronDown
          className={cn('ml-3 transition-transform', open && 'rotate-180')}
          size={16}
        />
      </CollapsibleTrigger>
      <CollapsibleContent className="flex flex-col justify-center mb-16">
        <form
          className="flex items-center bg-[#32395E] py-1 px-1.5 rounded-xl gap-3 my-3"
          onSubmit={async (e) => {
            e.preventDefault();
            if (editingComment) {
              await updateComment();
              return;
            }
            addNewComment(event.event._id, input);
            /*
            await addComment(input);
            setInput('');
            */
          }}
        >
          <MessageIcon className="h-5 w-5 shrink-0" />
          {/* <input
              className="w-full focus:outline-none bg-transparent px-2 text-sm "
              placeholder="Add Comment"
              value={input}
              onChange={(e) => setInput(e.target.value)}
          /> */}
          <TextField
            multiline
            maxRows={5}
            className={`w-full focus:outline-none bg-transparent px-2 text-sm`}
            placeholder="Add Comment"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            sx={{
              '&  *': {
                border: 'none !important',
                color: 'white !important',
                padding: '1px 0 !important',
                fontSize: '0.875rem !important',
                lineHeight: '1.25rem !important',
              },
            }}
            disabled={disabled || expired}
          ></TextField>
          <Button
            size={'icon'}
            variant="secondary"
            className="flex items-center justify-center  rounded-full h-6 w-6 shrink-0"
            disabled={disabled||isAdding||isEditing||isDeleting|| !input || expired}
          >
          {isAdding||isEditing||isDeleting?            
            <InLineLoader/>:
            <SendIcon />
          }
          </Button>
        </form>

        <div className="py-1">
          {commentsList.length === 0 ? (
            <div className="text-center text-white/50">No comments yet</div>
          ) : (
            commentsList?.map((comment, index) => {                  
              const commentedByPhoneNumber = comment.by.phoneNumber;  
              const isEditable = user? commentedByPhoneNumber === user?.phoneNumber : guestPhoneNumber? commentedByPhoneNumber === guestPhoneNumber:undefined;
              const isDeletable = isHost || (user && (commentedByPhoneNumber === user?.phoneNumber));
              return <Comment key={index} comment={comment} onDelete={isDeletable?()=>{
                !isDeleting && deleteComment(event.event._id, comment.id??'');
              }:undefined} onEdit={isEditable?()=>{
                !editingComment && editComment(event.event._id, comment);
              }:undefined} />
          })
          )}
        </div>
      </CollapsibleContent>
    </Collapsible>
  );
};

export default CommentList;
