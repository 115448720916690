import { useState } from 'react';
import vCard from 'vcf';
import { isEmpty } from 'lodash';
import { Loader } from 'lucide-react';
import { Contact } from 'modules/profile/types/types';
import { ContactActionProps } from 'modules/settings/types/types';
import {
  mergeContacts,
  transformVCFToContact,
} from 'modules/profile/utils/utils';
import FileUpload from 'components/upload/FileUpload';
import MainButton from 'modules/auth/components/MainButton';
import { Text } from 'components/text/primary/Text';
import MobileInstructionsTabs from 'modules/profile/components/import/UploadInstructions/MobileInstructionsTabs';
import { ReactComponent as ContactIcon } from 'assets/images/import/contacts-icon.svg';
import { SecondaryText } from 'components/text/secondary/Text';
import {
  androidInstructions,
  iphoneInstructions,
} from 'modules/profile/constants/common';
import { toast } from 'components/ui/toast/use-toast';

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const ImportContacts = ({
  initialContacts,
  handleContactsChange,
  loading,
  error,
}: ContactActionProps) => {
  const [importedContacts, setImportedContacts] =
    useState<Contact[]>(initialContacts);
  const [loadingContacts, setContactsLoading] = useState<boolean>(false);
  const [fileError, setImportError] = useState<string | null>(null);

  const handleFileChange = (file: File | null) => {
    setImportedContacts([]);
    if (file) {
      setContactsLoading(true);
      const reader = new FileReader();
      reader.onload = async (event) => {
        try {
          const fileContent = event.target?.result as string;
          if (!fileContent) {
            throw new Error('File content is empty.');
          }
          const vCardArray = vCard.parse(fileContent);
          const batchSize = 10;

          const contacts: Contact[] = [];

          const processBatch = async (startIndex: number) => {
            const endIndex = Math.min(startIndex + batchSize, vCardArray.length);
            const batch = vCardArray.slice(startIndex, endIndex);

            const transformedContacts = batch
              ?.map((card: any) => {
                const contact = transformVCFToContact(card?.data);
                return !isEmpty(contact) ? contact : null;
              })
              .filter((contact): contact is Contact => contact !== null)
              .flat();

            contacts.push(...transformedContacts);
            if (endIndex < vCardArray.length) {
              await delay(10);
              await processBatch(endIndex);
            } else {
              setContactsLoading(false);

              if (contacts.length === 0) {
                toast({
                  title: 'No new contacts found.',
                });
                return;
              }

              setImportedContacts(mergeContacts(initialContacts, contacts));
            }
          };

          await processBatch(0);
        } catch (error) {
          console.error('Error parsing VCF file:', error);
          setImportError('Error Importing contacts');
          setContactsLoading(false);
        }
      };
      reader.onerror = (error) => {
        console.error('Error reading file:', error);
        setContactsLoading(false);
        setImportedContacts([]);
      };
      reader.readAsText(file);
    } else {
      console.log('No file selected');
      clearContacts();
    }
  };

  const clearContacts = () => {
    setImportedContacts([]);
    setImportError(null);
  };

  return (
    <div className="flex flex-col w-full gap-2 px-6 py-4 my-6">
      <Text size="medium" className="mb-6">
        Import your contacts
      </Text>
      {isEmpty(importedContacts) ? (
        <FileUpload
          loading={loadingContacts}
          onFileChange={handleFileChange}
          error={fileError}
          setError={setImportError}
          accept={{
            'text/x-vcard': ['.vcf'],
          }}
        />
      ) : (
        <MainButton
          type="secondary"
          icon={
            <Text
              size="small"
              className="text-purple-200 underline cursor-pointer decoration-solid"
              onClick={(e) => {
                e.stopPropagation();
                clearContacts();
              }}
            >
              Reupload
            </Text>
          }
          className="flex items-center justify-between w-full px-8 py-6 bg-white border rounded-full border-opacity-20 bg-opacity-10 border-slate-300"
        >
          <Text
            size="small"
            className="inline-flex items-center justify-start gap-2 font-normal truncate"
          >
            {loading ? (
              <>
                <Loader className="w-6 h-6 text-white animate-spin" />
                <>Syncing contacts</>
              </>
            ) : (
              <>
                <ContactIcon />
                {`Uploaded ${importedContacts?.length} contacts`}
              </>
            )}
          </Text>
        </MainButton>
      )}{' '}
      {error && (
        <Text size="small" type="error" className="my-2">
          Error Syncing data!
        </Text>
      )}
      <MobileInstructionsTabs
        className="min-h-1"
        title={
          <>
            <Text size="small" className="inline-flex items-baseline gap-2">
              How to upload contacts to
              <SecondaryText size="small" className="text-lg">
                Caleido?
              </SecondaryText>
            </Text>
          </>
        }
        iphoneInstructions={iphoneInstructions}
        androidInstructions={androidInstructions}
      />
      <MainButton
        type="primary"
        className="mt-2"
        onClick={() => {
          if (importedContacts.length === 0) {
            toast({
              title: 'No contacts to save.',
            });
            return;
          }
          handleContactsChange(importedContacts);
        }}
        loading={loading}
        disabled={loading || loadingContacts}
      >
        <Text size="small">Save Changes</Text>
      </MainButton>
    </div>
  );
};

export default ImportContacts;
