import React, {useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {ChevronDown} from "lucide-react";

const type = [
    {label: "All events", value: "all"},
    {label: "Pending RSVPs", value: "pending"},
    {label: "Draft events", value: "draft"},
    {label: "My events", value: "my"},
];

type TypeSelectProps = {
    eventType: string;
};

export const TypeSelect = ({eventType}: TypeSelectProps) => {

    const navigate = useNavigate();

    const [menuVisible, setMenuVisible] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);

    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };

    const handleConfirmationModal = (value: boolean) => {
        // setVisible(value);
    };

    const setSelectedType = async (value: string, label: string) => {
        navigate(`/lets-meet/${value}`);
    }

    return (
        <div className={""}>
            <div onClick={toggleMenu} className={"text-xs bg-white bg-opacity-10 rounded-sm w-28 px-2 mb-1"}>
                <div className={"w-full flex justify-between items-center"}>
                    <b>{eventType}</b>
                    <div className={"scale-50"}>
                        <ChevronDown/>
                    </div>
                </div>
            </div>
            {menuVisible && (
                <div className="absolute right-0 mt-0 mr-5 w-28 bg-gray-800 shadow-lg rounded-md z-10">
                    <div className="py-1 mx-3">
                        {type.map((item, index) => (
                            <>
                                <div key={index} className="block px-0 py-2 text-[11px] text-white hover:bg-gray-700"
                                     onClick={() => {
                                         setSelectedType(item.value, item.label);
                                         toggleMenu();
                                     }}>{item.label}</div>
                                {index === type.length - 1 ? null : <hr className="opacity-20"/>}
                            </>
                        ))}
                    </div>
                </div>
            )}
            {/* <Select onValueChange={
                (value) => setSelectedType(value)
            }
            >
                <SelectTrigger
                    className="bg-white bg-opacity-10 rounded-full border-slate-600 outline-none focus:outline-none">
                    <SelectValue placeholder="Select type"/>
                </SelectTrigger>
                <SelectContent
                    className="w-full bg-slate-800 text-white border-none">
                    <SelectItem value="all">All events</SelectItem>
                    <SelectItem value="pending">Pending RSVPs</SelectItem>
                    <SelectItem value="draft">Draft events</SelectItem>
                    <SelectItem value="my">My events</SelectItem>
                </SelectContent>
            </Select> */}
        </div>
    );
};