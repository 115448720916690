import { InputOTP, InputOTPGroup, InputOTPSlot } from 'components/ui/input-otp';
import { REGEXP_ONLY_DIGITS } from 'input-otp';
import { OtpInputRef } from 'modules/auth/types/types';

import { forwardRef, useImperativeHandle, useRef } from 'react';

interface OTPInputProps {
  length: number;
  onChange: (otp: string) => void;
  disabled?: boolean;
}
const OTPInput = forwardRef<OtpInputRef, OTPInputProps>(
  ({ length, onChange, disabled }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const handleChange = (newValue: string) => {
      onChange(newValue);
    };

    useImperativeHandle(ref, () => ({
      reset: () => {
        if (inputRef.current) {
          inputRef.current.value = '';
        }
      },
    }));

    return (
      <InputOTP
        ref={inputRef}
        maxLength={length}
        minLength={length}
        pattern={REGEXP_ONLY_DIGITS}
        className=""
        onChange={handleChange}
        disabled={disabled}
      >
        <InputOTPGroup>
          {[...Array(length)].map((_, index) => (
            <InputOTPSlot
              key={index}
              className="rounded-md border-[1px] w-[46px] h-[46px] mx-1 caret-white text-white"
              index={index}
            />
          ))}
        </InputOTPGroup>
      </InputOTP>
    );
  },
);

OTPInput.displayName = 'OTPInput';

export default OTPInput;
