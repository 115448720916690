import {
  ReceiveMeetResponseState,
  ReceiveMeetResponseType,
  ReceivePublicMeetResponseState,
} from 'modules/letsMeet/types/types';
import React, { useEffect, useState } from 'react';
import ResponseButton from './responseButton';

import { ReactComponent as YesIcon } from 'assets/images/activity/yes.svg';
import { ReactComponent as NoIcon } from 'assets/images/activity/no.svg';
import MainButton from 'modules/auth/components/MainButton';
import { ChevronRight } from 'lucide-react';
import { toast } from 'components/ui/toast/use-toast';
import { cn } from 'utils/helpers';
import TextField from 'components/textfield/TextField';
import PollResults from './pollResults';
import { IEvent, IPollData } from 'types/event';
import PollTimeSelector from './pollTimeSelector';
import { useSendNotificationMutation } from 'services/notification.service';
import { NotificationType } from 'modules/letsMeet/utils/enum';
import { formatDateTimeSlot, formatDateToLocalTimezone, getUserTimezone, isTimePassed } from 'modules/letsMeet/utils/lib';
import ReceiveCountryCodeDropdown from './countryCodeInput';
import countryCodes from 'data/country-codes.json';
import useLocalStorage from 'hooks/useLocalStorage';
import { useSendOtpMutation } from 'modules/auth/services/verification.mutation.service';
import useSessionStorage from 'hooks/useSessionStorage';
import { format, parseISO } from 'date-fns';

type MeetResponseProps = {
  event?: IEvent;
  onSubmit: (name: string, selected: string, publicUserId?: string,contactNumber?:string) => Promise<void>;
  pollData: IPollData;
  selectedMap: Map<string, boolean>;
  setSelectedMap: React.Dispatch<React.SetStateAction<Map<string, boolean>>>;
  name: string;
  setParentName: React.Dispatch<React.SetStateAction<string>>;
  publicUserId?: string;
};

const MeetResponsePublicPoll: React.FC<MeetResponseProps> = (props) => {
  const [submitting, setSubmitting] = useState(false);
  const [selected, setSelected] = useState<string | undefined>(undefined);
  const [sendNotification] = useSendNotificationMutation()
  const [name, setName] = useSessionStorage('cal-user-name', '');
  const [phoneNumber, setPhoneNumber] = useSessionStorage('cal-user-phone-number', '');
  const [countryCode, setCountryCode] = useSessionStorage('cal-user-country-code', '+1');
  
  const [eventPollResponses, setEventPollResponses] = useLocalStorage('cal-user-event-poll-responses', {});
  const [sendOtp, { isLoading: isSendingOtp, isError:isErrorSending }] = useSendOtpMutation();
  const [isHostResponding, setIsHostResponding] = useSessionStorage('cal-host-responding', false);

  const handleNameChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    props.setParentName(e.target.value);
  }, [props.setParentName]);

  // find the event organizer 
  const eventOrganizer = props?.event?.contacts?.find(contact => contact.organizer === 1);

  const submitResponse = async () => {
    // props.setParentName(name);
    if (submitting) return;

    if (props.name.trim() === '') {
      toast({
        title: 'Please enter your name',
      });
      return;
    }

    // skit ui development test
    if (!selected && false) {
      toast({
        title: 'Please select a times(s)',
      });
      return;
    }

    setSubmitting(true);
    const formattedPhoneNumber = phoneNumber?`${countryCode}${phoneNumber}`:undefined;
    // update here too
    await props.onSubmit(props.name, selected ?? 'SELECTED_ID', props.publicUserId,formattedPhoneNumber);

    if(phoneNumber){
      const formattedPhoneNumber = `${countryCode}${phoneNumber}`;
      // identify if the host has responded
      if(eventOrganizer){
        const eventOrganizerContact = eventOrganizer?.phoneNumber??'';
        setIsHostResponding(formattedPhoneNumber === eventOrganizerContact);
      }
      // find if the number is already validated
      const foundValidated = JSON.parse(localStorage.getItem('cal-user-otp-validated-number')??'[]')?.find((vo:any)=>{
        return vo.phoneNumber==formattedPhoneNumber});

      if(!foundValidated){
        // send otp to the number
        sendOtpToNumber(formattedPhoneNumber);        
      }else{
        // user is already validated
      }
    }

    // TODO: commented cause server is crashing.
    // const notificationData = {
    //   type: NotificationType.POLL_VOTE,
    //   eventId: props?.event?._id
    // }
    // await sendNotification(notificationData)

    // set name to local storage
    setName(props.name);
    
    setSubmitting(false);

    // save response to local storage
    if(props.selectedMap) {
      setEventPollResponses((prev:any)=>{ return {...prev, [props.event?._id??'']: Array.from(props?.selectedMap?.entries())}});
    }else{
      setEventPollResponses((prev:any)=>{ return {...prev, [props.event?._id??'']: undefined}});  
    } 
  };

  const sendOtpToNumber = (formattedPhoneNumber:string) => {        
    if(!formattedPhoneNumber.startsWith('+1')){
      console.log("Only US numbers are allowed");
      return;
    }       
    sendOtp({ phoneNumber: `${formattedPhoneNumber}`}).unwrap().then(()=>{}).catch(()=>{});
  }

  return (
    <div
      className={cn('flex flex-col bg-[#1F2339] text-white rounded-none pb-16')}
    >
      <div className="mx-8 pt-8">
        <TextField
          label="What is your name?"
          placeholder="Your Name"
          value={props.name}
          
          onChange={handleNameChange}
        />
   
      </div>


      <div className="mx-8 pt-2">
        <div className="mb-2">Phone number</div>
        <ReceiveCountryCodeDropdown
          selectedCode={countryCode??'+1'}
          phoneNumber={phoneNumber??''}
          onCodeSelect={(code) => setCountryCode(code)}
          onPhoneNumberChange={(number) => setPhoneNumber(number)}
          countryCodes={countryCodes}
          disabled={false}
        />
      </div>          
      <p className='my-2 text-sm text-center font-sofia-light'>signup/login for reminders</p>

      <div className="mx-8">
        <div className="mb-4"></div>
        <PublicPollTimeSelector
          pollData={props.pollData}
          userId={ ''}
          selectedTimeMap={props.selectedMap}
          setSelectedTimeMap={props.setSelectedMap}
        />
      </div>

      <MainButton
        type="primary"
        onClick={submitResponse}
        className="mx-8 box-border w-auto"
        icon={<ChevronRight color="#fff" />}
        loading={submitting}
      >
        Send Response
      </MainButton>
    </div>
  );
};

export default MeetResponsePublicPoll;



interface PollTimeSelectorProps {
  pollData: IPollData;
  userId: string;
  selectedTimeMap: Map<string, boolean>;
  setSelectedTimeMap: React.Dispatch<React.SetStateAction<Map<string, boolean>>>;
}

const PublicPollTimeSelector: React.FC<PollTimeSelectorProps> = ({
  pollData,
  userId,
  selectedTimeMap,
  setSelectedTimeMap,
}) => {
  const [notAvailable, setNotAvailable] = useState<boolean>(() => {
    // Initialize notAvailable based on the selectedTimeMap
    return selectedTimeMap.get("Not available on all dates") || false;
  });

  const handleTimeToggle = (time: string) => {
    setSelectedTimeMap((prevMap) => {
      const newMap = new Map(prevMap);

      if (time === "Not available on all dates") {
        const newNotAvailable = !notAvailable;
        setNotAvailable(newNotAvailable);

        if (newNotAvailable) {
          // Clear all other selections and select "Not available on all dates"
          pollData.checkedTimes.forEach((timeSlot) => {
            newMap.set(timeSlot.time, false);
          });
          newMap.set(time, true);
        } else {
          // Deselect "Not available on all dates"
          newMap.set(time, false);
        }
      } else {
        if (notAvailable) {
          // If "Not available on all dates" is selected, clear it first
          setNotAvailable(false);
          newMap.set("Not available on all dates", false);
        }

        // Toggle the selected state of the clicked time slot
        const isSelected = newMap.get(time);
        newMap.set(time, !isSelected);
      }
      console.log("new map ", newMap);
      return newMap;
    });
  };

  return (
    <div>
      <h2 className="text-base font-semibold mb-1">When are you available?</h2>
      <p className="text-xs text-white mb-2">Select all that apply</p>

      {pollData.checkedTimes.map((timeSlot, index) => { 
      const isPassed = isTimePassed(timeSlot.time)

        const matchResult = timeSlot.time.match(
          /(\d{4}-\d{2}-\d{2})-(\d{2}:\d{2})(Z|[+-]\d{2}:?\d{2})?-(\d{2}:\d{2})(Z|[+-]\d{2}:?\d{2})?/,
        );
        const [date, startTime, endTime] = matchResult
          ? [
            matchResult[1],
            matchResult[2] + (matchResult[3] || ''),
            matchResult[4] + (matchResult[5] || '')
          ]
          : ['', '', ''];

      return(
        <div
          key={index}
          className={`mb-3 p-4 bg-[#2C3051] rounded-md shadow-md flex items-center ${
            notAvailable || isPassed ? "opacity-50 cursor-not-allowed" : ""
          }`}
          onClick={() => (!notAvailable && !isPassed) && handleTimeToggle(timeSlot.time)} // Disable clicking if "Not available on all dates" is selected
        >
          <div
            className={`h-5 w-5 mr-3 border-2 rounded bg-[#D7C0FB] border-[#D7C0FB] flex items-center justify-center cursor-pointer ${
              notAvailable || isPassed ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            {selectedTimeMap.get(timeSlot.time) && (
              <div className="h-3 w-3 bg-[#6000E9] rounded"></div>
            )}
          </div>
          <div className="flex justify-between items-center w-full">
            <div className="flex items-center gap-2">
              <span className="text-sm font-medium text-white">
                {formatDate(date)}
              </span>
              <span className="w-1.5 h-1.5 bg-purple-600 rounded-full inline-block"></span>
              <span className="text-sm text-white">
              {`${formatTime(date, startTime)} - ${formatTime(date, endTime)}`}
              </span>
            </div>
          </div>
        </div>
      )})}

      <div
        className="mb-3 p-4 bg-[#2C3051] rounded-md shadow-md flex items-center"
        onClick={() => handleTimeToggle("Not available on all dates")}
      >
        <div
          className={`h-5 w-5 mr-3 border-2 rounded bg-[#D7C0FB] border-[#D7C0FB] flex items-center justify-center cursor-pointer`}
        >
          {selectedTimeMap.get("Not available on all dates") && (
            <div className="h-3 w-3 bg-[#6000E9] rounded"></div>
          )}
        </div>
        <span className="text-sm font-medium text-white">
          Not available on all dates
        </span>
      </div>
    </div>
  );
};


const formatDate = (dateString: string) => {
  try {
    const date = parseISO(dateString);
    return format(date, 'EEE, MMM do');
  } catch (error) {
    console.error('Error parsing date:', error);
    return 'Invalid Date';
  }
};

const formatTime = (dateString: string, timeString: string) => {
  try {
    const date = new Date(`${dateString}T${timeString}`);
    return format(date, 'hh:mm a');
  } catch (error) {
    console.error('Error parsing time:', error);
    return 'Invalid Time';
  }
};

const getDaySuffix = (day: string) => {
  const dayNum = Number(day);
  if (dayNum >= 11 && dayNum <= 13) {
    return "th";
  }
  switch (dayNum % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};


