import { Fragment, forwardRef, useImperativeHandle, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import { IUser } from 'modules/auth/types/types';
import { SecondaryText } from 'components/text/secondary/Text';
import { Text } from 'components/text/primary/Text';
import ProfileAvatar from 'components/avatar/ProfileAvatar';
import { daysOfWeek } from 'constants/common';
import {
  IAvailability,
  IAvailabilityPeriod,
} from 'modules/profile/types/types';
import { formatDateTime } from 'utils/dateTimeUtils';
import { ReactComponent as DownloadIcon } from 'assets/images/profile/download-icon.svg';
import { ReactComponent as WidgetIcon } from 'assets/images/profile/caleido-widget-icon.svg';
import MobileInstructionsTabs from 'modules/profile/components/import/UploadInstructions/MobileInstructionsTabs';

type UserAccountProps = {
  setPrevOrSkip: () => void;
  update?: any;
  data?: IUser | undefined;
};

const daysOrder = Object.keys(daysOfWeek);

const UserAccount = forwardRef<(() => void) | null, UserAccountProps>(
  ({ data }, ref) => {
    const navigate = useNavigate();
    const [step, setStep] = useState<number>(0);
    const availability = (data?.availability as IAvailability) ?? [];

    const handleSubmit = () => {
      if (step !== 1) {
        setStep(1);
      } else {
        navigate('/home');
      }
    };

    useImperativeHandle(ref, () => handleSubmit);

    const List = ({
      title,
      periods,
    }: {
      title: string;
      periods?: IAvailabilityPeriod[];
    }) => (
      <div className="w-full px-4 pt-2 pb-4 mb-2 bg-white border rounded-lg border-opacity-20 bg-opacity-10 border-slate-300">
        <Text size="small" className="mb-2 font-bold">
          {title}
        </Text>
        <div className="grid grid-cols-2 gap-x-4 gap-y-3">
          {periods?.map((period, index) => (
            <div key={index} className="px-2 py-1 bg-purple-200 rounded-sm">
              <Text
                size="small"
                className="text-xs font-semibold text-center text-slate-900"
              >
                {period.allDay
                  ? 'Available full day'
                  : `${formatDateTime(period?.start?.dateTime, period?.start?.timeZone)} - ${formatDateTime(period?.end?.dateTime ?? '2024-06-27T09:00:00', period?.end?.timeZone ?? 'America/New_York')}`}
              </Text>
            </div>
          ))}
        </div>
      </div>
    );

    const sortedAvailabilityDays = Object.keys(availability ?? {}).sort(
      (a, b) => daysOrder.indexOf(a) - daysOrder.indexOf(b),
    );

    const renderAvailabilityPeriods = sortedAvailabilityDays?.map(
      (day, index) => (
        <Fragment key={index}>
          <List
            title={daysOfWeek[day].label}
            periods={availability?.[day as keyof IAvailability] || []}
          />
        </Fragment>
      ),
    );

    const renderRecurrencePeriods = data?.recurringDates.map((date) => {
      if (!daysOfWeek[date]) return null;
      return (
        <List
          key={date}
          title={daysOfWeek[date].label}
          periods={data.recurring}
        />
      );
    });

    if (!availability) return null;

    return (
      <div className="flex flex-col items-center w-full gap-2 mb-8">
        {step === 0 && (
          <>
            <div className="flex flex-col items-center gap-3 mt-8">
              <ProfileAvatar url={data?.photoURL} />
              <Text
                size="medium"
                className="text-3xl font-sofia-medium"
              >{`Hi, ${data?.name.split(' ')?.[0] ?? data?.name}!`}</Text>
              <Text
                size="small"
                className="inline-flex items-baseline gap-2 text-base"
              >
                Welcome to
                <SecondaryText size="small" className="text-lg">
                  CALEIDO!
                </SecondaryText>
              </Text>
            </div>
            <div className="w-full mt-3">
              <Text size="small" className="mb-6 text-center text-base">
                These are your available times to hang!
              </Text>
              <Transition
                show={true}
                appear={true}
                enter="transition-opacity duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                {(ref) => (
                  <div className="grid grid-cols-1 gap-2">
                    {data?.isRecurring
                      ? renderRecurrencePeriods?.map((list, index) => (
                          <div key={index}>{list}</div>
                        ))
                      : renderAvailabilityPeriods.map((list, index) => (
                          <div key={index}>{list}</div>
                        ))}
                  </div>
                )}
              </Transition>
            </div>
          </>
        )}
        {step === 1 && (
          <>
            <Text size="medium" className="font-sofia-medium text-3xl">
              Before you go
            </Text>
            <Text
              size="small"
              className="inline-flex items-baseline gap-1 mb-12 text-base font-sofia-light"
            >
              Install the
              <SecondaryText size="small" className="text-lg">
                CALEIDO
              </SecondaryText>
              App
            </Text>
            <MobileInstructionsTabs
              iphoneInstructions={[
                <Text
                  key={1}
                  size="small"
                  className="text-base font-sofia-light"
                >
                  <span>
                    Go to <DownloadIcon className="inline-block mx-1 my-1" />{' '}
                    and import the{' '}
                  </span>
                  <span className="inline-flex items-baseline">
                    <SecondaryText size="small" className="text-lg">
                      CALEIDO
                    </SecondaryText>
                  </span>
                  <span> app in the browser</span>
                </Text>,
                <Text
                  key={2}
                  size="small"
                  className="text-base font-sofia-light"
                >
                  <span>Scroll down and select</span>
                  <div className="inline-block px-1 py-1 mx-1 my-1 font-semibold bg-white rounded-sm bg-opacity-10">
                    Add to home screen
                  </div>
                </Text>,
                <Text
                  key={3}
                  size="small"
                  className="text-base font-sofia-light"
                >
                  <span>
                    Look for the{' '}
                    <img
                      src="/icon512_rounded.png"
                      alt="logo"
                      height={31}
                      width={31}
                      className="inline-block mx-1 my-1"
                    />
                  </span>{' '}
                  icon.
                </Text>,
                <Text
                  key={4}
                  size="small"
                  className="text-base font-sofia-light"
                >
                  <span>
                    Click on ‘Add’ to add the{' '}
                    <span className="inline-flex items-baseline">
                      <SecondaryText size="small" className="text-lg">
                        CALEIDO
                      </SecondaryText>
                    </span>{' '}
                    widget to your home screen
                  </span>
                </Text>,
              ]}
              androidInstructions={[
                <Text
                  key={1}
                  size="small"
                  className="text-base font-sofia-light"
                >
                  <span>
                    Go to <DownloadIcon className="inline-block mx-1 my-1" />{' '}
                    and import the{' '}
                  </span>
                  <span className="inline-flex items-baseline">
                    <SecondaryText size="small" className="text-lg">
                      CALEIDO
                    </SecondaryText>
                  </span>
                  <span> app in the browser</span>
                </Text>,
                <Text
                  key={2}
                  size="small"
                  className="text-base font-sofia-light"
                >
                  <span>Scroll down and select</span>
                  <div className="inline-block px-1 py-1 mx-1 my-1 font-semibold bg-white rounded-sm bg-opacity-10">
                    Add to home screen
                  </div>
                </Text>,
                <Text
                  key={3}
                  size="small"
                  className="text-base font-sofia-light"
                >
                  <span>
                    Look for the{' '}
                    <WidgetIcon className="inline-block mx-1 my-1" />
                  </span>{' '}
                  icon.
                </Text>,
              ]}
            />
          </>
        )}
      </div>
    );
  },
);

UserAccount.displayName = 'UserAccount';

export default UserAccount;
