import { Separator } from 'components/ui/separator';
import dayjs from 'dayjs';
import { cn } from 'utils/helpers';
import CommentList from 'modules/letsMeet/components/comments/commentList';
import InviteList from 'modules/letsMeet/components/inviteList/inviteList';
import { MeetInfo } from 'modules/letsMeet/components/meetInfo/meetInfo';
import MeetPublicResponseAfter from 'modules/letsMeet/components/meetResponse/meetResponsePublicAfter';
import MeetResponsePublicPoll from 'modules/letsMeet/components/meetResponse/meetResponsePublicPoll';
import { EventTitle } from 'modules/letsMeet/components/receive/eventTitle';
import ReceiveHeader from 'modules/letsMeet/components/receive/receiveHeader';
import ReceiveLaterPopup from 'modules/letsMeet/components/receive/receiveLaterPopup';
import {
  EventType,
  ReceiveMeetResponseType,
  ReceivePublicMeetResponseState,
  ReceivePublicPollResponseState,
} from 'modules/letsMeet/types/types';
import React from 'react';
import { useEffect, useState } from 'react';
import { useUpdatePublicEventMutation } from 'services/public_event.service';
import { IContact, IEvent, IPollData } from 'types/event';
import UnlockResponseView from 'modules/letsMeet/components/meetResponse/unlockResponseView';
import PublicResponseDrawer from 'modules/letsMeet/components/meetResponse/publicResponseDrawer';
import { useGuestRespondEventMutation } from 'services/slices/eventsApiSlice';
import useLocalStorage from 'hooks/useLocalStorage';
import useSessionStorage from 'hooks/useSessionStorage';
import EndedResponseView from 'modules/letsMeet/components/meetResponse/endedResponseView';
import { useNavigate } from 'react-router-dom';

interface ReceivePublicMeetNowProps {
  event?:  IEvent;
  refetch?: () => void;
}
const ReceivePublicMeetPoll = (receiveProps: ReceivePublicMeetNowProps) => {
  const [state, setState] = useState<ReceivePublicMeetResponseState>(ReceivePublicMeetResponseState.HIDDEN);

  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [publicUserId, setPublicUserId] = useLocalStorage('cal-user-public-id', '');
  const [name, setName] = useSessionStorage('cal-user-name', '');
  const [phoneNumber, setPhoneNumber] = useSessionStorage('cal-user-phone-number', '');
  const [countryCode, setCountryCode] = useSessionStorage('cal-user-country-code', '+1');
  const [hasRespondedBefore, setHasRespondedBefore] = useState<boolean>(false);
  const [response, setResponse] = useState<ReceiveMeetResponseType | undefined>(undefined); 
  const [viewState, setViewState] = useState<any>('initial');
  const [eventResponses, setEventResponses] = useLocalStorage('cal-user-event-responses', {});
  const [userAccountAvailable, setUserAccountAvailable] = useLocalStorage('cal-user-account-available', false);
  const [eventPollResponses, setEventPollResponses] = useLocalStorage('cal-user-event-poll-responses', {});
  const [drawerOpen, setDrawerOpen] = useState(true);

  const [pollData, setPollData] = useState<IPollData | undefined>(receiveProps?.event?.pollData);
  // Initialize the map with all time slots from pollData
  const initializeSelectedTimeMap = (pollData: IPollData) => {
    const map = new Map<string, boolean>();
    pollData.checkedTimes.forEach(timeSlot => {
      map.set(timeSlot.time, false);  // Initially set all to false or adjust if you need to reflect existing selections
    });
    return map;
  };

  const [selectedTimeMap, setSelectedTimeMap] = useState<Map<string, boolean>>(
    pollData ? initializeSelectedTimeMap(pollData) : new Map()
  );
  const [updatePublicEvent] = useUpdatePublicEventMutation();
  const onDialogClose = () => {
    setState(ReceivePublicMeetResponseState.ACCEPTED);
  };
  const PUBLIC_IDENTITY = '';   // TODO: Remove this, not needed anymore.

const handleNameChange = React.useCallback((newName: string) => {
  setName(newName);
}, []);


  const checkEnded = () => {
    receiveProps?.event?.end &&
      dayjs.tz(receiveProps.event.end).isBefore(dayjs.tz(new Date())) &&
      setState(ReceivePublicMeetResponseState.ENDED);
  };

  useEffect(() => {
    if (!publicUserId){
      // generate uuid and store in local storage
      const uuid = require('uuid');
      const newUserId = uuid.v4();
      setPublicUserId(newUserId);
    }
  }, []);


  useEffect(() => {    
    // clear session storage when unmount
    return ()=>{
      setName('');
      setPhoneNumber('');
      setCountryCode('+1');
    }
  },[]);

  useEffect(() => {
    checkEnded();

    const endCheck = setInterval(() => {
      checkEnded();
    }, 1000 * 60);

    return () => {
      clearInterval(endCheck);
    };
  }, [receiveProps]); // TODO: What exactly are we checking here?

  const inviteeContactList = receiveProps?.event?.contacts?.filter((contact) => contact.organizer === 0) ?? [];


  // find if the user has responded to this event before
  useEffect(() => {    
    if (eventPollResponses[receiveProps.event?._id ?? '']) {
      const response = eventPollResponses[receiveProps.event?._id ?? '']?? undefined;
      if(response){
        setHasRespondedBefore(true);
        setViewState('secondary');      
        setState(ReceivePublicMeetResponseState.ACCEPTED);
      }
    }
  }, []);

  // get previous responses
  const responseOfUser = JSON.parse(localStorage.getItem('cal-user-event-poll-responses')??'{}')[receiveProps.event?._id ?? '']?? undefined;

  const handleCommentSectionOnFocus = (isFocused:boolean) => {
    if(isFocused) setDrawerOpen(false);
    else setDrawerOpen(true);
  }

  const dataHidden = (state === ReceivePublicMeetResponseState.HIDDEN && !hasRespondedBefore) || !responseOfUser ;

  // TODO: Fix header to use actual data. Also find a consistent type defintion for contacts!
  return (
    <>
      <div className="bg-header rounded-b-md pt-4">
      <ReceiveHeader
          by={{
            name: `${receiveProps?.event?.organizer.split(' ')[0]}`,
            id: `${receiveProps?.event?.userId}`,
            photoURL: `${receiveProps?.event?.organizerPhoto ?? ''}`,
          }}
          emoji={receiveProps?.event?.emoji ?? '📖'}
          contacts={receiveProps!.event?.contacts  as any} // Todo: This needs to be fixed. Contacts currently doesn't have a PHOTOURL...
          type="poll"
          event={receiveProps?.event}
        />
      </div>

      <div className="px-8">
        <EventTitle title={receiveProps.event?.activity ?? ""} by={receiveProps.event?.organizer ?? ""} type={EventType.POLL} />
        <Separator className="bg-white/20" />

        <MeetInfo
          withPadding
          type="now-no-time"
          end={receiveProps.event?.end}
          location={receiveProps.event?.location ?? ""}
          details={receiveProps.event?.details ?? ""}
        />

        <div
          className={cn(
            'pb-6', dataHidden ? 'blur-sm':'',
          )}
        >
          <InviteList
            title="Who's responded"
            list={
              inviteeContactList.map((contact) => contact.name) ?? []
            }
            disabled={dataHidden}
            eventId={receiveProps.event?._id}
            includeAll={true}
            excludeOrganizer={true}
            isPoll={true}
          />
        {!dataHidden && <CommentList disabled={(responseOfUser === undefined && (state== ReceivePublicMeetResponseState.HIDDEN || state==ReceivePublicMeetResponseState.NOT_RESPONDED)) || dataHidden } event={{ event: receiveProps?.event }} refetchEvent={receiveProps.refetch}/> }
        </div>
      </div>

      <ReceiveLaterPopup
        isOpen={state === ReceivePublicMeetResponseState.SHOW_POPUP}
        onClose={onDialogClose}
        response={ReceiveMeetResponseType.ACCEPTED}
        triggerRespondChange={() => {
          setState(ReceivePublicMeetResponseState.NOT_RESPONDED);
        }}
        isPublic
      />

      <RenderPicker
        state={state}
        pollData={pollData}
        selectedTimeMap={selectedTimeMap}
        setSelectedTimeMap={setSelectedTimeMap}
        setName={setName}
        name={name}
        setState={setState }
        setSubmitting={setSubmitting}
        setSubmitted={setSubmitted}
        receiveProps={receiveProps}
        PUBLIC_IDENTITY={PUBLIC_IDENTITY}
        publicUserId={publicUserId}
        hasRespondedBefore={hasRespondedBefore}
        setHasRespondedBefore={setHasRespondedBefore}
        countryCode={countryCode}
        setCountryCode={setCountryCode}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        response={response}
        setResponse={setResponse}        
        setEventResponses={setEventResponses}
        viewState={viewState}
        setViewState={setViewState}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
      />
    </>
  );
};

interface RenderPickerProps {
  state: ReceivePublicMeetResponseState;
  pollData: IPollData | undefined;
  selectedTimeMap: Map<string, boolean>;
  setSelectedTimeMap: React.Dispatch<React.SetStateAction<Map<string, boolean>>>;
  setName: React.Dispatch<React.SetStateAction<string>>;
  name: string;
  setState: React.Dispatch<React.SetStateAction<ReceivePublicMeetResponseState>>;
  setSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
  setSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  receiveProps: ReceivePublicMeetNowProps,
  PUBLIC_IDENTITY: string;
  hasRespondedBefore: boolean;
  publicUserId?: string;
  setHasRespondedBefore: React.Dispatch<React.SetStateAction<boolean>>;
  response?: ReceiveMeetResponseType;
  setResponse: React.Dispatch<React.SetStateAction<ReceiveMeetResponseType | undefined>>;
  countryCode: string;
  setCountryCode: React.Dispatch<React.SetStateAction<string>>;
  phoneNumber: string;
  setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  viewState: any;
  setViewState: React.Dispatch<React.SetStateAction<any>>;
  setEventResponses: React.Dispatch<React.SetStateAction<any>>;
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const RenderPicker: React.FC<RenderPickerProps> = ({
  state,
  pollData,
  selectedTimeMap,
  setSelectedTimeMap,
  setName,
  name,
  setState,
  setSubmitting,
  setSubmitted,
  receiveProps,
  PUBLIC_IDENTITY,
  hasRespondedBefore,
  setHasRespondedBefore,
  response,
  setResponse,
  countryCode,
  setCountryCode,
  phoneNumber,
  setPhoneNumber,
  viewState,
  setViewState,
  setEventResponses,
  drawerOpen,
  setDrawerOpen,
}) => {

  const [publicUserId, setPublicUserId] = useLocalStorage('cal-user-public-id', '');
  const [currentView, setCurrentView] = useState<'complete'|'otp-input'|'phone-input'|'rsvp-view'|null>(null);
  const navigate = useNavigate();
  // API call to respond to the event
  const [updatePublicEvent] = useUpdatePublicEventMutation();
  if ((state === ReceivePublicMeetResponseState.HIDDEN) && !hasRespondedBefore) {
    return (
      <UnlockResponseView
        onSubmit={() => setState(ReceivePublicMeetResponseState.NOT_RESPONDED)}
      />
    );
  }

 const handlePollSubmission = async (name: string, selected: string, publicUserId? : string,contactNumber?:string): Promise<void> => {
  console.log(name, selected);

  const updatedPollData = getUpdatedPollData(pollData ?? { checkedTimes: [] }, selectedTimeMap, name, publicUserId);
  console.log('Updated Poll Data:', updatedPollData);

  const anyVoteChoicesForUser = checkIfUserHasVoted(updatedPollData, publicUserId ?? '');
  const voteStatus = anyVoteChoicesForUser ? 1 : 2;

  const data = prepareEventData(receiveProps?.event, name, updatedPollData, voteStatus, publicUserId,contactNumber);

  try {
    await updatePublicEvent(data);
    console.log('Poll data updated');
    // store publicUserId in local storage
    if (publicUserId && publicUserId !== '') {
      setPublicUserId(publicUserId);
      setName(name);
    }
    handlePostSubmission();
  } catch (error) {
    console.error('Error updating poll data', error);
  }
 };

  const getUpdatedPollData = (
    pollData: IPollData,
    selectedTimeMap: Map<string, boolean>,
    name: string,
    publicUserId?: string
  ): IPollData => {
    if (selectedTimeMap.get('Not available on all dates')) {
      return {
        checkedTimes: pollData.checkedTimes.map((timeSlot) => ({
          time: timeSlot.time,
          voters: timeSlot.voters.filter(
            (voter) => voter.userId !== publicUserId
          ),
        })),
      };
    }

    return {
      checkedTimes: pollData.checkedTimes.map((timeSlot) => ({
        time: timeSlot.time,
        voters: selectedTimeMap.get(timeSlot.time)
          ? Array.from(
              new Set([
                ...timeSlot.voters,
                { name: name, userId: publicUserId ?? '' },
              ])
            )
          : timeSlot.voters.filter(
              (voter) => voter.userId !== publicUserId
            ),
      })),
    };
  };

  const checkIfUserHasVoted = (pollData: IPollData, userId: string): boolean => {
    return pollData.checkedTimes.some((timeSlot) =>
      timeSlot.voters.some((voter) => voter.userId === userId)
    );
  };

  const prepareEventData = (
    event: IEvent | undefined,
    name: string,
    updatedPollData: IPollData,
    voteStatus: number,
    publicUserId?: string,
    contactNumber?: string
  ) => {

    console.log('Event:', event);
    console.log(voteStatus);
    const contactToAdd: IContact = {
      name: name,
      phoneNumber: contactNumber??'',
      status: voteStatus,
      organizer: 0,
      publicUserId: publicUserId ?? '',
    };

    const contactList = event?.contacts ?? [];
    // if contact exists just update the status
    const existingContact = contactList.find((contact) => contact.publicUserId === contactToAdd.publicUserId);
    if (existingContact) {
      // replace the existing contact with the new one
      contactList.splice(contactList.indexOf(existingContact), 1, contactToAdd);
    } else {
      contactList.push(contactToAdd);
    }

    return {
      ...event,
      contacts: contactList,
      publicUserId: publicUserId,
      pollData: updatedPollData,
      updateEventMetadata: {
        userId: publicUserId,
        phoneNumber: '',
        name: name ?? '',
        wasUpdatedFromPollVote: true,
      },
    };
  };

  const handlePostSubmission = (): Promise<void> => {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
        setSubmitting(false);
        setSubmitted(true);
        setState(ReceivePublicMeetResponseState.ACCEPTED);
      }, 1000);
    });
  };

  if (state === ReceivePublicMeetResponseState.ENDED){
    return(
      <EndedResponseView
        onSubmit={() => 
            navigate('/auth/login')
        }
      />
    );
  }

  if (state === ReceivePublicMeetResponseState.NOT_RESPONDED) {
    return (
      <MeetResponsePublicPoll
        event={receiveProps?.event}
        pollData={pollData ?? { checkedTimes: [] }}
        selectedMap={selectedTimeMap}
        setSelectedMap={setSelectedTimeMap}
        setParentName={setName}
        name={name}
        onSubmit={handlePollSubmission}
        publicUserId={publicUserId}
      />
    );
  }

  return (

    <PublicResponseDrawer
        state={state}
        setState={setState}
        public
        isPoll = {true}
        eventId={receiveProps.event?._id??''}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        name={name}
        setName={setName}
        hasRespondedBefore={hasRespondedBefore}
        setHasRespondedBefore={setHasRespondedBefore}
        event={receiveProps.event}
        response={response}
        setResponse={setResponse}
        countryCode={countryCode}
        setCountryCode={setCountryCode}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}     
        viewState={viewState}
        setViewState={setViewState}
        setEventResponses={setEventResponses}   
        currentView={currentView}
        setCurrentView={setCurrentView}
    />
    // <MeetPublicResponseAfter
    //   type={EventType.POLL}
    //   onRespondChange={() => {
    //     setState(ReceivePublicPollResponseState.NOT_RESPONDED);
    //   }}
    // />
  );
};


export default ReceivePublicMeetPoll;
