import { ChevronRight } from 'lucide-react';
import { cn } from 'utils/helpers';
import { Text } from 'components/text/primary/Text';
import MainButton from 'modules/auth/components/MainButton';

import AuthLayout from 'modules/auth/layouts/AuthLayout';

import styles from './notFound.module.css';

const NotFound: React.FC = () => {
  return (
    <AuthLayout
      className={cn('items-center justify-end text-center', styles.background)}
    >
      <div className="flex flex-col">
        <div className="flex flex-col gap-1 mb-32">
          <Text size="large">404 - Page Not Found</Text>
          <Text size="small">
            Sorry, the page you are looking for does not exist.
          </Text>
        </div>
        <MainButton
          type="secondary"
          to="/"
          icon={<ChevronRight color="#fff" />}
          className="flex items-center justify-between w-full px-8 py-6 mb-12 bg-white border rounded-full mb- border-opacity-20 bg-opacity-10 border-slate-300"
        >
          <Text size="small">Go to Home</Text>
        </MainButton>
      </div>
    </AuthLayout>
  );
};

export default NotFound;
