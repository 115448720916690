import { useState, useEffect } from 'react';

const useKeyboardHeightReset = () => {
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
  const [originalHeight, setOriginalHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      const currentHeight = window.innerHeight;
      // when height decreases, assume keyboard is open
      if (currentHeight < originalHeight) {
        setIsKeyboardOpen(true);
      } else {
        // if height increases back, assume keyboard is closed
        setIsKeyboardOpen(false);
      }
    };

    setOriginalHeight(window.innerHeight);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [originalHeight]);

  useEffect(() => {
    if (!isKeyboardOpen) {
      // reset body height when keyboard is closed
      document.body.style.height = `${originalHeight}px`;
    }
  }, [isKeyboardOpen, originalHeight]);

  return { isKeyboardOpen };
};

export default useKeyboardHeightReset;
