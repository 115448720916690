import { CalendarDaily } from 'modules/home/components/calendarDaily';
import BgGradient from 'modules/home/components/gradient';
import { getSearchParams } from 'modules/home/utils/helpers';
import ViewListHeader from 'modules/letsMeet/components/viewListHeader/ViewListHeader';
import { useLocation } from 'react-router-dom';
import { useValidateAuth } from 'hooks/useValidateAuth';

const HomeDaily = () => {
  // get search params
  const location = useLocation();
  const searchParams = getSearchParams(location.search ?? '');
  const { isValidAuth } = useValidateAuth();

  console.log('Search Params : ', searchParams);

  return (
    <div
      className={
        'p-5 text-white h-[100dvh] flex flex-col items-center calendar-date-selector-container relative'
      }
    >
      {/* header */}
      <div className="w-full">
        <ViewListHeader />
      </div>

      {/* calendar container */}
      <CalendarDaily queryDate={searchParams?.date} />
      <BgGradient />
    </div>
  );
};

export default HomeDaily;
