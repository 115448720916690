import axios from 'axios';
import { TokenResponse } from 'modules/auth/types/types';
import tokenService from 'modules/auth/utils/tokenUtils';

// get the base URL from the environment variables
const baseURL = process.env.REACT_APP_BASE_URL;

// get the auth token
const getAuthToken = () => tokenService.getToken();

// get the refresh token
const getRefreshToken = () => tokenService.getRefreshToken();

// function to save the tokens
const saveTokens = (accessToken: string, refreshToken: string) => {
  tokenService.setToken(accessToken);
  tokenService.setRefreshToken(refreshToken);
};

// function to refresh the auth token
const refreshAuthToken = async () => {
  try {
    const refreshToken = getRefreshToken();
    const response = await axios.post(`${baseURL}/auth/refresh-tokens`, {
      refreshToken: refreshToken,
    });
    const tokenData: TokenResponse = response.data;
    const accessToken = tokenData.access?.token;
    const newRefreshToken = tokenData.refresh?.token;
    if (!accessToken || !newRefreshToken) {
      throw new Error('Invalid tokens received from server');
    }
    saveTokens(accessToken, newRefreshToken);
    return accessToken;
  } catch (error) {
    throw error;
  }
};

// create an Axios instance
const apiClient = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// interceptor to attach the auth token to every request
apiClient.interceptors.request.use(
  (config) => {
    const token = getAuthToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

// response interceptor to handle token expiration and refresh
apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    // check if the error is due to an expired token and if the request has not been retried
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const newToken = await refreshAuthToken();
        apiClient.defaults.headers['Authorization'] = `Bearer ${newToken}`;
        originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
      } catch (error) {
        tokenService.clearTokens();
      }
      return axios(originalRequest);
    }
    return Promise.reject(error);
  },
);

export default apiClient;
