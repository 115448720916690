import { createApi } from '@reduxjs/toolkit/query/react';
import type {
  IUser,
  IUserForEdit,
  IUserForEditRequest,
} from 'modules/auth/types/types';
import baseQueryWithReauth from 'services/mutationInterceptor';

export const usersApiSlice = createApi({
  reducerPath: 'usersApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Users'],
  endpoints: (builder) => ({
    createUser: builder.mutation<IUser, IUser>({
      query: (body) => ({
        url: '/users',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Users'],
    }),
    getUsers: builder.query<IUser[], void>({
      query: () => ({
        url: '/users',
        method: 'GET',
      }),
      providesTags: ['Users'],
    }),
    getUser: builder.query<IUserForEdit, string>({
      query: (userId) => ({
        url: `/users/${userId}`,
        method: 'GET',
      }),
      providesTags: (result, error, id) => [{ type: 'Users', id }],
    }),
    updateUser: builder.mutation<IUser, { data: IUser }>({
      query: ({ data }) => ({
        url: '/users',
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Users'],
    }),
    updateUserSettings: builder.mutation<IUser, { data: IUserForEditRequest }>({
      query: ({ data }) => ({
        url: '/users',
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Users'],
    }),
    deleteUser: builder.mutation<void, string>({
      query: (userId) => ({
        url: `/users/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Users'],
    }),
    getUsernameSuggestions: builder.query<
      { suggestions: string[] | undefined; matches: boolean },
      { baseUsername: string }
    >({
      query: ({ baseUsername }) => ({
        url: '/users/username-suggestions',
        method: 'GET',
        params: { baseUsername },
      }),
    }),
  }),
});

export const {
  useCreateUserMutation,
  useGetUsersQuery,
  useGetUserQuery,
  useUpdateUserMutation,
  useUpdateUserSettingsMutation,
  useDeleteUserMutation,
  useLazyGetUsernameSuggestionsQuery,
} = usersApiSlice;
