import { IUser } from 'modules/auth/types/types';

export enum ActivityType {
  EVENT = 'event',
  FRIEND = 'friend',
}

export enum FriendActivityType {
  ACCEPT_EVENT = 'accept_event',
  REJECT_EVENT = 'reject_event',
  COMMENT_EVENT = 'comment_event',
}

export type IFriendActivity = {
  id: string;
  type: ActivityType.FRIEND;
  by: Pick<IUser, 'id' | 'name' | 'username' | 'photoURL'>;
  friendActivityType: FriendActivityType;
  eventEndDate?: Date | null;
  eventId?: string | null;
};

export type IEventActivity = {
  id: string;
  type: ActivityType.EVENT;
  event: {
    id: string;
    title: string;
    emoji: string;
  };
};

export type IUserActivity = IFriendActivity | IEventActivity;

export interface IActivityPoll {
  id: string;
  title: string;
  by: Pick<IUser, 'id' | 'name' | 'username' | 'photoURL'>;
}

export interface IActivityInvite {
  id: string;
  type: 'now' | 'later';
  by: Pick<IUser, 'id' | 'name' | 'username' | 'photoURL'>;
}
