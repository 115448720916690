export enum EventType {
  MEET_NOW = 'meet_now',
  MEET_LATER = 'meet_later',
  POLL = 'poll',
}

export interface IEventTimeRange {
  startTime: string;
  endTime: string;
  date: string;
}

interface IEventDataInit {
  is_public: boolean;
  emoji: string;
  title: string;
  location: string;
  description: string;
  attendees: string[];
}

export interface IMeetNow extends IEventDataInit {
  type: EventType.MEET_NOW;
  time: string;
  date: string;
}

export interface IMeetLater extends IEventDataInit {
  type: EventType.MEET_LATER;
  timeRange: IEventTimeRange;
}

export interface IMeetPoll extends IEventDataInit {
  type: EventType.POLL;
  times: IEventTimeRange[];
}

export type IEventData = IMeetNow | IMeetLater | IMeetPoll;

export enum ReceiveMeetResponseState {
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  MAYBE = 'MAYBE',
  NOT_RESPONDED = 'NOT_RESPONDED',
  EXPIRED = 'EXPIRED',
}

export enum ReceiveMeetResponseType {
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  MAYBE = 'MAYBE',
}

export enum ReceivePublicMeetResponseState {
  HIDDEN = 'hidden',
  NOT_RESPONDED = 'not_responded',
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
  MAYBE = 'maybe',
  SHOW_POPUP = 'SHOW_POPUP',
  ENDED = 'ended',
}

export enum ReceivePublicPollResponseState {
  HIDDEN = 'hidden',
  NOT_RESPONDED = 'not_responded',
  ACCEPTED = 'accepted',
  SHOW_POPUP = 'SHOW_POPUP',
  ENDED = 'ended',
}
