import { createApi } from '@reduxjs/toolkit/query/react';
import { ITimeBlock } from 'modules/auth/types/types';
import baseQueryWithReauth from 'services/mutationInterceptor';
import {
  AddEventCommentRequest,
  CreateEventRequest,
  EditEventCommentRequest,
  EventResponse,
  GuestEventSubscribeNotificationRequest,
  GuestEventSubscribeNotificationResponse,
  GuestRespondEventRequest,
  GuestRespondEventResponse,
  RespondEventRequest,
  ShareEventRequest,
} from 'types';

export const eventsApiSlice = createApi({
  reducerPath: 'eventsApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Events', 'TimeOff'],
  endpoints: (builder) => ({
    createEvent: builder.mutation<any, CreateEventRequest>({
      query: (data) => ({
        url: '/event',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Events'],
    }),
    respondEvent: builder.mutation<any, RespondEventRequest>({
      query: (data) => ({
        url: '/event/respond',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Events'],
    }),
    // send guest response to event. the response contains the user flag
    guestRespondEvent: builder.mutation<GuestRespondEventResponse, GuestRespondEventRequest>({
      query: (data) => ({
        url: `/public/event/${data.eventId}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Events'],
    }),
    guestSubscribeNotification: builder.mutation<GuestEventSubscribeNotificationResponse, GuestEventSubscribeNotificationRequest>({
      query: (data) => ({
        url: `/event/contact`,
        method: 'PUT',
        body: data,
      }),      
    }),
    shareEvent: builder.mutation<any, ShareEventRequest>({
      query: (data) => ({
        url: '/sms/send',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Events'],
    }),
    addEventComment: builder.mutation<any, AddEventCommentRequest>({
      query: (data) => ({
        url: '/event/comment',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error, { eventId }) => [{ type: 'Events', id: eventId }],
    }),
    // TODO: use after implementation of BE
    editEventComment: builder.mutation<any, EditEventCommentRequest>({
      query: (data) => ({
        url: `/event/comment/${data.commentId}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: (result, error, { eventId }) => [{ type: 'Events', id: eventId }],
    }),
    // TODO: use after implementation of BE
    deleteEventComment: builder.mutation<any, { eventId: string; commentId: string }>({
      query: ({ eventId, commentId }) => ({
        url: `/event/comment/${commentId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { eventId }) => [{ type: 'Events', id: eventId }],
    }),
    getEvent: builder.query<any, string>({
      query: (id) => ({
        url: `/event/${id}`,
        method: 'GET',
      }),
      transformResponse: (response) => {
        console.log('Event response:', response);
        return response;
      },
      providesTags: (result, error, id) => [{ type: 'Events', id }],
    }),
    getEvents: builder.query<any, void>({
      query: () => ({
        url: `/event`,
        method: 'GET',
      }),
      transformResponse: (response) => {
        console.log('Event response:', response);
        return response;
      },
      providesTags: ['Events'],
    }),
    getEventsByStatus: builder.query<any, number>({
      query: (status) => ({
        url: `/event/status/${status}`,
        method: 'GET',
      }),
      providesTags: (result, error, status) => [{ type: 'Events', status }],
    }),
    updateEvent: builder.mutation<any, EventResponse>({
      query: (data) => ({
        url: '/event',
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Events'],
    }),
    updateEventStatus: builder.mutation<number, EventResponse>({
      query: (status) => ({
        url: '/event/status',
        method: 'PUT',
        body: status,
      }),
      invalidatesTags: ['Events'],
    }),
    deleteEvent: builder.mutation<any, EventResponse>({
      query: (id: string) => ({
        url: `/event/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Events'],
    }),
    getAllPendingEvents: builder.query<any, number>({
      query: (page) => ({
        url: `event/pending?page=${page}&history=true`,
        method: 'GET',
      }),
      providesTags: (result, error, page) => [
        { type: 'Events', status: 'Pending', page },
      ],
    }),
    getPendingEvents: builder.query<any, { page: number; isPast?: boolean }>({
      query: ({ page, isPast }) => ({
        url: `event/pending?page=${page}${isPast === true ? '&history=true' : isPast === false ? '&history=false' : ''}`,
        method: 'GET',
      }),
      providesTags: (result, error, props) => [
        { type: 'Events', isPast: props.isPast, page: props.page },
      ],
    }),
    getMyEvents: builder.query<any, { status: number; page: number }>({
      query: ({ status, page }) => ({
        url: `event/my-events/${status}?page=${page}&history=false`,
        method: 'GET',
      }),
      providesTags: (result, error, props) => [
        {
          type: 'Events',
          variant: 'MyEvents',
          status: props.status,
          page: props.page,
        },
      ],
    }),
    getAllEvents: builder.query<any, { page: number; history: string }>({
      query: ({ page, history }) => ({
        url: `event/all-events?page=${page}&history=${history}`,
        method: 'GET',
      }),

      providesTags: (result, error, props) => [
        {
          type: 'Events',
          variant: 'AllEvents',
          isPast: props.history,
          page: props.page,
        },
      ],
    }),
    getEventByDateRange: builder.query<
      any,
      { startDate: string; endDate: string }
    >({
      query: ({ startDate, endDate }) => ({
        url: `event/range?startDate=${startDate}&endDate=${endDate}`,
        method: 'GET',
      }),
      providesTags: (result, error, props) => [
        {
          type: 'Events',
          startDate: props.startDate,
          endDate: props.endDate,
        },
      ],
    }),
    createTimeOff: builder.mutation<any, ITimeBlock>({
      query: (body) => ({
        url: '/time-off',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['TimeOff'],
    }),
    getTimeOffByDateRange: builder.query<
      any,
      { startDate: string; endDate: string }
    >({
      query: ({ startDate, endDate }) => ({
        url: `time-off?startDate=${startDate}&endDate=${endDate}`,
        method: 'GET',
      }),
      providesTags: (result, error, props) => [
        {
          type: 'TimeOff',
          startDate: props.startDate,
          endDate: props.endDate,
        },
      ],
    }),
    deleteTimeOff: builder.mutation<any, { id: string }>({
      query: ({ id }) => ({
        url: `/time-off/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['TimeOff'],
    }),
    checkTimeAvaliability: builder.query<
      any,
      { startDateTime: string; endDateTime: string }
    >({
      query: ({ startDateTime, endDateTime }) => ({
        url: `event/check-availability?startDateTime=${startDateTime}&endDateTime=${endDateTime}`,
        method: 'GET',
      }),
      providesTags: (result, error, props) => [
        {
          type: 'Events',
          startDate: props.startDateTime,
          endDate: props.endDateTime,
        },
      ],
    }),
  }),
});

export const {
  useCreateEventMutation,
  useRespondEventMutation,
  useGuestRespondEventMutation,
  useGuestSubscribeNotificationMutation,
  useShareEventMutation,
  useAddEventCommentMutation,
  useEditEventCommentMutation,
  useDeleteEventCommentMutation,
  useGetEventQuery,
  useGetEventsQuery,
  useGetEventsByStatusQuery,
  useUpdateEventMutation,
  useUpdateEventStatusMutation,
  useDeleteEventMutation,
  useGetAllPendingEventsQuery,
  useGetPendingEventsQuery,
  useGetMyEventsQuery,
  useGetAllEventsQuery,
  useGetEventByDateRangeQuery,
  useGetTimeOffByDateRangeQuery,
  useCreateTimeOffMutation,
  useDeleteTimeOffMutation,
  useCheckTimeAvaliabilityQuery,
} = eventsApiSlice;
