import ProfileAvatar from 'components/avatar/ProfileAvatar';
import { Separator } from 'components/ui/separator';
import { cn } from 'utils/helpers';
import { Contact } from 'modules/profile/types/types';
import React, { useMemo } from 'react';

type ContactsListProps = {
  userContacts: Contact[];
  selectedContacts: Contact[];
  currUserId: string;
  searchValue: string;
  onContactSelect: (contact: Contact) => void;
  onSearchChange: (value: string) => void;
  show: boolean;
};

const ContactsList: React.FC<ContactsListProps> = (props) => {
  const displayList = useMemo(() => {
    console.log(props.currUserId);
    if (props.searchValue === '') {
      return props.userContacts.filter(
        (contact) =>
          contact.userId !== props.currUserId &&
          !props.selectedContacts.some(
            (selected) => selected._id === contact._id,
          ),
      );
    }

    const searchValue = props.searchValue.toLowerCase().trim();

    return props.userContacts
      .filter((contact) => contact.name.toLowerCase().includes(searchValue))
      .filter(
        (contact) =>
          contact.userId !== props.currUserId &&
          !props.selectedContacts.some(
            (selected) => selected._id === contact._id,
          ),
      );
  }, [
    props.searchValue,
    props.selectedContacts,
    props.currUserId,
    props.userContacts,
  ]);

  return (
    <div
      className={cn(
        'relative',
        (!props.show || displayList.length === 0) && 'hidden',
      )}
    >
      <div
        className={cn(
          'absolute z-10 bg-gray-800 rounded-xl w-full max-h-32 h-auto p-4 py-2 overflow-auto ',
          props.userContacts.length === 0 && 'overflow-visible text-center',
        )}
      >
        {props.userContacts.length === 0 ? (
          <div className="text-sm px-2 py-4">
            <div className="font-medium">No contacts added yet.</div>
            <div className="font-light pt-2 opacity-85">
              You can create a public link or import your contacts to Caleido
            </div>
          </div>
        ) : (
          displayList
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((contact, n) => (
              <React.Fragment key={contact._id}>
                <div
                  className="flex items-center justify-between py-2"
                  onClick={() => props.onContactSelect(contact)}
                >
                  <div className="flex items-center gap-x-2">
                    <ProfileAvatar
                      size={22}
                      url={`${process.env.REACT_APP_BASE_URL}/users/profile-pic/${contact.userId}`}
                      hideBoarder
                    />
                    <div className="flex flex-col text-sm font-medium">
                      {contact.name}
                    </div>
                  </div>
                </div>
                {n !== displayList.length - 1 && (
                  <Separator className="bg-white/20 h-[0.8px]" />
                )}
              </React.Fragment>
            ))
        )}
      </div>
    </div>
  );
};

export default ContactsList;
