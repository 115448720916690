import {
  EventType,
  ReceiveMeetResponseState,
} from 'modules/letsMeet/types/types';
import MeetResponseAfterBody from './meetResponseAfterBody';

type MeetPollResponseAfter = {
  type: EventType.POLL;
};
type MeetNowAndLaterResponseAfter = {
  type: EventType.MEET_NOW | EventType.MEET_LATER;
  status: ReceiveMeetResponseState;
};

type MeetResponseAfterProps = (
  | MeetPollResponseAfter
  | MeetNowAndLaterResponseAfter
) & {
  onRespondChange: () => void;
  bottomSheet?: boolean;
  setBottomSheet?: any;
};

const messages = {
  [ReceiveMeetResponseState.ACCEPTED]: { emoji: '🎉', title: 'You are going!' },
  [ReceiveMeetResponseState.DECLINED]: {
    emoji: '😓',
    title: 'Sorry you can’t make it',
  },
  [ReceiveMeetResponseState.MAYBE]: {
    emoji: '🙌',
    title: 'You’re not sure',
  },
  // for ts and never going to happen
  [ReceiveMeetResponseState.NOT_RESPONDED]: {
    emoji: '🤔',
    title: 'You haven’t responded yet',
  },
  [ReceiveMeetResponseState.EXPIRED]: {
    emoji: '🕒',
    title: 'This event has expired',
  },
};

const MeetResponseAfter: React.FC<MeetResponseAfterProps> = (props) => {
  if (props.type === EventType.POLL)
    return (
      <MeetResponseAfterBody
        emoji="🙌"
        title="Thanks for responding"
        onRespondChange={props.onRespondChange}
        bottomSheet={props.bottomSheet}
        setBottomSheet={props.setBottomSheet}
      />
    );

  if (props.status === ReceiveMeetResponseState.EXPIRED) return null;

  return (
    <MeetResponseAfterBody
      emoji={messages[props.status].emoji}
      title={messages[props.status].title}
      onRespondChange={props.onRespondChange}
      bottomSheet={props.bottomSheet}
      setBottomSheet={props.setBottomSheet}
    />
  );
};

export default MeetResponseAfter;
