// disable eslint for this file
/* eslint-disable */

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import type { IAvailabilityPeriod } from 'modules/profile/types/types';
import { v4 as uuidv4 } from 'uuid';

dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);

export class AvailabilityPeriodModel {
  id: string;
  allDay: boolean;
  start: {
    dateTime: string;
    timeZone: string;
  };
  end?: {
    dateTime: string;
    timeZone: string;
  };

  constructor(
    slots: IAvailabilityPeriod[] = [],
    allDay?: boolean,
    id?: string | null | undefined,
    startDateTime?: string,
    endDateTime?: string,
    startTimeZone: string = new Intl.DateTimeFormat().resolvedOptions()
      .timeZone,
    endTimeZone: string = new Intl.DateTimeFormat().resolvedOptions().timeZone,
  ) {
    this.id = id || uuidv4();
    this.start = {
      dateTime: startDateTime || "",
      timeZone: startTimeZone,
    };

    this.end = endDateTime
      ? {
          dateTime: endDateTime,
          timeZone: endTimeZone,
        }
      : undefined;

    this.allDay = allDay || false;
  }

  private getCurrentTimeWithRound(timeZone: string, roundBy = 15): string {
    const currentTime = dayjs().tz(timeZone);
    const roundMinutes = Math.ceil(currentTime.minute() / roundBy) * roundBy;
    const roundedTime = currentTime
      .minute(roundMinutes)
      .second(0)
      .millisecond(0);

    return roundedTime.format();
  }

  private findNextAvailableSlot(
    slots: IAvailabilityPeriod[],
    today: string,
    timeZone: string,
  ): { start: string; end: string } {
    const maxDuration = dayjs.duration(2, 'hours');
    const minDuration = dayjs.duration(30, 'minutes');

    let potentialStart = dayjs.tz(`${today}T00:00:00`, timeZone);
    let potentialEnd = potentialStart.add(maxDuration);

    while (true) {
      if (
        !this.checkOverlap(
          { dateTime: potentialStart.format(), timeZone },
          { dateTime: potentialEnd.format(), timeZone },
          slots,
        )
      ) {
        break;
      }

      potentialStart = potentialEnd;
      potentialEnd = potentialStart.add(maxDuration);

      if (potentialEnd.date() !== potentialStart.date()) {
        potentialEnd = dayjs.tz(`${today}T23:59:59`, timeZone);
        const durationLeft = potentialEnd.diff(potentialStart);

        if (durationLeft >= minDuration.asMilliseconds()) {
          if (durationLeft < maxDuration.asMilliseconds()) {
            potentialEnd = potentialStart.add(dayjs.duration(durationLeft));
          } else {
            potentialEnd = potentialStart.add(maxDuration);
          }
          break;
        } else {
          throw new Error('No available slots for the rest of the day.');
        }
      }

      if (potentialEnd.hour() === 23 && potentialEnd.minute() === 59) {
        const lastSlotEnd = dayjs.tz(`${today}T23:59:59`, timeZone);
        if (
          !this.checkOverlap(
            { dateTime: potentialStart.format(), timeZone },
            { dateTime: lastSlotEnd.format(), timeZone },
            slots,
          )
        ) {
          potentialEnd = lastSlotEnd;
          break;
        }
      }
    }

    return {
      start: potentialStart.format(),
      end: potentialEnd.format(),
    };
  }

  private checkOverlap(
    start: { dateTime: string; timeZone: string },
    end: { dateTime: string; timeZone: string },
    slots: IAvailabilityPeriod[],
  ): boolean {
    const today = dayjs().format('YYYY-MM-DD');
    const startMoment = dayjs.tz(
      `${today}T${dayjs(start.dateTime).format('HH:mm')}`,
      start.timeZone,
    );
    const endMoment = dayjs.tz(
      `${today}T${dayjs(end.dateTime).format('HH:mm')}`,
      end.timeZone,
    );

    return slots.some((slot) => {
      if (!slot.end) return false;
      const slotStart = dayjs.tz(
        `${today}T${dayjs(slot.start.dateTime).format('HH:mm')}`,
        slot.start.timeZone,
      );
      const slotEnd = dayjs.tz(
        `${today}T${dayjs(slot.end.dateTime).format('HH:mm')}`,
        slot.end.timeZone,
      );
      return startMoment.isBefore(slotEnd) && endMoment.isAfter(slotStart);
    });
  }

  private limitSlotRange(): void {
    if (!this.end) return;

    const maxDuration = dayjs.duration(2, 'hours');
    const minDuration = dayjs.duration(30, 'minutes');
    const today = dayjs().format('YYYY-MM-DD');
    const start = dayjs.tz(
      `${today}T${dayjs(this.start.dateTime).format('HH:mm')}`,
      this.start.timeZone,
    );
    const end = dayjs.tz(
      `${today}T${dayjs(this.end.dateTime).format('HH:mm')}`,
      this.end.timeZone,
    );
    const duration = end.diff(start);

    if (duration > maxDuration.asMilliseconds()) {
      this.end.dateTime = start.add(maxDuration).format();
    } else if (duration < minDuration.asMilliseconds()) {
      this.end.dateTime = start.add(minDuration).format();
    }
  }

  public updateTimes(start: string, end: string): void {
    this.start.dateTime = start;
    if (!this.end)
      this.end = {
        dateTime: end,
        timeZone: this.start.timeZone,
      };
    else this.end.dateTime = end;
  }

  public static allDay(): AvailabilityPeriodModel {
    return new AvailabilityPeriodModel(
      [],
      true,
      uuidv4(),
      dayjs().startOf('day').format(),
      dayjs().endOf('day').format(),
    );
  }
}
