import { ChevronRight } from 'lucide-react';
import { cn } from 'utils/helpers';
import MainButton from 'modules/auth/components/MainButton';
import AuthLayout from 'modules/auth/layouts/AuthLayout';
import { Text } from 'components/text/primary/Text';
import { SecondaryText } from 'components/text/secondary/Text';
import LinkButton from 'components/buttons/main/LinkButton';

import styles from './onboarding.module.css';
import { Link } from 'react-router-dom';
import useSamsungS23Detection from 'hooks/isLatestSamsung';

const Start = () => {
  const { isSamsungS23 } = useSamsungS23Detection();

  return (
    <AuthLayout className={cn('justify-end', styles.background)}>
      <div className="flex flex-col items-center justify-end w-full max-w-xs my-8 min-w-64">
        <h1 className="Westmount text-5xl font-normal leading-normal tracking-[-3.36px]">
          CALEIDO
        </h1>

        <h2 className="Westmount text-xl font-normal leading-normal tracking-[-1.4px]">
          THE SOCIAL CALENDAR
        </h2>
      </div>
      <div className="flex flex-col items-center justify-end w-full max-w-xs mb-4 min-w-64">
        <MainButton
          type="secondary"
          to={'/auth/create'}
          icon={<ChevronRight color="#fff" />}
          className="flex items-center justify-between w-full px-8 py-6 bg-white border rounded-full border-opacity-20 bg-opacity-10 border-slate-300"
        >
          <Text size="small" className="text-base">
            Start your journey
          </Text>
        </MainButton>
      </div>
      <div className="inline-flex items-baseline justify-center w-full mb-6">
        <Text size="extra-small" className="text-sm">
          {' '}
          Already have an account?
        </Text>{' '}
        <LinkButton to="/auth/login">
          <Text
            size="extra-small"
            className="ml-1 font-semibold cursor-pointer text-sm"
          >
            Log in
          </Text>
        </LinkButton>
      </div>
      <div className={`flex w-full justify-center space-x-2 ${isSamsungS23 ? "mb-6" : ""}`}>
          <Link to="/terms" className="text-white text-sm">Terms</Link>
          <span className="text-white text-sm">|</span>
          <Link to="/privacy" className="text-white text-sm">Privacy</Link>
      </div>
    </AuthLayout>
  );
};

export default Start;
