import { EventType } from 'modules/home/types/EventType';

export type EventCardItemProps = {
  id?: string;
  variant: EventType;
  eventData: EventItem;
  additionalVariants?: VariantData;
  origin?: string;
};

export type EventItem = {
  id: string | undefined;
  pollData: PollData | null;
  _id: string;
  userId: string;
  organizer: string;
  organizerPhoto?: string;
  activity: string;
  emoji: string;
  location: string;
  contacts: Contact[];
  details: string;
  publicEvent: boolean;
  type: string;
  start: string;
  end: string;
  status: number;
  timestamp: Date | null | string;
  __v: number;
  variant?:
    | 'past'
    | 'new'
    | 'accepted'
    | 'ongoing'
    | 'maybe'
    | 'poll'
    | 'draft'
    | 'blocked';
};

export type Contact = {
  name: string;
  phoneNumber: string;
  status: number;
  organizer?: number;
};

export type PollData = {
  checkedTimes: any[];
};

export type EventObject = {
  event: EventItem;
  variantData: VariantData;
};

export type VariantData = {
  isPast: boolean;
  isAccepted: boolean;
  isPending: boolean;
  isOngoing: boolean;
  isUpcoming: boolean;
  isPoll: boolean;
  isDraft: boolean;
  isMaybe: boolean;
};

export interface TimeOffObject {
  message: string;
  timeOffRecords: TimeOffRecord[];
}

export interface TimeOffRecord {
  _id: string;
  userId: string;
  start: Date;
  end: Date;
  timestamp: Date;
  __v: number;
}

export const sampleEvents: EventObject[] = [{
  event: {
    pollData: null,
    _id: '001',
    userId: 'user1',
    organizer: 'John Doe',
    activity: 'Past Event Activity',
    emoji: '🎉',
    location: 'New York',
    contacts: [{ name: 'Alice', phoneNumber: '123456789', status: 1 }],
    details: 'This event was held last month.',
    publicEvent: true,
    type: 'Social',
    start: '2024-06-01T10:00:00Z',
    end: '2024-06-01T12:00:00Z',
    status: 1,
    timestamp: new Date().toISOString(),
    __v: 0,
    id: undefined,
    variant: 'past',
  },
  variantData: {
    isPast: true,
    isAccepted: false,
    isPending: false,
    isOngoing: false,
    isUpcoming: false,
    isPoll: false,
    isDraft: false,
    isMaybe: false,
  },
}];

export const sampleUpEvents: EventObject[] =  [
  {
    event: {
      pollData: null,
      _id: '001',
      userId: 'user1',
      organizer: 'John Doe',
      activity: 'Past Event Activity',
      emoji: '🎉',
      location: 'New York',
      contacts: [{ name: 'Alice', phoneNumber: '123456789', status: 1 }],
      details: 'This event was held last month.',
      publicEvent: true,
      type: 'Social',
      start: '2024-06-01T10:00:00Z',
      end: '2024-06-01T12:00:00Z',
      status: 1,
      timestamp: new Date().toISOString(),
      __v: 0,
      id: undefined,
      variant: 'past',
    },
    variantData: {
      isPast: true,
      isAccepted: false,
      isPending: false,
      isOngoing: false,
      isUpcoming: false,
      isPoll: false,
      isDraft: false,
      isMaybe: false,
    },
  }
]

// sample event to replicate with all variations
const templateEvent: EventObject = {
  event: {
    id: '66bf125b9c1d0a02bd2716cd',
    pollData: {
      checkedTimes: [],
    },
    _id: '66bf125b9c1d0a02bd2716cd',
    userId: '66b77f6183a7f732f4823351',
    organizer: 'Sachintha',
    organizerPhoto:
      'https://caleido-storage.s3.eu-north-1.amazonaws.com/66b77f6183a7f732f4823351',
    activity: 'Daily StandUp',
    emoji: '📺',
    location: 'WireApps, Bath Road, Hounslow, UK',
    contacts: [
      {
        name: 'Sachintha',
        phoneNumber: '+94704040302',
        status: 1,
        organizer: 1,
      },
    ],
    details: 'Meeting Daily',
    publicEvent: false,
    type: 'now',
    start: '2024-08-16T08:48:26.608Z',
    end: '2024-08-15T23:30:00.000Z',
    status: 1,
    timestamp: '2024-08-16T08:48:27.010Z',
    __v: 0,
  },
  variantData: {
    isPast: true,
    isAccepted: true,
    isPending: false,
    isOngoing: false,
    isUpcoming: false,
    isPoll: false,
    isDraft: false,
    isMaybe: false,
  },
};

export const generateEventsForVariant = (startDate: string): EventObject[] => {
  const start = new Date(startDate);
  const events: EventObject[] = [];
  const days = 7;

  // Array of variant properties to toggle
  const variantKeys: (keyof VariantData)[] = [
    'isPast',
    'isAccepted',
    'isPending',
    'isOngoing',
    'isUpcoming',
    'isPoll',
    'isDraft',
  ];

  for (let i = 0; i < days; i++) {
    // Create a deep copy of the templateEvent to avoid modifying the original object
    const newEvent: EventObject = JSON.parse(JSON.stringify(templateEvent));

    // Adjust the start and end times
    newEvent.event.start = new Date(
      start.getTime() + i * 24 * 60 * 60 * 1000 + i * 60 * 60 * 1000,
    ).toISOString();
    newEvent.event.end = new Date(
      start.getTime() + i * 24 * 60 * 60 * 1000 + i * 2 * 60 * 60 * 1000,
    ).toISOString();

    // Toggle the variant properties one by one
    variantKeys.forEach((key, index) => {
      newEvent.variantData[key] = index === i % variantKeys.length;
    });

    // Add the new event to the events array
    events.push(newEvent);
  }

  return events;
};

export const generateBlockedEvents = (startDate: string): TimeOffRecord[] => {
  const start = new Date(startDate);
  const timeOffs: TimeOffObject[] = [];
  const days = 7;

  for (let i = 0; i < days; i++) {
    const newTimeOff: TimeOffObject = {
      message: 'Blocked',
      timeOffRecords: [
        {
          _id: `blocked-${i}`,
          userId: 'system',
          start: new Date(
            start.getTime() + i * 24 * 60 * 60 * 1000 + i * 60 * 60 * 1000,
          ),
          end: new Date(
            start.getTime() + i * 24 * 60 * 60 * 1000 + i * 2 * 60 * 60 * 1000,
          ),
          timestamp: new Date(),
          __v: 0,
        },
      ],
    };

    timeOffs.push(newTimeOff);
  }

  return timeOffs.map((t) => t.timeOffRecords[0]).flatMap((t) => t);
};
