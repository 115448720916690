import { ReactNode } from 'react';

interface TextProps {
  children: ReactNode;
  size: 'small' | 'medium' | 'large' | 'logo' | 'extra-small';
  className?: string;
}

export const SecondaryText = ({ children, size, className }: TextProps) => {
  let wrapperClassName = `font-serif tracking-widest text-white ${className}`;

  switch (size) {
    case 'logo':
      wrapperClassName += ' font-semibold text-4xl';
      break;
    case 'large':
      wrapperClassName += ' font-semibold text-xl';
      break;
    case 'medium':
      wrapperClassName += ' text-lg';
      break;
    case 'small':
      wrapperClassName += 'text-sm';
      break;
    case 'extra-small':
      wrapperClassName += ' text-xs';
      break;
  }

  return <div className={wrapperClassName}>{children}</div>;
};
