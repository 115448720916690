import { format, isSameDay } from 'date-fns';
import { CalendarHeaderDateLabelProps } from './CalendarHeaderDateLabel.types';
import { useNavigate } from 'react-router-dom';

const CalendarHeaderDateLabel = (props: CalendarHeaderDateLabelProps) => {
  const { label, date, isOffRange, today, shouldNavigate } = props;

  const navigate = useNavigate();
  return (
    <div
      style={{}}
      onClick={() => {
        // navigate to daily view for the selected date
        navigate(`/home/daily?date=${format(date, 'yyyy-MM-dd')}`);
      }}
      className="flex flex-col items-center h-auto space-y-[10px] w-[33px]"
    >
      <div className="text-white text-[8px] font-light leading-normal font-sofia-extralight">
        {format(date, 'EEE')}
      </div>
      {/* use .date-label-number-selected for selected date */}
      <div
        className={`rounded-full w-8 h-8 flex justify-center items-center font-normal text-[13.8px] date-label-number font-manrope  ${isSameDay(today, date) ? 'date-label-number-today' : ''}`}
      >
        <span>{format(date, 'dd')}</span>
      </div>
    </div>
  );
};

export default CalendarHeaderDateLabel;
