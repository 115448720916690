import React from 'react';

interface EventIconNoBgProps {
  icon: string;
  active?: boolean;
  size?: 'medium' | 'large';
}

export default function EventIconNoBg({
  icon,
  active,
  size,
}: EventIconNoBgProps) {
  const container = {
    medium: '48',
    large: '116',
  }[size || 'large'];

  const fontSize = {
    medium: '28',
    large: '72',
  }[size || 'large'];

  return (
    <div
      className={`rounded-full flex justify-center items-center`}
      style={{
        //   backgroundColor: background,
        height: `${container}px`,
        width: `${container}px`,
      }}
    >
      <span
        className="translate-x-7"
        style={{
          fontSize: `${fontSize}px`,
        }}
      >
        {icon}
      </span>
    </div>
  );
}
