import { RootState } from 'app/store';
import { ReactComponent as TrickCircle } from 'assets/images/common/tick-circle.svg';
import classNames from 'classnames';
import { useAppSelector } from 'hooks/useAppSelector';
import styles from './CalendarEventWeekCard.module.css';
import { CalendarEventWeekCardProps } from './CalendarEventWeekCard.types';

export const CalendarEventWeekCard = ({
  id,
  variant,
  eventData,
  view,
  variantData,
}: CalendarEventWeekCardProps) => {
  // get user from store
  const user = useAppSelector((state: RootState) => state.auth.user);
  const isByYou =
    eventData?.userId && user?.id && eventData?.userId == user?.id;

  const isBlocked = variant === 'blocked';
  const isPoll = variant === 'poll';
  const isDraft = variant === 'draft';
  const isPast = variant === 'past';
  const isAccepted = variant === 'accepted';
  const isResponse = variant === 'response' && !isByYou;
  const isPending  = variant === 'pending' || variantData?.isPending;
  const isPendingPoll =
    (variant === 'response' || isAccepted) && eventData?.type === 'poll';

  const hasFooter = ![
    'past',
    'accepted',
    'ongoing',
    'maybe',
    'blocked',
    'new',
    'poll',
  ].includes(variant);


  const containerClasses = classNames(
    '!bg-white !bg-opacity-20 backdrop-blur-[30px]',
    { 'rounded-[8px]': !isBlocked },
    { 'diagonal-lines ': isBlocked },
    {
      'border-l-[3px] border-[#C94A2E] calendar-card-left-border': [
        'poll',
        'response',
      ].includes(variant),
    },
    {
      [styles.eventCardBackground]: variant === 'maybe',
    },
    {
      '!filter grayscale': variant === 'past',
    },
  );

  return isBlocked ? (
    <div
      id={id}
      className={`event-card-blocked h-full w-full flex flex-col items-start content-start`}
    >
      <p className="text-[10px]  p-1 w-full overflow-hidden whitespace-nowrap overflow-ellipsis font-sofia-medium">
        {eventData?.activity ?? ''}
      </p>
    </div>
  ) : (
    <div id={id} className={`${containerClasses} h-full w-full relative z-20`}>
      {/* Header */}
      <div className="pl-1 pt-2 pb-1 font-normal flex flex-col items-start justify-start space-y-1 overflow-hidden">
        <div>{isAccepted && <TrickCircle className="rounded-full" />}</div>
        <div className="text-[10px] w-full font-semibold leading-normal overflow-hidden whitespace-nowrap overflow-ellipsis">
          {eventData?.activity}
        </div>
      </div>
    </div>
  );
};
