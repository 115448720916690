import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { AxiosError, AxiosRequestConfig } from 'axios';
import tokenService from 'modules/auth/utils/tokenUtils';
import apiClient from 'services/apiClient';
import { CustomApiError } from 'services/customApiError';

const BASE_URL = process.env.REACT_APP_BASE_URL;

/*
 * Base query function that will be used for all API calls by default by the RTK Query API.
 * This does not handle token refresh. It just attaches the auth token to the request.
 */
export const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers) => {
    const token = tokenService.getToken();
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

/*
 * Replace the baseQuery function in the api object with a custom function that will handle token refresh.
 * This uses axios to make the API calls and uses axios interceptors to attach the auth token to every request and handle token expiration and refresh. *
 */
export const axiosBaseQuery: BaseQueryFn<
  unknown,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {  
  const { url, method, body: data,params } = args as FetchArgs;
  try {
    const result = await apiClient({
      url,
      method,
      data,
      params,
    });
    return { data: result.data };
  } catch (axiosError) {
    throw new CustomApiError(axiosError as AxiosError);
  }
};

export default axiosBaseQuery;
